import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { changeCreateContactModalStatus, createContact } from '../../features/ContactSlice'
const CreateContactPopupModal = () => {
    const dispatch = useDispatch()
    const isShow = useSelector((state)=> state.contact.isShowCreateContactModal)


  return (
    <div>
        <Modal show={isShow}
            size='lg'
         >
            <Modal.Header>
                <h3>Create Contact</h3>
                <div className='btn create-tender-package-button btn-outline-secondary' onClick={()=>{dispatch(changeCreateContactModalStatus())}}> close</div>
            </Modal.Header>
            <Modal.Body>
               
                <form  onSubmit={(e)=>{
                    e.preventDefault()
                    console.log("event",e)
                    dispatch(createContact( {contactData :{
                            first_name : e.target.first_name.value,
                            last_name : e.target.last_name.value,
                            title : e.target.title.value,
                            contact_type : e.target.contact_type.value,
                            specialism : e.target.specialism.value,
                            email_address : e.target.email_address.value,
                            home_address : e.target.home_address.value,
                            company_id : e.target.company_id.value,
                            mailing_address : e.target.mailing_address.value,
                            mobile_tel_number : e.target.mobile_tel_number.value,
                            land_tel_number : e.target.land_tel_number.value,
                            reviews : e.target.reviews.value,
                          
                    }}))
                    }}> 
                    <label className=' form-label' htmlFor='first_name' >First Name</label>
                    <input id='first_name' name='first_name' type='text' className=' form-control' placeholder='First Name'></input>
                    <label className=' form-label' htmlFor='last_name' >Last Name</label>
                    <input id='last_name' name='last_name' type='text' className=' form-control' placeholder='Last Name'></input>
                    <label className=' form-label' htmlFor='title' >Title</label>
                    <input id='title' name='title' type='text' className=' form-control' placeholder='Title'></input>
                    <label className=' form-label' htmlFor='contact_type' >Contact type</label>
                    <input id='contact_type' name='contact_type' type='text' className=' form-control' placeholder='Contact Type'></input>
                    <label className=' form-label' htmlFor='specialism' >Specialism</label>
                    <input id='specialism' name='specialism' type='text' className=' form-control' placeholder='Specialism'></input>
                    <label className=' form-label' htmlFor='email_address' >Email</label>
                    <input id='email_address' name='email_address' type='text' className=' form-control' placeholder='Email Address'></input>
                    <label className=' form-label' htmlFor='company_id' >Compnay Id</label>
                    <input id='company_id' name='company_id' type='text' className=' form-control' placeholder='Company Id'></input>
                    <label className=' form-label' htmlFor='mailing_address' >Mailing Address</label>
                    <input id='mailing_address' name='mailing_address' type='text' className=' form-control' placeholder='Mailing Address'></input>
                    <label className=' form-label' htmlFor='home_address' >Home Address</label>
                    <input id='home_address' name='home_address' type='text' className=' form-control' placeholder='Home Address'></input>
                    <label className=' form-label' htmlFor='mobile_tel_number' >Mobile No</label>
                    <input id='mobile_tel_number' name='mobile_tel_number' type='text' className=' form-control' placeholder='Mobile No'></input>
                    <label className=' form-label' htmlFor='land_tel_number' >Landline No</label>
                    <input id='land_tel_number' name='land_tel_number' type='text' className=' form-control' placeholder='Landline No'></input>
                    <label className=' form-label' htmlFor='reviews' >Reviews</label>
                    <input id='reviews' name='reviews' type='text' className=' form-control' placeholder='Reviews'></input>
                    <input type='submit' className='btn btn-success m-2' value="Create Contact"/>
                </form>
            </Modal.Body>
        </Modal>
    </div>
  )
}

export default CreateContactPopupModal