
import React from 'react'
import { Modal } from 'react-bootstrap'
import './CreateTenderPackagePopUpModal.css'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeCreateTenderPackageModalStatus } from '../../features/PricingSlice'
const CreateTenderPackagePopUpModal = () => {
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const showCreateTenderPackageModal = useSelector(state=> state.pricing.showCreateTenderPackageModal)
    const attachPlanes = [
        {
            name : "Kitchen Plan",
            
        },
        {
            name : "Bathroom Plan",

        },
        {
            name : "Roofing Plan",

        }
    ]

    const inviteTenders = [
        {
            name : "Bandara Bros"
        },
        {
            name : "Uncle John’s Paiting (3.8)"
        },
    ]

    const ShowAttechPlanes = ()=>{
        return (
            attachPlanes.map((plan)=>{
                return (<p className='grid-item '>{plan.name}</p>)
            })
        )
    }
    const ShowAttachRadioButtons = ()=>{
        return (
            attachPlanes.map((plan)=>{
                return (<div class="form-check form-switch create-tender-package-raio-button grid-item">
                             <input class="form-check-input" type="checkbox" value={plan.name} id="flexSwitchCheckChecked" />
                        </div>)
            })
        )
    }
    const ShowInvitations = ()=>{
        return (
            inviteTenders.map((plan)=>{
                return (<p className='grid-item '>{plan.name}</p>)
            })
        )
    }
    const ShowInvitationRadioButtons = ()=>{
        return (
            inviteTenders.map((plan)=>{
                return (<div class="form-check form-switch create-tender-package-raio-button grid-item">
                             <input class="form-check-input" type="checkbox" value={plan.name} id="flexSwitchCheckChecked" />
                        </div>)
            })
        )
    }

    const ShowAttach = ()=>{
        return (
            <div className='attach-container' >
                <div className='create-tender-package-radio-name-container'>
                                <ShowAttechPlanes/>
                               
                </div>
                <div className='create-tender-package-radio-container '>
                                <ShowAttachRadioButtons/>
                </div>
        
            </div>
        )
    }
  return (
    <div >
        <Modal className='create-tender-package-container'
         show={showCreateTenderPackageModal}
         size="lg"
         >
            <Modal.Header>
                {/* <div className='d-flex  justify-content-between  create-tender-package-header-container bg-black '>

                        <h3> Create New Tender Package</h3>
                        <div className='btn bg-light'> close</div>
                </div> */}
                 <h3> Create New Tender Package</h3>
                <div className='btn create-tender-package-button btn-outline-secondary' onClick={()=>{dispatch(changeCreateTenderPackageModalStatus())}}> close</div>
            </Modal.Header>
            <Modal.Body>
                <div className='d-block create-tender-package-body-container'>
                    
                        <div className='row'>
                                <label className='col-4 d-flex justify-content-end' >Trade: </label>
                                <select className='col-7' name="Painting" id="cars">
                                    <option value="Painting">Painting</option>
                                    <option value="saab">Sa</option>
                                    <option value="opel">Op</option>
                                    <option value="audi">Au</option>
                                </select>
                        </div>
                   

                        <div className='row'>
                                <label className='col-4 d-flex justify-content-end' >Tender package Name :</label>
                                <input className='col-7' type='text'></input>
                        </div>
                        <hr/>

                    {/* <div className='d-flex create-tender-package-input-container'>
                            <label >Trade: </label>
                        <select className=' form-select' name="Painting" id="cars">
                            <option value="Painting">Painting</option>
                            <option value="saab">Sa</option>
                            <option value="opel">Op</option>
                            <option value="audi">Au</option>
                        </select>
                    </div> 
                     <div className='d-flex create-tender-package-input-container'>
                        <label >Tender package Name :</label>
                        <input className='form-control ' type='text'></input>
                    </div> */}
                     <div className='row'>
                                <label className='col-4 d-flex justify-content-end' > Tender Due :</label>
                                <div className='col-7 d-block justify-content-start'>
                                    <button className='btn bg-light'>1 Days</button>
                                    <button className='btn bg-light'>1 Days</button>
                                    <button className='btn bg-light'>1 Days</button>
                                    <br></br>
                                    Or:
                                    <input className='form-control ' placeholder='End date' type='date'></input>
                                </div>
                    </div>
                    
                    <hr/>

                    {/* <div> 
                            <label > Tender Due : </label>

                            Or:
                            <input className='form-control ' type='date'></input>
                      
                    </div>
                    <hr/> */}

                        <div className='row'>
                                <label className='col-4 d-flex justify-content-end' >Proposed Start/Delivery :</label>
                                <input className='col-7 ' type='date'></input>
                        </div>

                    {/* <div>
                        <label>Proposed Start/Delivery : </label>
                        <input className='form-control ' type='date'></input>
                    </div> */}

                        <div className='row'>
                                <label className='col-4 d-flex justify-content-end' >Tender package Name :</label>
                                <input className='col-7' type='text'></input>
                        </div>



                        <div className='row'>
                                <label className='col-4 d-flex justify-content-end' >Trade type:</label>
                                <select className='col-7' name="Painting" id="cars">
                                    <option value="Painting">Material and Labour</option>
                                    <option value="saab">Sa</option>
                                    <option value="opel">Op</option>
                                    <option value="audi">Au</option>
                                </select>   
                        </div>
                        <div className='row'>
                                <label className='col-4 d-flex justify-content-end' > Retainer held :</label>
                                <input className='col-7 ' type='number' max={100} min="0"></input>
                        </div>
                        <div className='row'>
                                <label className='col-4 d-flex justify-content-end' > Retainer release :</label>
                                <div className='col-7 d-flex flex-row'>
                                    <input className='' type='text'></input>
                                    <p>weeks after completion</p>

                                </div>
                        </div>

                        <div className='row'>
                                <label className='col-4 d-flex justify-content-end' > Message to tenderes:</label>
                                <input className='col-7 ' type='text'></input>
                        </div>

                        




                    {/* <div>
                        <div className='d-flex'>    
                            <label >Tender package Name :</label>
                            <input className='form-control ' type='text'></input>
                        </div>
                    </div> */}

{/* 
                    <div>
                            <label >Trade type: </label>
                        <select className=' form-select' name="Painting" id="cars">
                            <option value="Painting">Material and Labour</option>
                            <option value="saab">Sa</option>
                            <option value="opel">Op</option>
                            <option value="audi">Au</option>
                        </select>
                    </div> */}
{/* 
                    <div className='d-flex'>
                            <label > Retainer held :  </label>
                            <input className='form-control ' type='number' max={100} min="0"></input><p>%</p>
                    </div> */}

                    {/* <div className='d-flex'>
                            <label > Retainer release :  </label>
                            <input className='form-control ' type='number'></input><p>weeks after completion</p>
                    </div> */}

                    {/* <div className='d-flex'>
                            <label > message to Tenderers :  </label>
                            <input className='form-control ' type='number'></input>
                    </div> */}
                    
                    
                    <div className='d-flex'>
                            <label className='col-4 d-flex justify-content-end'  > Attach plans:  </label>
                            {/* <input className='form-control ' type='number'></input> */}
                            {/* <div className='create-tender-package-radio-name-container'>
                                <ShowAttechPlanes/>
                               
                            </div>
                            <div className='create-tender-package-radio-container d-block'>
                                <ShowAttachRadioButtons/>
                            </div> */}
                           
                                <div className='create-tender-package-radio-name-container'>
                                                <ShowAttechPlanes/>
                                            
                                </div>
                                <div className='create-tender-package-radio-container '>
                                                <ShowAttachRadioButtons/>
                                </div>
                        
                          
                    </div>
                    <div className='d-flex'>
                            <label className='col-4 d-flex justify-content-end'  > </label>
                            <button className='btn create-tender-package-button btn-outline-secondary '> Add Document</button>

                    </div>
                    
                    
                    <div className='d-flex'>
                            <label className='col-4 d-flex justify-content-end'  > Invite Tenderes:  </label>
                            <div className='create-tender-package-radio-name-container'>
                                    <ShowInvitations/>
                            </div>
                            <div className='create-tender-package-radio-container'>
                                    <ShowInvitationRadioButtons/>
                            </div>

                    </div>
                    <div className='d-flex '>
                            <label className='col-4 d-flex justify-content-end'  > </label>
                            <button className='btn  create-tender-package-button btn-outline-secondary m-1 '> Add contact</button>
                            <button className='btn create-tender-package-button btn-outline-secondary m-1'> Add from contacts</button>

                    </div>
                    <div className='d-flex '>
                            <label className='col-4 d-flex justify-content-end'  > </label>
                            <button className='btn  create-tender-package-button btn-outline-secondary m-1 '> Submit for review</button>
                            <button className='btn create-tender-package-button btn-outline-secondary m-1'> Save as draft</button>
                            <button className='btn create-tender-package-button btn-outline-secondary m-1'> Cancel</button>

                    </div>
                   

                
                </div>
            </Modal.Body>
        </Modal>
    </div>
  )
}

export default CreateTenderPackagePopUpModal