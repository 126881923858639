import React, { useEffect, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './ProjectCardView.css'
import { useNavigate } from 'react-router-dom'
import UpdateProject from '../updateProject/UpdateProject';
import { useDispatch } from 'react-redux';
import { archiveProject, deleteProject, postNewProject } from '../../features/ProjectSlice';
import _ from 'lodash'
import { ProjectStatus } from '../../shared/enums';
import ConfirmationDialog from '../common/ConfiramationDialog';

const ProjectCardView = ({ project }) => {
  const [contractProgress, setcontractProgress] = useState(0)
  const [showUpdateProjectModal, setShowUpdateProjectModal] = useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showArchiveDialog, setShowArchiveDialog] = useState(false);
  const [showCloneDialog, setShowCloneDialog] = useState(false);

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const date = () => {
    let endDate = new Date(project.proposed_end_date)
    let contractDurationInMs = new Date(project.proposed_end_date) - new Date(project.proposed_start_date)

    const contractDuration = contractDurationInMs / (1000 * 60 * 60 * 24)
    const currentDate = new Date()
    const daysLeft = (endDate - currentDate) / (1000 * 60 * 60 * 24)

    const contractCompletionProgress = 100 - (100 * daysLeft) / contractDuration
    setcontractProgress(contractCompletionProgress)




    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    // console.log( new Date().toLocaleString("en-US", { timeZone: timezone })   )
  }
  useEffect(() => {
    date()
  }, []);

  const navigateToPricing = () => {
    console.log("id", project._id)
    navigate("/pricing", {
      state: {
        projectId: project._id
      }
    })

  }

  const archive = async () => {
    await dispatch(archiveProject({ projectId: project._id }))
    // e.preventDefault()
    window.location.reload();
  };

  const remove = async () => {
    await dispatch(deleteProject({ projectId: project._id }))
    // e.preventDefault()
    window.location.reload();
  };

  const clone = async () => {
    let projectData = project
    projectData = _.omit(projectData, '_id')
    projectData = _.omit(projectData, '__v')
    await dispatch(postNewProject({ newProjectData: projectData }))
    // e.preventDefault()
    window.location.reload();
  };

  return (
    <div className='card col-lg-5 col-md-12 mt-3 mr-1'>
      <div className='pt-3 pb-3'>
        <div className='row'>
          <div className='col-10' onClick={navigateToPricing}>
            {/* <div className='row'> */}
            <div className='col'>{project.project_name}</div>
            <div className='col'>{project.client_name}</div>
            <div className='col'>{project.site_address}</div>
            <div className='col'>{project.client_email}</div>
            <div className='col'>${project.client_budget}</div>
            <div className='col'>{project.contract_type}</div>
            <div className='col'> {project.status == 1 ? 'Active' : project.status == 2 ? 'Archived' : ''}</div>
            {/* </div> */}
          </div>

          <div className='col-2'>
            <div className='col justify-content-md-end h-100'>
              <a data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"> <MoreVertIcon className='more-vert-icon' /></a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#scrollspyHeading3" onClick={() => setShowUpdateProjectModal(true)}>Edit Project</a></li>
                <li><a className="dropdown-item" href="#scrollspyHeading4" onClick={() => setShowCloneDialog(true)}>Clone Project</a></li>
                {project.status !== ProjectStatus.Archieved &&
                  <li><a className="dropdown-item" href="#scrollspyHeading4" onClick={() => setShowArchiveDialog(true)}>Archive Project</a></li>
                }
                <li><hr className="dropdown-divider" /></li>
                <li><a className="dropdown-item" href="#scrollspyHeading5" onClick={() => setShowDeleteDialog(true)}>Delete Project</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {showUpdateProjectModal && (
        <UpdateProject showModel={showUpdateProjectModal} setshowModel={setShowUpdateProjectModal} project={project} />
      )}

      {showDeleteDialog &&
        <ConfirmationDialog message="Are you sure you want to delete this project?" onConfirm={remove} onCancel={() => setShowDeleteDialog(false)} />
      }
      {showArchiveDialog &&
        <ConfirmationDialog message="Are you sure you want to archive this project?" onConfirm={archive} onCancel={() => setShowArchiveDialog(false)} />
      }
      {showCloneDialog &&
        <ConfirmationDialog message="Are you sure you want to clone this project?" onConfirm={clone} onCancel={() => setShowCloneDialog(false)} />
      }
    </div>
  )
}

export default ProjectCardView