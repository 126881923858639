import axios from "axios";
import Axios from "axios";
import { interceptorConstants } from "../constants/Constants";
import { apiCodes, urls } from "../constants/Url";

let axiosInstance = Axios.create();

axiosInstance.interceptors.request.use(
  async function (config) {
   
    let token = localStorage.getItem("access_token");
    if (token != null) {
     
      config.headers.Authorization = "Bearer " + token;
      
    }
    return config;

  },
  function (err) {
    
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
   
    return response;
  },
  async function (error) {
    // console.log(error)
   
    const {
      config,
      response: { status, data },
    } = error;
    const initialRequest = config;
    const isRememberMeChecked = localStorage.getItem("is_remember_me_checked")
   

    if (status === apiCodes.RESPONSE_CODE_UNAUTHORIZED) {
      let errorMessage = data.message;
      

      if (data.toLowerCase() === interceptorConstants.JWT_EXPIRED.toLowerCase() && status === apiCodes.RESPONSE_CODE_UNAUTHORIZED && isRememberMeChecked === "true") {
       // console.log("err" + errorMessage);
        let refreshToken = localStorage.getItem("refresh_token");
        
        let headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + refreshToken,
        };
        
       return  Axios.get(urls.BASE_URL+urls.REFRESH_URL, {
          headers: headers,
        })
          .then((refreshResponse) => {
            if (refreshResponse.status === apiCodes.STATUS_SUCCESS_CODE) {
              console.log("New tokens here");
              // console.log(refreshResponse.data.tokens.access.token);
              localStorage.setItem(
                "access_token",
                refreshResponse.data.tokens.access.token
              );
              localStorage.setItem(
                "refresh_token",
                refreshResponse.data.tokens.refresh.token
              );
             
             
         
            return axiosInstance.request(initialRequest);
                
            }
            else{
              console.log("Server Error")
              return
            }
          })
          .catch((err) => {
           
            return Promise.reject(err);
          });
      }
      return Promise.reject(error);
    }
   
  }
);

export default axiosInstance;
