import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { urls } from "../constants/Url"
import { interceptedHttpGet, interceptedHttpPost, interceptedHttpPut, interceptedHttpDelete } from "../service/service"


const initialState = {
    fileUploadResponse: undefined,
    documents: [],
    pageCount: 0,
    filterCriteria: {
        project_id: '',
        file_type: '',
        file_status: ''        
    },
    fileDeletionResponse: undefined,
    fileArchivalResponse: undefined,
    fileUpdateResponse: undefined,
    updatingDocument: undefined
}

export const uploadDocument = createAsyncThunk('file/upload',({formData

})=>{ 
    interceptedHttpPost(urls.BASE_URL+urls.UPLOAD_FILE, {"document" : {...formData}})    
    .then(
        (response)=>{
           console.log('file upload response:', response)
            return response.data
        }
    )
})

export const getDocuments = createAsyncThunk('file/all',({page, limit, project_id, file_type, file_status})=>{ 
    const filterCriteria = {
        page: page,
        limit: limit,
        project_id: project_id,
        file_type: file_type,
        file_status: file_status
    }
    
    const responsePromise =interceptedHttpGet(urls.BASE_URL+urls.GET_FILES, {params: filterCriteria}) 

    return responsePromise.then(
        (response)=>{
            return response.data
        }
    )
})

export const archiveDocument = createAsyncThunk('file/archive', ({ fileId }) => {
    const responsePromise = interceptedHttpPut(urls.BASE_URL + urls.ARCHIVE_FILE + "/" + fileId)
    return responsePromise.then(
        (response) => {
            return response.data
        }
    )
})

export const deleteDocument = createAsyncThunk('file/delete', ({ fileId }) => {
    return interceptedHttpDelete(urls.BASE_URL + urls.DELETE_FILE + "/" + fileId)
        .then(
            (response) => {
                return response.data
            }
        )
})

export const updateDocument = createAsyncThunk('file/update', ({ fileId, file_type, file_status }) => {
    return interceptedHttpPut(urls.BASE_URL + urls.UPDATE_FILE + "/" + fileId, { file_type: file_type, file_status: file_status })
        .then(
            (response) => {
                return response.data
            }
        )
})

const documentSlice = createSlice({
    name: "document",
    initialState,
    reducers: {
        setFilterCriteria : (state, action)=>{
            state.filterCriteria = action.payload
        },
        setUpdatingDocument : (state, action)=>{
            state.updatingDocument = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(uploadDocument.fulfilled, (state, action) => {
            state.fileUploadResponse = [action.payload];
        });
        builder.addCase(uploadDocument.pending, (state) => {
            state.fileUploadResponse = undefined
        });
        builder.addCase(uploadDocument.rejected, (state, action) => {
            state.fileUploadResponse = undefined
        });
        builder.addCase(getDocuments.fulfilled, (state, action) => {
            state.documents = action.payload.files;
            state.pageCount = action.payload.totalPages;
        });
        builder.addCase(getDocuments.pending, (state) => {
            state.documents = []
            state.pageCount = 0
        });
        builder.addCase(getDocuments.rejected, (state) => {
            state.documents = []
            state.pageCount = 0
        });
        builder.addCase(archiveDocument.fulfilled, (state, action) => {
            state.fileArchivalResponse = action.payload
        });
        builder.addCase(archiveDocument.pending, (state) => {
            state.fileArchivalResponse = undefined
        });
        builder.addCase(archiveDocument.rejected, (state) => {
            state.fileArchivalResponse = undefined
        });
        builder.addCase(updateDocument.fulfilled, (state, action) => {
            state.fileUpdateResponse = action.payload
            state.updatingDocument = undefined
        });
        builder.addCase(updateDocument.pending, (state) => {
            state.fileUpdateResponse = undefined
            state.updatingDocument = undefined
        });
        builder.addCase(updateDocument.rejected, (state) => {
            state.fileUpdateResponse = undefined
            state.updatingDocument = undefined
        });
        builder.addCase(deleteDocument.fulfilled, (state, action) => {
            state.fileDeletionResponse = action.payload
        });
        builder.addCase(deleteDocument.pending, (state) => {
            state.fileDeletionResponse = undefined
        });
        builder.addCase(deleteDocument.rejected, (state) => {
            state.fileDeletionResponse = undefined
        });
    },
});
  
  export default documentSlice.reducer;

export const {setFilterCriteria, setUpdatingDocument} = documentSlice.actions
