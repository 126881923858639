import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { urls } from "../constants/Url";
import { interceptedHttpDelete, interceptedHttpGet, interceptedHttpPost, interceptedHttpPut } from "../service/service";

const initialState = {
    projectsRequestData: [],
    projectsRequestLoading: false,
    projectsRequestError: "",
    newProjectData: [],
    newProjectLoading: false,
    newProjectError: "",
    archiveProjectLoading: false,
    deleteProjectLoading: false,
    archiveProjectError: "",
    deleteProjectError: "",
    deleteProjectResponse: undefined
}

export const postNewProjects = createAsyncThunk('projects/new', ({ clientName,
    clientEmail,
    contractType,
    invoiceFrequency,
    proposedBudget,
    agreedMargin,
    phone,
    siteAddress,
    siteBuilder,
    homeAddress,
    proposalBasedOn,
    lotNumber,
    taxRate,
    proposedStartDate,
    proposedEndDate,
    proposedDuration,
    councilApproved,
    daysDeliveredBeforeInstallation,
    exclutionText,
    markupPercentage,
    contingencyPercentage,
    gmpPercentage,
    clientSalesTaxPercentage,
    clietnRetainerPercentage,

}) => {
    const projectRequest = {
        "project": {
            "client_name": clientName,
            "client_email": clientEmail,
            "contract_type": contractType,
            "invoice_frequency": invoiceFrequency,
            "proposed_budget": proposedBudget,
            "agreed_margin": agreedMargin,
            "phone": phone,
            "site_address": siteAddress,
            "home_address": homeAddress,
            "site_builder": siteBuilder,
            "proposal_based_on": proposalBasedOn,
            "lot_number": lotNumber,
            "tax_rate": taxRate,
            "proposed_start_date": proposedStartDate,
            "proposed_end_date": proposedEndDate,
            "proposed_duration": proposedDuration,
            "council_approved": councilApproved,
            "days_delivered_before_installation": daysDeliveredBeforeInstallation,
            "exclution_text": exclutionText,
            "markup_percentage": markupPercentage,
            "contingency_percentage": contingencyPercentage,
            "gmp_percentage": gmpPercentage,
            "client_sales_tax_percentage": clientSalesTaxPercentage,
            "client_retainer_percentage": clietnRetainerPercentage
        }
    }
    interceptedHttpPost(urls.BASE_URL + urls.POST_PROJECT, projectRequest)
        .then(
            (response) => {
                return response.data
            }
        )
})
export const postNewProject = createAsyncThunk('projects/new', ({ newProjectData }) => {
    const createResponse = interceptedHttpPost(urls.BASE_URL + urls.POST_PROJECT, { "project": { ...newProjectData } })
    return createResponse.then(
        (response) => {
            return response.data
        }
    )
})

export const updateProject = createAsyncThunk('projects/update', ({ updatedProjectData }) => {
    interceptedHttpPut(urls.BASE_URL + urls.PUT_PROJECT, { "project": { ...updatedProjectData } })
        .then(
            (response) => {
                return response.data
            }
        )
})

export const getProjects = createAsyncThunk('projects', () => {
    const responsePromise = interceptedHttpGet(urls.BASE_URL + urls.GET_PROJECTS)
    return responsePromise.then(
        (response) => {
            return response.data
        }
    )
})

export const archiveProject = createAsyncThunk('projects/archive', ({ projectId }) => {
    const archiveResponse = interceptedHttpPut(urls.BASE_URL + urls.ARCHIVE_PROJECT + "/" + projectId)
    return archiveResponse.then(
        (response) => {
            return response.data
        }
    )
})

export const deleteProject = createAsyncThunk('projects/delete', ({ projectId }) => {
    const deleteResponse = interceptedHttpDelete(urls.BASE_URL + urls.DELETE_PROJECT + "/" + projectId)
    return deleteResponse.then(
        (response) => {
            return response.data
        }
    )
})

const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        //   logged: (state) => {
        //     state.newProjectLoading = true;
        //   },
    },
    extraReducers: (builder) => {
        builder.addCase(postNewProject.fulfilled, (state, action) => {

            state.newProjectLoading = false;
            state.newProjectData = [action.payload];
        });
        builder.addCase(postNewProject.pending, (state) => {
            state.newProjectData = [];
            state.newProjectLoading = true;
        });
        builder.addCase(postNewProject.rejected, (state, action) => {
            state.newProjectError = action.error.message;
            state.newProjectData = [];
            state.newProjectLoading = false;
        });
        builder.addCase(getProjects.pending, (state) => {
            state.projectsRequestLoading = true;
            state.projectsRequestData = []
        });
        builder.addCase(getProjects.fulfilled, (state, action) => {

            state.projectsRequestLoading = false
            state.projectsRequestData = action.payload
            state.projectsRequestError = ""
        });
        builder.addCase(getProjects.rejected, (state, action) => {
            state.projectsRequestData = []
            state.projectsRequestLoading = false
            state.projectsRequestError = action.error.message
        });
        builder.addCase(archiveProject.pending, (state) => {
            state.archiveProjectLoading = true;
        });
        builder.addCase(archiveProject.fulfilled, (state, action) => {

            state.archiveProjectLoading = false
            state.archiveProjectError = ""
        });
        builder.addCase(archiveProject.rejected, (state, action) => {
            state.archiveProjectLoading = false
            state.archiveProjectError = action.error.message
        });
        builder.addCase(deleteProject.pending, (state) => {
            state.deleteProjectLoading = true;
            state.deleteProjectResponse = undefined;
        });
        builder.addCase(deleteProject.fulfilled, (state, action) => {
            state.deleteProjectLoading = false
            state.deleteProjectError = ""
            state.deleteProjectResponse = action.payload;
        });
        builder.addCase(deleteProject.rejected, (state, action) => {
            state.deleteProjectLoading = false
            state.deleteProjectError = action.error.message
            state.deleteProjectResponse = undefined;
        });
    },
});

export default projectSlice.reducer;

export const { logged } = projectSlice.actions;

export const selectProjectDeleteResponse = (state) => state.deleteProjectResponse;
