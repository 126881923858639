import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateDocument } from '../../features/DocumentSlice';

const DocumentEditForm = () => {
    const dispatch = useDispatch()
    const document = useSelector((state) => state.documents.updatingDocument)

    const [selectedFileName, setSelectedFileName] = useState(document?.filename);
    const [selectedFileType, setSelectedFileType] = useState(document?.file_type);
    const [selectedFileStatus, setSelectedFileStatus] = useState(document?.file_status);
    const [uploadButtonClicked, setUploadButtonClicked] = useState(false);

    const handleDocumentTypeChange = (value) => {
        setSelectedFileType(value)
    }

    const handleDocumentNameChange = (value) => {
        setSelectedFileName(value)
    }

    const handleDocumentStatusChange = (value) => {
        setSelectedFileStatus(value)
    }

    const editFile = async () => {
        console.log('----update files')
        setUploadButtonClicked(true);
        dispatch(updateDocument({ fileId: document._id, file_type: selectedFileType, file_status: selectedFileStatus }))
        // if (document) {
        //     const formData = packFilesToFormData([document], '');
        //         interceptedHttpPost(urls.BASE_URL + urls.UPLOAD_FILES, formData)
        //         .then((response) => { window.location.reload(); })
        //         .catch(err => window.location.reload())
        // }
    }

    return (
        <div className='col-12'>
            {document &&
                <>
                    <h3>Edit Document</h3>
                    <div>
                        <p className='mb-1'>Document Type</p>
                        <div>
                            <select className="form-select" placeholder='Document Type' id="document-type" name="document-type" defaultValue={document?.file_type} value={selectedFileType} onChange={(e) => handleDocumentTypeChange(e.target.value)}>
                                <option value="">Select Document Type</option>
                                <option value="legal">Legal</option>
                                <option value="financial">Financial</option>
                            </select>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <p  className='mb-1'>Document Name</p>
                        <div>
                            <input type="text" className="form-control" placeholder="Document Name" id='document-name' defaultValue={document?.filename} value={selectedFileName} onChange={e => handleDocumentNameChange(e.target.value)} disabled></input>

                        </div>
                    </div>
                    <div className='row mt-3'>
                        <p  className='mb-1'>Status</p>
                        <div>
                            <select className="form-select" placeholder='Document Status' id="document-status" name="document-status" defaultValue={document?.file_status} value={selectedFileStatus} onChange={(e) => handleDocumentStatusChange(e.target.value)}>
                                <option value="" selected>Select Document Status</option>
                                <option value="submitted">Submitted</option>
                                <option value="approved">Approved</option>
                            </select>
                        </div>
                    </div>

                    <Button style={{ marginTop: '1rem' }} variant='outlined' color='success' onClick={editFile} disabled={!document || uploadButtonClicked}>Submit</Button>
                </>
            }
        </div>
    )
}

export default DocumentEditForm;