import React from 'react'
import { Outlet } from 'react-router'
import Header from '../components/header/Header'

const AppLayout = () => {
  return (
    <div>
    <Header/>
    <Outlet></Outlet>
    </div>
  )
}

export default AppLayout