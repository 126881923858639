import "./TenderPackagePopUpModal.css"
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { billOfQuantities } from '../../constants/Constants'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from '@mui/icons-material/Add';

import CancelPresentationTwoToneIcon from "@mui/icons-material/CancelPresentationTwoTone";
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';

import { changeAddUpModalStatus, changeTenderPackageModalStatus, createItem, deleteItems, updateItemData, updateSectionData } from '../../features/PricingSlice'
import { Prev } from "react-bootstrap/esm/PageItem"
const TenderPackagePopUpModal = () => {
   const showTenderPackageModal =  useSelector((state)=>state.pricing.showTenderPackageModal)
   const checkedDataObject = useSelector((state)=>state.pricing.checkedDataObject)
   const itemRequestData = useSelector((state)=>state.pricing.itemRequestData)
   const sectionRequestData = useSelector((state)=>state.pricing.sectionRequestData)
   const pricingListRequestData = useSelector((state)=>state.pricing.pricingListRequestData)
   const userViewFilters = [  "COST_PER_UNIT","UOM","UNITS_REQUIRED","COST_OF_UNITS","MARGIN","CLIENT_CHARGE","STATUS","TYPE"]
   const dispatch = useDispatch()
    const [checkedItemsDataArray , setCheckedItemsDataArray] = useState([])
    const [nonLabourArray, setNonLabourArray] = useState([])
  useEffect(() => {
    setCheckedItemsDataArray([])
    checkedDataObject.items.forEach((itemId)=>{
      const itemData = itemRequestData.find((it)=> it._id ===itemId)
      setCheckedItemsDataArray((Prev)=>[...Prev,itemData])
      
    })
    console.log("ITEM ARRAY",checkedItemsDataArray)
    setNonLabourArray([])
    findMaterialLabours()
    console.log("Non Labour ARRAY",nonLabourArray)
  }, [showTenderPackageModal]);

//Find the labours which have to be included without as a labour cost.
// Just as to keep right indentations for the view.
  const findMaterialLabours = () =>{  
    checkedItemsDataArray.forEach((item)=>{
        if(item.type !== billOfQuantities.TYPES.LABOUR && !checkedDataObject.items.includes(item.labour_id) ){
            setNonLabourArray(prev=>[...prev,itemRequestData.find((it)=>it._id ===item.labour_id)])
        }
        console.log("Non ARRAY",nonLabourArray)
     })

  }


   const formatResult= (item)=>{
    
    return(
      <div className="result-wrapper">
          <span className="result-span">{item.name} : {item.type}</span>
      </div>
    )
  }

  const createOnSelect = (event,) => {


    // if(event.type ===billOfQuantities.TYPES.LABOUR){

    //   dispatch(createItem({item : { name : event.name, sectionId : labour.section_id, type : event.type , specification : billOfQuantities.TYPES.SPECIFIED, cost_per_unit : event.cost_per_unit, uom : event.uom, margin : event.margin}}))
    // }
    // else if (event.type === billOfQuantities.TYPES.ITEM){
    //   dispatch(createItem({item : { name : event.name, sectionId : labour.section_id , type : event.type, labourId : labour._id, specification : billOfQuantities.TYPES.SPECIFIED, cost_per_unit : event.cost_per_unit, uom : event.uom, margin : event.margin}}))
    // }
  
  }
  

   const DisplayLabour = ({sectionId})=>{
        // let laboursUnderSection = []
        // const laboursUnderSectionArray = checkedDataObject.filter((item)=>item.type === billOfQuantities.TYPES && sectionId === item.section_id)
        return(checkedDataObject.items.map((itemId)=>{
            let labour = itemRequestData.find((item)=>item._id === itemId)
            if(labour.type ===billOfQuantities.TYPES.LABOUR && sectionId === labour.section_id){
                return(
                    < >
                    <div   className={"section-labour-container row"}>
                    <div className="section-container-labour-name col-3 d-flex">
                      {/* <input className="form-check-input" type="checkbox"></input> */}
                      {/* {isCurrentChecked(labour._id , billOfQuantities.TYPES.LABOUR)?
                                        <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} defaultChecked></input>   
                                      :
                                      
                                      <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} ></input>   
                                    } */}
                      {/* <input
                        type="text"
                        list="labourDataList"
                        defaultValue={labour.name}
                        className=" section-input form-control m-0 p-0"
                       
                        onBlur={(e) => {
                          if(e.target.value.toLowerCase()!==labour.name.toLowerCase() && !isDuplicateName && pricingListRequestData.some((addUp)=>addUp.name=== e.target.value)){
              
                            dispatch(updateItemData({item : { ...labour, name: e.target.value }}));
                          }
                        }}
                        // onChange ={(e)=>{validateLabourName(e)}}
                      ></input>
                        <datalist id="labourDataList">
                          {
                            pricingListRequestData.map((addUp)=>{
                              if(addUp.type === billOfQuantities.TYPES.LABOUR && !pricingListRequestLoading){
                                return (
                                  <option key={addUp._id} value={addUp.name}>{addUp.name}</option>
                                )
                              }
                            })
                          }
                        </datalist> */}
                        <div style={{ width: 400}}>
                        <ReactSearchAutocomplete
                        
                          placeholder={labour.name}
                          fuseOptions={{keys : ["name","type"]}}
                          items={pricingListRequestData.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.LABOUR)} 
                          showIcon= {false}
                          formatResult = {formatResult}
                          readOnly= {true}
                          // onSelect = {(event)=>{dispatch(updateItemData({item : {...labour, name : event.name, type : event.type}}))}}
                          styling={{
                            height: "34px",
                            border: "none",
                            // border: "1px solid darkgreen",
                            borderRadius: "4px",
                            backgroundColor: "white",
                            boxShadow: "none",
                            hoverBackgroundColor: "lightgreen",
                            color: "black",
                            // fontSize: "12px",
                            // fontFamily: "Courier",
                            iconColor: "green",
                            lineColor: "lightgreen",
                            placeholderColor: "darkgreen",
                            clearIconMargin: "3px 8px 0 0",
                            zIndex : 999,
                            // zIndex: {labourZIndex},
                          }}
                        />
                      </div>
              
                     
                      
              
                      {/* {isDuplicateName? <p>Please Enter a unique Labour name</p> : null} */}
                    </div>
                      {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED)&& (userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)))?
                          <div className="col-1 d-flex">
                          {/* Qty */}
                          <input
                            type="text"
                            defaultValue={labour.units_required}
                            className="section-input form-control m-0 p-0"
                            readOnly={true}
                            onBlur={(e) => {
                              // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
                              // dispatch(updateItemData({item : { ...labour, units_required: e.target.value }}));
                            }}
                          ></input>
                  
                          {/* UOM */}
                          <input
                            type="text"
                            className="section-input form-control m-0 p-0"
                            defaultValue={labour.uom}
                            readOnly={true}
                            onBlur={(e) => {
                              // dispatch(updateLabour({ ...labour, uom: e.target.value }));
                              // dispatch(updateItemData({item :{ ...labour, uom: e.target.value }}));
                            }}
                          ></input>
                        </div>           
                        
                        :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
                        <div className="col-1 d-flex">
                        {/* Qty */}
                        <input
                          type="text"
                          defaultValue={labour.units_required}
                          readOnly={true}
                          className="section-input form-control m-0 p-0"
                          onBlur={(e) => {
                            // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
                            // dispatch(updateItemData({item : { ...labour, units_required: e.target.value }}));
                          }}
                        ></input>
                
                        {/* UOM */}
                        {/* <input
                          type="text"
                          className="section-input form-control m-0 p-0"
                          defaultValue={labour.uom}
                          onBlur={(e) => {
                            // dispatch(updateLabour({ ...labour, uom: e.target.value }));
                            dispatch(updateLabourData({labour :{ ...labour, uom: e.target.value }}));
                          }}
                        ></input> */}
                      </div>
              
                    :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                    <div className="col-1 d-flex">
                    {/* Qty */}
                    {/* <input
                      type="text"
                      defaultValue={labour.units_required}
                      className="section-input form-control m-0 p-0"
                      onBlur={(e) => {
                        // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
                        dispatch(updateLabourData({labour : { ...labour, units_required: e.target.value }}));
                      }}
                    ></input> */}
              
                    {/* UOM */}
                    <input
                      type="text"
                      className="section-input form-control m-0 p-0"
                      defaultValue={labour.uom}
                      readOnly={true}
                      onBlur={(e) => {
                        // dispatch(updateLabour({ ...labour, uom: e.target.value }));
                        // dispatch(updateItemData({item :{ ...labour, uom: e.target.value }}));
                      }}
                    ></input>
                  </div>
              
                    :null}
                    
                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
                        <div className="col-1">
                        {/* unit_cost */}
                        <input
                          type="text"
                          className="section-input form-control m-0 p-0"
                          defaultValue={labour.cost_per_unit}
                          readOnly={true}
                          onBlur={(e) => {
                            // dispatch(updateLabour({ ...labour, cost_per_unit: e.target.value }));
                            // dispatch(updateItemData({item :{ ...labour, cost_per_unit: e.target.value }}));
                          }}
                        ></input>
                      </div>  
                  :null}
                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
                       <div className="col-1">
                       {/* cost_of_units */}
                       <input
                         type="number"
                         defaultValue={(parseFloat(labour.cost_per_unit)*parseFloat(labour.units_required)).toFixed(2)} 
                         className="section-input"
                         readOnly={true}
                       ></input>
                     </div>  
                  :null}
                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
                     <div className="col-1">
                     {/* markup  */}
                     <input
                       type="text"
                       defaultValue={labour.margin}
                       className="section-input form-control m-0 p-0"
                       readOnly={true}
                       onBlur={(e) => {
                         // dispatch(updateLabour({ ...labour, margin: e.target.value }));
                        //  dispatch(updateItemData({item :{ ...labour, margin: e.target.value }}));
                       }}
                     ></input>
                   </div>
                  :null}
                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
                      <div className="col-1">
                      {/* Client Charge */}
                      <input
                        type="number"
                        defaultValue={(parseFloat(labour.cost_per_unit)*parseFloat(labour.units_required)*(1+(parseFloat(labour.margin)/100))).toFixed(2)} 
                        className="section-input"
                        readOnly={true}
                      ></input>
                    </div>
                  :null}
              
                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
                     <div className="col-2">
                     <select
                       className="section-input form-select m-0 p-0"
                       defaultValue={labour.status}
                       readOnly={true}
                       onBlur={(e) => {
                         // dispatch(updateLabour({ ...labour, status: e.target.value }));
                        //  dispatch(updateItemData({item :{ ...labour, status: e.target.value }}));
                       }}
                     >
                       <option value="Prime">Prime</option>
                       <option value="Provitional">Provitional</option>
                       <option value="Excluded">Excluded</option>
                     </select>
                   </div>
                  :null}
              
                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
                    //   <div className="col-1">
                    //   <input
                    //     type="text"
                    //     defaultValue={labour.type}
                    //     className="section-input form-control m-0 p-0"
                    //     onBlur={(e) => {
                    //       // dispatch(updateLabour({ ...labour, type: e.target.value }));
                    //       dispatch(updateLabourData({labour :{ ...labour, type: e.target.value }}));
                    //     }}
                    //   ></input>
                    // </div>
              
                        <div className="col-1">
                        {/* <select
                          className="section-input form-select m-0 p-0"
                          defaultValue={labour.name}
                          onChange={(e) => {
                            let itemObject = {...labour}
                            // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
                            // if(e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.ITEM && e.target.selectedOptions[0].getAttribute("labourid") && labour._id !==  e.target.selectedOptions[0].getAttribute("labourid"))
                            // {
                            //   console.log("under current labour",e.target.value)
                              
                            //   items.forEach(element => {
                            //     if(element.labour_id === labour._id){
                            //       let elementCurrent = {...element}
                            //       delete elementCurrent.labour_id
                            //       dispatch(updateItemData({item : {...elementCurrent, specification : billOfQuantities.TYPES.UNSPECIFIED}}))
                            //     }
                            //   });
              
                            //   dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("type"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}}))
                            // }
                            // else if (e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){
                            //   console.log("new labour",e.target.value)
                            //   delete itemObject.labour_id
                            //   dispatch(updateItemData({item :{...itemObject, type :e.target.selectedOptions[0].getAttribute("type")}}))
                            // }
                            if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.UNSPECIFIED){
                              
                              itemRequestData.forEach(element => {
                                if(element.labour_id === labour._id){
                                  let elementCurrent = {...element}
                                  delete elementCurrent.labour_id
                                  dispatch(updateItemData({item : {...elementCurrent, specification : billOfQuantities.TYPES.UNSPECIFIED}}))
                                }
                              });
                              delete itemObject.labour_id
                              dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}}))
              
                            }
                            
                            // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
                                                 }}
                        >
                   
              
                                      <option className="m-1" value={billOfQuantities.TYPES.SPECIFIED} specification={billOfQuantities.TYPES.LABOUR}>{labour.name}</option>
                                      <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option>
                                      
                        </select> */}
                        </div>
                  :null}
              
              
                  
                    
                    
                 
                   
                    {/* <div className="col-1">
                      <KeyboardArrowDownIcon
                        fontSize="large"
                        onClick={() => {
                        //   setShow((prev) => !prev);
                        }}
                      />
                      <CancelPresentationTwoToneIcon
                        onClick={() => {
                          console.log("delete Items ", labour)
                          dispatch(deleteItems({item :labour}))
                        }}
                        color="warning"
                      />
                    </div> */}
                    
                  </div>
                   
                    <DisplayItems labourId={labour._id} labour = {labour} />
{/*                     
                    <div className="row">
                      <div className="col-3 d-flex">
                      <div className="section-container-item-name " >
                         
                          <AddIcon  data-bs-toggle="dropdown" role="button" aria-expanded="false"  onClick={()=>{
                              // dispatch(changeLabourModalStatus(section._id))
                              // dispatch(createLabour({sectionId : section._id}))
                           // dispatch(createItem({ sectionId : section._id , type : billOfQuantities.TYPES.LABOUR}))
              
                              
                              }}  />
                        <ul className="dropdown-menu">
                       
                          <li onClick={()=>{ dispatch(changeAddUpModalStatus({sectionId : labour.section_id,labourId : labour._id, labourSpecification : billOfQuantities.TYPES.SPECIFIED, itemSpecification : billOfQuantities.TYPES.SPECIFIED}))}}><a className="dropdown-item" >New labour/Item</a></li>
                        </ul>
              
              
                      </div>
                      <div  style={{ width: 400}}>
                                          <ReactSearchAutocomplete
                                            placeholder={"Add Item"}
                                            fuseOptions={{keys : ["name","type"]}}
              
                                            items={pricingListRequestData} 
                                            showIcon= {false}
                                            formatResult = {formatResult}
                                            onSelect = {(event)=>{createOnSelect(event)}}
                                            styling={{
                                              height: "34px",
                                              border: "none",
                                              // border: "1px solid darkgreen",
                                              borderRadius: "4px",
                                              backgroundColor: "white",
                                              boxShadow: "none",
                                              hoverBackgroundColor: "lightgreen",
                                              color: "black",
                                              // fontSize: "12px",
                                              // fontFamily: "Courier",
                                              iconColor: "green",
                                              lineColor: "lightgreen",
                                              placeholderColor: "#002D62",
                                              clearIconMargin: "3px 8px 0 0",
                                              zIndex : "auto",
                                              // zIndex: {labourZIndex},
                                            }}
                                          />
                    </div>
                    </div>
                    </div> */}
                </>
                )
            }
        })

        )
   }

//    const DisplayLabour = ({sectionId})=>{
//     // let laboursUnderSection = []
//     const laboursUnderSectionArray = checkedDataObject.filter((item)=>item.type === billOfQuantities.TYPES && sectionId === item.section_id)
//     return(checkedDataObject.items.map((itemId)=>{
//         let labour = itemRequestData.find((item)=>item._id === itemId)
//         if(labour.type ===billOfQuantities.TYPES.LABOUR){
//             return(
//                 < >
//                 <div   className={"section-labour-container row"}>
//                 <div className="section-container-labour-name col-3 d-flex">
//                   {/* <input className="form-check-input" type="checkbox"></input> */}
//                   {/* {isCurrentChecked(labour._id , billOfQuantities.TYPES.LABOUR)?
//                                     <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} defaultChecked></input>   
//                                   :
                                  
//                                   <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} ></input>   
//                                 } */}
//                   {/* <input
//                     type="text"
//                     list="labourDataList"
//                     defaultValue={labour.name}
//                     className=" section-input form-control m-0 p-0"
                   
//                     onBlur={(e) => {
//                       if(e.target.value.toLowerCase()!==labour.name.toLowerCase() && !isDuplicateName && pricingListRequestData.some((addUp)=>addUp.name=== e.target.value)){
          
//                         dispatch(updateItemData({item : { ...labour, name: e.target.value }}));
//                       }
//                     }}
//                     // onChange ={(e)=>{validateLabourName(e)}}
//                   ></input>
//                     <datalist id="labourDataList">
//                       {
//                         pricingListRequestData.map((addUp)=>{
//                           if(addUp.type === billOfQuantities.TYPES.LABOUR && !pricingListRequestLoading){
//                             return (
//                               <option key={addUp._id} value={addUp.name}>{addUp.name}</option>
//                             )
//                           }
//                         })
//                       }
//                     </datalist> */}
//                     <div style={{ width: 400}}>
//                     <ReactSearchAutocomplete
//                       placeholder={labour.name}
//                       fuseOptions={{keys : ["name","type"]}}
//                       items={pricingListRequestData.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.LABOUR)} 
//                       showIcon= {false}
//                       formatResult = {formatResult}
//                       onSelect = {(event)=>{dispatch(updateItemData({item : {...labour, name : event.name, type : event.type}}))}}
//                       styling={{
//                         height: "34px",
//                         border: "none",
//                         // border: "1px solid darkgreen",
//                         borderRadius: "4px",
//                         backgroundColor: "white",
//                         boxShadow: "none",
//                         hoverBackgroundColor: "lightgreen",
//                         color: "black",
//                         // fontSize: "12px",
//                         // fontFamily: "Courier",
//                         iconColor: "green",
//                         lineColor: "lightgreen",
//                         placeholderColor: "darkgreen",
//                         clearIconMargin: "3px 8px 0 0",
//                         zIndex : "auto",
//                         // zIndex: {labourZIndex},
//                       }}
//                     />
//                   </div>
          
                 
                  
          
//                   {/* {isDuplicateName? <p>Please Enter a unique Labour name</p> : null} */}
//                 </div>
//                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED)&& (userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)))?
//                       <div className="col-1 d-flex">
//                       {/* Qty */}
//                       <input
//                         type="text"
//                         defaultValue={labour.units_required}
//                         className="section-input form-control m-0 p-0"
//                         onBlur={(e) => {
//                           // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
//                           dispatch(updateItemData({item : { ...labour, units_required: e.target.value }}));
//                         }}
//                       ></input>
              
//                       {/* UOM */}
//                       <input
//                         type="text"
//                         className="section-input form-control m-0 p-0"
//                         defaultValue={labour.uom}
//                         onBlur={(e) => {
//                           // dispatch(updateLabour({ ...labour, uom: e.target.value }));
//                           dispatch(updateItemData({item :{ ...labour, uom: e.target.value }}));
//                         }}
//                       ></input>
//                     </div>           
                    
//                     :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
//                     <div className="col-1 d-flex">
//                     {/* Qty */}
//                     <input
//                       type="text"
//                       defaultValue={labour.units_required}
//                       className="section-input form-control m-0 p-0"
//                       onBlur={(e) => {
//                         // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
//                         dispatch(updateItemData({item : { ...labour, units_required: e.target.value }}));
//                       }}
//                     ></input>
            
//                     {/* UOM */}
//                     {/* <input
//                       type="text"
//                       className="section-input form-control m-0 p-0"
//                       defaultValue={labour.uom}
//                       onBlur={(e) => {
//                         // dispatch(updateLabour({ ...labour, uom: e.target.value }));
//                         dispatch(updateLabourData({labour :{ ...labour, uom: e.target.value }}));
//                       }}
//                     ></input> */}
//                   </div>
          
//                 :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
//                 <div className="col-1 d-flex">
//                 {/* Qty */}
//                 {/* <input
//                   type="text"
//                   defaultValue={labour.units_required}
//                   className="section-input form-control m-0 p-0"
//                   onBlur={(e) => {
//                     // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
//                     dispatch(updateLabourData({labour : { ...labour, units_required: e.target.value }}));
//                   }}
//                 ></input> */}
          
//                 {/* UOM */}
//                 <input
//                   type="text"
//                   className="section-input form-control m-0 p-0"
//                   defaultValue={labour.uom}
//                   onBlur={(e) => {
//                     // dispatch(updateLabour({ ...labour, uom: e.target.value }));
//                     dispatch(updateItemData({item :{ ...labour, uom: e.target.value }}));
//                   }}
//                 ></input>
//               </div>
          
//                 :null}
                
//                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
//                     <div className="col-1">
//                     {/* unit_cost */}
//                     <input
//                       type="text"
//                       className="section-input form-control m-0 p-0"
//                       defaultValue={labour.cost_per_unit}
//                       onBlur={(e) => {
//                         // dispatch(updateLabour({ ...labour, cost_per_unit: e.target.value }));
//                         dispatch(updateItemData({item :{ ...labour, cost_per_unit: e.target.value }}));
//                       }}
//                     ></input>
//                   </div>  
//               :null}
//                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
//                    <div className="col-1">
//                    {/* cost_of_units */}
//                    <input
//                      type="number"
//                      defaultValue={(parseFloat(labour.cost_per_unit)*parseFloat(labour.units_required)).toFixed(2)} 
//                      className="section-input"
//                      readOnly={true}
//                    ></input>
//                  </div>  
//               :null}
//                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
//                  <div className="col-1">
//                  {/* markup  */}
//                  <input
//                    type="text"
//                    defaultValue={labour.margin}
//                    className="section-input form-control m-0 p-0"
//                    onBlur={(e) => {
//                      // dispatch(updateLabour({ ...labour, margin: e.target.value }));
//                      dispatch(updateItemData({item :{ ...labour, margin: e.target.value }}));
//                    }}
//                  ></input>
//                </div>
//               :null}
//                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
//                   <div className="col-1">
//                   {/* Client Charge */}
//                   <input
//                     type="number"
//                     defaultValue={(parseFloat(labour.cost_per_unit)*parseFloat(labour.units_required)*(1+(parseFloat(labour.margin)/100))).toFixed(2)} 
//                     className="section-input"
//                     readOnly={true}
//                   ></input>
//                 </div>
//               :null}
          
//                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
//                  <div className="col-2">
//                  <select
//                    className="section-input form-select m-0 p-0"
//                    defaultValue={labour.status}
//                    onBlur={(e) => {
//                      // dispatch(updateLabour({ ...labour, status: e.target.value }));
//                      dispatch(updateItemData({item :{ ...labour, status: e.target.value }}));
//                    }}
//                  >
//                    <option value="Prime">Prime</option>
//                    <option value="Provitional">Provitional</option>
//                    <option value="Excluded">Excluded</option>
//                  </select>
//                </div>
//               :null}
          
//                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
//                 //   <div className="col-1">
//                 //   <input
//                 //     type="text"
//                 //     defaultValue={labour.type}
//                 //     className="section-input form-control m-0 p-0"
//                 //     onBlur={(e) => {
//                 //       // dispatch(updateLabour({ ...labour, type: e.target.value }));
//                 //       dispatch(updateLabourData({labour :{ ...labour, type: e.target.value }}));
//                 //     }}
//                 //   ></input>
//                 // </div>
          
//                     <div className="col-1">
//                     <select
//                       className="section-input form-select m-0 p-0"
//                       defaultValue={labour.name}
//                       onChange={(e) => {
//                         let itemObject = {...labour}
//                         // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
//                         // if(e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.ITEM && e.target.selectedOptions[0].getAttribute("labourid") && labour._id !==  e.target.selectedOptions[0].getAttribute("labourid"))
//                         // {
//                         //   console.log("under current labour",e.target.value)
                          
//                         //   items.forEach(element => {
//                         //     if(element.labour_id === labour._id){
//                         //       let elementCurrent = {...element}
//                         //       delete elementCurrent.labour_id
//                         //       dispatch(updateItemData({item : {...elementCurrent, specification : billOfQuantities.TYPES.UNSPECIFIED}}))
//                         //     }
//                         //   });
          
//                         //   dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("type"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}}))
//                         // }
//                         // else if (e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){
//                         //   console.log("new labour",e.target.value)
//                         //   delete itemObject.labour_id
//                         //   dispatch(updateItemData({item :{...itemObject, type :e.target.selectedOptions[0].getAttribute("type")}}))
//                         // }
//                         if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.UNSPECIFIED){
                          
//                           itemRequestData.forEach(element => {
//                             if(element.labour_id === labour._id){
//                               let elementCurrent = {...element}
//                               delete elementCurrent.labour_id
//                               dispatch(updateItemData({item : {...elementCurrent, specification : billOfQuantities.TYPES.UNSPECIFIED}}))
//                             }
//                           });
//                           delete itemObject.labour_id
//                           dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}}))
          
//                         }
                        
//                         // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
//                                              }}
//                     >
//                    {/* {items.map((currentLabour)=>{
//                                   if(currentLabour.section_id === labour.section_id)
//                                   return <option value={currentLabour.name}>{currentLabour.name}</option>
//                                 })}
//                                   <hr className="dropdown-divider"/>
//                                   <option className="m-1" value="newLabour">Add New Labour</option>
//                                   <option className="m-1" value="unspecified">unspecified</option>
//                               */}
          
//                             {/* {items.map((currentLabour)=>{
//                                   if(currentLabour.type === billOfQuantities.TYPES.LABOUR && currentLabour.section_id ===labour.section_id)
//                                   return <option key={currentLabour._id} value={currentLabour.name} labourid={currentLabour._id}
//                                   type={billOfQuantities.TYPES.ITEM} 
//                                   >{currentLabour.name}</option>
//                                 })}
//                                   <hr className="dropdown-divider"/> */}
//                                   {/* <option className="m-1" value={billOfQuantities.TYPES.LABOUR} 
//                                   type={billOfQuantities.TYPES.LABOUR} 
//                                   >Add New Labour</option> */}
          
//                                   <option className="m-1" value={billOfQuantities.TYPES.SPECIFIED} specification={billOfQuantities.TYPES.LABOUR}>{labour.name}</option>
//                                   <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option>
//                                   {/* <option value="Provitional">Provitional</option>
//                                   <option value="Excluded">Excluded</option> */}
//                     </select>
//                     </div>
//               :null}
          
          
              
                
                
             
               
//                 <div className="col-1">
//                   <KeyboardArrowDownIcon
//                     fontSize="large"
//                     onClick={() => {
//                     //   setShow((prev) => !prev);
//                     }}
//                   />
//                   <CancelPresentationTwoToneIcon
//                     onClick={() => {
//                       console.log("delete Items ", labour)
//                       dispatch(deleteItems({item :labour}))
//                     }}
//                     color="warning"
//                   />
//                 </div>
                
//               </div>
               
//                 <DisplayItems labourId={labour._id} labour = {labour} />
                
//                 <div className="row">
//                   <div className="col-3 d-flex">
//                   <div className="section-container-item-name " >
//                     {/* <AddIcon  onClick={()=>{
                      
//                       //dispatch(initializeNewItem(labour._id))
//                       dispatch(createItem({labourId :labour._id, sectionId : labour.section_id, type :billOfQuantities.TYPES.ITEM}))
//                     }}  /> */}
//                       {/* <button className='btn btn-outline-secondary btn-light' data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"> Filters <div className="dropdown-arrow"></div></button> */}
                      
//                       <AddIcon  data-bs-toggle="dropdown" role="button" aria-expanded="false"  onClick={()=>{
//                           // dispatch(changeLabourModalStatus(section._id))
//                           // dispatch(createLabour({sectionId : section._id}))
//                        // dispatch(createItem({ sectionId : section._id , type : billOfQuantities.TYPES.LABOUR}))
          
                          
//                           }}  />
//                     <ul className="dropdown-menu">
//                       {/* {
//                         pricingListRequestData.map((addUp)=>{
//                           if(addUp.type === billOfQuantities.TYPES.ITEM && !pricingListRequestLoading){
//                             return (
//                               <li key={addUp._id} onClick={()=>{ dispatch(createItem({ name :addUp.name, sectionId : labour.section_id , type : billOfQuantities.TYPES.ITEM, labourId :labour._id, specialism : billOfQuantities.TYPES.ITEM} ))}}><a className="dropdown-item" >{addUp.name}</a></li>
//                             )
//                           }
//                         })
                        
//                       } */}
//                         {/* <li><hr className="dropdown-divider"/></li> */}
//                       <li onClick={()=>{ dispatch(changeAddUpModalStatus({sectionId : labour.section_id,labourId : labour._id, labourSpecification : billOfQuantities.TYPES.SPECIFIED, itemSpecification : billOfQuantities.TYPES.SPECIFIED}))}}><a className="dropdown-item" >New labour/Item</a></li>
//                     </ul>
          
          
//                   </div>
//                   <div  style={{ width: 400}}>
//                                       <ReactSearchAutocomplete
//                                         placeholder={"Add Item"}
//                                         fuseOptions={{keys : ["name","type"]}}
          
//                                         items={pricingListRequestData} 
//                                         showIcon= {false}
//                                         formatResult = {formatResult}
//                                         onSelect = {(event)=>{createOnSelect(event)}}
//                                         styling={{
//                                           height: "34px",
//                                           border: "none",
//                                           // border: "1px solid darkgreen",
//                                           borderRadius: "4px",
//                                           backgroundColor: "white",
//                                           boxShadow: "none",
//                                           hoverBackgroundColor: "lightgreen",
//                                           color: "black",
//                                           // fontSize: "12px",
//                                           // fontFamily: "Courier",
//                                           iconColor: "green",
//                                           lineColor: "lightgreen",
//                                           placeholderColor: "#002D62",
//                                           clearIconMargin: "3px 8px 0 0",
//                                           zIndex : "auto",
//                                           // zIndex: {labourZIndex},
//                                         }}
//                                       />
//                 </div>
//                 </div>
//                 </div>
//             </>
//             )
//         }
//     })

//     )
// }

   const DisplayItems = ({labourId, labour})=>{
    return( checkedDataObject.items.map((itemId)=>{
        const item = itemRequestData.find((currentItem)=>currentItem._id === itemId)
        if(item.labour_id === labourId){
            return(
                <div key={item._id} className={"section-item-container row"}> 
                
                <div className='section-container-item-name col-3 d-flex' > 
                    {/* {isCurrentChecked(item._id , billOfQuantities.TYPES.ITEM)?
                      <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}} defaultChecked></input>   
                    :
                    
                    <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}} ></input>   
                  } */}
                  {/* <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": item._id, "type": billOfQuantities.TYPES.ITEM}))}} ></input>    */}
                    {/* <input type="text" defaultValue={item.name} className='section-input form-control ' 
                    list="meterialDataList"
                    onBlur={(e)=>{
                    // dispatch(updateItem({...item, name :e.target.value}))
                    if(pricingListRequestData.some((addUp)=>addUp.name===e.target.value && addUp.type ===billOfQuantities.TYPES.ITEM && item.name !== e.target.value)){
                      dispatch(updateItemData({item:{...item, name :e.target.value}}))
                    }
                    }}></input>

                  <datalist id="meterialDataList">
                              {
                                pricingListRequestData.map((addUp)=>{
                                  if(addUp.type === billOfQuantities.TYPES.ITEM && !pricingListRequestLoading){
                                    return (
                                      <option key={addUp._id} value={addUp.name}>{addUp.name}</option>
                                    )
                                  }
                                })
                              }
                            </datalist> */}
                           <div style={{ width: 400}}>
                                <ReactSearchAutocomplete
                                  placeholder={item.name}
                                  formatResult = {formatResult}
                                  items={pricingListRequestData.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.ITEM)} 
                                  fuseOptions={{keys : ["name","type"]}}
                                  showIcon= {false}
                                  readOnly={true}
                                  // onSelect = {(event)=>{dispatch(updateItemData({item : {...item, name : event.name, type : event.type}}))}}
                                  styling={{
                                    height: "34px",
                                    border: "none",
                                    // border: "1px solid darkgreen",
                                    borderRadius: "4px",
                                    backgroundColor: "white",
                                    boxShadow: "none",
                                    hoverBackgroundColor: "lightgreen",
                                    color: "black",
                                    // fontSize: "12px",
                                    // fontFamily: "Courier",
                                    iconColor: "green",
                                    lineColor: "lightgreen",
                                    placeholderColor: "darkgreen",
                                    clearIconMargin: "3px 8px 0 0",
                                    zIndex : 999,
                                    // zIndex: {labourZIndex},
                                  }}
                                />
                            </div>
                </div>
                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED)&& (userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)))?
                    <div className='col-1 d-flex'> 
                    {/* Qty */}
                    <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0' 
                    readOnly={true}
                    onBlur={(e)=>{
                        // dispatch(updateItem({...item, units_required: e.target.value}))
                        // dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
                    }}></input>
                   
                   {/* UOM */}
                    <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom}
                    readOnly={true}
                    onBlur={(e)=>{
                    // dispatch(updateItem({...item, uom :e.target.value}))
                    // dispatch(updateItemData({item :{...item, uom :e.target.value}}))
                    
                    }}></input>
                </div>
                    :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
                        <div className='col-1 d-flex'> 
                        {/* Qty */}
                        <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0'
                        readOnly={true}
                        onBlur={(e)=>{
                            // dispatch(updateItem({...item, units_required: e.target.value}))
                            // dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
                        }}></input>
                      
                      {/* UOM */}
                        {/* <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom}  onBlur={(e)=>{
                        // dispatch(updateItem({...item, uom :e.target.value}))
                        dispatch(updateItemData({item :{...item, uom :e.target.value}}))
                        
                        }}></input> */}
                    </div>

                :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                    <div className='col-1 d-flex'> 
                    {/* Qty */}
                    {/* <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0' onBlur={(e)=>{
                        // dispatch(updateItem({...item, units_required: e.target.value}))
                        dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
                    }}></input> */}
                  
                  {/* UOM */}
                    <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom}  
                    readOnly={true}
                    onBlur={(e)=>{
                    // dispatch(updateItem({...item, uom :e.target.value}))
                    // dispatch(updateItemData({item :{...item, uom :e.target.value}}))
                    
                    }}></input>
                </div>

                :null}
                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
                  <div className='col-1'> 
                    {/* unit_cost */}
                    <input type="text"  className='section-input form-control  m-0 p-0' defaultValue={item.cost_per_unit} 
                    readOnly={true}
                    onBlur={(e)=>{
                    // dispatch(updateItem({...item, cost_per_unit :e.target.value}))
                    // dispatch(updateItemData({item :{...item, cost_per_unit :e.target.value}}))
                    
                    }}></input>
                  </div>
                :null}
                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
                   <div className='col-1'> 
                   {/* cost_of_units */}
                
                   <input type="number" defaultValue={(parseFloat(item.cost_per_unit)*parseFloat(item.units_required)).toFixed(2)} className='section-input'  
                     readOnly={true}></input>
               </div>
                :null}

                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
                   <div className='col-1'>
                   {/* markup  */}
                   <input type="text" defaultValue={item.margin} className='section-input form-control  m-0 p-0'
                   readOnly={true}
                   onBlur={(e)=>{
                   // dispatch(updateItem({...item, margin :e.target.value}))
                  //  dispatch(updateItemData({item :{...item, margin :e.target.value}}))
                   
                   }}></input>
               </div>
                :null}
                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
                    <div className='col-1'> 
                    {/* Client Charge */}
                    <input type="number" defaultValue={((parseFloat(item.cost_per_unit)*parseFloat(item.units_required))*(1+(parseFloat(item.margin)/100))).toFixed(2)} className='section-input '  readOnly={true}
                    ></input>
                </div>
                :null}


                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
                      <div className='col-2' > 
                      <select className='section-input form-select m-0 p-0' defaultValue={item.status} onBlur={(e)=>{
                      // dispatch(updateItem({...item, status :e.target.value}))
                      // dispatch(updateItemData({item : {...item, status :e.target.value}}))
                      
                      }}
                      readOnly={true}
                       >
                          <option value="Prime">Prime</option>
                          <option value="Provitional">Provitional</option>
                          <option value="Excluded">Excluded</option>
                      </select>
                  </div>
                :null}


                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
                //     <div className='col-1'> 
                //     <input type="text" defaultValue={item.type} className='section-input form-control m-0 p-0'  onBlur={(e)=>{
                //     // dispatch(updateItem({...item, type :e.target.value}))
                //     dispatch(updateItemData({item :{...item, type :e.target.value}}))
                    
                //     }} ></input>
                // </div>

                  <div className="col-1">
                  {/* <select
                    className="section-input form-select m-0 p-0"
                    defaultValue={labour.name}
                    onChange={(e) => {
                      let itemObject = {...item}
                      // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
                      if(e.target.selectedOptions[0].getAttribute("specification") ===billOfQuantities.TYPES.SPECIFIED && e.target.selectedOptions[0].getAttribute("labourid"))
                      {
                        console.log("under current labour",e.target.value)
                        
                        dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}}))
                      }
                      // else if (e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){
                        
                      //   delete itemObject.labour_id
                      //   console.log("new labour",itemObject)
                      //   dispatch(updateItemData({item :{...itemObject, specialism :e.target.selectedOptions[0].getAttribute("type")}}))
                      // }
                      else if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.UNSPECIFIED){
                        delete itemObject.labour_id
                        
                        dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}}))

                      }
                      
                      // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
                                           }}
                  > 
                  {itemRequestData.map((currentLabour)=>{
                    if(currentLabour.type === billOfQuantities.TYPES.LABOUR && currentLabour.section_id === labour.section_id && currentLabour.specification === billOfQuantities.TYPES.SPECIFIED)
                    return <option key={currentLabour._id} value={currentLabour.name} labourid={currentLabour._id}
                    specification={billOfQuantities.TYPES.SPECIFIED} 
                    >{currentLabour.name}</option>
                  })}
                    <hr className="dropdown-divider"/>
                  
                    <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option>
                   
                  </select> */}
                  </div>

                :null}
                
               
               
              
              
                
                {/* <div className='col-1'><CancelPresentationTwoToneIcon onClick={()=>{dispatch(deleteItems({item}))}} color='warning'/></div> */}
                
            </div>
            )
        }
    }))
   }

   const DisplaySection = ()=>{
      let sectionArray =[]
   
      checkedDataObject.items.forEach((itemId)=>{
        let item = itemRequestData.find((currentItem)=> currentItem._id ===itemId)
        if(!sectionArray.some((section)=>section._id === item.section_id)){
          const section = sectionRequestData.find((currentSection)=>currentSection._id ===item.section_id)
          sectionArray.push(section)
        }
      })
      console.log("SECTION_ID ARRAY", sectionArray )
      return (
        sectionArray.map((section)=>{

          return (
            <section className='section-container' id="accordian">
            <div className='section-inner-container  row' >
             {/* <h3 className='col-3'> <AddIcon  onClick={()=>{setNumOfNewSections((prev)=>prev+1)}} /><span data-bs-toggle="collapse" data-bs-target={"."+section.name}  aria-expanded="true" aria-controls="collapseWidthExample">{section.name}</span></h3> */}
             <div className='col-3 d-flex align-items-center justify-content-start'>
                 <h3><AddIcon  onClick={()=>{
                    // dispatch(changeSectionModalStatus())
                    // dispatch(createSection())
                    }} /></h3>

                 {/* {isCurrentChecked(section._id , billOfQuantities.TYPES.SECTION)  ?
                          <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked, id: section._id, type: billOfQuantities.TYPES.SECTION}))}} defaultChecked></input>   
                        :
                        
                        <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({ isChecked:e.target.checked, id: section._id, type: billOfQuantities.TYPES.SECTION}))}} ></input>   
                      } */}
                 {/* <h3 className='section-input'>{section.name}</h3> */}
                 <h3><input  className='section-input ' type='text' defaultValue={section.name} onBlur={(e)=>{
                        // if(!isDuplicateName && section.name.toLowerCase()!==e.target.value.toLowerCase()){
                            
                        //     // dispatch(updateSectionData({"updateSection" :{...section , name : e.target.value}}))
                        // }
                 }} onChange={(e)=>{
                    // validateSectionName(e)
                 }} ></input></h3>
                 {/* {isDuplicateName? <p>Please Enter a unique section name</p> : null} */}
            </div>
             {/* <h3 className='col-3'> <AddIcon  onClick={()=>{
                dispatch(changeSectionModalStatus()
                             
             )}} /><input  className='section-input' type='text' defaultValue={section.name}   
            
    ></input></h3> */}
            {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED)&& userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                <p className='col-1'></p>
            :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
                <p className='col-1'></p>
                

            :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                <p className='col-1'></p>
        :null}
            {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
                <p className='col-1'></p>
            :null}
            {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
            <div className='col-1 justify-content-start'><input value={0} type='number' className='section-input' readOnly={true}/></div>
            :null}
            {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
            <div className='col-1 justify-content-start'><input value={0} type='number' className='section-input' readOnly={true}/></div>
            :null}
            {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
            <div className='col-1 justify-content-start'><input value={0} type='number' readOnly={true} className='section-input'/></div>
            :null}
            {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
            <p className='col-2'></p>
            :null}
            {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
             <p className='col-1'></p>
            :null}
            {/* <p className='col-1'></p>
            <p className='col-1'></p>
            <div className='col-1 justify-content-start'><input value={costOfUnits} type='number' className='section-input' readOnly={true}/></div>
            <div className='col-1 justify-content-start'><input value={markup} type='number' className='section-input' readOnly={true}/></div>
            <div className='col-1 justify-content-start'><input value={clientCharge} type='number' readOnly={true} className='section-input'/></div>

            <p className='col-2'></p>
            <p className='col-1'>TENDER</p> */}
            {/* <div className='col-1 section-inner-util'><KeyboardArrowDownIcon  fontSize="large" onClick={()=>{
              // setShow((prev)=>!prev)
              }} /><CancelPresentationTwoToneIcon color='warning' onClick={()=>{
                // dispatch(deleteSections({index,section}))
                }} /><MoreVertTwoToneIcon/>
            </div> */}
            
       </div>
                <DisplayLabour sectionId={section._id}/>
                {checkedItemsDataArray.some((item)=>item.type!==billOfQuantities.TYPES.LABOUR && item.specification === billOfQuantities.TYPES.UNSPECIFIED)?
                <>
                <Unspecified sectionId={section._id}/>
                </>:null}
      
      
       


       
       
       
       
    
       
               
        

               
       {/* <div className={"section-container-item-name collapse  multi-collapse"+" "+section.name}>
       <AddIcon  onClick={()=>{
        setNumOfNewItems((prev)=>prev+1)
        setShow((prev)=>(prev== "show" ? "show" : "show" ))
       }}  />
       </div> */}

        
       
    </section>
          )
          
        })
      )
   }

   const Unspecified = ({sectionId})=>{

    const labour= {
        name:"UNSPECIFIED",
        cost_per_unit:"0",
        units_required:"0",
        margin:"0",
        total:"0",
        
        uom:"",
        status:"",
        type:"UNSPECIFIED"}
    return(
    <>
        
        <div  className={"section-labour-container row"}>
        <div className="section-container-labour-name col-3 d-flex">
          {/* <input className="form-check-input" type="checkbox"></input> */}
          
          {/* check box */}
          {/* {checkAllUnspecified()?
            <input className="form-check-input" type="checkbox" onChange={(e)=>{
              itemRequestData.forEach((item)=>{
                if(!isCurrentChecked(item._id, item.type) && section._id === item.section_id && e.target.checked && item.specification === billOfQuantities.TYPES.UNSPECIFIED){

                  dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: item.type}))
                }
              })
            }}
              defaultChecked ></input> 
            :
            <>
            <input className="form-check-input" type="checkbox" onChange={(e)=>{
                            itemRequestData.forEach((item)=>{
                              if(!isCurrentChecked(item._id, item.type) && section._id === item.section_id && e.target.checked && item.specification === billOfQuantities.TYPES.UNSPECIFIED){

                                dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: item.type}))
                              }
                            })
                          }}
                             ></input> 
             </>

          } */}
            
          {/* {isCurrentChecked(labour._id , billOfQuantities.TYPES.LABOUR)?
                            <input className="form-check-input" type="checkbox" onChange={(e)=>{
                              itemRequestData.forEach((item)=>{
                                if(!isCurrentChecked(item._id, item.type) && section.id === item.section_id && e.target.checked){

                                  dispatch(addToCheckedList({"isChecked":e.target.checked,"id": item._id, "type": item.type}))
                                }
                              })
                            }} defaultChecked></input>   
                          :
                          
                          <input className="form-check-input" type="checkbox" onChange={(e)=>{
                            itemRequestData.forEach((item)=>{
                              if(!isCurrentChecked(item._id, item.type) && section.id === item.section_id && e.target.checked){

                                dispatch(addToCheckedList({"isChecked":e.target.checked,"id": item._id, "type": item.type}))
                              }
                            })
                            dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} ></input>   
                        } */}
          <input
            type="text"
            defaultValue={labour.name}
            className=" section-input form-control m-0 p-0"
           
            // onBlur={(e) => {
            //   if(e.target.value.toLowerCase()!==labour.name.toLowerCase() && !isDuplicateName){
  
            //     dispatch(updateItemData({item : { ...labour, name: e.target.value }}));
            //   }
            // }}
            readOnly={true}
          ></input>
          
        </div>
          {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED)&& (userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)))?
              <div className="col-1 d-flex">
              {/* Qty */}
              <input
                type="text"
                defaultValue={labour.units_required}
                className="section-input form-control m-0 p-0"
                // onBlur={(e) => {
                //   // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
                //   dispatch(updateItemData({item : { ...labour, units_required: e.target.value }}));
                // }}
              ></input>
      
              {/* UOM */}
              <input
                type="text"
                className="section-input form-control m-0 p-0"
                defaultValue={labour.uom}
                // onBlur={(e) => {
                //   // dispatch(updateLabour({ ...labour, uom: e.target.value }));
                //   dispatch(updateItemData({item :{ ...labour, uom: e.target.value }}));
                // }}
              ></input>
            </div>           
            
            :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
            <div className="col-1 d-flex">
            {/* Qty */}
            <input
              type="text"
              defaultValue={labour.units_required}
              className="section-input form-control m-0 p-0"
            //   onBlur={(e) => {
            //     // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
            //     dispatch(updateItemData({item : { ...labour, units_required: e.target.value }}));
            //   }}
            ></input>
    
           
          </div>
  
        :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
        <div className="col-1 d-flex">
        {/* Qty */}
        {/* <input
          type="text"
          defaultValue={labour.units_required}
          className="section-input form-control m-0 p-0"
          onBlur={(e) => {
            // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
            dispatch(updateLabourData({labour : { ...labour, units_required: e.target.value }}));
          }}
        ></input> */}
  
        {/* UOM */}
        <input
          type="text"
          className="section-input form-control m-0 p-0"
          defaultValue={labour.uom}
        //   onBlur={(e) => {
        //     // dispatch(updateLabour({ ...labour, uom: e.target.value }));
        //     dispatch(updateItemData({item :{ ...labour, uom: e.target.value }}));
        //   }}
        ></input>
      </div>
  
        :null}
        
        {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
            <div className="col-1">
            {/* unit_cost */}
            <input
              type="text"
              className="section-input form-control m-0 p-0"
              defaultValue={labour.cost_per_unit}
            //   onBlur={(e) => {
            //     // dispatch(updateLabour({ ...labour, cost_per_unit: e.target.value }));
            //     dispatch(updateItemData({item :{ ...labour, cost_per_unit: e.target.value }}));
            //   }}
            ></input>
          </div>  
      :null}
        {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
           <div className="col-1">
           {/* cost_of_units */}
           <input
             type="number"
             defaultValue={(parseFloat(labour.cost_per_unit)*parseFloat(labour.units_required)).toFixed(2)} 
             className="section-input"
             readOnly={true}
           ></input>
         </div>  
      :null}
        {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
         <div className="col-1">
         {/* markup  */}
         <input
           type="text"
           defaultValue={labour.margin}
           className="section-input form-control m-0 p-0"
        //    onBlur={(e) => {
        //      // dispatch(updateLabour({ ...labour, margin: e.target.value }));
        //      dispatch(updateItemData({item :{ ...labour, margin: e.target.value }}));
        //    }}
         ></input>
       </div>
      :null}
        {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
          <div className="col-1">
          {/* Client Charge */}
          <input
            type="number"
            defaultValue={(parseFloat(labour.cost_per_unit)*parseFloat(labour.units_required)*(1+(parseFloat(labour.margin)/100))).toFixed(2)} 
            className="section-input"
            readOnly={true}
          ></input>
        </div>
      :null}
  
        {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
         <div className="col-2">
         {/* <select
           className="section-input form-select m-0 p-0"
           defaultValue={labour.status}
           readOnly={true}
        //    onBlur={(e) => {
        //      // dispatch(updateLabour({ ...labour, status: e.target.value }));
        //      dispatch(updateItemData({item :{ ...labour, status: e.target.value }}));
        //    }}
         >
           <option value="Prime">Prime</option>
           <option value="Provitional">Provitional</option>
           <option value="Excluded">Excluded</option>
         </select> */}
       </div>
      :null}
  
        {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
        //   <div className="col-1">
        //   <input
        //     type="text"
        //     defaultValue={labour.type}
        //     className="section-input form-control m-0 p-0"
        //     onBlur={(e) => {
        //       // dispatch(updateLabour({ ...labour, type: e.target.value }));
        //       dispatch(updateLabourData({labour :{ ...labour, type: e.target.value }}));
        //     }}
        //   ></input>
        // </div>
  
            <div className="col-1">
            {/* <select
              className="section-input form-select m-0 p-0"
              defaultValue={labour.type}
              onBlur={(e) => {
                let itemObject = {...labour}
                // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
                if(e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.ITEM && e.target.selectedOptions[0].getAttribute("labourid"))
                {
                  console.log("under current labour",e.target.value)
                  // delete itemObject.section_id
                  dispatch(updateItemData({item :{...itemObject, type :e.target.selectedOptions[0].getAttribute("type"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}}))
                }
                else if (e.target.selectedOptions[0].getAttribute("type")&& e.target.selectedOptions[0].getAttribute("labourid")==null){
                  console.log("new labour",e.target.value)
                  delete itemObject.labour_id
                  dispatch(updateItemData({item :{...itemObject, type :e.target.selectedOptions[0].getAttribute("type"), section_id : labour.section_id}}))
                }
                else if (e.target.selectedOptions[0].getAttribute("type")===billOfQuantities.TYPES.UNSPECIFIED){
                  delete itemObject.labour_id
                  
                  dispatch(updateItemData({item :{...itemObject, type :e.target.selectedOptions[0].getAttribute("type")}}))
  
                }
                
                // dispatch(updateLabour({ ...labour, type: e.target.value }));
                // dispatch(updateItemData({labour :{ ...labour, type: e.target.value }}));
              }}
            >
           {items.map((currentLabour)=>{
                          if(currentLabour.section_id === labour.section_id)
                          return <option value={currentLabour.name}>{currentLabour.name}</option>
                        })}
                          <hr className="dropdown-divider"/>
                          <option className="m-1" value="newLabour">Add New Labour</option>
                          <option className="m-1" value="unspecified">unspecified</option>
                          
            </select> */}
            </div>
            :null}
    
        {/* <div className="col-1">
            <KeyboardArrowDownIcon
            fontSize="large"
            onClick={() => {
                // setShow((prev) => !prev);
            }}
            />
            <CancelPresentationTwoToneIcon
            onClick={() => {
                console.log("delete Items ", labour)
                dispatch(deleteItems({item :labour}))
            }}
            color="warning"
            />
        </div> */}
        </div>
        <UnspecifiedItems sectionId={sectionId}/>

            {/* <div className="row">
              <div className='col-3 d-flex'>
                <div className="section-container-item-name " >
                  <AddIcon data-bs-toggle="dropdown"  role="button" aria-expanded="false"   />
                  <ul className="dropdown-menu">
                        
                          <li onClick={()=>{ 
                            // dispatch(changeAddUpModalStatus({sectionId : section._id, labourSpecification : billOfQuantities.TYPES.UNSPECIFIED, itemSpecification : billOfQuantities.TYPES.UNSPECIFIED}))
                            }}><a className="dropdown-item" >New labour/Item</a></li>
                    </ul> 
                </div>
                <div  style={{ width: 400}}>
                                    <ReactSearchAutocomplete
                                      placeholder={"Add Unspecified"}
                                      fuseOptions={{keys : ["name","type"]}}
                                      items={pricingListRequestData} 
                                      showIcon= {false}
                                      formatResult ={formatResult}
                                      onSelect = {(event)=>{
                                        // dispatch(createItem({item : { name :event.name , sectionId : section._id , type : billOfQuantities.TYPES.ITEM , specification : billOfQuantities.TYPES.UNSPECIFIED, cost_per_unit : event.cost_per_unit, uom : event.uom, margin : event.margin}} ))
                                      }}
                                      styling={{
                                        height: "34px",
                                        border: "none",
                                        // border: "1px solid darkgreen",
                                        borderRadius: "4px",
                                        backgroundColor: "white",
                                        boxShadow: "none",
                                        hoverBackgroundColor: "lightgreen",
                                        color: "black",
                                        // fontSize: "12px",
                                        // fontFamily: "Courier",
                                        iconColor: "green",
                                        lineColor: "lightgreen",
                                        placeholderColor: "#002D62",
                                        clearIconMargin: "3px 8px 0 0",
                                        zIndex : "auto",
                                        // zIndex: {labourZIndex},
                                      }}
                                    />
                    </div>
                  </div>
            </div> */}
    </>
    )
}


const UnspecifiedItems = ({sectionId})=>{

  return checkedItemsDataArray.map((item)=>{
      if((item.specification === billOfQuantities.TYPES.UNSPECIFIED ) && item.section_id === sectionId && item.type === billOfQuantities.TYPES.ITEM){
              
        
         return(
            
            
              <div key={item._id} className={"section-item-container row"}> 
              
                  <div className='section-container-item-name col-3 d-flex' > 
                  {/* {isCurrentChecked(item._id , billOfQuantities.TYPES.ITEM)?
                        <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}} defaultChecked></input>   
                      :
                      
                      <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}} ></input>   
                    } */}
                    {/* <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": item._id, "type": billOfQuantities.TYPES.ITEM}))}} ></input>    */}
                      {/* <input type="text" defaultValue={item.name} className='section-input form-control ' onBlur={(e)=>{
                      // dispatch(updateItem({...item, name :e.target.value}))
                      dispatch(updateItemData({item:{...item, name :e.target.value}}))
                      }}></input> */}
                       <div style={{ width: 400}}>
                          <ReactSearchAutocomplete
                            placeholder={item.name}
                            fuseOptions={{keys : ["name","type"]}}
                            items={pricingListRequestData.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.ITEM)} 
                            showIcon= {false}
                            // onSelect = {(event)=>{dispatch(updateItemData({item : {...item, name : event.name, type : event.type}}))}}
                            readOnly={true}
                            styling={{
                              height: "34px",
                              border: "none",
                              // border: "1px solid darkgreen",
                              borderRadius: "4px",
                              backgroundColor: "white",
                              boxShadow: "none",
                              hoverBackgroundColor: "lightgreen",
                              color: "black",
                              // fontSize: "12px",
                              // fontFamily: "Courier",
                              iconColor: "green",
                              lineColor: "lightgreen",
                              placeholderColor: "darkgreen",
                              clearIconMargin: "3px 8px 0 0",
                              zIndex :999,
                              // zIndex: {labourZIndex},
                            }}
                          />
                        </div>
                  </div>
                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED)&& (userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)))?
                      <div className='col-1 d-flex'> 
                      {/* Qty */}
                      <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0'
                      readOnly={true}
                      onBlur={(e)=>{
                          // dispatch(updateItem({...item, units_required: e.target.value}))
                          // dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
                      }}></input>
                     
                     {/* UOM */}
                      <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom}
                      readOnly={true}
                      onBlur={(e)=>{
                      // dispatch(updateItem({...item, uom :e.target.value}))
                      // dispatch(updateItemData({item :{...item, uom :e.target.value}}))
                      
                      }}></input>
                  </div>
                      :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
                          <div className='col-1 d-flex'> 
                          {/* Qty */}
                          <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0'
                          readOnly={true}
                          onBlur={(e)=>{
                              // dispatch(updateItem({...item, units_required: e.target.value}))
                              // dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
                          }}></input>
                        
                        {/* UOM */}
                          {/* <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom}  onBlur={(e)=>{
                          // dispatch(updateItem({...item, uom :e.target.value}))
                          dispatch(updateItemData({item :{...item, uom :e.target.value}}))
                          
                          }}></input> */}
                      </div>

                  :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                      <div className='col-1 d-flex'> 
                      {/* Qty */}
                      {/* <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0' onBlur={(e)=>{
                          // dispatch(updateItem({...item, units_required: e.target.value}))
                          dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
                      }}></input> */}
                    
                    {/* UOM */}
                      <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom} 
                      readOnly={true}
                      onBlur={(e)=>{
                      // dispatch(updateItem({...item, uom :e.target.value}))
                      // dispatch(updateItemData({item :{...item, uom :e.target.value}}))
                      
                      }}></input>
                  </div>

                  :null}
                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
                    <div className='col-1'> 
                      {/* unit_cost */}
                      <input type="text"  className='section-input form-control  m-0 p-0' defaultValue={item.cost_per_unit}
                      readOnly={true}
                      onBlur={(e)=>{
                      // dispatch(updateItem({...item, cost_per_unit :e.target.value}))
                      // dispatch(updateItemData({item :{...item, cost_per_unit :e.target.value}}))
                      
                      }}></input>
                    </div>
                  :null}
                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
                     <div className='col-1'> 
                     {/* cost_of_units */}
                  
                     <input type="number" defaultValue={(parseFloat(item.cost_per_unit)*parseFloat(item.units_required)).toFixed(2)} className='section-input'  
                       readOnly={true}></input>
                 </div>
                  :null}

                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
                     <div className='col-1'>
                     {/* markup  */}
                     <input type="text" defaultValue={item.margin} className='section-input form-control  m-0 p-0'
                     readOnly={true}
                     onBlur={(e)=>{
                     // dispatch(updateItem({...item, margin :e.target.value}))
                    //  dispatch(updateItemData({item :{...item, margin :e.target.value}}))
                     
                     }}></input>
                 </div>
                  :null}
                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
                      <div className='col-1'> 
                      {/* Client Charge */}
                      <input type="number" defaultValue={((parseFloat(item.cost_per_unit)*parseFloat(item.units_required))*(1+(parseFloat(item.margin)/100))).toFixed(2)} className='section-input '  readOnly={true}
                      ></input>
                  </div>
                  :null}


                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
                        <div className='col-2' > 
                        <select className='section-input form-select m-0 p-0' 
                        defaultValue={item.status}
                        readOnly={true}
                        onBlur={(e)=>{
                        // dispatch(updateItem({...item, status :e.target.value}))
                        // dispatch(updateItemData({item : {...item, status :e.target.value}}))
                        
                        }} >
                            <option value="Prime">Prime</option>
                            <option value="Provitional">Provitional</option>
                            <option value="Excluded">Excluded</option>
                        </select>
                    </div>
                  :null}


                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
                  //     <div className='col-1'> 
                  //     <input type="text" defaultValue={item.type} className='section-input form-control m-0 p-0'  onBlur={(e)=>{
                  //     // dispatch(updateItem({...item, type :e.target.value}))
                  //     dispatch(updateItemData({item :{...item, type :e.target.value}}))
                      
                  //     }} ></input>
                  // </div>

                    <div className="col-1">
                    {/* <select
                      className="section-input form-select m-0 p-0"
                      defaultValue={item.specification}
                      onChange={(e) => {
                        let itemObject = {...item}
                        // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
                        if(e.target.selectedOptions[0].getAttribute("specification") ===billOfQuantities.TYPES.SPECIFIED && e.target.selectedOptions[0].getAttribute("labourid"))
                        {
                          console.log("under current labour",e.target.value)
                          
                          dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}}))
                        }
                        // else if (e.target.selectedOptions[0].getAttribute("specialism") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){
                        //   console.log("new labour",e.target.value)
                        //   delete itemObject.labour_id
                        //   dispatch(updateItemData({item :{...itemObject, specialism :e.target.selectedOptions[0].getAttribute("specialism")}}))
                        // }
                        else if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.UNSPECIFIED){
                          delete itemObject.labour_id
                          
                          dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}}))
          
                        }
                        
                        // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
                                             }}
                    > 
                  {itemRequestData.map((currentLabour)=>{
                      if(currentLabour.type === billOfQuantities.TYPES.LABOUR && currentLabour.section_id === sectionId && currentLabour.specification === billOfQuantities.TYPES.SPECIFIED)
                      return <option key={currentLabour._id} value={currentLabour.name} labourid={currentLabour._id}
                      specification={billOfQuantities.TYPES.SPECIFIED} 
                      >{currentLabour.name}</option>
                    })}
                      <hr className="dropdown-divider"/>
                      {/* <option className="m-1" value={billOfQuantities.TYPES.LABOUR} 
                      specialism={billOfQuantities.TYPES.LABOUR} 
                      >Add New Labour</option> */}
                      {/* <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option> */}
                      {/* <option value="Provitional">Provitional</option>
                     
                    </select> */}
                    </div>

                  :null}
                  
                 
                 
                
                
                  
                  {/* <div className='col-1'><CancelPresentationTwoToneIcon onClick={()=>{dispatch(deleteItems({item}))}} color='warning'/></div> */}
                  
              </div>
              
      ) 
      }
      // else if (item.specification === billOfQuantities.TYPES.UNSPECIFIED  && item.section_id === section._id && item.type === billOfQuantities.TYPES.LABOUR){
      //    return (
      //    <div key={item._id} className={"section-item-container row"}> 
                
      //     <div className='section-container-item-name col-3 d-flex' > 
      //     {isCurrentChecked(item._id , billOfQuantities.TYPES.LABOUR)?
      //           <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked, id: item._id, type: billOfQuantities.TYPES.LABOUR}))}} defaultChecked></input>   
      //         :
              
      //         <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked, id: item._id, type: billOfQuantities.TYPES.LABOUR}))}} ></input>   
      //       }
      //       {/* <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": item._id, "type": billOfQuantities.TYPES.ITEM}))}} ></input>    */}
      //         {/* <input type="text" defaultValue={item.name} className='section-input form-control ' onBlur={(e)=>{
      //         // dispatch(updateItem({...item, name :e.target.value}))
      //         dispatch(updateItemData({item:{...item, name :e.target.value}}))
      //         }}></input> */}
      //         <div style={{ width: 400}}>
      //             <ReactSearchAutocomplete
      //               placeholder={item.name}
      //               fuseOptions={{keys : ["name","type"]}}
      //               items={pricingListCurrent.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.ITEM)} 
      //               showIcon= {false}
      //               onSelect = {(event)=>{dispatch(updateItemData({item : {...item, name : event.name, type : event.type}}))}}
      //               styling={{
      //                 height: "34px",
      //                 border: "none",
      //                 // border: "1px solid darkgreen",
      //                 borderRadius: "4px",
      //                 backgroundColor: "white",
      //                 boxShadow: "none",
      //                 hoverBackgroundColor: "lightgreen",
      //                 color: "black",
      //                 // fontSize: "12px",
      //                 // fontFamily: "Courier",
      //                 iconColor: "green",
      //                 lineColor: "lightgreen",
      //                 placeholderColor: "darkgreen",
      //                 clearIconMargin: "3px 8px 0 0",
      //                 zIndex : "auto",
      //                 // zIndex: {labourZIndex},
      //               }}
      //             />
      //           </div>
      //     </div>
      //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED)&& (userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)))?
      //         <div className='col-1 d-flex'> 
      //         {/* Qty */}
      //         <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0' onBlur={(e)=>{
      //             // dispatch(updateItem({...item, units_required: e.target.value}))
      //             dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
      //         }}></input>
            
      //       {/* UOM */}
      //         <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom}  onBlur={(e)=>{
      //         // dispatch(updateItem({...item, uom :e.target.value}))
      //         dispatch(updateItemData({item :{...item, uom :e.target.value}}))
              
      //         }}></input>
      //     </div>
      //         :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
      //             <div className='col-1 d-flex'> 
      //             {/* Qty */}
      //             <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0' onBlur={(e)=>{
      //                 // dispatch(updateItem({...item, units_required: e.target.value}))
      //                 dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
      //             }}></input>
                
      //           {/* UOM */}
      //             {/* <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom}  onBlur={(e)=>{
      //             // dispatch(updateItem({...item, uom :e.target.value}))
      //             dispatch(updateItemData({item :{...item, uom :e.target.value}}))
                  
      //             }}></input> */}
      //         </div>

      //     :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
      //         <div className='col-1 d-flex'> 
      //         {/* Qty */}
      //         {/* <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0' onBlur={(e)=>{
      //             // dispatch(updateItem({...item, units_required: e.target.value}))
      //             dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
      //         }}></input> */}
            
      //       {/* UOM */}
      //         <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom}  onBlur={(e)=>{
      //         // dispatch(updateItem({...item, uom :e.target.value}))
      //         dispatch(updateItemData({item :{...item, uom :e.target.value}}))
              
      //         }}></input>
      //     </div>

      //     :null}
      //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
      //       <div className='col-1'> 
      //         {/* unit_cost */}
      //         <input type="text"  className='section-input form-control  m-0 p-0' defaultValue={item.cost_per_unit}  onBlur={(e)=>{
      //         // dispatch(updateItem({...item, cost_per_unit :e.target.value}))
      //         dispatch(updateItemData({item :{...item, cost_per_unit :e.target.value}}))
              
      //         }}></input>
      //       </div>
      //     :null}
      //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
      //       <div className='col-1'> 
      //       {/* cost_of_units */}
          
      //       <input type="number" defaultValue={(parseFloat(item.cost_per_unit)*parseFloat(item.units_required)).toFixed(2)} className='section-input'  
      //         readOnly={true}></input>
      //   </div>
      //     :null}

      //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
      //       <div className='col-1'>
      //       {/* markup  */}
      //       <input type="text" defaultValue={item.margin} className='section-input form-control  m-0 p-0' onBlur={(e)=>{
      //       // dispatch(updateItem({...item, margin :e.target.value}))
      //       dispatch(updateItemData({item :{...item, margin :e.target.value}}))
            
      //       }}></input>
      //   </div>
      //     :null}
      //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
      //         <div className='col-1'> 
      //         {/* Client Charge */}
      //         <input type="number" defaultValue={((parseFloat(item.cost_per_unit)*parseFloat(item.units_required))*(1+(parseFloat(item.margin)/100))).toFixed(2)} className='section-input '  readOnly={true}
      //         ></input>
      //     </div>
      //     :null}


      //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
      //           <div className='col-2' > 
      //           <select className='section-input form-select m-0 p-0' defaultValue={item.status} onBlur={(e)=>{
      //           // dispatch(updateItem({...item, status :e.target.value}))
      //           dispatch(updateItemData({item : {...item, status :e.target.value}}))
                
      //           }} >
      //               <option value="Prime">Prime</option>
      //               <option value="Provitional">Provitional</option>
      //               <option value="Excluded">Excluded</option>
      //           </select>
      //       </div>
      //     :null}


      //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
      //     //     <div className='col-1'> 
      //     //     <input type="text" defaultValue={item.type} className='section-input form-control m-0 p-0'  onBlur={(e)=>{
      //     //     // dispatch(updateItem({...item, type :e.target.value}))
      //     //     dispatch(updateItemData({item :{...item, type :e.target.value}}))
              
      //     //     }} ></input>
      //     // </div>

      //       <div className="col-1">
      //       <select
      //         className="section-input form-select m-0 p-0"
      //         defaultValue={item.specification}
      //         onChange={(e) => {
      //           let itemObject = {...item}
      //           // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
      //           // if(e.target.selectedOptions[0].getAttribute("specification") ===billOfQuantities.TYPES.ITEM && e.target.selectedOptions[0].getAttribute("labourid"))
      //           // {
      //           //   console.log("under current labour",e.target.value)
                  
      //           //   dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}}))
      //           // }
      //           // else if (e.target.selectedOptions[0].getAttribute("specialism") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){
      //           //   console.log("new labour",e.target.value)
      //           //   delete itemObject.labour_id
      //           //   dispatch(updateItemData({item :{...itemObject, specialism :e.target.selectedOptions[0].getAttribute("specialism")}}))
      //           // }
      //           if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.SPECIFIED){
      //             delete itemObject.labour_id
                  
      //             dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}}))
  
      //           }
                
      //           // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
      //                               }}
      //       > 
      //     {/* {items.map((currentLabour)=>{
      //         if(currentLabour.type === billOfQuantities.TYPES.LABOUR && currentLabour.section_id === section._id)
      //         return <option key={currentLabour._id} value={currentLabour.name} labourid={currentLabour._id}
      //         specification={billOfQuantities.TYPES.ITEM} 
      //         >{currentLabour.name}</option>
      //       })} */}
      //         <hr className="dropdown-divider"/>
      //         {/* <option className="m-1" value={billOfQuantities.TYPES.LABOUR} 
      //         specialism={billOfQuantities.TYPES.LABOUR} 
      //         >Add New Labour</option> */}
      //         <option className="m-1" value={billOfQuantities.TYPES.SPECIFIED} specification={billOfQuantities.TYPES.SPECIFIED}>SPECIFIED</option>
      //         <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option>
      //         {/* <option value="Provitional">Provitional</option>
      //         <option value="Excluded">Excluded</option> */}
      //       </select>
      //       </div>

      //     :null}
          
        
        
        
        
          
      //     <div className='col-1'><CancelPresentationTwoToneIcon onClick={()=>{dispatch(deleteItems({item}))}} color='warning'/></div>
          
      // </div>
      //    )
      // }
  })
}


  return (
    <div className='tender-packages-popup-modal-container'>
        
        <Modal  show={showTenderPackageModal} size="xl">
        <Modal.Header closeButton onHide={()=>{dispatch(changeTenderPackageModalStatus())}}>
            <div >
                    <h3>Tender Packages</h3>
                    {/* <button className=' button-cancel'onClick={()=>{dispatch(changeTenderPackageModalStatus())}}>close</button> */}
            </div>
        </Modal.Header>
            <Modal.Body >
                <div className="row">
                  <div className="col-3">Name</div>
                
                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED) && (userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)))?
                  <div className="col-1 d-flex align-items-center">

                  <h6 className="w-50" >Qty&</h6>
                  <h6 className="w-50" >UOM</h6>
              
                  </div>
              :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
                  <div className="col-1 d-flex">

                      <h6 >Qty&</h6>
                      {/* <h6 >UoM</h6> */}
                  </div>

              :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                  <div className="col-1 d-flex">

                      {/* <h6 >Qty&</h6> */}
                      <h6 >UoM</h6>
                  </div>

              :null}
              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
                  <h6 className="col-1">Unit Cost</h6>
              :null}
              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
                  <h6 className="col-1">Cost of Units</h6>
              :null}
              
              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
                  <h6 className="col-1">Markup</h6>
              :null}
              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
                  <h6 className="col-1">Client Charge</h6>
              :null}
              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
                  <h6 className="col-2">Prime/Prov/Excl?</h6>
              :null}
              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
                  <h6 className="col-1" ></h6>
              :null}
                 </div>
                <DisplaySection/>

                
                
            </Modal.Body>
        </Modal>
    </div>
  )
}

export default TenderPackagePopUpModal