import React, { useRef } from 'react'
import Section from '../section/Section'
import './ViewPricing.css'
import { useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeCreateTenderPackageModalStatus, changeOverheadsPercentage, changeSectionModalStatus, changeTenderPackageModalStatus, createSection, getAddUp, getItems, getLabour, getLaboursAndItems, getSections, getUserViewFilter, setCurrentProjectId, setIdArray, updateItem, updateItemData, updateItems, updateLabours, updateSections, updateUserViewFilter, updatedEstimateIdDataOnBackend } from '../../features/PricingSlice'
import SectionPopupModal from '../sectionPopupModal/SectionPopupModal'
import { useLocation } from 'react-router'
import LabourPopupModal from '../labourPopupModal/LabourPopupModal'
import AddIcon from '@mui/icons-material/Add';
import SelectedPopupModal from '../selectedPopupModal/SelectedPopupModal'
import { billOfQuantities, schema } from '../../constants/Constants'
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import PricingListPopupModal from '../pricingListPopUpModal/PricingListPopupModal'
import TenderPackagePopUpModal from '../tenderPackagePopUpModal/TenderPackagePopUpModal'
import dropdownIcon from "../../assets/icons/dropdown-arrow-icon.png"
import CreateTenderPackagePopUpModal from '../createTenderPackagesPopUpModal/CreateTenderPackagePopUpModal'


//ICONS

import ellipseIcon from "../../assets/icons/ellipse_10.png"
import settingsIcon from "../../assets/icons/image_57.png"
import hammerIcon from "../../assets/icons/vector.png"
import whiteTruckIcon from "../../assets/icons/white-truck-icon.png"
import threeDotIcon from "../../assets/icons/three-dot.png"
import menuAddLine from "../../assets/icons/ri_menu-add-line.png"
import dragIcon from '../../assets/icons/material-symbols_drag-indicator-sharp.png'
import treeSwichterOpenIcon from '../../assets/icons/Tree-Switcher-open.png'
import filterIconOne from "../../assets/icons/filter-icon-one.png"
import filterIconTwo from "../../assets/icons/filter-icon-two.png"
import filterIconThree from "../../assets/icons/filter-icon-three.png"
import filterIconFour from "../../assets/icons/filter-icon-four.png"

import transparentIcon from "../../assets/icons/transparent-icon.png"
import ViewPricingColumnChangeIcon from '../viewPricingColumnChangeIcon/ViewPricingColumnChangeIcon'
import DummySection from '../dummysection/DummySection'
import { DragDropContext } from 'react-beautiful-dnd'


const ViewPricing = () => {
    const [numOfNewSections, setNumOfNewSections] = useState(0)
    const [projectId, setProjectId] = useState('')
    // const items = useSelector((state)=>state.pricing.itemRequestData)
    const overheadsPercentage = useSelector((state)=>state.pricing.overheadsPercentage)
    // const items = []  
    // const itemRequestData = useSelector((state)=>state.pricing.itemRequestData)
    // const itemRequestData =[]
    const [selectedOption, setSelectedOption] =  useState(true);
    const newItems = useSelector((state)=>state.pricing.newItems)
    const labours = useSelector((state)=>state.pricing.labourRequestData)
    // const sections = []
    const sections = useSelector((state)=>state.pricing.sectionRequestData)
    const sectionIdArray = useSelector((state)=>state.pricing.sectionIdArray)
    // const sectionRequestData =[]
    const sectionRequestData = useSelector((state)=>state.pricing.sectionRequestData)
    const checkedDataObject = useSelector((state)=> state.pricing.checkedDataObject)

    const updateItemRequestError = useSelector((state)=>state.pricing.updateItemRequestError)
    const sectionRequestError = useSelector((state)=>state.pricing.sectionRequestError)
    const itemRequestError = useSelector((state)=>state.pricing.itemRequestError)
    const updateSectionRequestError = useSelector((state)=>state.pricing.updateSectionRequestError)
    const deleteItemRequestError = useSelector((state)=>state.pricing.deleteItemRequestError)
    const deleteSectionRequestError = useSelector((state)=>state.pricing.deleteSectionRequestError)
    const createSectionRequestError = useSelector((state)=>state.pricing.createSectionRequestError)

    const sectionRequestLoading = useSelector((state)=>state.pricing.sectionRequestLoading)
    const itemRequestLoading = useSelector((state)=>state.pricing.itemRequestLoading)
    const labourRequesetLoading = useSelector((state)=>state.pricing.labourRequesetLoading)
    // const updateItemRequestLoading= useSelector((state)=>state.pricing.updateItemRequestLoading)
    // const updateSectionRequestLoading = useSelector((state)=>state.pricing.updateSectionRequestLoading)
    
    
    const userViewFilters = useSelector((state)=>state.pricing.userViewFilterRequestData)
    const userViewFilterRequestLoading = useSelector((state)=>state.pricing.userViewFilterRequestLoading)



    const [clientCharge, setClientCharge] = useState(0)
    const [costOfUnits, setCostOfUnits] = useState(0)
    const [markup, setMarkup] = useState(0)
    
            


    const [selectedUserFilters, setSelectedUserFilters] = useState(0)
    let selectedUserFilter = []
    const location = useLocation()
    const dispatch = useDispatch()

    const viewPricingElement = useRef()

    useEffect(() => {
      const element = viewPricingElement.current
      const updateItems = (e )=>{
        if(e.key === "Enter"){
            dispatch(updatedEstimateIdDataOnBackend())
        }

      }
      element.addEventListener("keydown",updateItems)
    
      // return () => {
      //   "second"
      // }
    }, [])
    
    useEffect(() => {
      
        setSelectedUserFilters(userViewFilters.length)
        console.log("Filter lenght ", selectedUserFilters)
    }, [userViewFilters])



    
    // useEffect(() => {
    //   console.log("UserFilter",selectedUserFilters)
        
     
    // }, [selectedUserFilters])
    

    const userViewFilterss = {
        UNITS_REQUIRED:"UNITS_REQUIRED",
        UOM:"UOM",
        COST_PER_UNIT:"COST_PER_UNIT",
        COST_OF_UNITS:"COST_OF_UNITS",
        MARGIN:"MARGIN",
        CLIENT_CHARGE:"CLIENT_CHARGE",
        STATUS:"STATUS",
        COST_TYPE:"COST_TYPE",
        TYPE:"TYPE",

        ITEM_TYPE: "ITEM_TYPE",
    }
    const userViewFilter = [
        "UNITS_REQUIRED",
        "UOM",
        "COST_PER_UNIT",
        "COST_OF_UNITS",
        "MARGIN",
        "CLIENT_CHARGE",
        "STATUS",
        "COST_TYPE",
        "TYPE",

        // new filter
        "ITEM_TYPE",
        "UNIT_EX_WASTAGE",
        "WASTAGE_PERCENTAGE",
        "BUY_UNIT_PRICE",
        "SELL_UNIT_PRCIE",
        "CONTINGENCY_PERCENTAGE",
        "MARKUP_PERCENTAGE",
        "CLIENT_CHARGE_INC", 
        "IS_PRICELIST_ITEM",
      
    ]
   
    // const section = {
    //     id : "1",
    //     name :"Bathroom",
    //     project_id : "here is id",
    //     group_items: ""
    // }



    const checkUserViewFilter = (event)=>{
        let newUserViewFilter = []
    
        if( event.target[0].checked){
            newUserViewFilter.push(event.target[0].value)
        }
        if( event.target[1].checked){
            newUserViewFilter.push(event.target[1].value)
        }
        if( event.target[2].checked){
            newUserViewFilter.push(event.target[2].value)
        }
        if( event.target[3].checked){
            newUserViewFilter.push(event.target[3].value)
        }
        if( event.target[4].checked){
            newUserViewFilter.push(event.target[4].value)
        }
        if( event.target[5].checked){
            newUserViewFilter.push(event.target[5].value)
        }
        if( event.target[6].checked){
            newUserViewFilter.push(event.target[6].value)
        }
        if( event.target[7].checked){
            newUserViewFilter.push(event.target[7].value)
        }
        if( event.target[8].checked){
            newUserViewFilter.push(event.target[8].value)
        }
        if( event.target[9].checked){
            newUserViewFilter.push(event.target[9].value)
        }
        if( event.target[10].checked){
            newUserViewFilter.push(event.target[10].value)
        }
        if( event.target[11].checked){
            newUserViewFilter.push(event.target[11].value)
        }
        if( event.target[12].checked){
            newUserViewFilter.push(event.target[12].value)
        }
        if( event.target[13].checked){
            newUserViewFilter.push(event.target[13].value)
        }
        if( event.target[14].checked){
            newUserViewFilter.push(event.target[14].value)
        }
        if( event.target[15].checked){
            newUserViewFilter.push(event.target[15].value)
        }
        if( event.target[16].checked){
            newUserViewFilter.push(event.target[16].value)
        }
        if( event.target[17].checked){
            newUserViewFilter.push(event.target[17].value)
        }
        if( event.target[18].checked){
            newUserViewFilter.push(event.target[18].value)
        }
        if( event.target[19].checked){
            newUserViewFilter.push(event.target[19].value)
        }
        if( event.target[20].checked){
            newUserViewFilter.push(event.target[20].value)
        }
        if( event.target[21].checked){
            newUserViewFilter.push(event.target[21].value)
        }
        if( event.target[22].checked){
            newUserViewFilter.push(event.target[22].value)
        }
        if( event.target[23].checked){
            newUserViewFilter.push(event.target[23].value)
        }
        if( event.target[24].checked){
            newUserViewFilter.push(event.target[24].value)
        }
        if( event.target[25].checked){
            newUserViewFilter.push(event.target[25].value)
        }
        if( event.target[26].checked){
            newUserViewFilter.push(event.target[26].value)
        }
        if( event.target[27].checked){
            newUserViewFilter.push(event.target[27].value)
        }
        if( event.target[28].checked){
            newUserViewFilter.push(event.target[28].value)
        }

        
        console.log("newUserViewFilter",newUserViewFilter)
        dispatch(updateUserViewFilter({updateUserViewFilter : newUserViewFilter}))
    }
    // const items =[
    //     {
    //         id : "1",
    //         name: "Painting",
    //         type : "type",
    //         specialism : "specialism",
    //         supplier : "supplier",
    //         order_status : "order_status",
    //         cost_model :"const_model",
    //         cost_per_unit :"cost_per_unit" ,
    //         overruled_cost_per_unit:"overruled_cost_per_unit",
    //         overruled_cost_total:"overruled_cost_total",
    //         quantity_per_unit:"12",
    //         units_required:"2",
    //         wastage:"wastage",
    //         margin:"12.5",
    //         delivery_charge:"2000",
    //         notes :"delivery_charge",
    //         sales_tax_applied:"sales_tax_applied",
    //         section_id : "1",
    //         total :"15000",
    //         uom : "days",
    //         status :"Prime"
    
    //     },
    //     {
    //         id : "2",
    //         name: "flooring",
    //         type : "type",
    //         specialism : "specialism",
    //         supplier : "supplier",
    //         order_status : "order_status",
    //         cost_model :"const_model",
    //         cost_per_unit :"cost_per_unit" ,
    //         overruled_cost_per_unit:"overruled_cost_per_unit",
    //         overruled_cost_total:"overruled_cost_total",
    //         quantity_per_unit:"quantity_per_unit",
    //         units_required:"units_required",
    //         wastage:"wastage",
    //         margin:"12.5",
    //         delivery_charge:"1000",
    //         notes :"delivery_charge",
    //         sales_tax_applied:"sales_tax_applied",
    //         section_id : "1",
    //         total :"25000",
    //         uom : "days",
    //         status :"Provitional"
    
    
    //     },
    //     {
    //         id : "3",
    //         name: "roofing",
    //         type : "type",
    //         specialism : "specialism",
    //         supplier : "supplier",
    //         order_status : "order_status",
    //         cost_model :"const_model",
    //         cost_per_unit :"cost_per_unit" ,
    //         overruled_cost_per_unit:"overruled_cost_per_unit",
    //         overruled_cost_total:"overruled_cost_total",
    //         quantity_per_unit:"quantity_per_unit",
    //         units_required:"units_required",
    //         wastage:"wastage",
    //         margin:"12.5",
    //         delivery_charge:"2300",
    //         notes :"delivery_charge",
    //         sales_tax_applied:"sales_tax_applied",
    //         total :"35000",
    //         uom : "days",
    //         status :"Provitional"
    
    //     },
    // ]

    const ShowSections = ()=>{
      console.log("DDD")
      const itemRequestData = useSelector((state)=>state.pricing.itemRequestData)

    const onDragEnd = (result)=>{

      const {destination, source, draggableId, type} = result
      // console.log("bbb",destination.droppableId, source.droppableId , draggableId)
    


      if (!destination){
       
        return
      }

      if((destination.droppableId === source.droppableId &&
         destination.index === source.index) || destination.droppableId === source.droppableId)
         {
        
          return
         }

      if(type === billOfQuantities.DROPPABLE_TYPE_FOR_NESTED_HIERARCHY.NON_LABOUR){

                    const item = itemRequestData.find((item)=>item._id === draggableId)
                  //To find if Labour or Item add constant to front
                  // const item = itemRequestData.find((item)=>item._id === draggableId.split()[1])
                  
                  if(item.item_type === billOfQuantities.ITEM_TYPES.MATHLAB || item.item_type === billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || item.item_type === billOfQuantities.ITEM_TYPES.SUB_MATHLAB || item.item_type === billOfQuantities.ITEM_TYPES.STAFF){
                    
                  
                  }
                  else if (item.specification === billOfQuantities.TYPES.SPECIFIED && (destination.droppableId.split(" ")[0] === billOfQuantities.DROPPABLE_TYPES.SPECI.trim()) ){
                      // SPECIFIED TO SPECIFIED
                        //WITH IN THE SECTION
                        //ACROSS DIFFERENT SECTIONS

                        const destinationDroppableItem = itemRequestData.find((item)=> destination.droppableId.split(" ")[1] === item._id)

                      dispatch(updateItem({...item, labour_id : destination.droppableId.split(" ")[1],  section_id : item.section_id !==destinationDroppableItem.section_id? destinationDroppableItem.section_id : item.section_id}))
                      dispatch(setIdArray())
                      dispatch(updateItemData({item : {...item, labour_id : destination.droppableId.split(" ")[1],  section_id : item.section_id !==destinationDroppableItem.section_id? destinationDroppableItem.section_id : item.section_id}}))
                      console.log("bbb", "SPECI",)
                    
                  }
                  else if (item.specification === billOfQuantities.TYPES.SPECIFIED && (destination.droppableId.split(" ")[0] === billOfQuantities.DROPPABLE_TYPES.UNSPE.trim()) ){
                      // SPECIFIED TO UNSPECIFIED
                          // WITH IN SAME SECTION
                          // ACROSS DIFFERENT SECTIONS
                      let newItem = {...item,}
                    delete newItem.labour_id
                    //  const a = destination.droppableId.split(" ")[0]
                    console.log("bbb","SPECI TO UNSPECI")
                      dispatch(updateItem({...newItem, specification : billOfQuantities.TYPES.UNSPECIFIED, section_id : newItem.section_id !==destination.droppableId.split(" ")[1]? destination.droppableId.split(" ")[1] : newItem.section_id}))
                      dispatch(setIdArray())
                      dispatch(updateItemData({item : {...newItem, specification : billOfQuantities.TYPES.UNSPECIFIED, section_id : newItem.section_id !==destination.droppableId.split(" ")[1]? destination.droppableId.split(" ")[1] : newItem.section_id}}))
                    

                  }
                  else if (item.specification === billOfQuantities.TYPES.UNSPECIFIED && (destination.droppableId.split(" ")[0] === billOfQuantities.DROPPABLE_TYPES.SPECI.trim())){
                    // UNSPECIFIED TO SPECIFIED
                          // WITH IN SAME SECTION
                          // ACROSS DIFFERENT SECTIONS
                    console.log("bbb", "UNSPECI TO SPECI")

                    //upon droping to a Different Section
                    const destinationDroppableItem = itemRequestData.find((item)=> destination.droppableId.split(" ")[1] === item._id)
                  

                    // add section upon changing 
                      dispatch(updateItem({...item, labour_id : destination.droppableId.split(" ")[1], specification : billOfQuantities.TYPES.SPECIFIED, section_id : item.section_id !==destinationDroppableItem.section_id? destinationDroppableItem.section_id : item.section_id}))
                      dispatch(setIdArray())
                      dispatch(updateItemData({item : {...item, labour_id : destination.droppableId.split(" ")[1], specification : billOfQuantities.TYPES.SPECIFIED, section_id : item.section_id !==destinationDroppableItem.section_id? destinationDroppableItem.section_id : item.section_id}}))
                    
                  }
                  else if (item.specification === billOfQuantities.TYPES.UNSPECIFIED && (destination.droppableId.split(" ")[0] === billOfQuantities.DROPPABLE_TYPES.UNSPE.trim())){
                    // UNSPECIFIED TO UNSPECIFIED
                          // ONLY ACROSS DIFFERENT SECTIONS NEEDED
                          
                    console.log("bbb", "UNSPECI TO UNSPEC")
                      dispatch(updateItem({...item,  section_id : item.section_id !==destination.droppableId.split(" ")[1]? destination.droppableId.split(" ")[1] : item.section_id}))
                      dispatch(setIdArray())
                      dispatch(updateItemData({item :{...item,  section_id : item.section_id !==destination.droppableId.split(" ")[1]? destination.droppableId.split(" ")[1] : item.section_id}}))
                    
                  }
                  // console.log("bbb", destination.specification, destination.droppableId)


          }

          else if (type === billOfQuantities.DROPPABLE_TYPE_FOR_NESTED_HIERARCHY.LABOUR){
            const labour = itemRequestData.find((item)=>item._id === draggableId)
            const nestedItems = itemRequestData.filter((item)=>(item.labour_id === draggableId && item.specification === billOfQuantities.TYPES.SPECIFIED && (item.item_type !== billOfQuantities.ITEM_TYPES.MATHLAB || item.item_type !== billOfQuantities.ITEM_TYPES.STAFF || item.item_type !== billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || item.item_type !== billOfQuantities.ITEM_TYPES.SUB_MATHLAB)))
            console.log("bbb",labour,nestedItems)
            nestedItems.map((item)=>{
                dispatch(updateItem({...item, section_id : destination.droppableId }))
                dispatch(updateItemData({item : {...item, section_id : destination.droppableId }}))
            })
            dispatch(updateItem({...labour, section_id : destination.droppableId}))
            dispatch(setIdArray())
            dispatch(updateItemData({item :{...labour, section_id : destination.droppableId}}))

          }
      


    }
        return (
          <DragDropContext 
          onDragEnd={onDragEnd}
        >
         {    sectionIdArray.map((section,index)=>{
                // const item =items.filter((item)=>item.section_id === section)
               
                    // return(<Section section={sections.find((s)=> s._id === section)} key={index} index= {index} setNumOfNewSections={setNumOfNewSections}  userViewFilters={userViewFilters}/>)
                    return(
               <Section section={sections.find((s)=> s._id === section)} key={index} index= {index} setNumOfNewSections={setNumOfNewSections}  userViewFilters={userViewFilters}/>

               
                    )
                })}
          </DragDropContext>
        )
    }

    // useEffect(() => {
    //     setProjectId(location.state.projectId)
    //     dispatch(setCurrentProjectId(location.state.projectId))
    //     dispatch(getSections(location.state.projectId)).then(()=>{
    //         // dispatch(getLabour()).then(()=>{

    //         //     dispatch(getItems())
    //         // })
    //         dispatch(getLaboursAndItems()).then(()=>{
              
    //         })
    //     })
    //     dispatch(getUserViewFilter())
    //     dispatch(getAddUp())
    //    console.log("GET |section | items ")
    // }, []);

   

    // useEffect(()=>{
    //     calculateCharges()
    //     calculatesSummeryForItemType()
    // },[labours,sections,items,newItems])


const AddSection = ()=> {
  return (
    <>
        <section className='section-container' id="accordian">
        







        <div className='section-inner-container  row m-0' >
                <div className='col-3 p-0'>
                                <div className='row row-cols-auto view-pricing-icon-container'>
                                    {/* <div className='col-7   '>
                                      
                                        <img src={dragIcon} className="col " alt='ellipseIcon'/>
                                        <img src={greenEllipseIcon} className="col" alt='settingsIcon'/>
                                        <img src={pcIcon} className="col" alt='hammerIcon'/>
                                        <img src={gavelIcon} className="col" alt='whiteTruckIcon'/>
                                        <img src={blueTruckIcon} className="col" alt='whiteTruckIcon'/>
                                        <img src={treeSwichterOpenIcon} className="col" alt='whiteTruckIcon'/>
                                        <input className="form-check-input m-1 " type="checkbox" ></input> 
                                        
                                    </div>
                                    
                                    <div className='col-5 bg-danger d-flex align-items-center'>
                                       
                                        <h6>Title</h6>
                                    </div> */}
                                      <div >
                                       
                                        <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
                                        <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
                                        <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
                                        <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
                                       
                                        {/* <select id="test-select"  className='labour-select-input  m-0 p-0' >
                                                <option value="Prime" style={{width:"24px",height:"24px"}}>ICC</option>
                                                <option className='d-flex justify-content-center' value="Provitional" style={{width:"34px",height:"34px", backgroundImage :("blueTruckIcon"),}}><img style={{width:"34px",height:"34px"}} className=' ' src={blueTruckIcon}></img></option>
                                                <option value="Excluded" className='test-select-option'  style={{width:"24px",height:"24px"}} data-img_src="https://data.world/api/datadotworld-apps/dataset/python/file/raw/logo.png" ></option>
                                            </select> */}
                                        {/* {show?
                                          <img src={treeSwichterOpenIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>

                                        :<img src={treeSwichterCloseIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>
                                      } */}
                                                                                {/* <input className="form-check-input m-1 " type="checkbox" ></input> */}
                                        {/* {isCurrentChecked(section._id , billOfQuantities.TYPES.SECTION)  ?
                                              <input className="form-check-input pricing-checkbox m-1 align-items-center" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked, id: section._id, type: billOfQuantities.TYPES.SECTION}))}} defaultChecked></input>   
                                            :
                                            
                                            <input className="form-check-input pricing-checkbox m-1 align-items-center " type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({ isChecked:e.target.checked, id: section._id, type: billOfQuantities.TYPES.SECTION}))}} ></input>   
                                          }     */}

                                      </div>
                                      {/* <h3><AddIcon  onClick={()=>{
                                          // dispatch(changeSectionModalStatus())
                                          dispatch(createSection())
                                          }} /></h3> */}
                                      {/* <p className='col-5  p-0'>{section.name}</p> */}
                                        <div className='d-flex flex-row'>
                                       <AddIcon  onClick={()=>{
                                                // dispatch(changeSectionModalStatus())
                                                dispatch(createSection({sectionName : "", isRandomName : true}))
                                            }} />
                                    <input type='text' className=' section-input p-0 m-0' onKeyDown={(e)=>{
                                        if (e.key === "Enter"){
                                            dispatch(createSection({sectionName : e.target.value , isRandomName : false}))
                                        }
                                    }} ></input>
                                        </div>
                                    
                                </div>

                  </div>

                  <div className='col-9'>
                          <div className='row row-cols-10'>

                              {/*--------- Item Name for Proposal Column---------- */}

                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_NAME_FOR_PROPOSAL))?
                                                    <div className='col justify-content-start'></div>
                                                :null}                                                              

                                                
                                                {/*--------- ITEM_TYPE Column---------- */}
                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_TYPE))?
                                                  <div className='col justify-content-start'>
                                                        

                                                  </div>
                                                                  :null}

                                                                            

                                                                          


                                                {/*---------Parent Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PARENT))?
                                                     <div className='col justify-content-start'></div>
                                                :null}                                                              


                                                {/*---------Cost Code Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_CODE))?
                                                     <div className='col justify-content-start'></div>
                                                :null}
                                                
                                                  {/*--------- Units exc Wastage Column---------- */}

                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_EX_WASTAGE))?
                                                  <div className='col justify-content-start'></div>
                                                  :null}

                                                
                                                  {/*--------- UOM Column---------- */}


                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                                                    <div className='col justify-content-start'>
                                                         
                                                    </div>
                                                  :null}

                                                

                                                  {/*--------- Wastage % Column---------- */}



                                                  
                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_PERCENTAGE))?
                                                  <div className='col justify-content-start'></div>
                                                  :null}

                                                  {/*--------- BUY Unit $ Column---------- */}

                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.BUY_UNIT_PRICE))?
                                                  <div className='col justify-content-start'></div>
                                                  :null}



                                                  {/*--------- Sell Unit $ Column---------- */}

                                                
                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_UNIT_PRICE))?
                                                    <div className='col justify-content-start'></div>
                                                  :null}
                                                                            


                                                {/*---------Supplier Inc Sales Tax Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_INC_SALES_TAX))?
                                                    <div className='col justify-content-start'>
                                                    
                                               </div>
                                                :null}                                                              


                                                {/*--------- Supplier Item Code Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_ITEM_CODE))?
                                                    <div className='col justify-content-start'></div>
                                                :null}
                                                
  
                                                
                                                  {/*--------- Contingency % Column---------- */}


                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_PERCENTAGE))?
                                                  <div className='col justify-content-start'></div>
                                                  :null}


                                                
                                                {/*--------- Contingency Value Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_VALUE))?
                                                    <p className="col"> </p>
                                                :null}   

                                                
                                                  {/*--------- Marup percentage % Column---------- */}


                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_PERCENTAGE))?
                                                    <div className='col justify-content-start'></div>

                                                  :null}
                                                
                                                {/*--------- Markup Value Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_VALUE))?
                                                    <div className='col justify-content-start'>
                                                      <p className="col"> </p>

                                                  </div>
                                                :null}                                                                


                                                {/*--------- Client Sales Tax Percentage  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_PERCENTAGE))?
                                                    <div className='col justify-content-start'></div>
                                                :null}                                                              


                                                {/*--------- Client Sales Tax Value  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_VALUE))?
                                                    <div className='col justify-content-start'>
                                                      <p className="col">  </p>

                                                  </div>
                                                :null}
                                                
   

                                                
                                                  {/*--------- Client Charge Inc Column---------- */}

                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE_INC))?
                                                    <div className='col justify-content-start'></div>
                                                  :null}
                                                                            


                                                {/*--------- Overhead  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.OVERHEAD))?
                                                    <div className='col justify-content-start'>
                                                      <p className="col">  </p>

                                                  </div>
                                                :null}                                                              


                                                {/*--------- Margin   Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
                                                    <div className='col justify-content-start'>

                                                      <p className="col"> </p>
                                                    </div>
                                                :null}                                                              


                                                {/*--------- Supplier Contact Id  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_CONTACT_ID))?
                                                     <div className='col justify-content-start'></div>
                                                :null}                                                              


                                                {/*--------- Contractor Item Note  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTRACTOR_ITEM_NOTE))?
                                                     <div className='col justify-content-start'></div>
                                                :null}                                                              


                                                {/*--------- Tender Item Note  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TENDER_ITEM_NOTE))?
                                                      <div className='col justify-content-start'></div>
                                                :null}  

                                                {/*--------- Pricinglist ? Column---------- */}

                                                
                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.IS_PRICELIST_ITEM))?
                                                    <div className='col justify-content-start'>
                                                          
                                                      </div>
                                                  :null}


                                                {/*--------- NESTED  Column---------- */}

                                        {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.NESTED))?
                                          <p className="col"></p>
                                        :null}


                              <div className='view-pricing-user-filter-icon'>

                                  <img  src={threeDotIcon}></img>
                              </div>



                          </div>
                    
                    
                  
                  
                  </div> 


        </div>
 
       
       
        
 
        
 
        
     
        
                
         
 
                
        {/* <div className={"section-container-item-name collapse  multi-collapse"+" "+section.name}>
        <AddIcon  onClick={()=>{
         setNumOfNewItems((prev)=>prev+1)
         setShow((prev)=>(prev== "show" ? "show" : "show" ))
        }}  />
        </div> */}
 
         
        
     </section>





    
    </>
  )
  }


  const ShowSummery = ()=>{
    const itemRequestData = useSelector((state)=>state.pricing.itemRequestData)
    useEffect(()=>{
      // calculateCharges()
      calculatesSummeryForItemType()
      console.log("VVV")
  },[itemRequestData])
       
  const [assembly, setAssembly] = useState(0)
  const [equipment, setEquipment] = useState(0)
  const [material, setMaterial] = useState(0)
  const [mathLab, setMathLab] = useState(0)
  const [other, setOther] = useState(0)
  const [staff, setStaff] = useState(0)
  const [subLabourOnly, setSubLabourOnly] = useState(0)
  const [subMaterialOnly, setSubMaterialOnly] = useState(0)
  const [subMathLab, setsubMathLab] = useState(0)

    const calculatesSummeryForItemType = () => {
      let assemblyValue = 0
      let equipmentValue = 0
      let materialValue = 0
      let mathLabValue =  0
      let otherValue = 0
      let staffValue = 0
      let subLabourOnlyValue = 0
      let subMaterialOnlyValue = 0
      let subMathLabValue = 0
      
      itemRequestData.forEach ((item)=>{
          if (item.item_type === billOfQuantities.ITEM_TYPES.ASSEMBLY){
              assemblyValue = assemblyValue+parseFloat(calculateClientCharge(item))
          }
          else if (item.item_type === billOfQuantities.ITEM_TYPES.EQUIPMENT){
              equipmentValue = equipmentValue+parseFloat(calculateClientCharge(item))
              
          }
          else if (item.item_type === billOfQuantities.ITEM_TYPES.MATERIALS){
              materialValue = materialValue+parseFloat(calculateClientCharge(item))
              
          }
          else if (item.item_type === billOfQuantities.ITEM_TYPES.MATHLAB){
              mathLabValue = mathLabValue+parseFloat(calculateClientCharge(item))
              
          }
          else if (item.item_type === billOfQuantities.ITEM_TYPES.OTHER){
              otherValue = otherValue+parseFloat(calculateClientCharge(item))
              
          }
          else if (item.item_type === billOfQuantities.ITEM_TYPES.STAFF){
              staffValue = staffValue+parseFloat(calculateClientCharge(item))
              
          }
          else if (item.item_type === billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY){
              subLabourOnlyValue = subLabourOnlyValue+parseFloat(calculateClientCharge(item))
              
          }
          else if (item.item_type === billOfQuantities.ITEM_TYPES.SUB_MATERIAL_ONLY){
              subMaterialOnlyValue = subMaterialOnlyValue+parseFloat(calculateClientCharge(item))
              
          }
          else if (item.item_type === billOfQuantities.ITEM_TYPES.SUB_MATHLAB){
              subMathLabValue = subMathLabValue+parseFloat(calculateClientCharge(item))
              
          }

      })
      setAssembly(assemblyValue)
      setEquipment(equipmentValue)
      setMaterial(materialValue)
      setMathLab(mathLabValue)
      setOther(otherValue)
      setStaff(staffValue)
      setSubLabourOnly(subLabourOnlyValue)
      setSubMaterialOnly(subMaterialOnlyValue)
      setsubMathLab(subMathLabValue)
      // console.log("TTS",assemblyValue, equipmentValue, materialValue,mathLabValue, otherValue,staffValue,subLabourOnlyValue)
      // console.log("TEST LET",itemRequestData,assemblyValue,equipmentValue,materialValue,mathLabValue,otherValue,staffValue,subLabourOnlyValue, subMaterialOnlyValue,subMathLabValue)
      // console.log("TEST",itemRequestData,assembly,equipment,material,mathLab,other,staff,subLabourOnly, subMaterialOnly,subMathLab)
  }


      // new calculations - 5 

          const calculateWastageValue = (item)=>{

            // console.log(calculateUnitsIncWastage(item), " - ",item.unit_ex_wastage, " = " ,calculateUnitsIncWastage(item) - parseFloat(item.unit_ex_wastage))
              return Math.round(calculateUnitsIncWastage(item) - item.unit_ex_wastage *100)/100
              // return Math.floor((calculateUnitsIncWastage(item) - item.unit_ex_wastage )* 100)/100
          }

          const calculateUnitsIncWastage = (item)=>{
            // return Math.floor( (item.unit_ex_wastage * (1 + (item.wastage_percentage/100))) * 100)/100
            return Math.round(item.unit_ex_wastage * (1 + (item.wastage_percentage/100)) * 100)/100
          }

          const calculateSellAdjesmentUnitValue = (item)=>{
            if (item.sell_unit_price>item.buy_unit_price){
              
              // return Math.floor( (  item.sell_unit_price - item.buy_unit_price  ) * 100)/100
              return Math.round(  (item.sell_unit_price - item.buy_unit_price) *100  )/100
            }
            else if (item.sell_unit_price > 0){
            
              return Math.round(  (item.sell_unit_price - item.buy_unit_price) *100 )/100

            }
            else {
              return 0
            }
          }

          const calculateTotalSellPriceOfUnitsValue = (item)=>{
            if(item.sell_unit_price > 0){
              return Math.round((item.sell_unit_price * parseFloat(calculateUnitsIncWastage(item))) * 100)/100
              //  return Math.floor( (  item.sell_unit_price * parseFloat(calculateUnitsIncWastage(item))  ) * 100)/100

            }
            else {
              return Math.round((item.buy_unit_price * parseFloat(calculateUnitsIncWastage(item))) * 100)/100
              // return Math.floor( (  item.buy_unit_price * parseFloat(calculateUnitsIncWastage(item))  ) * 100)/100

            }

          }

          const calculatePreMarkupValue =  (item) => {
            return  Math.round( (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) *(item.pre_markup_percentage/100)) * 100)/ 100
            // return Math.floor( ( parseFloat(calculateTotalSellPriceOfUnitsValue(item)) *(item.pre_markup_percentage/100)  ) * 100)/100

            
          }

          const calculateCostOfUnits = (item)=> {
            let costOfUnits = (item.unit_ex_wastage * (item.cost_per_unit*(1 + item.wastage_percentage/100)))
            // if(item._id === "6455d407c9b5d4f7613360a6"){
            //   console.log(typeof item.wastage_percentage)
            //   console.log(1 + item.wastage_percentage/100)
            //   console.log((item.cost_per_unit*(1 + item.wastage_percentage/100)).toFixed(2))
            //   console.log("costOfUnits  : ",item.name," - ",costOfUnits.toFixed(2))
            // }
            return Math.round(costOfUnits * 100)/100
            // return Math.floor( (  costOfUnits  ) * 100)/100


          }

          const calculateContingency = (item)=>{
            let contingencyValue = (calculateTotalSellPriceOfUnitsValue(item)*item.contingency_percentage/100)
            //  if(item._id === "6455d407c9b5d4f7613360a6"){
            //   console.log(typeof item.contingency_percentage)
            //   // console.log(1 + item.wastage_percentage/100)
            //   // console.log((item.cost_per_unit*(1 + item.wastage_percentage/100)).toFixed(2))
            //   console.log("ContingencyValue  : ",item.name," - ",contingencyValue)
            // }
            return Math.round(contingencyValue *100)/100
            // return Math.floor( (  contingencyValue  ) * 100)/100
          }
          const calculateMarkupValue = (item)=>{
            let markupValue = ((parseFloat(calculateTotalSellPriceOfUnitsValue(item))+ parseFloat(calculateContingency(item)))*item.markup_percentage/100)
            //  if(item._id === "6455d407c9b5d4f7613360a6"){
            //   console.log(typeof item.contingency_percentage)
            //   console.log(calculateCostOfUnits(item)+calculateContingency(item))
            //   // console.log((item.cost_per_unit*(1 + item.wastage_percentage/100)).toFixed(2))
            //   console.log("Mark Up Value  : ",item.name," - ",markupValue)
            // }
            // return markupValue.toFixed(2)
            return Math.round(markupValue*100)/100
            // return Math.floor( (  markupValue  ) * 100)/100

          }


          const calculateClientSalesTax = (item) =>{
            let clientSalesTax = ((parseFloat(calculateTotalSellPriceOfUnitsValue(item))+parseFloat(calculateContingency(item))+parseFloat(calculateMarkupValue(item)))*item.client_sales_tax_percentage/100)

            // if(item._id === "6455d407c9b5d4f7613360a6"){
              
            //   console.log("JUst another",)
            //   console.log("Client Sales Tax", clientSalesTax)
            // }
            return Math.round(clientSalesTax)
          }


          const calculateClientCharge = (item)=>{
            // let clientCharge = (parseFloat(calculateCostOfUnits(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)) ).toFixed(2)
            // let clientCharge = Math.ceil((parseFloat(calculateCostOfUnits(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)) ))  
            let clientCharge = (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)))  
            // return Math.floor(clientCharge)
            return Math.ceil(clientCharge)
          }

          const calculateOverheads = (item) => {
            let overhead = ((  parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))  ) * overheadsPercentage/100)
            return Math.round(overhead * 100)/100
          }

          const calculateMargin = (item) => {
            let marginBeforeTax = (parseFloat(calculateClientCharge(item)) - ( parseFloat(calculateOverheads(item)) + parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateClientSalesTax(item)) ))
            return Math.round(marginBeforeTax * 100)/100
          }
          const calculateGMPBuffer = (item)=>{
            let gmpBuffer = (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) * (item.gmp_percentage/100))
            return Math.round(gmpBuffer * 100) /100
          }

          
          
            const calculateTotalSellPriceOfUnitsValueForSection = (section) => {
              const costOfUnitsArray = itemRequestData.map((item)=>{
                  if(item.section_id === section._id){
                    
                    return parseFloat(calculateTotalSellPriceOfUnitsValue(item))
                  }
                  else{
                    return null
                  }
              })
              if(costOfUnitsArray.length !== 0){
                return Math.round(costOfUnitsArray.reduce((acc,cur)=> acc+cur) *100)/100
                }
              else{
                  return 0
              }
            
              // return 11
            
            

            }

            const calculateTotalContingency = (section) => {
              const contingencyArray = itemRequestData.map((item)=>{
                  if(item.section_id === section._id){
                    
                    return parseFloat(calculateContingency(item))
                  }
                  else{
                    return null
                  }
              })

              if(contingencyArray.length !== 0){
                return Math.round(contingencyArray.reduce((acc,cur)=> acc+cur) * 100) /100
                }
              else{
                  return 0
              }
            
              
            

            }
            const calculateTotalMarkupValue = (section) => {
              const markupValueArray = itemRequestData.map((item)=>{
                  if(item.section_id === section._id){
                    
                    return parseFloat(calculateMarkupValue(item))
                  }
                  else{
                    return null
                  }
              })
            
              if(markupValueArray.length !== 0){
                return Math.round( markupValueArray.reduce((acc,cur)=> acc+cur) *100) / 100
            
                }
              else{
                  return 0
              }

            }

            const calculateTotalClientSalesTax = (section) => {
              const totalClientSalesTax = itemRequestData.map((item)=>{
                  if(item.section_id === section._id){
                    
                    return parseFloat(calculateClientSalesTax(item))
                  }
                  else{
                    return null
                  }
              })

              if(totalClientSalesTax.length !== 0){
                
              return Math.round(totalClientSalesTax.reduce((acc,cur)=> acc+cur) * 100) /100
              }
            else{
                return 0
            }
            
            

            }
            const calculateTotalClientCharge = (section) => {
              const totalClientCharge = itemRequestData.map((item)=>{
                  if(item.section_id === section._id){
                    
                    return parseFloat(calculateClientCharge(item))
                  }
                  else{
                    return null
                  }
              })
              
              if(totalClientCharge.length !== 0){
                return Math.round(totalClientCharge.reduce((acc,cur)=> acc+cur))
              }
            else{
                return 0
            }
            
            

            }
            const calculateTotalOverhead = (section) => {
              
              // console.log("---------------------------",itemRequestLoading ,"------" )
              
              const totalOverheadArray = itemRequestData.map((item)=>{
                  if(item.section_id === section._id){
                    
                    return parseFloat(calculateOverheads(item))
                  }
                  else{
                    return null
                  }
              })
              // console.log("++++++++++++++++++++++++++++",itemRequestLoading ,"------",itemRequestData ,totalOverheadArray )
              if(totalOverheadArray.length !== 0){
                return Math.round( totalOverheadArray.reduce((acc,cur)=> acc+cur) * 100) /100
              }
            else{
                return 0
            }
            

            

            }
            const calculateTotalMargin = (section) => {
              const totalMarginBeforeTaxArray = itemRequestData.map((item)=>{
                  if(item.section_id === section._id){
                    
                    return parseFloat(calculateMargin(item))
                  }
                  else{
                    return null
                  }
              })


              if(totalMarginBeforeTaxArray.length !== 0){
                return Math.round(totalMarginBeforeTaxArray.reduce((acc,cur)=> acc+cur) * 100) / 100
              }
            else{
                return 0
            }
            

            }

            
            const calculateTotalGMPBufferValue = (section) => {
              const totalArray = itemRequestData.map((item)=>{
                  if(item.section_id === section._id){
                    
                    return parseFloat(calculateGMPBuffer(item))
                  }
                  else{
                    return null
                  }
              })

              if(totalArray.length !== 0){
                return Math.round(totalArray.reduce((acc,cur)=> acc+cur) * 100) / 100
              }
            else{
                return 0
            }
            

            }

            const calculateTotalContingencyPercentage = ()=>{
                let total = ((parseFloat(calculateTotalContingency())/ parseFloat(calculateTotalSellPriceOfUnitsValueForSection()))*100)
              
                if(!isNaN(total)){
                  return Math.round(total)
                }
                else {

                  return 0 
                }

            }
            const calculateTotalMarkupPercentage = ()=>{

              let total=  ((parseFloat(calculateTotalMarkupValue()) / (parseFloat(calculateTotalSellPriceOfUnitsValueForSection()) + parseFloat(calculateTotalContingency()) ) )*100)
              if(!isNaN(total)){
                return Math.round(total)
              }
              else {

                return 0 
              }

            }
            const calculateTotalClientSalesTaxPercentage = ()=>{

              let total= ((parseFloat(calculateTotalClientSalesTax())/(parseFloat(calculateTotalSellPriceOfUnitsValueForSection()) + parseFloat(calculateTotalContingency()) + parseFloat(calculateTotalMarkupValue()) ) )*100)
              if(!isNaN(total)){
                return Math.round(total)
              }
              else {

                return 0 
              }

            }


              const calculateTotalCostOfUnitsForSummary =  () => {
                
                const costOfUnits =sectionRequestData.map((section)=>{
                    return parseFloat(calculateTotalSellPriceOfUnitsValueForSection(section))
                })
                
                
                if(costOfUnits.length !== 0){
                    return Math.round(costOfUnits.reduce((acc,cur)=> acc+cur))
                }
                else{
                    return 0
                }

              }
              const calculateTotalContingencyPercentageValueForSummary = () =>{
                if(parseFloat(calculateTotalCostOfUnitsForSummary()) !== 0 ){

                    return  Math.round( (((parseFloat(calculateTotalCostOfUnitsForSummary()) + parseFloat(calculateTotalContingencyValueForSummary()))/parseFloat(calculateTotalCostOfUnitsForSummary()))-1) * 10)/10
                }else {

                    return 0
                }
              }

              const calculateTotalContingencyValueForSummary =  () => {
                
                const total =sectionRequestData.map((section)=>{
                    return parseFloat(calculateTotalContingency(section))
                })
                if(total.length !== 0){
                    return Math.round(total.reduce((acc,cur)=> acc+cur))
                }
                else{
                    return 0
                }

            

              }

            

              const calculateTotalMarkupValueForSummary =  () => {
                
                const total =sectionRequestData.map((section)=>{
                    return parseFloat(calculateTotalMarkupValue(section))
                })
                if(total.length !== 0){
                    return Math.round(total.reduce((acc,cur)=> acc+cur))
                }
                else{
                    return 0
                }

              }

              const calculateTotalMarkupPercentageValueForSummary = ()=>{
                if ((parseFloat(calculateTotalCostOfUnitsForSummary()) + parseFloat(calculateTotalContingencyValueForSummary()) ) !== 0){

                    return Math.round((( (parseFloat(calculateTotalCostOfUnitsForSummary()) + parseFloat(calculateTotalContingencyValueForSummary()) + parseFloat(calculateTotalMarkupValueForSummary()) ) / (parseFloat(calculateTotalCostOfUnitsForSummary()) + parseFloat(calculateTotalContingencyValueForSummary()) ) )-1) * 10) /10
                }
                else {
                    return undefined
                }

          }

              const calculateTotalClientSalesTaxForSummary =  () => {
                
                const total =sectionRequestData.map((section)=>{
                    return parseFloat(calculateTotalClientSalesTax(section))
                })
                if(total.length !== 0){
                    return Math.round(total.reduce((acc,cur)=> acc+cur))
                }
                else{
                    return 0
                }

              }
              const calculateTotalClientChargeForSummary =  () => {
                
                const total =sectionRequestData.map((section)=>{
                    return parseFloat(calculateTotalClientCharge(section))
                })
                if(total.length !== 0){
                    return Math.round(total.reduce((acc,cur)=> acc+cur))
                }
                else{
                    return 0
                }

              }

            //   const calculateTotalOverheadForSummary =  () => {
                
            //     const total =sectionRequestData.map((section)=>{
            //         return parseFloat(calculateTotalOverhead(section))
            //     })
            //     if(total.length !== 0){
            //         return total.reduce((acc,cur)=> acc+cur)
            //     }
            //      else{
            //         return 0
            //      }

            //   }

              const calculateTotalForSummary =  () => {
                
                const total =sectionRequestData.map((section)=>{
                    return parseFloat(calculateTotalMargin(section))
                })
                if(total.length !== 0){
                    return Math.round(total.reduce((acc,cur)=> acc+cur))
                }
                else{
                    return 0
                }

              }

              const calculateTotalGMPValueForSummary =  () => {
                
                const total =sectionRequestData.map((section)=>{
                    return parseFloat(calculateTotalGMPBufferValue(section))
                })
                if(total.length !== 0){
                    return Math.round(total.reduce((acc,cur)=> acc+cur) *100) /100
                }
                else{
                    return 0
                }

              }


              const calculateTotalGMPPercentageForSummary = () => {
                if(parseFloat(calculateTotalCostOfUnitsForSummary())!== 0){
                return Math.round(parseFloat(calculateTotalGMPValueForSummary())/parseFloat(calculateTotalCostOfUnitsForSummary()) * 100) / 100
                }
                else {
                  return 0
                }
              }




              const calculateTotalPriceForSummary = ()=> {

                return Math.round((parseFloat(calculateTotalCostOfUnitsForSummary())+parseFloat(calculateTotalContingencyValueForSummary())+parseFloat(calculateTotalMarkupValueForSummary())+parseFloat(calculateTotalClientSalesTaxForSummary())) * 100) /100
              }



              const calculateTotalOverheadForSummary =  ()=> {

                return Math.round((parseFloat(calculateTotalCostOfUnitsForSummary()) + parseFloat(calculateTotalContingencyValueForSummary()) + parseFloat(calculateTotalMarkupValueForSummary()))*overheadsPercentage/100)
              }



              const calculateTotalMarginForSummary = ()=> {

                return Math.round( parseFloat(calculateTotalClientChargeForSummary())-(parseFloat(calculateTotalOverheadForSummary()) + parseFloat(calculateTotalCostOfUnitsForSummary()) + parseFloat(calculateTotalClientSalesTaxForSummary()) ))
              }


              const calculateTotalMarginPercentageForSummary= ()=>{
                
                if(parseFloat(calculateTotalPriceForSummary())!==0){
                    return Math.round(((parseFloat(calculateTotalMarginForSummary()) / parseFloat(calculateTotalClientChargeForSummary()))*100) * 10)/10
                
                }
                else{
                    return undefined
                }

              }

            

    return (
      <table className="table-borderless table-container ">
      {/* <thead>
          <tr>
          <th scope="col">Classification </th>
          <th scope="col">Margin</th>
          <th scope="col">Amount</th>
          <th scope="col">%</th>
          </tr>
      </thead> */}
      <tbody >
          {/* <tr>
          
          <td>Direct Labour</td>
          <td></td>
          <td>$12,000</td>
          </tr>image.png
          <tr>
          <td>Trades</td>
          <td>5%</td>
          <td>$3,000</td>
          </tr>
          <tr>
          <td>Material inc Del</td>
          <td>25%</td>
          <td>$3,400</td>
          </tr>
          <tr>
          <td>Additional Del</td>
          <td></td>
          <td>$1,200</td>
          </tr>
          <tr>
          <td>Hire</td>
          <td>10%</td>
          <td>$2,700</td>
          </tr>
          <tr>
          <td>Unchargeable</td>
          <td></td>
          <td>$350</td>
          </tr>
          <tr>
              <td>     <br></br></td>
          </tr>
  
          
          <tr>
          <td>profit</td>
          <td>25%</td>
          <td>$6,400</td>
          </tr>
          
          <tr>
          <td>Contingency</td>
          <td>10%</td>
          <td>$2,700</td>
          </tr> */}
          <tr >
                  <td className=''>Cost of Units</td>
                  <td></td>
                  <td >
                      <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{calculateTotalCostOfUnitsForSummary()}</span>
                          </span> 
                      
                  </td>
          </tr>
          <tr >
                  <td>Contingency</td>
                  <td>
                    {/* {calculateTotalContingencyPercentageValueForSummary()} */}
                    <span class="summery-td-container"><span className='d-flex justify-content-end' >{calculateTotalContingencyPercentageValueForSummary()}</span><p >%</p>
                          </span> 
                      
                  </td>
                  <td>
                    {/* {calculateTotalContingencyValueForSummary()} */}
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{calculateTotalContingencyValueForSummary()}</span>
                          </span>
                    </td>
          </tr>
          <tr>
                  <td>Markup</td>
                  <td>
                    {/* {calculateTotalMarkupPercentageValueForSummary()} */}
                    <span class="summery-td-container"><span className='d-flex justify-content-end' >{calculateTotalMarkupPercentageValueForSummary()}</span><p >%</p>
                          </span>
                  </td>
                  <td>
                    {/* {calculateTotalMarkupValueForSummary()} */}
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{calculateTotalMarkupValueForSummary()}</span>
                            </span>
                    </td>
          </tr>
          <tr>
                  <td>Sales Tax</td>
                  <td></td>
                  <td>
                    {/* {calculateTotalClientSalesTaxForSummary()} */}
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{calculateTotalClientSalesTaxForSummary()}</span>
                            </span>
                    </td>
          </tr>
          <tr><hr/></tr>
          <tr>
                  
                  <td>CONTRACT PRICE</td>
                  <td></td>
                  <td>
                    {/* {calculateTotalClientChargeForSummary()} */}
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{calculateTotalClientChargeForSummary()}</span>
                            </span>
                  </td>
          </tr>
         
          <tr><hr/></tr>
          <tr>
                  
                  <td>GMP  Buffer</td>
                  <td>
                    {/* {calculateTotalGMPPercentageForSummary()} */}
                    <span class="summery-td-container"><span className='d-flex justify-content-end' >{calculateTotalGMPPercentageForSummary()}</span><p >%</p>
                          </span>
                  </td>
                  <td>
                    {/* {calculateTotalGMPValueForSummary()} */}
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{calculateTotalGMPValueForSummary()}</span>
                            </span>
                  </td>
                 
                  
          </tr>
          <tr>
                  <td>OverHeads</td>
                  <td >
                    {/* <input className='td-style'  type='number' defaultValue={overheadsPercentage} onChange={(e)=>{dispatch(changeOverheadsPercentage({overheadsPercentage: e.target.value }))}} ></input> */}
                    <span class="summery-td-container"><span className='d-flex justify-content-end' ><input className='td-style text-end'  type='number' defaultValue={overheadsPercentage} onBlur={(e)=>{dispatch(changeOverheadsPercentage({overheadsPercentage: e.target.value }))}} ></input></span><p >%</p>
                          </span> 
                      
                    </td>
                  <td>
                    {/* {calculateTotalOverheadForSummary()} */}
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{calculateTotalOverheadForSummary()}</span>
                            </span>
                  </td>
          </tr>

          <tr>
                  <td>Margin</td>
                  <td>
                    {/* {calculateTotalMarginPercentageForSummary()} */}
                    <span class="summery-td-container"><span className='d-flex justify-content-end' >{calculateTotalMarginPercentageForSummary()}</span><p >%</p>
                          </span>
                  </td>
                  <td>
                    {/* {calculateTotalMarginForSummary()} */}
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{calculateTotalMarginForSummary()}</span>
                            </span>
                  </td>
          </tr>
          <tr>
              <hr/>
          </tr>

          <tr>
                  <td>Staff</td>
                  <td>
                    {/* {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((assembly + staff)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  : 0} */}
                    <span class="summery-td-container"><span className='d-flex justify-content-end' >  
                    {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((assembly + staff)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  : 0}</span><p >%</p>
                          </span>
                  </td>
                  <td>
                    {/* { assembly + staff} */}
                  <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{assembly + staff}</span>
                            </span>
                  </td>
                  
          </tr>
          <tr>
                  <td>MatLab</td>
                  <td>
                    {/* {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((mathLab)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  :0} */}
                  
                  <span class="summery-td-container"><span className='d-flex justify-content-end' >
                  {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((mathLab)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  :0}
                    </span><p >%</p>
                          </span>
                  </td>
                  <td>
                    
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{mathLab}</span>
                            </span>
                  </td>
                 
          </tr>
          <tr>
                  <td>Material</td>
                  <td>
                    {/* {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((material)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  :0} */}
                  
                  <span class="summery-td-container"><span className='d-flex justify-content-end' >
                  {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((material)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  :0}
                    </span><p >%</p>
                          </span>
                  </td>
                  <td>
                    {/* {material} */}
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{material}</span>
                            </span>
                  </td>
                  <td>{parseFloat(calculateTotalPriceForSummary())!==0? 
                      ((((assembly + staff)/parseFloat(calculateTotalPriceForSummary()))*100)+(((mathLab)/parseFloat(calculateTotalPriceForSummary()))*100)+  (((material)/parseFloat(calculateTotalPriceForSummary()))*100)+(((equipment)/parseFloat(calculateTotalPriceForSummary()))*100) +(((other)/parseFloat(calculateTotalPriceForSummary()))*100)).toFixed(1)
                  :0}</td>
          </tr>
          <tr>
                  <td>Equipment</td>
                  <td>
                    {/* {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((equipment)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  :0} */}
                  <span class="summery-td-container"><span className='d-flex justify-content-end' >
                  {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((equipment)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  :0}
                    </span><p >%</p>
                          </span>
                  </td>
                  <td>
                    {/* {equipment} */}
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{equipment}</span>
                            </span>
                  </td>
          </tr>
          <tr>
                  <td>Other</td>
                  <td>
                    {/* {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((other)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                      :0} */}
                       <span class="summery-td-container"><span className='d-flex justify-content-end' >
                       {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((other)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                      :0}
                    </span><p >%</p>
                          </span>
                  </td>
                  <td>
                    {/* {other} */}
                  <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{other}</span>
                            </span>
                  </td>
                  
          </tr>
          <tr> <hr/> </tr>
          <tr>
                  <td>Sub (Labour only)</td>
                  <td>
                    {/* {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((subLabourOnly)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  :0} */}
                   <span class="summery-td-container"><span className='d-flex justify-content-end' >
                   {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((subLabourOnly)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  :0}
                    </span><p >%</p>
                          </span>
                    </td>
                  <td>
                    {/* {subLabourOnly} */}
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{subLabourOnly}</span>
                            </span>
                  </td>
          </tr>

          <tr>
                  <td>Sub (MatLab)</td>
                  <td>
                    {/* {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((subMathLab)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  :0} */}
                  <span class="summery-td-container"><span className='d-flex justify-content-end' >
                  {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((subMathLab)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  :0}
                    </span><p >%</p>
                          </span>
                    </td>
                  <td>
                    {/* {subMathLab} */}
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{subMathLab}</span>
                              </span>
                  </td>
                  <td>{parseFloat(calculateTotalPriceForSummary())!==0?
                      ((((subLabourOnly)/parseFloat(calculateTotalPriceForSummary()))*100) +  (((subMathLab)/parseFloat(calculateTotalPriceForSummary()))*100) +  (((subMaterialOnly)/parseFloat(calculateTotalPriceForSummary()))*100)).toFixed(1)
                  :0}</td>
          </tr>


          <tr>
                  <td>Sub(Material only)</td>
                  <td>
                    {/* {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((subMaterialOnly)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  :0} */}
                  <span class="summery-td-container"><span className='d-flex justify-content-end' >
                  {parseFloat(calculateTotalPriceForSummary())!==0?
                  (((subMaterialOnly)/parseFloat(calculateTotalPriceForSummary()))*100).toFixed(1)
                  :0}
                    </span><p >%</p>
                          </span>
                    </td>
                  <td>
                    {/* {subMaterialOnly} */}
                    <span class="summery-td-container"><p >$</p><span className='d-flex justify-content-end' >{subMaterialOnly}</span>
                              </span>
                    </td>
          </tr>
          {/* <tr>
                  <td>Cost of Units</td>
                  <td></td>
                  <td>{costOfUnits}</td>
          </tr> */}

          {/* <tr>
          <td>Total cost of units</td>
          <td></td>
          <td>{costOfUnits}</td>
          </tr> */}
          {/* <tr>
          <td>Total client Charge</td>
          <td></td>
          <td>{clientCharge}</td>
          </tr> */}
          
      </tbody>
      
      </table>
    )
  }


   
  return (
    <div ref={viewPricingElement}>
        
        
        {/* <div className='pricing-column-header row'>
                    <p className='col-3'></p>
                    <h6 className="col">Quantity</h6>
                    <h6 className="col">UoM</h6>
                    <h6 className="col">Margin</h6>
                    <h6 className="col">Delivery</h6>
                    <h6 className="col">Total</h6>
                    <h6 className="col-2">Prime/Prov/Excl?</h6>
                    <h6 className="col" >Tender?</h6>
                    <p className='col-2'></p>
        </div>    */}
        {/* <table className='table table-borderless'>
        <thead className='pricing-column-header '>
            <tr>
                    <th colSpan="3"></th>
                    <th scope="col" >Quantity</th>
                    <th scope="col">UoM</th>
                    <th scope="col">Margin</th>
                    <th scope="col">Delivery</th>
                    <th scope="col">Total</th>
                    <th colSpan="2">Prime/Prov/Excl?</th>
                    <th scope="col" >Tender?</th>
                    <th colSpan="2"></th>
            </tr>
        </thead>    */}
            
        {/* </table> */}
         

           
  
        {/*------------------------------------ New ROW, Matching with the UI------------------------------------------------------- */}

        <div className='pricing-column-header pricing-column-header-sticky   row'>
                    {/* <p className='col-3'></p> */}
                    <div className='col-3 '>
                        <div className='row row-cols-auto view-pricing-icon-container  '>
                            {/* <div className='col-5   '>
                            
                               
                                <img src={ellipseIcon} className="col " alt='ellipseIcon'/>
                                <img src={settingsIcon} className="col" alt='settingsIcon'/>
                                <img src={hammerIcon} className="col" alt='hammerIcon'/>
                                <img src={whiteTruckIcon} className="col" alt='whiteTruckIcon'/>
                            </div>
                            
                            <div className='col-7 bg-danger d-flex align-items-center'>
                                <input className="form-check-input m-1 " type="checkbox" ></input>  
                                <h6>Title</h6>
                            </div> */}

                            <div  className=''>
                                        <img src={dragIcon} className="col" alt='ellipseIcon'/>
                                        <img src={ellipseIcon} className="col" alt='settingsIcon'/>
                                        <img src={settingsIcon} className="col" alt='hammerIcon'/>
                                        <img src={hammerIcon} className="col" alt='whiteTruckIcon'/>
                                        <img src={whiteTruckIcon} className="col" alt='whiteTruckIcon'/>
                                        <img src={treeSwichterOpenIcon} className="col" alt='whiteTruckIcon'/>
                                        <input className="form-check-input m-1 " type="checkbox" ></input>
                                       
                            </div>
                                      {/* <p className='col-6 p-0'>Title</p> */}
                                      <div className='pricing-column-title'><div className='d-flex'> Title <div><img src={filterIconOne}/><img src={filterIconTwo}/><img src={filterIconFour}/></div></div></div>
                                    
                        </div>

                    </div>
                    {userViewFilterRequestLoading?
                         <div className="spinner-border " style={{"width": "3rem", "height": "3rem","position":"absolute","left": "50%"}} role="status">
                         {/* <span className="sr-only">Loading...</span> */}
                       </div>
                    :
                        <div className='col-9'>
                            <div className='row row-cols-10'>


                                    

                                {/*--------- Item Name for Proposal Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_NAME_FOR_PROPOSAL))?
                                    <div className="col w-100 pricing-column-title">Item Name for Proposal  <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>
                                :null}                                                              

                                
                                {/*--------- ITEM_TYPE Column---------- */}
                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_TYPE) )?
                                    // <div className="col d-flex align-items-center">

                                         <div className="col w-100 pricing-column-title ">Item Type  <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                
                                    // </div>

                                :null}

                                                            

                                                           
                               

                                {/*---------Parent Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PARENT))?
                                    <div className="col w-100  pricing-column-title ">Parent <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>
                                :null}                                                              


                                {/*---------Cost Code Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_CODE))?
                                    
                                    <div className="col w-100  pricing-column-title">Cost Code <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}
                                
                                  {/*--------- Units exc Wastage Column---------- */}

                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_EX_WASTAGE))?
                                    <div className="col w-100 pricing-column-title"><p>Units ex wastage</p> <div className='d-flex flex-row'> <img src={filterIconFour}/></div></div>
                                :null}

                                
                                  {/*--------- UOM Column---------- */}

                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                                    <div className="col w-100  pricing-column-title">UOM <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>
                                :null}

                                

                                  {/*--------- Wastage % Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_PERCENTAGE))?
                                    <div className="col w-100 pricing-column-title">Wastage%  <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}


                                  {/*--------- Wastage Value---------- */}




                                    
                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_VALUE))?
                                    <div className="col w-100 pricing-column-title">Wastage V.  <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>
                                    :null} 



                                {/*--------- Units inc Wastage---------- */}


                                    
                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_INC_WASTAGE))?
                                    <div className="col w-100 pricing-column-title">Units Inc Wastage  <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>
                                    :null} 
 

                                
                                  {/*--------- BUY Unit $ Column---------- */}

                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.BUY_UNIT_PRICE))?
                                    
                                <div className="col w-100 pricing-column-title">Buy Unit  <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}


                                  {/*--------- Sell Unit $ Column---------- */}
                                  {/* <p className="col">Sell Unit $ (if diff)</p> */}
                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_UNIT_PRICE))?
                                    
                                    <div className="col w-100 pricing-column-title">Sell Unit  <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}

                                  {/*--------- Sell Adjestment Unit Value---------- */}


                                                  
                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_ADJ_UNIT_VALUE))?
                                    <div className="col w-100 pricing-column-title">Sell Adj Unit V  <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>
                                    :null} 
                                                             


                                {/*---------Supplier Inc Sales Tax Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_INC_SALES_TAX))?
                                    <div className="col w-100 pricing-column-title">Supplier Inc Sales Tax  <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                    
                                :null}                                                              


                                {/*--------- Supplier Item Code Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_ITEM_CODE))?
                                    <div className="col w-100 pricing-column-title">Supplier Item Code<div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}
                                




                                                            
                            {/*--------- Total Sell Price Of Units---------- */}


                                
                            {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TOTAL_SELL_PRICE_OF_UNITS))?
                                <div className="col w-100 pricing-column-title">Total tell price of units<div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                            :null} 



                                {/* --------- Pre-markup % Column---------- */}


                                {/* {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PRE_MARKUP_PERCENTAGE))?
                                    <div className="col w-100 pricing-column-title">pre-markup %<div className='d-flex flex-row'><img src={filterIconThree}/><img src={filterIconFour}/></div></div>
      
                                :null} */}


                                {/*--------- Pre-markup Value---------- */}


{/*                                 
                            {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PRE_MARKUP_VALUE))?
                                <div className="col w-100 pricing-column-title">pre-markup V<div className='d-flex flex-row'><img src={filterIconThree}/><img src={filterIconFour}/></div></div>

                            :null}  */}

                                
                                  {/*--------- Contingency % Column---------- */}

                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_PERCENTAGE))?
                                    <div className="col w-100 pricing-column-title">Pre-markup Contin. %<div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}

                                
                                {/*--------- Contingency Value Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_VALUE))?
                                    <div className="col w-100 pricing-column-title">Pre-markup Cont. Value<div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}   

                                
                                  {/*--------- Marup percentage % Column---------- */}

                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_PERCENTAGE))?
                                    <div className="col w-100 pricing-column-title">Markup<div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}

                                
                                {/*--------- Markup Value Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_VALUE))?
                                  
                                    <div className="col w-100 pricing-column-title">Markup V<div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}                                                                


                                {/*--------- Client Sales Tax Percentage  Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_PERCENTAGE))?
                                    <div className="col w-100 pricing-column-title">Client Sales Tax Percentage <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}                                                              


                                {/*--------- Client Sales Tax Value  Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_VALUE))?
                                    <div className="col w-100 pricing-column-title">Client Sales Tax Value<div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}
                                


                                
                                  {/*--------- Client Charge Inc Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE_INC))?
                                    <div className="col w-100 pricing-column-title">Client Charge Inc <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}
                                                             


                                {/*--------- Overhead  Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.OVERHEAD))?
                                    <div className="col w-100 pricing-column-title">Overhead <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}                                                              


                                {/*--------- Margin   Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
                                    <div className="col w-100 pricing-column-title">Margin V <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}                                                              
                                {/*--------- GMP Buffer %   Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_PERCENTAGE))?
                                    <div className="col w-100 pricing-column-title">GMP Buffer <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}                                                              
                                {/*--------- GMP Buffer V   Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_VALUE))?
                                    <div className="col w-100 pricing-column-title">GMP buffer V <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}                                                              


                                {/*--------- Supplier Contact Id  Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_CONTACT_ID))?
                                    <div className="col w-100 pricing-column-title"> Supplier    <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}                                                              


                                {/*--------- Contractor Item Note  Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTRACTOR_ITEM_NOTE))?
                                    <div className="col w-100 pricing-column-title"> Contractor Item Note   <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}                                                              


                                {/*--------- Tender Item Note  Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TENDER_ITEM_NOTE))?
                                    <div className="col w-100 pricing-column-title">Tender Item Note<div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}  

                                {/*--------- Pricinglist ? Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.IS_PRICELIST_ITEM))?
                                    <div className="col w-100 pricing-column-title">Pricinglist?<div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}  


                                {/*--------- NESTED  Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.NESTED))?
                                    <div className="col w-100 pricing-column-title">NESTED <div className='d-flex flex-row'><img src={filterIconFour}/></div></div>

                                :null}                                                              









{/* 
                                                            {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_NAME_FOR_PROPOSAL))?
                                    <p className="col"> Item Name for Proposal </p>
                                :null}                                                              

                                
                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_TYPE) )?
                                    <div className="col d-flex align-items-center">

                                         <p >Item_Type</p>
                                
                                    </div>

                                :null}

                                                            

                                                           



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PARENT))?
                                    <p className="col">Parent </p>
                                :null}                                                              



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_CODE))?
                                    <p className="col">Cost Code </p>
                                :null}
                                

                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_EX_WASTAGE))?
                                    <p className="col">Units exc Wastage</p>
                                :null}

                                

                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                                    <p className="col">UOM</p>
                                :null}

                                


                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                                    <p className="col">Wastate %</p>
                                :null} 


                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
                                    <p className="col">Cost Per Unit </p>
                                :null} 
                                

                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.BUY_UNIT_PRICE))?
                                    <p className="col">Buy Unit $</p>
                                :null}



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_UNIT_PRICE))?
                                    <p className="col">Sell Unit $ (if diff)</p>
                                :null}
                                                             



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_INC_SALES_TAX))?
                                    <p className="col">Supplier Inc Sales Tax </p>
                                :null}                                                              



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_ITEM_CODE))?
                                    <p className="col"> Supplier Item Code </p>
                                :null}
                                


                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
                                    <p className="col"> Cost of Units </p>
                                :null}   
                                

                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_PERCENTAGE))?
                                    <p className="col"> Contingency % </p>
                                :null}

                                

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_VALUE))?
                                    <p className="col"> Contingency Value </p>
                                :null}   

                                

                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_PERCENTAGE))?
                                    <p className="col"> Markup % </p>
                                :null}

                                

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_VALUE))?
                                    <p className="col"> Markup Value </p>
                                :null}                                                                



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_PERCENTAGE))?
                                    <p className="col"> Client Sales Tax Percentage  </p>
                                :null}                                                              



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_VALUE))?
                                    <p className="col"> Client Sales Tax Value  </p>
                                :null}
                                

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
                                    <p className="col"> Client Charge </p>
                                :null}     

                                

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE_INC))?
                                    <p className="col">Client Charge Inc </p>
                                :null}
                                                             



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.OVERHEAD))?
                                    <p className="col"> Overhead  </p>
                                :null}                                                              



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN_BEFORE_TAX))?
                                    <p className="col"> Margin Before Tax  </p>
                                :null}                                                              



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_CONTACT_ID))?
                                    <p className="col"> Supplier Contact Id  </p>
                                :null}                                                              



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTRACTOR_ITEM_NOTE))?
                                    <p className="col"> Contractor Item Note  </p>
                                :null}                                                              



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TENDER_ITEM_NOTE))?
                                    <p className="col"> Tender Item Note  </p>
                                :null}  


                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.IS_PRICELIST_ITEM))?
                                    <p className="col"> Pricinglist ? </p>
                                :null}  



                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.NESTED))?
                                    <p className="col"> NESTED  </p>
                                :null} 




                              

                                                            
                                                            */}

                                                          


                                                                                        

                                <ViewPricingColumnChangeIcon/>
                                
                               





                                {/* <div className='col'>
                                    <div className='row'>
                                        <div className='col-9'>
                                            

                                        </div>
                                        <div className='col-2'>
                                            <img src={menuAddLine}></img>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                        </div>
                    }
                    
                     

        </div>       


 

            

           {sectionRequestLoading || itemRequestLoading ||userViewFilterRequestLoading?
                <div className="spinner-border" style={{"width": "3rem", "height": "3rem","position":"absolute","left": "50%"}} role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            :userViewFilterRequestLoading?
              null
              
            :sections.length !==0 ?
                   <>
                    
                    <ShowSections/>
                    <AddSection/>
                    {/* <h3><AddIcon  onClick={()=>{
                // dispatch(changeSectionModalStatus())
                dispatch(createSection())
             }} /></h3> */}
                   
                    
                </>  
            : 
                <section className='section-container'>
                   
                    <div className='section-inner-container row'>
                        <h3 className='col-12'><AddIcon onClick={()=>{
                            // dispatch(changeSectionModalStatus())
                            dispatch(createSection({isRandomName : true}))

                        }}/> Add Section</h3>
                    </div>
                    <div className='section-inner-container row'>
                        <h6 className='col-12 section-container-labour-name'><AddIcon  onClick={()=>{
                             dispatch(createSection({isRandomName : true}))
                             }}/> Add Labour</h6>
                    </div>
                    <div className='section-inner-container row'>
                        <h6 className='col-12 section-container-item-name'><AddIcon  onClick={()=>{ 
                            dispatch(createSection({isRandomName : true}))
                            }}/> Add Item/Material/Equipment/Other</h6>
                    </div>

                </section>
                }
        {/* {items.length !==0 ?
            <>
            {      console.log("---------------------------",itemRequestLoading, items ,"|||||||||",sectionRequestLoading, sections )
}
        <ShowSections/>
            </>
        :null} */}

    
            {/* <SectionPopupModal projectId= {projectId}/> */}
            {/* <LabourPopupModal/> */}
            <PricingListPopupModal/>
            <TenderPackagePopUpModal/>
            <CreateTenderPackagePopUpModal/>
            {/* <SelectedPopupModal/> */}
           
            {/* <Section section={sections[2]}  index= {2} setNumOfNewSections={setNumOfNewSections} items={items}/> */}
     

           

        {!userViewFilterRequestLoading && !sectionRequestLoading && !itemRequestLoading?
              <div className='pricing-bottum d-flex justify-content-around'>
                {/* <div className='d-flex flex-row justify-content-end'>
                    
                        <p className='m-2'>classification</p>
                        <p className='m-2'>Margin</p>
                        <p className='m-2'>Amount</p>
                </div> */}
                    {/* <div className='pricing-bottum-btn-container w-50  d-flex justify-content-around'>
                        <button className='pricing-bottum-btn'>Action Selected</button>
                        <button className='pricing-bottum-btn'>Tender Packages</button>
                        <button className='pricing-bottum-btn'>Delivery Packages</button>
                    </div> */}
                    {/* <button className='pricing-bottum-btn '>Action Selected <img src={dropdownIcon} alt="arrowDown"/></button> */}

                    <div className="pricing-bottum-btn " 
                                role="button"
                                id="dropdownMenuLinkForPricingActionSelected"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                                
                                  {/* <img src={avatarIcon} alt="Avatar" className="btn header-avatar-btn" /> */}
                                  <span>Action Selected <img src={dropdownIcon} alt="arrowDown"/></span>
                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLinkForPricingActionSelected"
                                  >
                                    <li >
                                      <a className="dropdown-item">Bulk Change</a>
                                    </li>
                                    <li >
                                      <a className="dropdown-item" onClick={()=>{dispatch(changeCreateTenderPackageModalStatus())}} >Create Tender Package</a>
                                    </li>
                                    <li >
                                      <a className="dropdown-item" onClick = {()=>{
                                            // itemRequestData.forEach(item => {
                                            // });

                                      }}>Create Deliver Package</a>
                                    </li>
                                  
                                  </ul>
                                </div>
                          {/* <button className="btn header-avatar-btn"><img src={avatarIcon}/></button> */}
                        


                    <button className='pricing-bottum-btn'onClick={()=>{dispatch(changeTenderPackageModalStatus())}}>Tender Packages</button>
                    <button className='pricing-bottum-btn'>Delivery Packages</button>
            
                 <div className='d-flex justify-content-end'>
                                        <ShowSummery/>
                </div>
                {/* <div className='d-flex justify-content-start button-fixed'>
                        <button className='btn btn-outline-secondary btn-light m-1'>Action Selected</button>
                        <button className='btn btn-outline-secondary btn-light m-1' onClick={()=>{dispatch(changeTenderPackageModalStatus())}}>Tender Packages</button>
                        <button className='btn btn-outline-secondary btn-light m-1'>Delivery Packages</button>
                        <button className='btn btn-outline-secondary btn-light m-1' >Proposals</button>
                        {/* <button className='btn btn-outline-primary btn-light m-1' onClick={async()=>{
                            
                            await dispatch(updateSections())
                            await dispatch(updateItems())
                            await dispatch(updateLabours())
                        
                            //window.location.reload();
                        }}>{updateItemRequestLoading || updateSectionRequestLoading ||itemRequestLoading || sectionRequestLoading?
                            <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span>Saving...</span></>
                        :"Save Pricing"}</button> */}
                {/*</div> */}
                
            </div>
         :null}
        <div className='fixed-bottom alert-popup-container'>
            {/* {true ? 
            <div className="alert alert-warning alert-dismissible fade show alert-popup" role="alert">
                <h5><strong>Error : HTTP Error</strong></h5>
                <strong>Holy guacamole!</strong> You should check in on some of those fields below.
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : <></>} */}
            {updateSectionRequestError ? 
            <div className="alert alert-warning alert-dismissible fade show  alert-popup" role="alert">
                <h5><strong>Error : HTTP Error</strong></h5>
                <strong>{updateSectionRequestError}</strong> please Try again.
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : <></>}
            {updateItemRequestError ? 
            <div className="alert alert-warning alert-dismissible fade show  alert-popup" role="alert">
                <h5><strong>Error : HTTP Error</strong></h5>
                <strong>{updateSectionRequestError}</strong> please Try again.
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : <></>}
            {sectionRequestError ? 
            <div className="alert alert-warning alert-dismissible fade show  alert-popup" role="alert">
                <h5><strong>Error : HTTP Error</strong></h5>
                <strong>{sectionRequestError}</strong> please Try again.
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : <></>}
            {itemRequestError ? 
            <div className="alert alert-warning alert-dismissible fade show  alert-popup" role="alert">
                <h5><strong>Error : HTTP Error</strong></h5>
                <strong>{itemRequestError}</strong> please Try again.
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : <></>}
            {deleteSectionRequestError ? 
            <div className="alert alert-warning alert-dismissible fade show  alert-popup" role="alert">
                <h5><strong>Error : HTTP Error</strong></h5>
                <strong>{deleteSectionRequestError}</strong> please Try again.
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : <></>}
            {deleteItemRequestError ? 
            <div className="alert alert-warning alert-dismissible fade show  alert-popup" role="alert">
                <h5><strong>Error : HTTP Error</strong></h5>
                <strong>{deleteItemRequestError}</strong> please Try again.
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : <></>}
            {createSectionRequestError ? 
            <div className="alert alert-warning alert-dismissible fade show  alert-popup" role="alert">
                <h5><strong>Error : HTTP Error while Creating New Section</strong></h5>
                <strong>{createSectionRequestError}</strong> please Try again.
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : <></>}
        </div>
    </div>
  )
}

export default ViewPricing



















// -----------------------------------------------header Old UI view----------------------------------------------

// <div className='pricing-column-header row'>
//                     {/* <p className='col-3'></p> */}
//                     <div className='col-3'>
//                         <div className='row view-pricing-icon-container'>
//                             <div className='col-5   '>
                          
//                             <img src={ellipseIcon} className="col " alt='ellipseIcon'/>
//                             <img src={settingsIcon} className="col" alt='settingsIcon'/>
//                             <img src={hammerIcon} className="col" alt='hammerIcon'/>
//                             <img src={whiteTruckIcon} className="col" alt='whiteTruckIcon'/>
//                             </div>
                            
//                             <div className='col-7 bg-danger d-flex align-items-center'>
                                
//                                 <input className="form-check-input m-1 " type="checkbox" ></input>  
//                                 <h6>Title</h6>
//                             </div>

                              
//                     </div>

//                     </div>
//                     {userViewFilterRequestLoading?
//                          <div className="spinner-border" style={{"width": "3rem", "height": "3rem","position":"absolute","left": "50%"}} role="status">
//                          {/* <span className="sr-only">Loading...</span> */}
//                        </div>
//                     :<>
//                             {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED) && (userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)))?
//                             <div className="col-1 d-flex align-items-center">

//                             <h6 className="w-50" >Qty&</h6>
//                             <h6 className="w-50" >UOM</h6>
                        
//                             </div>
//                         :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
//                             <div className="col-1 d-flex">

//                                 <h6 >Qty&</h6>
//                                 {/* <h6 >UoM</h6> */}
//                             </div>

//                         :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
//                             <div className="col-1 d-flex">

//                                 {/* <h6 >Qty&</h6> */}
//                                 <h6 >UoM</h6>
//                             </div>

//                         :null}
//                         {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
//                             <h6 className="col-1">Unit Cost</h6>
//                         :null}
//                         {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
//                             <h6 className="col-1">Cost of Units</h6>
//                         :null}
                        
//                         {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
//                             <h6 className="col-1">Markup</h6>
//                         :null}
//                         {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
//                             <h6 className="col-1">Client Charge</h6>
//                         :null}
//                         {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
//                             <h6 className="col-2">Prime/Prov/Excl?</h6>
//                         :null}
//                         {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
//                             <h6 className="col-1" >Supplier</h6>
//                         :null}
//                     </>}

                    
//                     {/* <div className="col-1 d-flex">

//                         <h6 >Qty&</h6>
//                         <h6 >UoM</h6>
//                     </div>
//                     <h6 className="col-1">Unit Cost</h6>
//                     <h6 className="col-1">Cost of Units</h6>
//                     <h6 className="col-1">Markup</h6>
//                     <h6 className="col-1">Client Charge</h6>
//                     <h6 className="col-2">Prime/Prov/Excl?</h6>
//                     <h6 className="col-1" >Supplier</h6> */}
//                     {/* <p className='col-1'></p> */}
//                     <div className="col-1">
//                     <button type="button" className="btn btn-primary  " data-bs-toggle="collapse" data-bs-target="#demo" aria-expanded="false"> <MoreVertTwoToneIcon/></button>
//                     <form  id="demo" className="collapse dropdown-menu " onSubmit={(e)=>{
//                         e.preventDefault()
//                         console.log("Submit",e.target[0].value,e.target[0].checked)
//                         checkUserViewFilter(e)
//                         }}>
//                     {console.log("userViewFilters",userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))}
//                     <label><input type="checkbox" id="UNITS_REQUIRED" name="UNITS_REQUIRED" value="UNITS_REQUIRED" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED)} onChange={(event)=>{console.log("event",event)}}/>Quantity</label>
//                       <label><input type="checkbox" id="UOM" name="UOM" value="UOM" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)} onChange={(event)=>{console.log("event",event)}} />UOM</label>
//                       <label><input type="checkbox" id="COST_PER_UNIT" name="COST_PER_UNIT" value="COST_PER_UNIT" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT)}/>Cost per unit</label>
//                       <label><input type="checkbox" id="COST_OF_UNITS" name="COST_OF_UNITS" value="COST_OF_UNITS" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS)}/>Cost of units</label>
//                       <label><input type="checkbox" id="MARGIN" name="MARGIN" value="MARGIN" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN)}/>Markup</label>
//                       <label><input type="checkbox" id="CLIENT_CHARGE" name="CLIENT_CHARGE" value="CLIENT_CHARGE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE)}/>Client Charge</label>
//                       <label><input type="checkbox" id="STATUS" name="STATUS" value="STATUS" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS)}/>Prime/Prov/Excl</label>
//                       <label><input type="checkbox" id="COST_TYPE" name="COST_TYPE" value="COST_TYPE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_TYPE)}/>Cost type</label>
//                       {/* <label><input type="checkbox" id="TYPE" name="TYPE" value="TYPE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE)}/>Status</label> */}
//                       <label><input type="checkbox" id="TYPE" name="TYPE" value="TYPE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE)}/>Supplier</label>
//                       <button type="submit" className="btn btn-success" data-bs-toggle="collapse" data-bs-target="#demo" aria-expanded="false">Success</button>
                   
//                     </form>
                  
//                     </div>
                     

//         </div>
