import { configureStore } from "@reduxjs/toolkit"
import authReducer from '../features/AuthSlice'
import ContactSlice from "../features/ContactSlice";
import PricingListSlice from "../features/PricingListSlice";
import pricingSlice from "../features/PricingSlice";
import projectReducer from '../features/ProjectSlice'
import documentSlice from '../features/DocumentSlice'

const store  = configureStore({
    reducer : {
        auth : authReducer,
        project:  projectReducer,
        pricing : pricingSlice,
        pricingList : PricingListSlice,
        contact : ContactSlice,
        documents: documentSlice
    }
    
})


export default store;