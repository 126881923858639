import React from 'react'
import './PricingListItem.css'
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import { billOfQuantities, pricingList } from '../../constants/Constants';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { useDispatch } from 'react-redux';
import { updatePricingList } from '../../features/PricingListSlice';
import { useEffect } from 'react';
import { useState } from 'react';
const PricingListItem = ({pricingData}) => {
  const dispatch = useDispatch()
  const [formattedDate, setFormattedDate ] = useState('')


  const formatDate = ()=>{
    const date = new Date(pricingData.date);

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    const newDate = `${year}-${month}-${day}`;
    setFormattedDate(newDate)
  }
  useEffect(() => {
  
       
        formatDate()
  }, []);
  return (
    <div className='pricing-list-container-item   row'>
            {/* <div className='col-1'>
              <input defaultValue={"suborder_id"} className="pricing-list-input form-control m-0 p-0"></input>
            </div> */}
            <div className='col-2'>
              <input defaultValue={pricingData.name} className="pricing-list-input form-control m-0 p-0" onBlur={(e)=>{
                dispatch(updatePricingList({pricingListData:{...pricingData, name : e.target.value }}))
              }}></input>
            </div>
            {/* <p className='col-5'>{pricingData.name}</p> */}
            <div className='col-1'>
              {/* <input defaultValue={pricingData.type} className=" form-control"></input> */}

              <select className='w-100' defaultValue={pricingData.item_type} onBlur={(e)=>{
                                                           // dispatch(updateItem({...item, status :e.target.value}))
                                                           dispatch(updatePricingList({pricingListData : {...pricingData, item_type :e.target.value}}))
                                                      
                                                           }} >
                                                               <option value="Assembly (Global)">Assembly (Global)</option>
                                                               <option value="Equipment">Equipment</option>
                                                               <option value="Material">Material</option>
                                                               <option value="MathLab">MathLab</option>
                                                               <option value="Other">Other</option>
                                                               <option value="Staff">Staff</option>
                                                               <option value="Sub(Labour only)">Sub(Labour only)</option>
                                                               <option value="Sub(Material only)">Sub(Material only)</option>
                                                               <option value="Sub(MathLab)">Sub(MathLab)</option>
                                                           </select>
                {/* <select 
                  defaultValue={pricingData.type}
                  className="pricing-list-input form-control m-0 p-0"
                  onChange={(e)=>{
                    dispatch(updatePricingList({pricingListData:{...pricingData, type : e.target.value }}))
                  }}
                  >
                    <option value ={billOfQuantities.TYPES.ITEM}>{billOfQuantities.TYPES.ITEM}</option>
                    <option value ={billOfQuantities.TYPES.LABOUR}>{billOfQuantities.TYPES.LABOUR}</option>
                </select> */}
            </div>
            {/* <p className='col-1'>{pricingData.type}</p> */}
            <div className='col-1'>
                <input defaultValue={pricingData.specialism} className="pricing-list-input form-control m-0 p-0" onBlur={(e)=>{
                dispatch(updatePricingList({pricingListData:{...pricingData, specialism : e.target.value }}))
              }}></input>
            </div>
            <div className='col-1'>
                <input defaultValue={"0001"} className="pricing-list-input form-control m-0 p-0" ></input>
            </div>
            <div className='col-1'>
                <input defaultValue={pricingData.buy_unit_price} className="pricing-list-input form-control m-0 p-0" onBlur={(e)=>{
                dispatch(updatePricingList({pricingListData:{...pricingData, buy_unit_price : e.target.value }}))
              }}></input>
            </div>
            <div className='col-1'>
                {/* <input defaultValue={pricingData.uom} className="pricing-list-input form-control m-0 p-0" onBlur={(e)=>{
                dispatch(updatePricingList({pricingListData:{...pricingData, uom : e.target.value }}))
              }}></input> */}
              <select className='w-100' defaultValue={pricingData.uom} onBlur={(e)=>{
                                                           // dispatch(updateItem({...item, status :e.target.value}))
                                                           dispatch(updatePricingList({pricingListData : {...pricingData, uom :e.target.value}}))
                                                      
                                                           }} >
                                                               <option value="ea">ea</option>
                                                               <option value="m2">m2</option>
                                                               <option value="hrs">hrs</option>
                                                               <option value="im">im</option>
                                                               
                                                           </select>
            </div>
            <div className='col-1'>
                <input defaultValue={pricingData.margin} className="pricing-list-input form-control m-0 p-0" onBlur={(e)=>{
                dispatch(updatePricingList({pricingListData:{...pricingData, margin : e.target.value }}))
              }}></input>
            </div>
            {/* <div className='col-1'>
                <select 
                  defaultValue={pricingData.is_searchable}
                  className="pricing-list-input form-control m-0 p-0"
                  onChange={(e)=>{
                    dispatch(updatePricingList({pricingListData:{...pricingData, is_searchable : e.target.value }}))
                  }}
                  >
                    <option value ={pricingList.YES_OR_NO.YES}>{pricingList.YES_OR_NO.YES}</option>
                    <option value ={pricingList.YES_OR_NO.NO}>{pricingList.YES_OR_NO.NO}</option>
                </select>
            </div> */}
            {/* <div className='col-1'>
                <select 
                  defaultValue={pricingData.is_assemblies}
                  className="pricing-list-input form-control m-0 p-0"
                  onChange={(e)=>{
                    dispatch(updatePricingList({pricingListData:{...pricingData, is_assemblies : e.target.value }}))
                  }}
                  >
                    <option value ={pricingList.YES_OR_NO.YES}>{pricingList.YES_OR_NO.YES}</option>
                    <option value ={pricingList.YES_OR_NO.NO}>{pricingList.YES_OR_NO.NO}</option>
                </select>
            </div> */}
            <div className='col-2 d-inline-flex'>
              
                <input defaultValue={formattedDate} type="date" className="pricing-list-input form-control m-0 p-0" 
                onBlur={(e)=>{
                  
                  dispatch(updatePricingList({pricingListData:{...pricingData, date : e.target.value }}))
                }}
                ></input>
                
            </div>
            <div className='col-1'></div>
          
            <div className='col-1'><MoreVertTwoToneIcon/></div>
            {/* <div className='col-1'>
                <input defaultChecked={pricingData.date} type="text" className="form-control"></input>
            </div> */}
            {/* <p className='col-2'></p> */}
            {/* <div className='col-1'>
                    <CancelPresentationTwoToneIcon   color="warning" />
            </div> */}
    </div>
  )
}

export default PricingListItem