import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';

const UploadedFileTable = ({files}) => {
    return <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead style={{backgroundColor: 'lightgray'}}>
        <TableRow>
          <TableCell>No.</TableCell>
          <TableCell align="left">File Name</TableCell>
          <TableCell align="left">Type</TableCell>
          <TableCell align="left">Status</TableCell>
          <TableCell align="left">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {files.map((file, index) => (
          <TableRow
            key={file._id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {index + 1}
            </TableCell>
            <TableCell align="left">{file.filename}</TableCell>
            <TableCell align="left">{file.file_type}</TableCell>
            <TableCell align="left">{file.file_status}</TableCell>
            <TableCell align="left"><a href={file.url}><DownloadIcon/></a></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer> 
}

export default UploadedFileTable