import { Modal, Box } from "@mui/material";
import DocumentEditForm from "./DocumentEditForm";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    minWidth: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
  };

const DocumentEditModal = ({show, onClose}) => {
    return (
        <Modal
            open={show}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <DocumentEditForm />
            </Box>
        </Modal>
    )
}

export default DocumentEditModal;