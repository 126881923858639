import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiCodes, urls } from "../constants/Url"
import { interceptedHttpGet, interceptedHttpPost, interceptedHttpPut } from "../service/service"


const initialState = {
    contactRequestData : [],
    contactRequestLoading : false,
    contactRequestError : "",

    updateContactRequestData : [],
    updateContactRequestLoading : false,
    updateContactRequestError : "",


    createContactRequestData : [],
    createContactRequestLoading : false,
    createContactRequestError : "",

    isShowCreateContactModal : false,

    

}

export const getContacts = createAsyncThunk('/getContacts', (args,{getState,dispatch})=>{
    const contactsResponse = interceptedHttpGet(urls.BASE_URL+ urls.GET_ALL_CONTACTS)

    return contactsResponse.then(
        (response)=>{
            return response.data

    })
})

export const updateContacts = createAsyncThunk('/updateContact', (args,{getState,dispatch})=>{
    const state = getState()
    const {contactData} = args
    const contactsResponse = interceptedHttpPut(urls.BASE_URL+ urls.UPDATE_CONTACT, {
        contactData : contactData
    })

    return contactsResponse.then(
        (response)=>{

            if(response.status === apiCodes.STATUS_SUCCESS_CODE){

                return contactData
            }

    })
})


export const createContact = createAsyncThunk('/createContact', (args, {getState,dispatch})=>{
    const {contactData} = args

  
    const contactsResponse = interceptedHttpPost(urls.BASE_URL+ urls.POST_CONTACT, {
        contactData : {...contactData}
    })

    return contactsResponse.then(
        (response)=>{
            return response.data

    })

})


const contact = createSlice({
    name : "contact",
    initialState,
    reducers : {

        changeCreateContactModalStatus : (state,action) =>{
                state.isShowCreateContactModal  = ! state.isShowCreateContactModal
        },

    },
    extraReducers : (builder, thunkApi)=>{
        builder.addCase(getContacts.fulfilled,(state,action)=>{
            state.contactRequestData = action.payload
            state.contactRequestLoading = false
            state.contactRequestError =""

        })
        builder.addCase(getContacts.pending,(state,action)=>{
            state.contactRequestData = []
            state.contactRequestLoading = true
            state.contactRequestError =""

        })
        builder.addCase(getContacts.rejected,(state,action)=>{
            state.contactRequestData = []
            state.contactRequestLoading = false
            state.contactRequestError =action.error.message

        })
        builder.addCase(updateContacts.fulfilled,(state,action)=>{
            state.updateContactRequestData = action.payload
            state.updateContactRequestLoading = false
            state.updateContactRequestError =""

            state.contactRequestData = state.contactRequestData.map((contact)=>{
                if(contact._id === action.payload._id){
                    return action.payload
                }
                else {
                    return contact
                }
            })

        })
        builder.addCase(updateContacts.pending,(state,action)=>{
            state.updateContactRequestData = []
            state.updateContactRequestLoading = true
            state.updateContactRequestError =""

        })
        builder.addCase(updateContacts.rejected,(state,action)=>{
            state.updateContactRequestData = []
            state.updateContactRequestLoading = false
            state.updateContactRequestError =action.error.message

        })



        builder.addCase(createContact.fulfilled,(state,action)=>{
            state.createContactRequestData = action.payload
            state.createContactRequestLoading = false
            state.createContactRequestError =""

            state.contactRequestData = [...state.contactRequestData, action.payload]

        })
        builder.addCase(createContact.pending,(state,action)=>{
            state.createContactRequestData = []
            state.createContactRequestLoading = true
            state.createContactRequestError =""

        })
        builder.addCase(createContact.rejected,(state,action)=>{
            state.createContactRequestData = []
            state.createContactRequestLoading = false
            state.createContactRequestError =action.error.message

        })
    }
})



export default contact.reducer



export const {changeCreateContactModalStatus} = contact.actions