import logo from './logo.svg';
import './App.css';
import {useDispatch, useSelector} from 'react-redux';
import { useEffect } from 'react';
import {login, logged} from './features/AuthSlice'
function App() {
    const dispatch = useDispatch()
    const loged  = useSelector((state)=>state.auth.loginRequestData)
 
    useEffect(() => {
    console.log(loged)
    
  }, [loged]);
  return (
    <div className="App">
      <h1>Hello</h1>
      
      <button onClick={()=>{dispatch(login()) 
      //console.log(loged)
    }}>Ops</button>
    </div>
  );
}

export default App;
