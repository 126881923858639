import { Link } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ViewPricing from '../../components/viewPricing/ViewPricing'
import {ReactComponent as ArrowDownIcon} from '../../assets/icons/icon-wrapper.svg'
import {ReactComponent as SearchIcon} from '../../assets/icons/icon-search.svg'

import undoIcon from '../../assets/icons/undo-icon.png'
import redoIcon from '../../assets/icons/redo-icon.png'
import printIcon from '../../assets/icons/print-icon.png'
import exportIcon from '../../assets/icons/export-icon.png'


import documentIcon from '../../assets/icons/document-icon.png'
import estimateIcon from '../../assets/icons/estimate-icon.png'
import tendersIcon from '../../assets/icons/tenders-icon.png'
import deliveriesIcon from '../../assets/icons/deliveries-icon.png'
import proposalsIcon from '../../assets/icons/proposals-icon.png'
import historyIcon from '../../assets/icons/history-icon.png'
import './Pricing.css'
import { useDispatch, useSelector } from 'react-redux'
import { changeTenderPackageModalStatus, getAddUp, getLaboursAndItems, getSections, getUserViewFilter, getproject, setCurrentProjectId, setIdArray } from '../../features/PricingSlice'
import { useLocation } from 'react-router'
const Pricing = () => {
    const dispatch = useDispatch()
    const [projectId, setProjectId] = useState('')
    const location = useLocation()
    const itemRequestLoading = useSelector((state)=>state.pricing.itemRequestLoading)
    const sectionRequestLoading = useSelector((state)=>state.pricing.sectionRequestLoading)
    const userViewFilterRequestLoading = useSelector((state)=>state.pricing.userViewFilterRequestLoading)
    
    useEffect(() => {
      setProjectId(location.state.projectId)
      dispatch(setCurrentProjectId(location.state.projectId))
      dispatch(getproject())
      dispatch(getSections(location.state.projectId)).then(()=>{
          // dispatch(getLabour()).then(()=>{

          //     dispatch(getItems())
          // })
          dispatch(getLaboursAndItems()).then((e)=>{
            
              dispatch(setIdArray())
          })
      })
      dispatch(getUserViewFilter())
      dispatch(getAddUp())
     console.log("GET |section | items ",location.state.projectId,)
  }, []);
  return (
    <div className='price-container'>
        <div className='price-top-container d-block'>
          <div className='pricing-breadcrumb-container d-flex justify-content-between align-items-center'>
            <nav aria-label="breadcrumb" >
              <ol className="breadcrumb d-inline-flex">
                <li className="breadcrumb-item active"><a >Home</a></li>
                <li className="breadcrumb-item active"><a >Project address</a></li>
                <li className="breadcrumb-item " aria-current="page">Estimate</li>
              </ol>
            </nav>
          <div className='d-inline-flex flex-row'>
            <div  className='d-inline-flex flex-row'>
                <img className="btn btn-ligh pricing-top-icon" src={redoIcon}/>
                <img className="btn btn-ligh pricing-top-icon" src={undoIcon}/>
                <img className="btn btn-ligh pricing-top-icon" src={printIcon}/>
                <img className="btn btn-ligh pricing-top-icon" src={exportIcon}/>
            </div>

            <div className='pricing-search-box'>
                <input className="pricing-search-box-input" type="search" placeholder="keyword Search" aria-label="Search"/>
                {/* <SearchIcon/> */}
                <button><SearchIcon/></button>
              {/* <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
              </div>
            </div>
          </div>
          {/* <div className='d-flex flex-row align-items-center p-0 pricing-icon-container'>
                  
                  <img className="btn btn-ligh" src={documentIcon}/>
                  <img className="btn btn-ligh" src={estimateIcon}/>
                  <img className="btn btn-ligh" src={tendersIcon} onClick={()=>{dispatch(changeTenderPackageModalStatus())}}/>
                  <img className="btn btn-ligh" src={deliveriesIcon}/>
                  <img className="btn btn-ligh" src={proposalsIcon}/>
                  <img className="btn btn-ligh" src={historyIcon}/>
                 

          </div> */}
          {/* <div className='d-flex justify-content-between pricing-search-box-container-row'> */}
            {/* <div className="dropdown pricing-dropdown-container" >
                

            <button className='pricing-expand-all-groups-btn' data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"> Exapand all groups <ArrowDownIcon/></button>
                        <ul className="dropdown-menu">
                          <li><a className="dropdown-item" href="#scrollspyHeading3">Add to Archive</a></li>
                          <li><a className="dropdown-item" href="#scrollspyHeading4">Fourth</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#scrollspyHeading5">Fifth</a></li>
                        </ul>
            </div> */}
            {/* <h3>Bill of Quantities</h3> */}
            {/* <div className='d-flex'>
              <div className="dropdown pricing-dropdown-container actions-dropdown">
                <button className='pricing-expand-all-groups-btn' data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"> Actions<ArrowDownIcon/></button>
                            <ul className="dropdown-menu">
                              <li><a className="dropdown-item" href="#scrollspyHeading3">Add to Archive</a></li>
                              <li><a className="dropdown-item" href="#scrollspyHeading4">Fourth</a></li>
                              <li><hr className="dropdown-divider"/></li>
                              <li><a className="dropdown-item" href="#scrollspyHeading5">Fifth</a></li>
                            </ul>
              </div>
              <div className='pricing-search-box'>
                <input className="pricing-search-box-input" type="search" placeholder="keyword Search" aria-label="Search"/>
                <SearchIcon/>
              
              </div>
            </div> */}
        {/* </div>   */}
      </div>
        {/* {!itemRequestLoading &&!sectionRequestLoading && !userViewFilterRequestLoading?<ViewPricing/>:null} */}
        <ViewPricing/>
 
    </div>
  )
}

export default Pricing