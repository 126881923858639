import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Pagination, Tooltip } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { archiveDocument, deleteDocument, getDocuments, setUpdatingDocument } from "../../features/DocumentSlice";
import DownloadIcon from '@mui/icons-material/Download';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import { TABLE_LIMIT } from '../../constants/Constants';
import DocumentEditModal from './DocumentEditModal';

const DocumentTable = () => {
  const dispatch = useDispatch()
  const documents = useSelector((state) => state.documents.documents)
  const pageCount = useSelector((state) => state.documents.pageCount)
  const deleteResponse = useSelector((state) => state.documents.fileDeletionResponse)
  const archiveResponse = useSelector((state) => state.documents.fileArchivalResponse)
  const updateResponse = useSelector((state) => state.documents.fileUpdateResponse)
  const filterCriteria = useSelector((state) => state.documents.filterCriteria)
  const updatingDocument = useSelector((state) => state.documents.updatingDocument)

  const [page, setPage] = useState(1);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const onPageChange = (page) => {
    setPage(page)
  }

  const onEdit = async (document) => {
    dispatch(setUpdatingDocument(document))
  }

  const onArchive = async (document) => {
    dispatch(archiveDocument({ fileId: document._id }))
  }

  const onRemove = async (document) => {
    dispatch(deleteDocument({ fileId: document._id }))
  }

  const fetchDocuments = async () => {
    dispatch(getDocuments({ page: page, limit: TABLE_LIMIT, project_id: filterCriteria?.project_id, file_type: filterCriteria?.file_type, file_status: filterCriteria?.file_status }))
  }

  useEffect(() => {
    if (documents.length === 0) {
      fetchDocuments()
    }
  }, []);

  useEffect(() => {
    if(updatingDocument) {
      setShowEditDialog(true)
    }
  }, [updatingDocument]);

  useEffect(() => {
    fetchDocuments()
  }, [page, filterCriteria]);

  useEffect(() => {
    if (deleteResponse && deleteResponse.message === 'File deleted successfully') {
      fetchDocuments()
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (updateResponse) {
      setShowEditDialog(false)
      fetchDocuments()
    }
  }, [updateResponse]);

  useEffect(() => {
    if (archiveResponse && archiveResponse.message === 'File archived successfully') {
      fetchDocuments()
    }
  }, [archiveResponse]);

  return <>
    {showEditDialog &&
      <DocumentEditModal show={showEditDialog} onClose={() => setShowEditDialog(false)} />
    }
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{ backgroundColor: 'lightgray' }}>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell align="left">File Name</TableCell>
            <TableCell align="left">Project</TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents?.map((document, index) => (
            <TableRow
              key={document._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {((page - 1) * TABLE_LIMIT) + (index + 1)}
              </TableCell>
              <TableCell align="left">{document.filename}</TableCell>
              <TableCell align="left">{document.project?.project_name}</TableCell>
              <TableCell align="left">{document.file_type}</TableCell>
              <TableCell align="left">{document.file_status}</TableCell>
              <TableCell align="left">
                <Tooltip title="Edit">
                  <EditIcon style={{ cursor: 'pointer' }} onClick={() => onEdit(document)} />
                </Tooltip>
                <Tooltip title="Archive">
                  <IconButton disableRipple disabled={document.file_status === 'archived'}><ArchiveIcon style={{ marginLeft: '20px', cursor: 'pointer' }} onClick={() => onArchive(document)} /></IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <DeleteIcon style={{ marginLeft: '20px', cursor: 'pointer' }} color='error' onClick={() => onRemove(document)} />
                </Tooltip>
                <Tooltip title="Download">
                  <a style={{ marginLeft: '20px' }} href={document.url}>
                    <DownloadIcon />
                  </a>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination count={pageCount} color="primary" style={{ marginTop: '1rem', display: 'flex', justifyContent: 'right' }} onChange={(e, value) => onPageChange(value)} />

    </TableContainer>
  </>
}

export default DocumentTable