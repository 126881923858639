import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getProjects } from '../../features/ProjectSlice'
import ProjectCardView from '../projectCardView/ProjectCardView'
import { ProjectStatus } from '../../shared/enums'
import FilterCriteria from '../../components/viewProjects/FilterCriteria';

const ViewProjects = ({ active }) => {
  const dispatch = useDispatch()
  const projects = useSelector((state) => state.project);

  const [budget, setBudget] = useState(0);
  const [contractType, setContractType] = useState(0);

  const handleBudgetChange = (value) => {
    setBudget(value)
  }

  const handleContractTypeChange = (value) => {
    setContractType(value)
  }

  const getAllProjects = async () => {
    await dispatch(getProjects())
  }

  const isFilterCriteriaSatisfied = (project) => {
    if ((contractType == 0 || ((contractType == 1 && project.contract_type == 'fixed cost') || (contractType == 2 && project.contract_type == 'time & material'))) &&
      (budget == 0 || ((budget == 1 && project.client_budget > 0 && project.client_budget <= 10000) || (budget == 2 && project.client_budget > 10000 && project.client_budget <= 20000) || (budget == 3 && project.client_budget > 20000)))) {
      return true;
    }
    return false;
  }

  const DisplayProjects = () => {
    return projects.projectsRequestData.map(element => {
      if (isFilterCriteriaSatisfied(element)) {
        if (active && element.status === ProjectStatus.Active) {
          return (<ProjectCardView key={element._id} project={element} />)
        }
        else if (!active && element.status === ProjectStatus.Archieved) {
          return (<ProjectCardView key={element._id} project={element} />)
        }
      }
    });
  }

  useEffect(() => {
    getAllProjects()
  }, [])


  return (
    <div className='container-fluid'>
      <div className='row justify-content-between'>
        <FilterCriteria onBudgetChange={handleBudgetChange} onContractTypeChange={handleContractTypeChange} />

        <DisplayProjects />
      </div>
    </div>
  )
}

export default ViewProjects