import React from 'react'
import './Dashboard.css'
import { Modal } from "react-bootstrap";
import NewProject from '../../components/newProject/NewProject';
import { useState } from 'react';
import { Prev } from 'react-bootstrap/esm/PageItem';
import ViewProjects from '../../components/viewProjects/ViewProjects';
import MyActivityCard from '../../components/activityCard.js/MyActivityCard';
import CompanyActivityCard from '../../components/companyActivityCard/CompanyActivityCard';
import CreateNewProject from '../../components/createNewProject/CreateNewProject';

const Dashboard = () => {
  const [newprojectModal, setNewProjectModal] = useState(false);
  const [isActive, setIsActive] = useState(true)
  return (
    <div>
    <div className="dashboard-container">
      <div className="d-flex justify-content-between dashborad-activity-container">
      {/* <MyActivityCard/> */}
      {/* <CompanyActivityCard/> */}
       
      </div>
      <div className="dashboard-project-container">
        <div>
            <h3>Project</h3>
            <div className="project-top-container-category" >
                <div className="project-top-category-status">
                <span onClick={()=>{setIsActive(true)
                }}>Active</span>

                <span onClick={()=>{setIsActive(false)
                }}>Archive</span>
                </div>
                {/* <button className="project-top-container-button">New Project</button> */}

              <button className="btn btn-primary"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseWidthMyActivity"
                // aria-expanded="false" 
                aria-controls="collapseWidthExample"
                onClick={() => { setNewProjectModal((prev) => !prev) }}>
                New Project
              </button>
            
            </div>
           
        </div>
        <div >

        <ViewProjects active={isActive}/>
        </div>
       
    </div>
    
        
    </div>
    {newprojectModal && (
          <>
           {/* <NewProject setshowModel = {setNewProjectModal}/> */}
           <CreateNewProject showModel= {newprojectModal} setshowModel = {setNewProjectModal}/>
           </>
        )}
    {/* <CreateNewProject showModel= {newprojectModal} setshowModel = {setNewProjectModal}/> */}
    </div>
  )
}

export default Dashboard