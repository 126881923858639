import React, { useEffect, useState } from 'react'

import Nav from 'react-bootstrap/Nav';
import "./CreateNewProject.css"
import { Modal } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { schema } from '../../constants/Constants';
import { useDispatch } from 'react-redux';
import { postNewProject } from '../../features/ProjectSlice';
import { getContacts } from '../../features/ContactSlice';
import { useSelector } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import { urls } from "../../constants/Url"
import DocumentUploadForm from '../project/DocumentUploadForm';
import { packFilesToFormData } from '../../shared/utils';
import { interceptedHttpPost } from '../../service/service';

// insted of newProject Component
const CreateNewProject = ({ showModel, setshowModel }) => {

  const [page, setPage] = useState(0)
  const [files, setFiles] = useState([])
  const dispatch = useDispatch()
  const contactRequestData = useSelector((state) => state.contact.contactRequestData).map((contact) => {
    return { ...contact, id: contact._id }
  })

  const [contactRequestDataCurrent, setContactRequestDataCurrent] = useState(() => {
    return contactRequestData.map((contact) => {
      return { ...contact, id: contact._id }
    })
  })



  const contactRequestLoading = useSelector((state) => state.contact.contactRequestLoading)

  const [formValues, setFormValues] = useState({
    [schema.PROJECT.INVOICE_FREQUENCY]: "daily",
    [schema.PROJECT.PAYMENT_STRUCTURE]: "0",
    [schema.PROJECT.CLIENT_SALES_TAX_PERCENTAGE]: "10",
    [schema.PROJECT.CONTINGENCY_PERCENTAGE]: "10",
    [schema.PROJECT.GMP_PERCENTAGE]: "0",
    [schema.PROJECT.CLIENT_RETAINER_PERCENTAGE]: "0",
    [schema.PROJECT.MARKUP_PERCENTAGE]: "15",
  })
  const [isContractTypeContractPrice, setisContractTypeContractPrice] = useState(true)

  const addFile = (file) => {
    setFiles(files => [...files, file])
  }

  // const removeFile = (removedFile) => {
  //   setFiles(files.filter(f => f.name !== removedFile.name))
  // }

  const removeFile = (removedFile) => {
    setFiles(files.filter(f => f.documentName !== removedFile.documentName))
}

  const PillNavBar = () => {
    return (
      <Nav variant="pills" defaultActiveKey={() => {
        if (page === 0) {
          return "link-0"
        }
        else if (page === 1) {
          return "link-1"
        }
        else {
          return "link-2"
        }
      }} className='create-new-project-nav'>
        <Nav.Item>
          <Nav.Link eventKey="link-0" onClick={() => setPage(0)}>Client Details</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-1" onClick={() => setPage(1)}>Contract Structure</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-2" onClick={() => setPage(2)} >Documents</Nav.Link>
        </Nav.Item>

      </Nav>
    );
  }

  const ShowclientDetails = () => {


  }


  const handleSubmit = (e) => {
    e.preventDefault()
    console.log("ooo", e, formValues)
  }
  const handleChange = (e) => {
    console.log("999", formValues)
    // console.log("999", e.target.id, e.target.name,e.target.value, e.target.type)

    if (e.target.type === "checkbox") {
      setFormValues((prev) => { return { ...prev, [e.target.id]: e.target.checked } })

    }
    else if (e.target.type === "radio") {
      setFormValues((prev) => { return { ...prev, [e.target.name]: e.target.value } })

    }
    else {

      setFormValues((prev) => { return { ...prev, [e.target.id]: e.target.value } })
    }
  }

  useEffect(() => {
    if (isContractTypeContractPrice) {

      setFormValues((prev) => {
        let object = { ...prev, [schema.PROJECT.GMP_PERCENTAGE]: 0 }
        delete object[schema.PROJECT.IS_INCLUDE_HIGH_LEVEL_ESTIMATE]
        delete object[schema.PROJECT.COST_PLUS_METHOD]
        //  delete object[schema.PROJECT.GMP_PERCENTAGE]
        return object
      })
    }
    else {
      setFormValues((prev) => {
        let object = { ...prev, [schema.PROJECT.CLIENT_RETAINER_PERCENTAGE]: 0 }
        delete object[schema.PROJECT.PAYMENT_STRUCTURE]
        // delete object[schema.PROJECT.CLIENT_RETAINER_PERCENTAGE]
        delete object[schema.PROJECT.CLIENT_RETAINER_PERIOD_IN_WEEKS]
        delete object[schema.PROJECT.IS_UNIT_PRICE_WITH_FLEXIBLE_QTY]
        delete object[schema.PROJECT.UNIT_QTY_MIN]
        delete object[schema.PROJECT.UNIT_QTY_MAX]
        return object
      })
    }


  }, [isContractTypeContractPrice])

  useEffect(() => {
    console.log("888")
    dispatch(getContacts())

  }, [])

  const CreateModal = () => {
    return (
      <Modal show={showModel} size="lg">
        <Modal.Header>
          <div className='d-flex flex-column justify-content-between w-100'>
            <div className='d-flex justify-content-between'>
              <h4>Create New Project</h4>
              <button className='btn btn-danger' onClick={() => { setshowModel((prev) => !prev) }}>Close </button>

            </div>
            <div className='create-new-project-phill-nav-bar-container'  >

              <PillNavBar />
            </div>

          </div>
        </Modal.Header>
        <Modal.Body>
          <form>
            {page === 0 ? (
              <>
                <h1>Page 0</h1>
              </>
            )
              : page === 1 ? (
                <>
                  <h1>Page 1</h1>

                </>)
                : (<>
                  <h1>Page 2</h1>

                </>)}

          </form>
        </Modal.Body>
      </Modal>
    )
  }

  const formatResult = (item) => {

    return (
      <div className="result-wrapper">
        <span className="result-span">{item.first_name} : {item.last_name}</span>
      </div>
    )
  }

  // const packFiles = () => {
  //   const data = new FormData();
  //   [...files].forEach((file, i) => {
  //     data.append('files', file)
  //   })
  //   return data
  // }

  const uploadFiles = async () => {
    if (files.length > 0) {
      const formData = packFilesToFormData(files, formValues[schema.PROJECT.ID]);
      // await axios.post(urls.BASE_URL + urls.UPLOAD_FILES, formData, {
      // }).then((response) => { window.location.reload(); })
      // .catch(err => console.log(err))

      interceptedHttpPost(urls.BASE_URL + urls.UPLOAD_FILES, formData)
        .then((response) => { window.location.reload(); })
        .catch(err => window.location.reload())
    }
  }

  const saveProject = async () => {
    // dispatch(
    //   postNewProject(
    //     { 
    //         clientName: client,
    //          clientEmail: clientEmail,
    //          contractType:contractType,
    //          invoiceFrequency :invoiceFrequency,
    //          proposedBudget : proposedBudget,
    //          agreedMargin: agreedMargin,
    //          phone:phone,
    //          siteAddress: siteAddress,
    //          homeAddress:homeAddress,
    //          siteBuilder:siteBuilder,
    //          proposalBasedOn: proposalBasedOn,
    //          lotNumber : lotNumber,
    //          taxRate:taxRate,
    //          proposedStartDate : proposedStartDate,
    //          proposedEndDate :proposedEndDate,
    //          proposedDuration: proposedDuration,
    //          councilApproved: councilApproved,
    //          daysDeliveredBeforeInstallation: daysDeliveredBeforeInstallation,
    //          exclutionText: exclutionText,
    //          markupPercentage,
    //          contingencyPercentage,
    //          gmpPercentage,
    //          clientSalesTaxPercentage,
    //          clietnRetainerPercentage
    //          }));

    dispatch(postNewProject({ newProjectData: formValues }))
    uploadFiles();

    window.location.reload();
    // console.log(client," : ", clientEmail)
    // const url = urls.BASE_URL+urls.POST_PROJECT
    // const projectRequest ={
    //     "project" : {
    //         "client" : client,
    //         "email" : clientEmail,
    //     }
    // }
    // const responsePromise = await interceptedHttpPost(url, projectRequest)
    // .then ((response)=>{
    //     console.log(response.data)
    // })
    // console.log(responsePromise.data)
    // console.log("here")
    // httpGet("http://localhost:5000/api/v1/get")
    // .then((respose)=>{
    //     console.log(respose.data)
    // })
  };
  useEffect(() => {
    console.log("FFF", formValues)


  }, [formValues])


  return (
    <div className="project-popup-container">
      {!contactRequestLoading ?
        <Modal show={showModel} size='xl'>
          <Modal.Header>
            <div className='d-flex flex-column justify-content-between w-100'>
              <div className='d-flex justify-content-between'>
                <h4>Create New Project</h4>
                <button className='btn btn-danger' onClick={() => { setshowModel((prev) => !prev) }}>Close </button>

              </div>
              <div className='create-new-project-phill-nav-bar-container'  >

                <PillNavBar />
              </div>

            </div>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              {page === 0 ? (
                <>
                  {/* <input type="text" class="form-control" placeholder="Client name" id={schema.PROJECT.CLIENT_NAME} name={schema.PROJECT.CLIENT_NAME} aria-label="Client name" aria-describedby="basic-addon1" defaultValue={formValues[schema.PROJECT.CLIENT_NAME]} onChange={handleChange}></input> */}
                  {/* <ReactSearchAutocomplete
                               placeholder={"client name"}
                               formatResult={formatResult}
                               items={contactRequestData || []}
                               fuseOptions={{keys : ["first_name","last_name"]}}
                              //  fuseOptions={{keys : ["first_name"]}}
                               showIcon= {false}
                               onSelect = {(event)=>{
                                
                                console.log("qq",formValues)
                                setFormValues((prev)=>{return {...prev, [schema.PROJECT.CLIENT_NAME]: event.first_name, [schema.PROJECT.CLIENT_EMAIL]: event.email_address, [schema.PROJECT.CLIENT_PHONE]: event.mobile_tel_number, [schema.PROJECT.SITE_ADDRESS_TWO]: event.home_address, }})
                              }}
                                // onSelect={(event)=>{event.preventDefault()}}
                                
                              styling={{
                                 height: "34px",
                                 border: "none",
                                 // border: "1px solid darkgreen",
                                 borderRadius: "4px",
                                 backgroundColor: "#EFEFEF",
                                 boxShadow: "none",
                                 hoverBackgroundColor: "lightgreen",
                                 color: "black",
                                 // fontSize: "12px",
                                 // fontFamily: "Courier",
                                 iconColor: "green",
                                 lineColor: "lightgreen",
                                 placeholderColor: "darkgreen",
                                 clearIconMargin: "3px 8px 0 0",
                                 zIndex : "auto",
                                 // zIndex: {labourZIndex},
                               }}
                            /> */}
                  <Typeahead
                    id="basic-example"
                    className=' mb-1'
                    labelKey={"first_name"}
                    filterBy={['first_name', 'last_name']}
                    onChange={(event) => {
                      console.log("qq", event)
                      if (event.length === 1) {

                        setFormValues((prev) => { return { ...prev, [schema.PROJECT.CLIENT_NAME]: event[0].first_name, [schema.PROJECT.CLIENT_EMAIL]: event[0].email_address, [schema.PROJECT.CLIENT_PHONE]: event[0].mobile_tel_number, [schema.PROJECT.SITE_ADDRESS_TWO]: event[0].home_address, } })
                      }
                    }}
                    options={contactRequestData}
                    placeholder="Client name"
                    selected={formValues.first_name}
                  />

                  <input type="text" class="form-control mb-1" placeholder="Client email" id={schema.PROJECT.CLIENT_EMAIL} name={schema.PROJECT.CLIENT_EMAIL} aria-label="Client email" aria-describedby="basic-addon2" defaultValue={formValues[schema.PROJECT.CLIENT_EMAIL]} onChange={handleChange}></input>
                  <input type="text" class="form-control mb-1" placeholder="Client phone" id={schema.PROJECT.CLIENT_PHONE} name={schema.PROJECT.CLIENT_PHONE} aria-label="Client phone" aria-describedby="basic-addon3" defaultValue={formValues[schema.PROJECT.CLIENT_PHONE]} onChange={handleChange}></input>
                  <input type="text" class="form-control mb-1" placeholder="Site Address Line 1" id={schema.PROJECT.SITE_ADDRESS_ONE} name={schema.PROJECT.SITE_ADDRESS_ONE} aria-label="Site Address Line 1" aria-describedby="basic-addon4" defaultValue={formValues[schema.PROJECT.SITE_ADDRESS_ONE]} onChange={handleChange}></input>
                  <input type="text" class="form-control mb-1" placeholder="Site Address Line 2" id={schema.PROJECT.SITE_ADDRESS_TWO} name={schema.PROJECT.SITE_ADDRESS_TWO} aria-label="Site Address Line 2" aria-describedby="basic-addon5" defaultValue={formValues[schema.PROJECT.SITE_ADDRESS_TWO]} onChange={handleChange}></input>
                  <input type="text" class="form-control mb-1" placeholder="Site City" id={schema.PROJECT.SITE_CITY} name={schema.PROJECT.SITE_CITY} aria-label="Site City" aria-describedby="basic-addon6" defaultValue={formValues[schema.PROJECT.SITE_CITY]} onChange={handleChange}></input>
                  <input type="text" class="form-control mb-1" placeholder="Site State/Territory/Province/Region" id={schema.PROJECT.SITE_STATE_TERRITORY_PROVINCE_REGION} name={schema.PROJECT.SITE_STATE_TERRITORY_PROVINCE_REGION} aria-label="Site State/Territory/Province/Region" aria-describedby="basic-addon7" defaultValue={formValues[schema.PROJECT.SITE_STATE_TERRITORY_PROVINCE_REGION]} onChange={handleChange}></input>
                  <input type="text" class="form-control mb-1" placeholder="Site Postal Code/ZIP" id={schema.PROJECT.SITE_ZIP_CODE} name={schema.PROJECT.SITE_ZIP_CODE} aria-label="Site Postal Code/ZIP" aria-describedby="basic-addon8" defaultValue={formValues[schema.PROJECT.SITE_ZIP_CODE]} onChange={handleChange}></input>

                  {/* <div class="input-group-prepend">
                                <label class="input-group-text" for="inputGroupSelect01">Options</label>
                              </div> */}
                  <select class="form-select mb-1" placeholder='Project Type' id={schema.PROJECT.PROJECT_TYPE} name={schema.PROJECT.PROJECT_TYPE} defaultValue={formValues[schema.PROJECT.PROJECT_TYPE]} onSelect={handleChange}>
                    <option selected>Project Type</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                  <input type="text" class="form-control mb-1" placeholder="Project Name" id={schema.PROJECT.PROJECT_NAME} name={schema.PROJECT.PROJECT_NAME} aria-label="Project Name" aria-describedby="basic-addon9" defaultValue={formValues[schema.PROJECT.PROJECT_NAME]} onChange={handleChange}></input>
                  <input type="text" class="form-control mb-1" placeholder="Client Budget" id={schema.PROJECT.CLIENT_BUDGET} name={schema.PROJECT.CLIENT_BUDGET} aria-label="Client Budget" aria-describedby="basic-addon10" defaultValue={formValues[schema.PROJECT.CLIENT_BUDGET]} onChange={handleChange}></input>
                  <div class="d-flex align-items-center mb-3 mt-3">
                    <p className=''>Development Approved?</p>
                    <input type='checkbox' id={schema.PROJECT.IS_DEVELOPMENT_APPROVED} name={schema.PROJECT.IS_DEVELOPMENT_APPROVED} defaultValue={formValues[schema.PROJECT.IS_DEVELOPMENT_APPROVED]} onChange={handleChange} />
                  </div>
                  <div class="d-flex align-items-center mb-3 mt-3">
                    <label className='w-60' htmlFor={schema.PROJECT.PROPOSED_START_DATE}>Proposed Start date</label>
                    <input type="date" id={schema.PROJECT.PROPOSED_START_DATE} name={schema.PROJECT.PROPOSED_START_DATE} class="form-control" placeholder="Client Budget" aria-label="Client Budget" aria-describedby="basic-addon11" defaultValue={formValues[schema.PROJECT.PROPOSED_START_DATE]} onChange={handleChange}></input>
                  </div>
                  <div class="d-flex align-items-center ">

                    <label className='w-60' htmlFor={schema.PROJECT.PROPOSED_DURATION_IN_WEEKS}>Likely Duration</label>
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" >Months</span>
                      </div>
                      <input type="text" class="form-control" id={schema.PROJECT.PROPOSED_DURATION_IN_WEEKS} name={schema.PROJECT.PROPOSED_DURATION_IN_WEEKS} aria-label="Small" aria-describedby="inputGroup-sizing-sm" defaultValue={formValues[schema.PROJECT.PROPOSED_DURATION_IN_WEEKS]} onChange={handleChange} />
                    </div>
                  </div>


                </>
              )
                : page === 1 ? (
                  <>
                    <h3>Contract Structure</h3>
                    <div className='container'>
                      <div className='row'>
                        <div className='col-6 d-flex justify-content-center align-items-center'>
                          <div class="">

                            <input type="radio" id='create-new-project-contract-type-contract-price' className='form-check-input m-1' name="contract-type" aria-label="Radio button for following text input" defaultChecked={isContractTypeContractPrice} onChange={() => { setisContractTypeContractPrice(true) }} />

                            <label htmlFor='create-new-project-contract-type-contract-price'>Contract Price</label>
                          </div>

                        </div>
                        <div className='col-6 d-flex justify-content-center align-items-center'>
                          <div >

                            <input type="radio" id='create-new-project-contract-type-cost-plus-price' className='form-check-input m-1' name="contract-type" aria-label="Radio button for following text input" defaultChecked={!isContractTypeContractPrice} onChange={() => { setisContractTypeContractPrice(false) }} />

                            <label htmlFor='create-new-project-contract-type-cost-plus-price'>Cost Plus Price</label>
                          </div>


                        </div>

                      </div>
                      <div className='row'>
                        <div className='col-6 bg-light'>
                          {/* <div className='d-flex flex-column'> */}
                          <div class="form-group row">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Payment Structure</label>
                            <div className='col-sm-7 '>

                              {/* <input type='text' className="form-control  form-control-sm"></input> */}
                              <ButtonGroup className="mb-2"  >
                                <ToggleButton disabled={!isContractTypeContractPrice} id={schema.PROJECT.PAYMENT_STRUCTURE + 0} type="radio" variant="outline-primary" name={schema.PROJECT.PAYMENT_STRUCTURE} value="0" checked={formValues[schema.PROJECT.PAYMENT_STRUCTURE]} onChange={(e) => { console.log("uuu", e) }}  >State Payments   </ToggleButton>
                                <ToggleButton disabled={!isContractTypeContractPrice} id={schema.PROJECT.PAYMENT_STRUCTURE + 1} type="radio" variant="outline-primary" name={schema.PROJECT.PAYMENT_STRUCTURE} value="1" checked={formValues[schema.PROJECT.PAYMENT_STRUCTURE]} onChange={(e) => { console.log("uuu", e) }} >Completion %   </ToggleButton>

                                {/* <ToggleButton
                                                                     id='1'
                                                                      type="radio"
                                                                      variant={ 'outline-success' }
                                                                      name="radio"
                                                                      value={"radio.value"}
                                                                     
                                                                     
                                                                    >
                                                                      {"radio"}
                                                                    </ToggleButton> */}
                              </ButtonGroup>
                              {/* <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                                              <label class="btn btn-secondary active">
                                                                <input type="radio" name="options" id="0" autocomplete="off" defaultChecked={formValues[schema.PROJECT.PAYMENT_STRUCTURE]} /> Active
                                                              </label>
                                                              <label class="btn btn-secondary">
                                                                <input type="radio" name="options" id="1" autocomplete="off" checked={formValues[schema.PROJECT.PAYMENT_STRUCTURE]}/> Radio
                                                              </label>
                                                              <label class="btn btn-secondary">
                                                                <input type="radio" name="options" id="2" autocomplete="off" defaultChecked={formValues[schema.PROJECT.PAYMENT_STRUCTURE]}/> Radio
                                                              </label>
                                                            </div> */}
                            </div>

                          </div>




                          <div class="form-group row mb-1">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Invoice Period</label>
                            <div className='col-sm-4 '>

                              <select class="form-select  form-select-sm" id={schema.PROJECT.INVOICE_FREQUENCY} name={schema.PROJECT.INVOICE_FREQUENCY} disabled={!isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.INVOICE_FREQUENCY]} onChange={handleChange}>
                                <option value="monthly">Monthly</option>
                                <option value="weekly">Weekly</option>
                                <option value="daily">Daily</option>

                              </select>
                            </div>

                          </div>

                          <div class="form-group row">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Client Retainer</label>
                            <div className='col-sm-3 '>

                              <input type='text' className="form-control  form-control-sm" id={schema.PROJECT.CLIENT_RETAINER_PERCENTAGE} name={schema.PROJECT.CLIENT_RETAINER_PERCENTAGE} disabled={!isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.CLIENT_RETAINER_PERCENTAGE]} onChange={handleChange}></input>
                            </div>
                            <label for="colFormLabelSm" class="col-sm-1 col-form-label col-form-label-sm">%</label>

                          </div>

                          <div class="form-group row">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Retainer Period</label>
                            <div className='col-sm-7 '>

                              <div className='col-sm-10 '>
                                <div class="input-group align-items-center">
                                  <input type="text" class="form-control form-control-sm" id={schema.PROJECT.CLIENT_RETAINER_PERIOD_IN_WEEKS} name={schema.PROJECT.CLIENT_RETAINER_PERIOD_IN_WEEKS} aria-label="Amount (to the nearest dollar)" disabled={!isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.CLIENT_RETAINER_PERIOD_IN_WEEKS]} onChange={handleChange} />
                                  <div class="input-group-append ">
                                    <span class="input-group-text input-group-text-sm ">Weeks</span>
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>





                          {/* <div className='form-group row'>
                                                <label className='col-sm-2 col-form-label col-form-label-sm'>Retainer Period</label>
                                                <div className='col-sm-10 '>
                                                    <div class="input-group align-items-center">
                                                      <input type="text" class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)"/>
                                                      <div class="input-group-append ">
                                                        <span class="input-group-text input-group-text-sm ">Weeks</span>
                                                      </div>
                                                    </div>

                                                </div>
                                               
                                            </div> */}

                          <br />
                          <br />
                          {/* <div className='d-flex flex-row align-items-center'>
                                                <label>Unit Price with Flexible Qty </label>
                                                <input type='number' class="form-control"></input>
                                            </div> */}

                          <div class="form-group row">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Unit Price with Flexible Qty</label>
                            <div className='col-sm-7 '>
                              <div class="form-check form-switch">
                                <input class="form-check-input" id={schema.PROJECT.IS_UNIT_PRICE_WITH_FLEXIBLE_QTY} name={schema.PROJECT.IS_UNIT_PRICE_WITH_FLEXIBLE_QTY} type="checkbox" role="switch" defaultChecked disabled={!isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.IS_UNIT_PRICE_WITH_FLEXIBLE_QTY]} onChange={handleChange} />
                                {/* <label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label> */}
                              </div>
                            </div>

                          </div>

                          <div class="form-group row">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Unit Qty</label>
                            <div class="col-sm-3 ">
                              <input placeholder="Min" type="text" class="form-control form-control-sm" id={schema.PROJECT.UNIT_QTY_MIN} name={schema.PROJECT.UNIT_QTY_MIN} disabled={!isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.UNIT_QTY_MIN]} onChange={handleChange} />
                            </div>
                            <div class="col-sm-3 ">

                              <input placeholder="Max" type="text" class="form-control form-control-sm" id={schema.PROJECT.UNIT_QTY_MAX} name={schema.PROJECT.UNIT_QTY_MAX} disabled={!isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.UNIT_QTY_MAX]} onChange={handleChange} />
                            </div>
                          </div>


                          <br />
                          <div class="form-group row mb-1">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Contractor Contingency</label>
                            <div class="col-sm-3">
                              <input type="text" class="form-control form-control-sm" id={schema.PROJECT.CONTINGENCY_PERCENTAGE} name={schema.PROJECT.CONTINGENCY_PERCENTAGE} disabled={!isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.CONTINGENCY_PERCENTAGE]} onChange={handleChange} />
                            </div>
                            <label for="colFormLabelSm" class="col-sm-1 col-form-label col-form-label-sm">%</label>

                          </div>

                          <div class="form-group row mb-1">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Project Markup</label>
                            <div class="col-sm-3">
                              <input type="text" class="form-control form-control-sm" id={schema.PROJECT.MARKUP_PERCENTAGE} name={schema.PROJECT.MARKUP_PERCENTAGE} disabled={!isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.MARKUP_PERCENTAGE]} onChange={handleChange} />
                            </div>
                            <label for="colFormLabelSm" class="col-sm-1 col-form-label col-form-label-sm">%</label>

                          </div>

                          <div class="form-group row mb-1">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Client Sales Tax <br />(GST/VAT/Sales Tax)</label>
                            <div class="col-sm-3">
                              <input type="text" class="form-control form-control-sm" id={schema.PROJECT.CLIENT_SALES_TAX_PERCENTAGE} name={schema.PROJECT.CLIENT_SALES_TAX_PERCENTAGE} disabled={!isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.CLIENT_SALES_TAX_PERCENTAGE]} onChange={handleChange} />
                            </div>
                            <label for="colFormLabelSm" class="col-sm-1 col-form-label col-form-label-sm">%</label>

                          </div>









                          {/* </div> */}
                        </div>
                        <div className='col-6 bg-light'>
                          {/* <div class="form-group row">
                                                  <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Client Retainer</label>
                                                  <div className='col-sm-3 '>

                                                     <input type='text' className="form-control  form-control-sm"></input>
                                                  </div>
                                                  <label for="colFormLabelSm" class="col-sm-1 col-form-label col-form-label-sm">%</label>

                                              </div> */}

                          <div class="form-group row">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Include high-level estimate </label>
                            <div className='col-sm-7 '>
                              <div class="form-check form-switch">
                                <input class="form-check-input" id={schema.PROJECT.IS_INCLUDE_HIGH_LEVEL_ESTIMATE} name={schema.PROJECT.IS_INCLUDE_HIGH_LEVEL_ESTIMATE} type="checkbox" role="switch" defaultChecked disabled={isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.IS_INCLUDE_HIGH_LEVEL_ESTIMATE]} onChange={handleChange} />
                                {/* <label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label> */}
                              </div>
                            </div>

                          </div>


                          <div class="form-group row">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Invoice Period</label>
                            <div className='col-sm-4 '>

                              <select class="form-select  form-select-sm" id={schema.PROJECT.INVOICE_FREQUENCY} name={schema.PROJECT.INVOICE_FREQUENCY} disabled={isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.INVOICE_FREQUENCY]} onChange={handleChange}>
                                <option value="monthly">Monthly</option>
                                <option value="weekly">Weekly</option>
                                <option value="daily">Daily</option>

                              </select>
                            </div>

                          </div>

                          <br></br>

                          <div class="form-group row">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Cost Plus Method</label>
                            <div className='col-sm-7 '>

                              {/* <input type='text' className="form-control  form-control-sm"></input> */}
                              <ButtonGroup className="mb-2">
                                <ToggleButton disabled={isContractTypeContractPrice} id={schema.PROJECT.COST_PLUS_METHOD + 0} type="radio" variant="outline-primary" name={schema.PROJECT.COST_PLUS_METHOD} value="0" checked={formValues[schema.PROJECT.COST_PLUS_METHOD]} onChange={handleChange}>Fixed %   </ToggleButton>
                                <ToggleButton disabled={isContractTypeContractPrice} id={schema.PROJECT.COST_PLUS_METHOD + 1} type="radio" variant="outline-primary" name={schema.PROJECT.COST_PLUS_METHOD} value="1" checked={formValues[schema.PROJECT.COST_PLUS_METHOD]} onChange={handleChange} >Fixed Fee   </ToggleButton>
                              </ButtonGroup>
                              {/* <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                                              <label class="btn btn-secondary active">
                                                                <input type="radio" name="options" id="option1" autocomplete="off" checked/> Active
                                                              </label>
                                                              <label class="btn btn-secondary">
                                                                <input type="radio" name="options" id="option2" autocomplete="off"/> Radio
                                                              </label>
                                                              <label class="btn btn-secondary">
                                                                <input type="radio" name="options" id="option3" autocomplete="off"/> Radio
                                                              </label>
                                                            </div> */}
                            </div>

                          </div>


                          <div class="form-group row mb-1">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Contractor Contingency</label>
                            <div className='col-sm-3 '>

                              <input type='text' className="form-control  form-control-sm" id={schema.PROJECT.CONTINGENCY_PERCENTAGE} name={schema.PROJECT.CONTINGENCY_PERCENTAGE} disabled={isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.CONTINGENCY_PERCENTAGE]} onChange={handleChange}></input>
                            </div>
                            <label for="colFormLabelSm" class="col-sm-1 col-form-label col-form-label-sm">%</label>

                          </div>


                          <div class="form-group row">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Project Markup</label>
                            <div className='col-sm-3 '>

                              <input type='text' className="form-control  form-control-sm" id={schema.PROJECT.MARKUP_PERCENTAGE} name={schema.PROJECT.MARKUP_PERCENTAGE} disabled={isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.MARKUP_PERCENTAGE]} onChange={handleChange}></input>
                            </div>
                            <label for="colFormLabelSm" class="col-sm-1 col-form-label col-form-label-sm">%</label>

                          </div>

                          <br />
                          <div class="form-group row">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Additional % for GMP</label>
                            <div className='col-sm-3 '>

                              <input type='number' className="form-control  form-control-sm" id={schema.PROJECT.GMP_PERCENTAGE} name={schema.PROJECT.GMP_PERCENTAGE} disabled={isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.GMP_PERCENTAGE]} onChange={handleChange}></input>
                            </div>
                            <label for="colFormLabelSm" class="col-sm-1 col-form-label col-form-label-sm">%</label>

                          </div>

                          <br />


                          <div class="form-group row">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Project Markup</label>
                            <div className='col-sm-3 '>

                              <input type='number' className="form-control  form-control-sm" id={schema.PROJECT.MARKUP_PERCENTAGE} name={schema.PROJECT.MARKUP_PERCENTAGE} disabled={isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.MARKUP_PERCENTAGE]} onChange={handleChange}></input>
                            </div>
                            <label for="colFormLabelSm" class="col-sm-1 col-form-label col-form-label-sm">%</label>

                          </div>

                          <br />

                          <div class="form-group row">
                            <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">Client Sales Tax <br />(GST/VAT/Sales Tax)</label>
                            <div class="col-sm-3">
                              <input type="text" class="form-control form-control-sm" id={schema.PROJECT.CLIENT_SALES_TAX_PERCENTAGE} name={schema.PROJECT.CLIENT_SALES_TAX_PERCENTAGE} disabled={isContractTypeContractPrice} defaultValue={formValues[schema.PROJECT.CLIENT_SALES_TAX_PERCENTAGE]} onChange={handleChange} />
                            </div>
                            <label for="colFormLabelSm" class="col-sm-1 col-form-label col-form-label-sm">%</label>

                          </div>



                        </div>
                      </div>

                    </div>


                  </>)
                  : (
                    <>
                      <DocumentUploadForm onFileChange={(file) => addFile(file)} onRemoveFile={(file) => removeFile(file)} />

                    </>)}

            </form>
            <div className='d-flex justify-content-around mt-3'>
              <button className='btn btn-primary' onClick={() => { saveProject() }}> Continue</button>
              <button className='btn btn-danger' onClick={() => { setshowModel((prev) => !prev) }}>Cancel</button>
            </div>
          </Modal.Body>
        </Modal>
        : null}
    </div>
  )
}

export default CreateNewProject