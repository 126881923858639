import { Button } from "@mui/material";
import { useState } from 'react';
import DocumentUploadModal from "../../components/documents/DocumentUploadModal";
import DocumentTable from "../../components/documents/DocumentTable";
import FilterCriteria from "../../components/documents/FilterCriteria";
import { useSelector } from 'react-redux';

const Documents = () => {
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);

    return (
        <div style={{ margin: '2vh 10vw' }}>
            <h3>Documents</h3>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="contained" onClick={() => setShowDocumentUploadModal(true)}>
                    Upload Document
                </Button>

                {showDocumentUploadModal &&
                    <DocumentUploadModal show={showDocumentUploadModal} onClose={() => setShowDocumentUploadModal(false)} />
                }
            </div>
            <FilterCriteria />

            <div className="mt-3">
                <DocumentTable />
            </div>

        </div>
    )
}

export default Documents;