import { Component } from "react";
import { Routes,Route, Outlet, Navigate, } from "react-router"
import Header from "./components/header/Header";
import Settings from "./components/settings/Settings"
import AppLayout from "./layout/AppLayout";
import Dashboard from "./container/dashboard/Dashboard";
import Pricing from "./container/pricing/Pricing";
import { PricingList } from "./container/pricingList/PricingList";
import Contact from "./container/contact/Contact";
import Documents from "./container/documents/Documents";

const isAuth = ()=>{

    if( localStorage.getItem('access_token')&& localStorage.getItem('access_token').length>0){
        return true;
    }
    else {
        return false;
    }
}

const PrivateRoute= ({component :Component})=>{
    return(
        isAuth() === true? <Outlet/> :<Navigate to='/'/>
    )
}

const routes = (
    <Routes>
        {/* <Route exact path="/" element={<Settings/>} ></Route> */}
        <Route element={<AppLayout/>}>
            <Route exact path="/"></Route>
            <Route element = {<PrivateRoute/>}>
                    {/* Private routes are here */}
                    <Route exact path="/specification"></Route>
                    <Route exact path="/dashboard" element={<Dashboard/>}></Route>
                    <Route exact path="/documents" element={<Documents/>}></Route>
                    <Route exact path="/pricing" element={<Pricing/>}></Route>
                    <Route exact path="/pricingList" element={<PricingList/>}></Route>
                    <Route exact path="/contacts" element={<Contact/>}></Route>
            </Route>
        </Route>

    </Routes>
)

export default routes