import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserViewFilter } from '../../features/PricingSlice'
import './ViewPricingColumnChangeIcon.css'

// Icon
import menuAddLine from "../../assets/icons/ri_menu-add-line.png"
import { billOfQuantities } from '../../constants/Constants'


const ViewPricingColumnChangeIcon = () => {
    const [selectedUserFilters,setSelectedUserFilters] = useState()
    const userViewFilters = useSelector((state)=>state.pricing.userViewFilterRequestData)
    const dispatch = useDispatch()





    const checkUserViewFilter = (event)=>{
        let newUserViewFilter = []
    
        if( event.target[0].checked){
            newUserViewFilter.push(event.target[0].value)
        }
        if( event.target[1].checked){
            newUserViewFilter.push(event.target[1].value)
        }
        if( event.target[2].checked){
            newUserViewFilter.push(event.target[2].value)
        }
        if( event.target[3].checked){
            newUserViewFilter.push(event.target[3].value)
        }
        if( event.target[4].checked){
            newUserViewFilter.push(event.target[4].value)
        }
        if( event.target[5].checked){
            newUserViewFilter.push(event.target[5].value)
        }
        if( event.target[6].checked){
            newUserViewFilter.push(event.target[6].value)
        }
        if( event.target[7].checked){
            newUserViewFilter.push(event.target[7].value)
        }
        if( event.target[8].checked){
            newUserViewFilter.push(event.target[8].value)
        }
        if( event.target[9].checked){
            newUserViewFilter.push(event.target[9].value)
        }
        if( event.target[10].checked){
            newUserViewFilter.push(event.target[10].value)
        }
        if( event.target[11].checked){
            newUserViewFilter.push(event.target[11].value)
        }
        if( event.target[12].checked){
            newUserViewFilter.push(event.target[12].value)
        }
        if( event.target[13].checked){
            newUserViewFilter.push(event.target[13].value)
        }
        if( event.target[14].checked){
            newUserViewFilter.push(event.target[14].value)
        }
        if( event.target[15].checked){
            newUserViewFilter.push(event.target[15].value)
        }
        if( event.target[16].checked){
            newUserViewFilter.push(event.target[16].value)
        }
        if( event.target[17].checked){
            newUserViewFilter.push(event.target[17].value)
        }
        if( event.target[18].checked){
            newUserViewFilter.push(event.target[18].value)
        }
        if( event.target[19].checked){
            newUserViewFilter.push(event.target[19].value)
        }
        if( event.target[20].checked){
            newUserViewFilter.push(event.target[20].value)
        }
        if( event.target[21].checked){
            newUserViewFilter.push(event.target[21].value)
        }
        if( event.target[22].checked){
            newUserViewFilter.push(event.target[22].value)
        }
        if( event.target[23].checked){
            newUserViewFilter.push(event.target[23].value)
        }
        if( event.target[24].checked){
            newUserViewFilter.push(event.target[24].value)
        }
        if( event.target[25].checked){
            newUserViewFilter.push(event.target[25].value)
        }
        if( event.target[26].checked){
            newUserViewFilter.push(event.target[26].value)
        }
        if( event.target[27].checked){
            newUserViewFilter.push(event.target[27].value)
        }
        if( event.target[28].checked){
            newUserViewFilter.push(event.target[28].value)
        }
        if( event.target[29].checked){
            newUserViewFilter.push(event.target[29].value)
        }
        if( event.target[30].checked){
            newUserViewFilter.push(event.target[30].value)
        }

        
        console.log("newUserViewFilter",newUserViewFilter)
        dispatch(updateUserViewFilter({updateUserViewFilter : newUserViewFilter}))
    }

    useEffect(() => {
      
        setSelectedUserFilters(userViewFilters.length)
        console.log("Filter lenght ", selectedUserFilters)
    }, [userViewFilters])

  return (
                             
                                    <div className='view-pricing-user-filter-icon '>

                            <img className='bg-danger' src={menuAddLine} data-bs-toggle="collapse" data-bs-target="#user-filter-dropdown-container" aria-expanded="false"></img>
                            <form  id="user-filter-dropdown-container" className="collapse dropdown-menu view-pricing-column-dropdown " onSubmit={(e)=>{
                            e.preventDefault()
                            console.log("Submit",e.target[0].value,e.target[0].checked)
                            checkUserViewFilter(e)
                            }}>
                            {/* {console.log("userViewFilters",userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))} */}
        <div className='d-flex'>
            <div className='column-change-dropdown-container-column'>
                            <label><input type="checkbox" id="ITEM_NAME_FOR_PROPOSAL" name="ITEM_NAME_FOR_PROPOSAL" value="ITEM_NAME_FOR_PROPOSAL" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_NAME_FOR_PROPOSAL)}onChange={(event)=>{
                                if(event.target.checked){
                                    setSelectedUserFilters((prev)=>prev+1)                   
                                    // selectedUserFilter++
                            }
                            else{
                                setSelectedUserFilters((prev)=>prev-1)
                                // selectedUserFilter--
                            } 
                            }}/>Item Name for Proposal</label>


                            <label><input type="checkbox" name="ITEM_TYPE" value="ITEM_TYPE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_TYPE)} onChange={(event)=>{
                                if(event.target.checked){
                                        setSelectedUserFilters((prev)=>prev+1)                   
                                        // selectedUserFilter++
                                }
                                else{
                                    setSelectedUserFilters((prev)=>prev-1)
                                    // selectedUserFilter--
                                } 
                            }}/>Item Type</label>

                            <label><input type="checkbox" id="PARENT" name="PARENT" value="PARENT" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PARENT)}onChange={(event)=>{
                                                            if(event.target.checked){
                                                                setSelectedUserFilters((prev)=>prev+1)                   
                                                                // selectedUserFilter++
                                                        }
                                                        else{
                                                            setSelectedUserFilters((prev)=>prev-1)
                                                            // selectedUserFilter--
                                                        } 
                                                        }}/>Parent</label>

                                    <label><input type="checkbox" id="COST_CODE" name="COST_CODE" value="COST_CODE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_CODE)}onChange={(event)=>{
                                                            if(event.target.checked){
                                                                setSelectedUserFilters((prev)=>prev+1)                   
                                                                // selectedUserFilter++
                                                        }
                                                        else{
                                                            setSelectedUserFilters((prev)=>prev-1)
                                                            // selectedUserFilter--
                                                        } 
                                                        }}/>Cost Code</label>


                                        <label><input type="checkbox" name="UNITS_EX_WASTAGE" value="UNITS_EX_WASTAGE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_EX_WASTAGE)} onChange={(event)=>{
                                            if(event.target.checked){
                                                setSelectedUserFilters((prev)=>prev+1)                   
                                                // selectedUserFilter++
                                        }
                                        else{
                                            setSelectedUserFilters((prev)=>prev-1)
                                            // selectedUserFilter--
                                        } 
                                        }} />Units Exc Wastage</label>

                                    <label><input type="checkbox" id="UOM" name="UOM" value="UOM" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)} onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                            // selectedUserFilter++
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                        // selectedUserFilter--
                                    } 
                                    }}/>Uom</label>

                                    <label><input type="checkbox" id="WASTAGE_PERCENTAGE" name="WASTAGE_PERCENTAGE" value="WASTAGE_PERCENTAGE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_PERCENTAGE)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                            // selectedUserFilter++
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                        // selectedUserFilter--
                                    } 
                                    }}/>Wastage Percentage</label>
                                    <label><input type="checkbox" id="WASTAGE_VALUE" name="WASTAGE_VALUE" value="WASTAGE_VALUE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_VALUE)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                            // selectedUserFilter++
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                        // selectedUserFilter--
                                    } 
                                    }}/>Wastage </label>
                                    <label><input type="checkbox" id="UNITS_INC_WASTAGE" name="UNITS_INC_WASTAGE" value="UNITS_INC_WASTAGE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_INC_WASTAGE)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                            // selectedUserFilter++
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                        // selectedUserFilter--
                                    } 
                                    }}/>Units Inc Wastage</label>




                                    <label><input type="checkbox" id="BUY_UNIT_PRICE" name="BUY_UNIT_PRICE" value="BUY_UNIT_PRICE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.BUY_UNIT_PRICE)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                            // selectedUserFilter++
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                        // selectedUserFilter--
                                    } 
                                    }}/>Buy Unit </label>
                                    <label><input type="checkbox" id="SELL_UNIT_PRICE" name="SELL_UNIT_PRICE" value="SELL_UNIT_PRICE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_UNIT_PRICE)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                            // selectedUserFilter++
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                        // selectedUserFilter--
                                    } 
                                    }}/>Sell Unit  (if diff)</label>

                                    <label><input type="checkbox" id="SELL_ADJ_UNIT_VALUE" name="SELL_ADJ_UNIT_VALUE" value="SELL_ADJ_UNIT_VALUE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_ADJ_UNIT_VALUE)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                            // selectedUserFilter++
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                        // selectedUserFilter--
                                    } 
                                    }}/>Sell Adj Unit Value</label>
                </div>
        <div className='column-change-dropdown-container-column'>
                                    <label><input type="checkbox" id="SUPPLIER_INC_SALES_TAX" name="SUPPLIER_INC_SALES_TAX" value="SUPPLIER_INC_SALES_TAX" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_INC_SALES_TAX)}onChange={(event)=>{
                                                    if(event.target.checked){
                                                        setSelectedUserFilters((prev)=>prev+1)                   
                                                        // selectedUserFilter++
                                                }
                                                else{
                                                    setSelectedUserFilters((prev)=>prev-1)
                                                    // selectedUserFilter--
                                                } 
                                                }}/>Supplier Inc Sales Tax</label>
                                                <label><input type="checkbox" id="SUPPLIER_ITEM_CODE" name="SUPPLIER_ITEM_CODE" value="SUPPLIER_ITEM_CODE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_ITEM_CODE)}onChange={(event)=>{
                                                    if(event.target.checked){
                                                        setSelectedUserFilters((prev)=>prev+1)                   
                                                        // selectedUserFilter++
                                                }
                                                else{
                                                    setSelectedUserFilters((prev)=>prev-1)
                                                    // selectedUserFilter--
                                                } 
                                                }}/>Supplier Item Code</label>
  

 

                                    <label><input type="checkbox" id="TOTAL_SELL_PRICE_OF_UNITS" name="TOTAL_SELL_PRICE_OF_UNITS" value="TOTAL_SELL_PRICE_OF_UNITS" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TOTAL_SELL_PRICE_OF_UNITS)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                            // selectedUserFilter++
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                        // selectedUserFilter--
                                    } 
                                    }}/>Total sell Price of Units</label>
       

                                    {/* <label><input type="checkbox" id="PRE_MARKUP_PERCENTAGE" name="PRE_MARKUP_PERCENTAGE" value="PRE_MARKUP_PERCENTAGE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PRE_MARKUP_PERCENTAGE)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                            // selectedUserFilter++
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                    } 
                                    }}/>Pre-markup %</label> */}


                                    {/* <label><input type="checkbox" id="PRE_MARKUP_VALUE" name="PRE_MARKUP_VALUE" value="PRE_MARKUP_VALUE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PRE_MARKUP_VALUE)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                    } 
                                    }}/>Pre-markup V</label> */}



                                    <label><input type="checkbox" id="CONTINGENCY_PERCENTAGE" name="CONTINGENCY_PERCENTAGE" value="CONTINGENCY_PERCENTAGE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TOTAL_SELL_PRICE_OF_UNITS)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                    } 
                                    }}/>Contingency Percentage</label>

                                    <label><input type="checkbox" id="CONTINGENCY_VALUE" name="CONTINGENCY_VALUE" value="CONTINGENCY_VALUE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_VALUE)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                    } 
                                    }}/>Contingency Value</label>
                             
                                    <label><input type="checkbox" id="MARKUP_PERCENTAGE" name="MARKUP_PERCENTAGE" value="MARKUP_PERCENTAGE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_PERCENTAGE)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                    } 
                                    }}/>Markup Percentage</label>

                                    <label><input type="checkbox" id="MARKUP_VALUE" name="MARKUP_VALUE" value="MARKUP_VALUE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_VALUE)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                    } 
                                    }}/>Markup Value</label>


                                    <label><input type="checkbox" id="CLIENT_SALES_TAX_PERCENTAGE" name="CLIENT_SALES_TAX_PERCENTAGE" value="CLIENT_SALES_TAX_PERCENTAGE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_PERCENTAGE)}onChange={(event)=>{
                                                            if(event.target.checked){
                                                                setSelectedUserFilters((prev)=>prev+1)                   
                                                        }
                                                        else{
                                                            setSelectedUserFilters((prev)=>prev-1)
                                                        } 
                                                        }}/>Client Sales Tax Percentage</label>
                                                        <label><input type="checkbox" id="CLIENT_SALES_TAX_VALUE" name="CLIENT_SALES_TAX_VALUE" value="CLIENT_SALES_TAX_VALUE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_VALUE)}onChange={(event)=>{
                                                            if(event.target.checked){
                                                                setSelectedUserFilters((prev)=>prev+1)                   
                                                        }
                                                        else{
                                                            setSelectedUserFilters((prev)=>prev-1)
                                                        } 
                                                        }}/>Client Sales Tax Value</label>
                          </div>
        <div className='column-change-dropdown-container-column'>                            

   


                                    <label><input type="checkbox" id="CLIENT_CHARGE_INC" name="CLIENT_CHARGE_INC" value="CLIENT_CHARGE_INC" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE_INC)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                    } 
                                    }}/>Client Charge Inc</label>

                                    <label><input type="checkbox" id="OVERHEAD" name="OVERHEAD" value="OVERHEAD" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.OVERHEAD)}onChange={(event)=>{
                                                            if(event.target.checked){
                                                                setSelectedUserFilters((prev)=>prev+1)                   
                                                        }
                                                        else{
                                                            setSelectedUserFilters((prev)=>prev-1)
                                                        } 
                                                        }}/>Overhead</label>

                                    <label><input type="checkbox" id="MARGIN" name="MARGIN" value="MARGIN" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN)}onChange={(event)=>{
                                                            if(event.target.checked){
                                                                setSelectedUserFilters((prev)=>prev+1)                   
                                                        }
                                                        else{
                                                            setSelectedUserFilters((prev)=>prev-1)
                                                        } 
                                                        }}/>Margin </label>
                                    <label><input type="checkbox" id="GMP_BUFFER_PERCENTAGE" name="GMP_BUFFER_PERCENTAGE" value="GMP_BUFFER_PERCENTAGE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_PERCENTAGE)}onChange={(event)=>{
                                                            if(event.target.checked){
                                                                setSelectedUserFilters((prev)=>prev+1)                   
                                                        }
                                                        else{
                                                            setSelectedUserFilters((prev)=>prev-1)
                                                        } 
                                                        }}/>GMP Percentage </label>
                                    <label><input type="checkbox" id="GMP_BUFFER_VALUE" name="GMP_BUFFER_VALUE" value="GMP_BUFFER_VALUE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_VALUE)}onChange={(event)=>{
                                                            if(event.target.checked){
                                                                setSelectedUserFilters((prev)=>prev+1)                   
                                                        }
                                                        else{
                                                            setSelectedUserFilters((prev)=>prev-1)
                                                        } 
                                                        }}/>GMP Buffer Value </label>

                                    <label><input type="checkbox" id="SUPPLIER_CONTACT_ID" name="SUPPLIER_CONTACT_ID" value="SUPPLIER_CONTACT_ID" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_CONTACT_ID)}onChange={(event)=>{
                                                            if(event.target.checked){
                                                                setSelectedUserFilters((prev)=>prev+1)                   
                                                        }
                                                        else{
                                                            setSelectedUserFilters((prev)=>prev-1)
                                                        } 
                                                        }}/>Supplier </label>

                                        <label><input type="checkbox" id="CONTRACTOR_ITEM_NOTE" name="CONTRACTOR_ITEM_NOTE" value="CONTRACTOR_ITEM_NOTE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTRACTOR_ITEM_NOTE)}onChange={(event)=>{
                                                                if(event.target.checked){
                                                                    setSelectedUserFilters((prev)=>prev+1)                   
                                                            }
                                                            else{
                                                                setSelectedUserFilters((prev)=>prev-1)
                                                            } 
                                                            }}/>Contractor Item Note</label>
                                                            <label><input type="checkbox" id="TENDER_ITEM_NOTE" name="TENDER_ITEM_NOTE" value="TENDER_ITEM_NOTE" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TENDER_ITEM_NOTE)}onChange={(event)=>{
                                                                if(event.target.checked){
                                                                    setSelectedUserFilters((prev)=>prev+1)                   
                                                            }
                                                            else{
                                                                setSelectedUserFilters((prev)=>prev-1)
                                                            } 
                                                            }}/>Tender Item Note</label>

                                    <label><input type="checkbox" id="IS_PRICELIST_ITEM" name="IS_PRICELIST_ITEM" value="IS_PRICELIST_ITEM" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.IS_PRICELIST_ITEM)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                    } 
                                    }}/>Pricinglist?</label>


                                    <label><input type="checkbox" id="NESTED" name="NESTED" value="NESTED" defaultChecked={userViewFilters.includes(billOfQuantities.VIEW_FILTERS.NESTED)}onChange={(event)=>{
                                        if(event.target.checked){
                                            setSelectedUserFilters((prev)=>prev+1)                   
                                    }
                                    else{
                                        setSelectedUserFilters((prev)=>prev-1)
                                    } 
                                    }}/>Nested</label>
        </div>

</div>  



                                        
                                            {(selectedUserFilters <11 )?
                                            <div className='d-flex justify-content-around'>
                                            
                                            <button type="submit" className="btn btn-success" data-bs-toggle="collapse" data-bs-target="#user-filter-dropdown" aria-expanded="false">Success</button>
                                            <button type="submit"  className="btn btn-warning" data-bs-toggle="collapse" data-bs-target="#user-filter-dropdown" aria-expanded="false" onClick={()=>{dispatch(updateUserViewFilter({updateUserViewFilter : []}))}}>Clear</button>

                                            </div>
                                            :
                                            <div className='d-flex justify-content-around'>
                                            <button type="submit" disabled className="btn btn-success" data-bs-toggle="collapse" data-bs-target="#user-filter-dropdown" aria-expanded="false">Success</button>
                                            <button type="submit" disabled className="btn btn-warning" data-bs-toggle="collapse" data-bs-target="#user-filter-dropdown" aria-expanded="false" onClick={()=>{dispatch(updateUserViewFilter({updateUserViewFilter : []}))}}>Clear</button>
                                            </div>
                                            }
                                    
                                        </form>
                            </div>






  )
}

export default ViewPricingColumnChangeIcon