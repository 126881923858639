import React from 'react'
import './PricingList.css'
import ViewPricingList from '../../components/viewPricingList/ViewPricingList'

import documentIcon from '../../assets/icons/document-icon.png'
import estimateIcon from '../../assets/icons/estimate-icon.png'
import tendersIcon from '../../assets/icons/tenders-icon.png'
import deliveriesIcon from '../../assets/icons/deliveries-icon.png'
import proposalsIcon from '../../assets/icons/proposals-icon.png'
import historyIcon from '../../assets/icons/history-icon.png'
import searchIcon from '../../assets/icons/search-icon.png'
import arrowDownIcon from '../../assets/icons/arrow-down-icon.png'

import { useDispatch } from 'react-redux'
import { changeTenderPackageModalStatus } from '../../features/PricingSlice'

export const PricingList = () => {
    const dispatch = useDispatch()
  return (
    <div className='pricing-list-container'> 
        {/* <div className='price-list-container d-flex justify-content-center'>
            <h3> Pricing List</h3>
        </div> */}
        <nav aria-label="breadcrumb" >
              <ol className="breadcrumb d-inline-flex">
                <li className="breadcrumb-item active"><a >Home</a></li>
                <li className="breadcrumb-item active"><a >Project address</a></li>
                <li className="breadcrumb-item " aria-current="page">Pricing</li>
              </ol>
            </nav>
         <div className='d-flex flex-row align-items-center p-0 pricing-icon-container'>
                  
                  <img className="btn btn-ligh" src={documentIcon}/>
                  <img className="btn btn-ligh" src={estimateIcon}/>
                  <img className="btn btn-ligh" src={tendersIcon} />
                  <img className="btn btn-ligh" src={deliveriesIcon}/>
                  <img className="btn btn-ligh" src={proposalsIcon}/>
                  <img className="btn btn-ligh" src={historyIcon}/>
                 

          </div>

           <div className='d-flex justify-content-between pricing-list-search-box-container-row'>
            <div className="dropdown pricing-list-dropdown-container" >
                

            <button className='pricing-list-expand-all-groups-btn' data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"> Expand all groups <img src={arrowDownIcon}/></button>
                        <ul className="dropdown-menu">
                          <li><a className="dropdown-item" href="#scrollspyHeading3">Add to Archive</a></li>
                          <li><a className="dropdown-item" href="#scrollspyHeading4">Fourth</a></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><a className="dropdown-item" href="#scrollspyHeading5">Fifth</a></li>
                        </ul>
            </div>
            {/* <h3>Bill of Quantities</h3> */}
            <div className='d-flex'>
              <div className="dropdown  pricing-list-dropdown-container actions-dropdown">
                <button className='pricing-list-expand-all-groups-btn' data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"> Actions<img src={arrowDownIcon}/></button>
                            <ul className="dropdown-menu">
                              <li><a className="dropdown-item" href="#scrollspyHeading3">Add to Archive</a></li>
                              <li><a className="dropdown-item" href="#scrollspyHeading4">Fourth</a></li>
                              <li><hr className="dropdown-divider"/></li>
                              <li><a className="dropdown-item" href="#scrollspyHeading5">Fifth</a></li>
                            </ul>
              </div>
              <div className='pricing-list-search-box'>
                <input className="pricing-list-search-box-input" type="search" placeholder="keyword Search" aria-label="Search"/>
                <div className='pricing-list-search-icon-container'><img className='pricing-list-search-icon' src={searchIcon}/></div>
              </div>
            </div>
        </div>  
              
            
        <ViewPricingList/>
    </div>
  )
}
