import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getProjects } from '../../features/ProjectSlice'
import { setFilterCriteria } from '../../features/DocumentSlice'

const FilterCriteria = () => {
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.project.projectsRequestData)
    const [selectedProject, setSelectedProject] = useState(undefined);
    const [selectedDocumentType, setSelectedDocumentType] = useState(undefined);
    const [selectedDocumentStatus, setSelectedDocumentStatus] = useState(undefined);

    useEffect(() => {
        if (projects.length === 0) {
            getAllProjects()
        }
    }, [])

    const getAllProjects = async () => {
        dispatch(getProjects())
    }

    const onProjectChange = (value) => {
        setSelectedProject(value);
    }

    const onTypeChage = (value) => {
        setSelectedDocumentType(value)
    }

    const onStatusChange = (value) => {
        setSelectedDocumentStatus(value)
    }

    useEffect(() => {
        dispatch(setFilterCriteria(
            {
                project_id: selectedProject,
                file_type: selectedDocumentType,
                file_status: selectedDocumentStatus
            }
        ))
    }, [selectedProject, selectedDocumentType, selectedDocumentStatus]);

    return (
        <div className='container-fluid mt-3 p-0'>
            <div className='row justify-content-start'>
                <div className='col-lg-3'>
                    <select className="form-select" placeholder='Project Type' id="project-budget-filter" name="project-budget" defaultValue={0} onChange={(e) => onProjectChange(e.target.value)}>
                        <option value="" selected>Select Project</option>
                        {projects?.map(project => {
                            return (
                                <option key={project._id} value={project._id}>{project.project_name}</option>
                            )
                        })}
                    </select>
                </div>

                <div className='col-lg-3'>
                    <select className="form-select" placeholder='Project Type' id="project-budget-filter" name="project-budget" defaultValue={0} onChange={(e) => onTypeChage(e.target.value)}>
                        <option value="" selected>Select Document Type</option>
                        <option value="legal">Legal</option>
                        <option value="financial">Financial</option>
                    </select>
                </div>

                <div className='col-lg-3'>
                    <select className="form-select" placeholder='Project Type' id="project-budget-filter" name="project-budget" defaultValue={0} onChange={(e) => onStatusChange(e.target.value)}>
                        <option value="" selected>Select Document Status</option>
                        <option value="submitted">Submitted</option>
                        <option value="approved">Approved</option>
                        <option value="archived">Archived</option>
                    </select>
                </div>
            </div>
        </div>
    )
}

export default FilterCriteria