import axios from "axios"
import { apiCodes } from "../constants/Url"
import axiosInstance from "./Interceptor"
// import {axiosInstance} from "./Interceptor"
export const httpPost = (url,data)=>{
    console.log(data)
    return new Promise((resolve,reject)=>{
        axios.post(
            url,
            data
        )
        .then(response=>{
           if(response.status ===apiCodes.STATUS_SUCCESS_CODE){
            
               resolve(response);
           }
           else{
            
            reject(response.data)
           }
        })
        .catch((err)=>{
             console.log(err.response.data)
            reject(err.response.data)
        })
       
    })
}
   
export const httpGet = (url)=>{
    return new Promise((resolve,reject)=>{
        axios.get(
            url
        )
        .then(response=>{
           
            resolve( response);
        })
    })

}


export const interceptedHttpPost = (url, data)=>{

    return new Promise((resolve,reject)=>{
        axiosInstance.post(url,data)
        .then(response=>{
            if(response.status ===apiCodes.STATUS_SUCCESS_CODE){
             
                resolve(response);
            }
            else{
             
             reject(response.data)
            }
         })
         .catch((err)=>{
              console.log(err.response.data)
             reject(err.response.data)
         })
    })
}
export const interceptedHttpPut = (url, data)=>{

    return new Promise((resolve,reject)=>{
        axiosInstance.put(url,data)
        .then(response=>{
            if(response.status ===apiCodes.STATUS_SUCCESS_CODE){
             
                resolve(response);
            }
            else{
             
             reject(response.data)
            }
         })
         .catch((err)=>{
              console.log(err.response.data)
             reject(err.response.data)
         })
    })
}

export const interceptedHttpDelete = (url, data)=>{

    return new Promise((resolve,reject)=>{
        axiosInstance.delete(url,data)
        .then(response=>{
            if(response.status ===apiCodes.STATUS_SUCCESS_CODE){
             
                resolve(response);
            }
            else{
             
             reject(response.data)
            }
         })
         .catch((err)=>{
              console.log(err.response.data)
             reject(err.response.data)
         })
    })
}

export const interceptedHttpGet = (url, params)=>{
    return new Promise((resolve,reject)=>{
        axiosInstance.get(url, params)
        .then((response)=>{
            if(response.status ===apiCodes.STATUS_SUCCESS_CODE){
             
                resolve(response);
            }
            else{
             
             reject(response.data)
            }
        })
        .catch((err)=>{
            console.log(err.response.data)
           reject(err.response.data)
       })
    })
}
   
