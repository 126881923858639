import React from "react";
import "./Header.css";
import { Search } from "@mui/icons-material";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../features/AuthSlice";
import axiosInstance from "../../service/Interceptor";
import axios, { Axios } from "axios";
import cryptoJs from "crypto-js";
import Avatar from "../../assets/images/img_avatar.png";
import {  Link, useNavigate } from "react-router-dom";
import builderNinjaIcon from "../../assets/icons/builder-ninja.png"
import avatarIcon from "../../assets/icons/avatar-icon.png"
import whiteSearchIcon from "../../assets/icons/white-search-icon.png"
const Header = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loggedUsername, setLoggedUsername] = useState("");
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentlyLoggedUser = useSelector(
    (state) => state.auth.loginRequestData
  );
  //setLoggedUsername(localStorage.getItem("username"))

  const signInUser = async(e) => {
    e.preventDefault();
    //     console.log(username + " " + password);
    //  //   const bpassword = bcrypt.hashSync(password,10)
    //  const key = cryptoJs.enc.Utf8.parse("1234567887654321");
    //  const iv = cryptoJs.enc.Utf8.parse("8765432112345678")
    //  var encryptPassword = cryptoJs.AES.encrypt(cryptoJs.enc.Utf8.parse(password),key,
    //  {
    //  keySize: 128/8,
    //  iv : iv,
    // mode: cryptoJs.mode.CBC,
    // padding: cryptoJs.pad.Pkcs7
    //  }
    //  )

    //     console.log(" AES Encrypt :"+encryptPassword.toString());
    //     console.log(cryptoJs.enc.Utf8.parse(password));
    
    await dispatch(login({ username: username, password: password, isRememberMeChecked: isRememberMeChecked }));
    navigate("/dashboard", { replace: true })
    
  

  };
  const logoutUser = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.reload();
    console.log(localStorage.getItem("access_token"));
    setIsAuth(false);
    
  };

  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token").length > 0
    ) {
      setIsAuth(true);
      setShowPopup(false);
      setLoggedUsername(localStorage.getItem("username"));
    } else {
      setIsAuth(false);
    }
  }, [currentlyLoggedUser]);

  const checkAuthFetch = () => {
    // const header = {
    //   "Content-Type": "application/json",
    //   Accept: "application/json",

    //   Authorization:
    //     "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmb29vIiwiZXhwIjoxNjY2MTI0MDc1LCJpYXQiOjE2NjYxMjQwNDV9.--Mm92ooQULiBvbXf60uLBW4p3tV7MFyU8anFjthiOs",
    // };
    let body = {};
    axiosInstance
      .get("http://localhost:8080/hello", { hello: "heallo" })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        //res.data.headers['Content-Type']
      });
  };

  return (
    <>
      <div className="header-container">
        <div className="header-top-container">
          {/* <div className="header-top-curve"></div> */}
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark header-nav-bar-container ">
              <div className="container-fluid">
             
                        {/* <a className="navbar-brand" href="#">Navbar</a> */}
                        <img className="navbar-brand" src={builderNinjaIcon} />
                        {isAuth ? (
                      <>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                          <span className="navbar-toggler-icon"></span>
                        </button>
                        
                        <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarNavAltMarkup">
                          <div className="navbar-nav">
                            <a className="nav-link  nav-tab-names" onClick={()=>navigate('/contacts')} >Contacts</a>
                            <a className="nav-link nav-tab-names active" aria-current="page" onClick={()=>navigate('/dashboard')} >Projects</a>
                            <a className="nav-link nav-tab-names active" aria-current="page" onClick={()=>navigate('/documents')} >Documents</a>
                            {/* <a className="nav-link nav-tab-names" >Support</a> */}
                            <a className="nav-link nav-tab-names" onClick={()=>navigate('/pricingList')}>PricingList</a>
                            <a className="nav-link nav-tab-names" >Settings</a>
                            {/* <a className="nav-link disabled"  tabindex="-1" aria-disabled="true">Disabled</a> */}
                          </div>
                          <div className='header-search-box'>
                              <input className="header-search-box-input" type="search" placeholder="Global Search" aria-label="Search"/>
                              {/* <blueSearchIcon/> */}
                              
                              {/* <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
                              {/* <button className="btn btn-primary"><img className="header-search-icon" src={whiteSearchIcon}/></button> */}
                              <div className="header-search-icon">
                                <img  src={whiteSearchIcon}/>
                                </div>
                          </div>
                          <div className="dropdown " 
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                                
                                  <img src={avatarIcon} alt="Avatar" className="btn header-avatar-btn" />
                                  {/* <span><p>{username} </p></span> */}
                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    <li onClick={()=>navigate("/user-settings", { replace: true })}>
                                      <a className="dropdown-item" href="#">
                                        User settings
                                      </a>
                                    </li>
                                    <li onClick={() => {
                                    logoutUser();
                                  }}>
                                      <a className="dropdown-item">
                                        Log Out
                                      </a>
                                    </li>
                                  
                                  </ul>
                                </div>
                          {/* <button className="btn header-avatar-btn"><img src={avatarIcon}/></button> */}
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-end">
                           <button
                              className="btn btn-outline-info header-top-user-button "
                              onClick={() => {
                                setShowPopup((prev) => !prev);
                                
                              }}
                            >
                              Login
                      </button> 
                      </div>
                     
                      )}
               
              </div>
            </nav>
         
        </div>
        {/* <div className="header-buttom-container">
        
        </div> */}
        <Modal show={showPopup}>
          <Modal.Header>
            <h3>Sign in</h3>
            <button
              className="btn btn-danger"
              onClick={() => {
                setShowPopup(!showPopup);
              }}
            >
              Close
            </button>
          </Modal.Header>
          <Modal.Body>
            <div>
              <form>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="passoword"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <span><label><input type="checkbox" onClick={(e)=>{setIsRememberMeChecked(e.target.checked)}}/> Remember me</label></span>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    signInUser(e);
                  }}
                >
                  Log In
                </button>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <i>Haven't Sign up yet?</i>
            <i className="btn btn-warning">Sign up</i>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Header;
