import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPricingList } from '../../features/PricingListSlice'
import PricingListItem from '../pricingListItem/PricingListItem'

const ViewPricingList = () => {

  const dispatch = useDispatch()
  const pricingListRequestData = useSelector(state=>state.pricingList.pricingListRequestData)
  useEffect(()=>{
    dispatch(getPricingList())
  },[])

  const ShowPricingList= ()=>{
    return pricingListRequestData.map((pricingData)=>{
       return <PricingListItem key={pricingData._id} pricingData={pricingData}/>
    })
  }
  return (
    <div>
        <div className='p-1 row'>
            {/* <div className='col-1'>
                <h6>Project</h6>
            </div> */}
            <div className='col-2'>
                <h6>Name</h6>
            </div>
            <div className='col-1'>
                <h6>Item type</h6>
            </div>
            <div className='col-1'>
                <h6>Specialism</h6>
            </div>
            <div className='col-1'>
                <h6>Company</h6>
            </div>
            <div className='col-1'>
                <h6>Buy Unit</h6>
            </div>
            <div className='col-1'>
                <h6>UOM</h6>
            </div>
            <div className='col-1'>
                <h6>Markup</h6>
            </div>
            {/* <div className='col-1'>
                <h6>Searchable</h6>
            </div>
            <div className='col-1'>
                <h6>In Assemblies?</h6>
            </div> */}
            <div className='col-1'>
                <h6>Date</h6>
            </div>
            <div className='col-1'>
                <h6>:</h6>
            </div>
            {/* <div className='col-1'>
                <h6>One Time Use</h6>
            </div> */}
            <div className='col-1'></div>
            <div className='col-1'></div>
        </div>
        
        <ShowPricingList/>
    </div>
  )
}

export default ViewPricingList