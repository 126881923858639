import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { billOfQuantities, localStorageConstants } from "../constants/Constants"
// import { ObjectId } from "mongodb"
import { urls } from "../constants/Url"
import { apiCodes } from "../constants/Url"
import { interceptedHttpDelete, interceptedHttpGet, interceptedHttpPost, interceptedHttpPut } from "../service/service"




const initialState = {
    
    labourRequestData : [],
    labourRequestLoading : false,
    labourRequestError : "",

    sectionRequestData : [],
    sectionRequestLoading : false,
    sectionRequestError : "",

    itemRequestData: [],
    itemRequestLoading: false,
    itemRequestError:"",

    updateSectionRequestData : [],
    updateSectionRequestLoading : false,
    updateSectionRequestError : "",
    
    updateItemRequestData : [],
    updateItemRequestLoading : false,
    updateItemRequestError : "",
    
    deleteSectionRequestData : [],
    deleteSectionRquestLoading : false,
    deleteSectionRequestError : "",
    
    deleteItemRequestData : [],
    deleteItemRquestLoading : false,
    deleteItemRequestError : "",

    createSectionRequestData:[],
    createSectionRequestLoading:false,
    createSectionRequestError:"",

    
    createLabourRequestData:[],
    createLabourRequestLoading:false,
    createLabourRequestError:"",

    createAddUpRequestData:[],
    createAddUpRequestLoading:false,
    createAddUpRequestError:"",

    userViewFilterRequestData:[],
    userViewFilterRequestLoading:false,
    userViewFilterRequestError:"",

    //Sample user Filter
    userViewFilter : [
        "UNITS_REQUIRED",
        "UOM",
        "COST_PER_UNIT",
        "COST_OF_UNITS",
        "MARGIN",
        "CLIENT_CHARGE",
        "STATUS",
        "COST_TYPE",
        "TYPE",

        // new filter
        "ITEM_TYPE",
        "UNITS_EX_WASTAGE",
        "WASTAGE_PERCENTAGE",
        "BUY_UNIT_PRICE",
        "SELL_UNIT_PRCIE",
        "CONTINGENCY_PERCENTAGE",
        "MARKUP_PERCENTAGE",
        "CLIENT_CHARGE_INC", 
        "IS_PRICELIST_ITEM",
      
    ],

    pricingListRequestData: [],
    pricingListRequestLoading: false,
    pricingListRequestError:"",


    projectRequestData: [],
    projectRequestLoading: false,
    projectRequestError:"",

    showSectionModal : false,
    showLabourModal : false,
    
    currentProjectId :"",
    overheadsPercentage : 10.0,

    showAddUpModal : false,
    pricingListModalData : {},

    showTenderPackageModal : false,
    showCreateTenderPackageModal : false,

    labourAssignedSectionId :"",
    newItems :[],

    checkedDataObject : {sections : [], labours : [], items:[]},
    updatedEstimateIdData : {sections : [],items : []},
    itemIdArray : [],
    laboursId : {},
    itemsId : {},
    unspecifiedId : {},
    sectionIdArray : [],

    nestedLabours : [],
    nestedItems : [],
    nestedUnspecifiedItems : [],


    clientCharge :0,
    costOfUnits:0,
    markup:0,

    selectedAutoSearch: {}
    
}

export const getLabour = createAsyncThunk('pricing/labour/get',(args,{dispatch,getState})=>{
    const state = getState()
    const sectionIdArray = state.pricing.sectionRequestData.map((section)=>section._id)
    console.log("sectionIdArray",sectionIdArray)
    const responsePromise = interceptedHttpGet(urls.BASE_URL+urls.GET_PRICING_LABOUR+`?sectionIdArray=${sectionIdArray}`)
    return responsePromise.then(
        (response)=>{
            console.log("GET Labour ",response.data)
            return response.data
        }
    )
})

export const getproject = createAsyncThunk('pricing/project/get',(args,{dispatch,getState})=>{
    const state = getState()
   
    const responsePromise = interceptedHttpGet(urls.BASE_URL+urls.GET_PROJECT+`/${state.pricing.currentProjectId}`)
    return responsePromise.then(
        (response)=>{
           
            return response.data
        }
    )
})

export const getLaboursAndItems = createAsyncThunk('pricing/labourAndItem/get',(args,{dispatch,getState})=>{
    const state = getState()
    const sectionIdArray = state.pricing.sectionRequestData.map((section)=>section._id)
    console.log("sectionIdArray",sectionIdArray)
    const responsePromise = interceptedHttpGet(urls.BASE_URL+urls.GET_PRICING_LABOURS_AND_ITEMS+`?sectionIdArray=${sectionIdArray}`)
    return responsePromise.then(
        (response)=>{
            console.log("GET Labour and Items ",response.data)
            return response.data
        }
    )
})


//projectId ="639ac299bb1711d8fa4d4cc5"
export const getSections = createAsyncThunk('pricing/sections/get', (projectId)=>{
   
    const responsePromise =interceptedHttpGet(urls.BASE_URL+urls.GET_PRICING_SECTIONS+`/${projectId}`)    
    return responsePromise.then(
        (response)=>{
            
            return response.data
        }
    )
})

export const getItems = createAsyncThunk('pricing/items/get', (args,{dispatch,getState})=>{
    const state = getState()
    dispatch(removeNewItems())
    const labourIdArray =state.pricing.labourRequestData.map((labour)=>labour._id)
    
   
    console.log(state.pricing.sectionRequestData)
    console.log("get item request",labourIdArray)
    const params =  new URLSearchParams()
   
    const queryString = params.toString()
    
    const responsePromise =interceptedHttpGet(urls.BASE_URL+urls.GET_PRICING_ITEMS+`?sectionIdArray=${labourIdArray}`)    
    return responsePromise.then(
        (response)=>{
            
            return response.data
        }
    )
})

export const updateItems = createAsyncThunk('pricing/items',(args,{dispatch,getState})=>{
    const state = getState()
    // console.log(state.pricing.itemRequestData)
    // console.log(state.pricing.newItems)
    const responsePromise = interceptedHttpPut(urls.BASE_URL+urls.UPDATE_PRICING_ITEMS
        ,{
            "updateItems" :state.pricing.itemRequestData,
            "newItems" : state.pricing.newItems
        })
        return responsePromise.then(
            (response)=>{
                if(response.status===apiCodes.STATUS_SUCCESS_CODE) {
                   
                    dispatch(getItems())
                }
                return response.data
            }
        )

})


export const updateItemData = createAsyncThunk('pricing/item',(args,{dispatch,getState})=>{
    const state = getState()
    const {item} = args
     
    console.log("itemmmm",item)
    
    const responsePromise = interceptedHttpPut(urls.BASE_URL+urls.UPDATE_PRICING_ITEM
        ,{
            "updateItem" :item,
            
        })
        return responsePromise.then(
            (response)=>{
                if(response.status===apiCodes.STATUS_SUCCESS_CODE) {
                    return item
                }
               
            }
        )

})


export const updateLabours = createAsyncThunk('pricing/labour/update',(args,{dispatch,getState})=>{
    const state = getState()
    const responsePromise = interceptedHttpPut(urls.BASE_URL+urls.UPDATE_PRICING_LABOURS,
        {
            "updateLabours": state.pricing.labourRequestData
        })
        return responsePromise.then(
            (response)=>{
                if(response.status === apiCodes.STATUS_SUCCESS_CODE){
                    dispatch(getLabour())
                }
                return response.data
            }
        )
})

export const updateLabourData = createAsyncThunk('pricing/labours/update',(args,{dispatch,getState})=>{
    const state = getState()
    const {labour} = args
    const responsePromise = interceptedHttpPut(urls.BASE_URL+urls.UPDATE_PRICING_LABOUR,
        {
            "updateLabour": labour
        })
        return responsePromise.then(
            (response)=>{
                if(response.status === apiCodes.STATUS_SUCCESS_CODE){
                   return labour
                }
                
            }
        )
})

export const updateSections = createAsyncThunk('pricing/sections', (args,{dispatch,getState})=>{
    const state = getState()
    const sections = state.pricing.sectionRequestData
    console.log("get section request",sections)
    const responsePromise = interceptedHttpPut(urls.BASE_URL+urls.UPDATE_PRICING_SECTIONS,{"sections" :sections})
    return responsePromise.then(
        async(response)=>{
            if(response.status===apiCodes.STATUS_SUCCESS_CODE) {
                   
                await dispatch(getSections(state.pricing.currentProjectId))
            }
            return response.data
        }
    )
})
export const updateSectionData = createAsyncThunk('pricing/section/update', (args,{dispatch,getState})=>{
    const state = getState()
    const {updateSection} = args
    
    const responsePromise = interceptedHttpPut(urls.BASE_URL+urls.UPDATE_PRICING_SECTION,{"updateSection" :updateSection})
    return responsePromise.then(
        async(response)=>{
            if(response.status===apiCodes.STATUS_SUCCESS_CODE) {
                   
               return updateSection
            }
           
        }
    )
})
export const deleteSections = createAsyncThunk('pricing/sections/delete', ({section,index},{dispatch,getState})=>{
    
    if(section._id !== undefined){
        
        const responsePromise = interceptedHttpDelete(urls.BASE_URL+urls.UPDATE_PRICING_SECTIONS+`/${section._id}`)
        
        responsePromise.then(
            async(response)=>{
               
            if(response.status === apiCodes.STATUS_SUCCESS_CODE){
               await dispatch(deleteSection(index))
            }   

            return response.data
            }
        )

    }
    else{
        
        dispatch(deleteSection(index))
    }
})

export const deleteItems = createAsyncThunk('pricing/items/delete',({item},{dispatch,getState})=>{
    const state = getState()

    const responsePromise = interceptedHttpDelete(urls.BASE_URL+urls.DELETE_PRICING_ITEMS+`/${item._id}`)

    responsePromise.then(
        (response)=>{
            
            if(response.status === apiCodes.STATUS_SUCCESS_CODE){
                
                dispatch(deleteItem(item._id))
                // const a= state.pricing.itemRequestData.filter((currentItem,index)=>{
                //     console.log(currentItem._id !== item._id)
                  
                //     return currentItem._id !== item._id})
                    
            }
            return response.data
        }
    )
})

export const deleteLabours = createAsyncThunk('pricing/labour/delete',({labour},{dispatch,getState})=>{
    console.log("labour", labour._id)
    const responsePromise = interceptedHttpDelete(urls.BASE_URL+urls.DELETE_PRICING_LABOUR+`/${labour._id}`)
    responsePromise.then(
        (response)=>{
            
            if(response.status === apiCodes.STATUS_SUCCESS_CODE){
                
                dispatch(deleteLabour(labour._id))
            
                    
            }
            return response.data
        }
    )
})


export const createSection = createAsyncThunk('pricing/section/create',({sectionName,isRandomName},{getState,dispatch})=>{
    const state = getState()
  
    // const genRand = (len) => {
    //     return Math.random().toString(36).substring(2,len+2);
    //   }
    // let isDuplicate =true

    let newSectionName ="SECTION #"
    if(isRandomName) {

        while(state.pricing.sectionRequestData.some((section)=>section.name ===newSectionName)){
            newSectionName=newSectionName+Math.random().toString(36).substring(2,1+2);
        }
    
    }
    else {
        newSectionName = sectionName
    }
    console.log("newSectionName",newSectionName ,isRandomName)

    
    const responsePromise = interceptedHttpPost(urls.BASE_URL+urls.POST_PRICING_SECTION,{
        section :
                {
                    name : newSectionName, 
                    project_id: state.pricing.currentProjectId
                },
            })
    return responsePromise.then((response)=>{

        return response.data        
    
    })


})

export const createLabour = createAsyncThunk('pricing/labour/create',(args,{dispatch,getState})=>{
    const state = getState()
    const {sectionId} = args
    let newLabourName ="LABOUR #"
    
    while(state.pricing.labourRequestData.some((labour)=>labour.name ===newLabourName)){
        newLabourName=newLabourName+Math.random().toString(36).substring(2,1+2);
    }
    
    const responsePromise = interceptedHttpPost(urls.BASE_URL+urls.POST_PRICING_LABOUR,{
        labour :
                {
                    name : newLabourName, 
                    cost_per_unit :"0",
                    units_required: "0",
                    margin: "0" ,
                    // section_id: state.pricing.labourAssignedSectionId
                    section_id: sectionId
                },
            })
    return responsePromise.then((response)=>{
        console.log("Create Labour",response.data)
        return response.data
        
    })


})

export const createItem = createAsyncThunk('pricing/item/create',(args, {dispatch,getState})=>{
    // const{labourId,sectionId, type,name,specialism } =args
    const {item} = args
    // console.log("name",name)
    const state = getState()
    // let newItemName ="ITEM #"
    // let newItemName =name

    // if(type === billOfQuantities.TYPES.LABOUR){
    //     newItemName = "LABOUR #"
    // }
    // else if (type ===billOfQuantities.TYPES.UNSPECIFIED){
    //     newItemName = "UNSPECIFIED #"
    // }
   
    
    // while(state.pricing.itemRequestData.some((item)=>item.name ===newItemName )){
    //     newItemName=newItemName+Math.random().toString(36).substring(2,1+2);
    // }

    const responsePromise = interceptedHttpPost(urls.BASE_URL+urls.POST_PRICING_ITEM,{
        // item :      {name :item.name ,margin:item.margin, cost_per_unit : item.cost_per_unit, uom:item.uom, units_required: "0",labour_id :item.labourId, section_id :item.sectionId, total : "0", type:item.type , specialism :item.specialism, specification : item.specification , buy_unit_price : item.buy_unit_price, sell_unit_price : item.sell_unit_price, markup_percentage : state.pricing.projectRequestData.markup_percentage,contingency_percentage : state.pricing.projectRequestData.contingency_percentage, gmp_percentage: state.pricing.projectRequestData.gmp_percentage, client_sales_tax_percentage : state.pricing.projectRequestData.client_sales_tax_percentage, client_retainer_percentage : state.pricing.projectRequestData.client_retainer_percentage}      })
        item :      {...item,name :item.name ,margin:item.margin, cost_per_unit : item.cost_per_unit, uom:item.uom, units_required: "0",labour_id :item.labourId, section_id :item.sectionId, total : "0", type:item.type , specialism :item.specialism, specification : item.specification , buy_unit_price : item.buy_unit_price, sell_unit_price : item.sell_unit_price, markup_percentage : state.pricing.projectRequestData.markup_percentage,contingency_percentage : state.pricing.projectRequestData.contingency_percentage, gmp_percentage: state.pricing.projectRequestData.gmp_percentage, client_sales_tax_percentage : state.pricing.projectRequestData.client_sales_tax_percentage, client_retainer_percentage : state.pricing.projectRequestData.client_retainer_percentage }      })
    return responsePromise.then((response)=>{
        console.log("item Response", response.data)
        return response.data

    })
})

export const updateUserViewFilter = createAsyncThunk('pricing/userViewFilter/update', (args,{dispatch,getState})=>{
    const state = getState()
    const {updateUserViewFilter} = args
    
    const responsePromise = interceptedHttpPut(urls.BASE_URL+urls.UPDATE_PRICING_USER_VIEW_FILTER,{"updateUserViewFilter" :updateUserViewFilter})
    return responsePromise.then(
        async(response)=>{
           
           return updateUserViewFilter
        }
    )
})

export const getUserViewFilter = createAsyncThunk('pricing/userViewFilter/get', ()=>{
    let username = localStorage.getItem("username")
    const responsePromise =interceptedHttpGet(urls.BASE_URL+urls.GET_PRICING_USER_VIEW_FILTER+`/${username}`)    
    return responsePromise.then(
        (response)=>{
            
            return response.data
        }
    )
})

export const createAddUp = createAsyncThunk('pricing/addUp/create',({pricingListData},{dispatch,getState})=>{
    const state = getState()
    const contractorId = localStorage.getItem(localStorageConstants.USER.CONTRACTOR_ID)
    
    const responsePromise = interceptedHttpPost(urls.BASE_URL+urls.POST_PRICING_LIST,{
        pricingListData : {...pricingListData, contractor_id : contractorId}
    })

    return responsePromise.then(
        (response)=>{
            
            if(response.status === apiCodes.STATUS_SUCCESS_CODE){
                
                
                if(pricingListData.type === billOfQuantities.TYPES.LABOUR){
                    console.log("TTT", pricingListData)
                    dispatch(createItem({item : { ...pricingListData, sectionId : state.pricing.pricingListModalData.sectionId, specification : state.pricing.pricingListModalData.labourSpecification  }}))
                }
                else if (pricingListData.type === billOfQuantities.TYPES.ITEM && state.pricing.pricingListModalData.itemSpecification === billOfQuantities.TYPES.SPECIFIED  ){
                    dispatch(createItem({item : {...pricingListData, sectionId : state.pricing.pricingListModalData.sectionId ,labourId : state.pricing.pricingListModalData.labourId, specification : state.pricing.pricingListModalData.itemSpecification,}}))
                    
                }
                else if (pricingListData.type === billOfQuantities.TYPES.ITEM && state.pricing.pricingListModalData.itemSpecification === billOfQuantities.TYPES.UNSPECIFIED ){
                    dispatch(createItem({item : { ...pricingListData, sectionId : state.pricing.pricingListModalData.sectionId, specification : state.pricing.pricingListModalData.itemSpecification, }}))
                    
                }
               
                dispatch(changeAddUpModalStatus())
            }
            return response.data
        }
    )
})
export const getAddUp = createAsyncThunk('pricing/addUp/get',(args,{dispatch,getState})=>{
    const state = getState()
    const contractorId = localStorage.getItem(localStorageConstants.USER.CONTRACTOR_ID)
    const responsePromise = interceptedHttpGet(urls.BASE_URL+urls.GET_PRICING_LIST+`/${contractorId}`)

    return responsePromise.then(
        (response)=>{
            
            // if(response.status === apiCodes.STATUS_SUCCESS_CODE){
                
                
                // }
        //    console.log( "return ",response.data)
            return response.data
        }
    )
})
export const updatedEstimateIdDataOnBackend  = createAsyncThunk('pricing/update/all',(args,{dispatch,getState})=>{
    const state = getState()
    // const contractorId = localStorage.getItem(localStorageConstants.USER.CONTRACTOR_ID)
    const updatedEstimateIdData = state.pricing.updatedEstimateIdData
    const updateItemArray =updatedEstimateIdData.items.map((id)=>{
        return state.pricing.itemRequestData.find((item)=>item._id === id)
    })
    const responsePromise = interceptedHttpPut(urls.BASE_URL+urls.UPDATE_PRICING_ITEMS, {
        updateItems : updateItemArray
    })

    return responsePromise.then(
        (response)=>{
            
            // if(response.status === apiCodes.STATUS_SUCCESS_CODE){
                
                
                // }
         
            return response.data
        }
    )
})



const pricingSlice = createSlice({
    name : "pricing",
    initialState,
    reducers: {
        setCurrentProjectId : (state, action)=>{
            state.currentProjectId = action.payload
        },
        setIdArray  : (state, action)=>{
            state.itemIdArray =    state.itemRequestData.map((item)=>{
                return item._id
            })
            state.sectionIdArray =    state.sectionRequestData.map((section)=>{
                return section._id
            })
            // new structre for nestedlabours 
            const nestedLabours =    state.sectionRequestData.map((section)=>{
                // const itemIdArray =state.itemRequestData.map((item)=>{
                //     if (item.type === billOfQuantities.TYPES.LABOUR && item.section_id === section._id){
                //         return item._id
                //     }
                //     else {
                //         return
                //     }
                // })
                const itemArray = state.itemRequestData.filter((item)=>(item.item_type === billOfQuantities.ITEM_TYPES.STAFF ||item.item_type === billOfQuantities.ITEM_TYPES.MATHLAB || item.item_type === billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || item.item_type === billOfQuantities.ITEM_TYPES.SUB_MATHLAB) && item.section_id === section._id)
                // const itemArray = state.itemRequestData.filter((item)=>item.type === billOfQuantities.TYPES.LABOUR && item.section_id === section._id)
            
                const itemIdArray = itemArray.map((i)=>i._id)
                // const sectionId = section._id
                // let output ={ [section._id] : itemIdArray,  }
                const output ={ "sectionId" :section._id , nestedLabourIdArray : itemIdArray}
            
                return output

            })
            state.nestedLabours = nestedLabours.filter((nestedLabours)=> nestedLabours != null)

                                        const labourArry =    state.sectionRequestData.map((section)=>{
                                            // const itemIdArray =state.itemRequestData.map((item)=>{
                                            //     if (item.type === billOfQuantities.TYPES.LABOUR && item.section_id === section._id){
                                            //         return item._id
                                            //     }
                                            //     else {
                                            //         return
                                            //     }
                                            // })
                                            const itemArray = state.itemRequestData.filter((item)=>(item.item_type === billOfQuantities.ITEM_TYPES.STAFF ||item.item_type === billOfQuantities.ITEM_TYPES.MATHLAB || item.item_type === billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || item.item_type === billOfQuantities.ITEM_TYPES.SUB_MATHLAB) && item.section_id === section._id)
                                            // const itemArray = state.itemRequestData.filter((item)=>item.type === billOfQuantities.TYPES.LABOUR && item.section_id === section._id)
                                        
                                            const itemIdArray = itemArray.map((i)=>i._id)
                                            const sectionId = section._id
                                            let output ={ [section._id] : itemIdArray,  }
                                            return output

                                        })
                                        
                                        labourArry.forEach((labour,index)=>{
                                            Object.assign(state.laboursId, labour)
                                        })
                                        // state.laboursId= laboursId



            // new Structure for nestedItems

            const nestedItems= state.itemRequestData.map((labour)=>{
                if((labour.item_type === billOfQuantities.ITEM_TYPES.STAFF || labour.item_type === billOfQuantities.ITEM_TYPES.MATHLAB || labour.item_type === billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || labour.item_type === billOfQuantities.ITEM_TYPES.SUB_MATHLAB)){
                    const itemsArray =state.itemRequestData.filter((item)=>(item.item_type !== billOfQuantities.ITEM_TYPES.STAFF || item.item_type !== billOfQuantities.ITEM_TYPES.MATHLAB || item.item_type !== billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || item.item_type !== billOfQuantities.ITEM_TYPES.SUB_MATHLAB) && item.labour_id === labour._id)
                    // const itemsArray =state.itemRequestData.filter((item)=>item.type === billOfQuantities.TYPES.ITEM && item.labour_id === labour._id)

                    const itemIdArray = itemsArray.map((i)=>i._id)
                    
                    // let output ={ [labour._id] : itemIdArray,  }
                    const  output ={ labourId : labour._id, nestedItemIdArray : itemIdArray  }
                    return output

                } 
              
         

            })

            state.nestedItems = nestedItems.filter((nestedItem)=> nestedItem!=null)

                                                        const itemsArray= state.itemRequestData.map((labour)=>{
                                                            if((labour.item_type === billOfQuantities.ITEM_TYPES.STAFF || labour.item_type === billOfQuantities.ITEM_TYPES.MATHLAB || labour.item_type === billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || labour.item_type === billOfQuantities.ITEM_TYPES.SUB_MATHLAB)){
                                                                const itemsArray =state.itemRequestData.filter((item)=>(item.item_type !== billOfQuantities.ITEM_TYPES.STAFF || item.item_type !== billOfQuantities.ITEM_TYPES.MATHLAB || item.item_type !== billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || item.item_type !== billOfQuantities.ITEM_TYPES.SUB_MATHLAB) && item.labour_id === labour._id)
                                                                // const itemsArray =state.itemRequestData.filter((item)=>item.type === billOfQuantities.TYPES.ITEM && item.labour_id === labour._id)

                                                                const itemIdArray = itemsArray.map((i)=>i._id)
                                                                let output ={ [labour._id] : itemIdArray,  }
                                                                console.log("111",output,itemIdArray,itemsArray)
                                                                return output

                                                            }   
                                                    

                                                        })
                                                        itemsArray.forEach((item,index)=>{
                                                            Object.assign(state.itemsId, item)
                                                            
                                                        })

            
            // new Structure for nestedUnspecifiedItems

            const nestedUnpecified =state.sectionRequestData.map((section)=>{
                // const itemIdArray =state.itemRequestData.map((item)=>{
                //     if (item.type === billOfQuantities.TYPES.LABOUR && item.section_id === section._id){
                //         return item._id
                //     }
                //     else {
                //         return
                //     }
                // })
                const itemArray = state.itemRequestData.filter((item)=>item.specification ===billOfQuantities.TYPES.UNSPECIFIED && item.section_id === section._id)
                const itemIdArray = itemArray.map((i)=>i._id)
                const sectionId = section._id
                // let output ={ [section._id] : itemIdArray,  }
                const  output ={ sectionId : section._id ,nestedUnspecifiedItemIdArray : itemIdArray  }
                return output

            })
            state.nestedUnspecifiedItems = nestedUnpecified.filter((nestedUnpecifiedItem)=>nestedUnpecifiedItem != null)


                                                            const unspecifiedArray=state.sectionRequestData.map((section)=>{
                                                                // const itemIdArray =state.itemRequestData.map((item)=>{
                                                                //     if (item.type === billOfQuantities.TYPES.LABOUR && item.section_id === section._id){
                                                                //         return item._id
                                                                //     }
                                                                //     else {
                                                                //         return
                                                                //     }
                                                                // })
                                                                const itemArray = state.itemRequestData.filter((item)=>item.specification ===billOfQuantities.TYPES.UNSPECIFIED && item.section_id === section._id)
                                                                const itemIdArray = itemArray.map((i)=>i._id)
                                                                const sectionId = section._id
                                                                let output ={ [section._id] : itemIdArray,  }
                                                                return output

                                                            })
                                                            unspecifiedArray.forEach((item)=>{
                                                                Object.assign(state.unspecifiedId, item)
                                                            })
        },
        updateItem : (state , action)=>{
            
            state.itemRequestData = state.itemRequestData.map((item,index)=>{
                if(item._id === action.payload._id){
                    if(!state.updatedEstimateIdData.items.includes(action.payload._id)){

                        state.updatedEstimateIdData = {...state.updatedEstimateIdData, items :[...state.updatedEstimateIdData.items,action.payload._id]}
                    }
                    console.log('updatecheck',action.payload)

                    return action.payload
                    console.log('updatecheck',action.payload)
                    // return item
                  
                }
                else{
                    console.log('updatecheck',item._id , action.payload._id, index)

                    return item
                }
                
            })   
        },
        
        updateSection : (state , action)=>{
            
            state.sectionRequestData = state.sectionRequestData.map((section,index)=>{
               
                if((index ==action.payload.index) && (section.id ==undefined) ){
                    return action.payload.section
                    
                }
                else if((section.id ===action.payload.section.id) &&(section.id !=undefined) ){
                 
                    return action.payload.section
                  
                }
                else{
                    return section
                }
               
                
            })   
        },
        updateLabour : (state, action )=>{
            state.labourRequestData = state.labourRequestData.map((labour)=>{
                if(labour._id === action.payload._id){
                    console.log("updated labour")
                    return action.payload
                }
                else{
                    return labour
                }
            })
        },
    
  

        initializeNewItem : (state, action)=>{
            // console.log("labour_id",action.payload)
            state.newItems = [...state.newItems, {name :"newItem",margin: "0" ,cost_per_unit :"0", units_required: "0",labour_id :action.payload, total : "0"}]
          
        },

        // initializeNewSection: (state, action)=>{
            
        //     state.sectionRequestData = [...state.sectionRequestData, {name :"newSection",project_id :action.payload}]
        //     state.sectionRequestData.forEach((e)=>{
        //         console.log(e._id, e.name)
        //     })
        // },
        removeNewItems : (state)=>{
            state.newItems = []
        }
        ,
    

        updateNewItem : (state , action)=>{
           
            state.newItems = state.newItems.map((item,index)=>{
                
                if(index ==action.payload.index){
                  
                    return {...action.payload.item}
                  
                }
                else{
                    return item
                }
                
            })   
        },
        deleteSection : (state , action)=>{
            console.log(action.payload)
            state.sectionRequestData = state.sectionRequestData.filter((section,index)=> index != action.payload)
        },    
        deleteNewItem : (state , action)=>{
            console.log(action.payload)
            state.newItems = state.newItems.filter((item,index)=> index != action.payload)
        },   
        deleteItem : (state , action)=>{
           
            state.itemRequestData = state.itemRequestData.filter((item,index)=> item._id !== action.payload)
            console.log(state.itemRequestData)
        },
        deleteLabour : (state, action)=>{
            state.labourRequestData = state.labourRequestData.filter((labour)=>labour._id !== action.payload)
        },
        changeSectionModalStatus: (state , action)=>{
            state.showSectionModal = !state.showSectionModal
           
        },    
        changeLabourModalStatus: (state , action)=>{
            console.log(action.payload)
            state.showLabourModal = !state.showLabourModal
            state.labourAssignedSectionId = action.payload
            
           
        },
        changeAddUpModalStatus: (state , action)=>{
            
            state.showAddUpModal = !state.showAddUpModal
            
            state.pricingListModalData = action.payload
            
           
        },
        changeTenderPackageModalStatus : (state, action)=>{
            state.showTenderPackageModal = !state.showTenderPackageModal

        },
        changeCreateTenderPackageModalStatus : (state, action)=>{
            state.showCreateTenderPackageModal = !state.showCreateTenderPackageModal
        },
        calculateSummery : (state, action)=>{
            // console.log("CALCULATE SUMMERY",action.payload)
            state.costOfUnits = state.costOfUnits + action.payload.costOfUnits
        },
        calculateBill : (state, action)=>{
        
        //     state.clientCharge=0
        //     state.costOfUnits=0
        //     state.markup = 0
        //     //section
        //     state.labourRequestData.forEach(async labour => {
            
        //     if(labour.section_id === action.payload._id){
                
                    
                    
        //             state.clientCharge=(parseFloat(state.clientCharge)+(parseFloat(labour.units_required)*parseFloat(labour.cost_per_unit))*(1+(parseFloat(labour.margin)/100))).toFixed(2)
        //             // console.log("clientCharge",clientCharge)
                
        //             state.costOfUnits =(parseFloat(state.costOfUnits)+(parseFloat(labour.cost_per_unit)*parseFloat(labour.units_required))).toFixed(2)
                    
                    
                
                
            
            
        //         state.itemRequestData.forEach(async item =>{
                    
        //             if(item.labour_id === labour._id){
                        
        //                 //console.log("clientCharge item prev",clientCharge)
        //                 state.clientCharge=(parseFloat(state.clientCharge)+(parseFloat(item.units_required)*parseFloat(item.cost_per_unit))*(1+(parseFloat(item.margin)/100))).toFixed(2)
        //                 //console.log("clientCharge item",clientCharge)
        //                 // console.log("cost of units item prev", (parseFloat(prev)+(parseFloat(item.cost_per_unit)*parseFloat(item.units_required))).toFixed(2))
        //                 state.costOfUnits = (parseFloat(state.costOfUnits)+(parseFloat(item.cost_per_unit)*parseFloat(item.units_required))).toFixed(2)
        //                 //console.log("cost of units item",costOfUnits)
                    
        //             }

        //         })

        //         state.newItems.forEach(async item => {

        //             if(item.labour_id === labour._id){
                        
        //                 state.clientCharge= (parseFloat(state.clientCharge)+(parseFloat(item.units_required)*parseFloat(item.cost_per_unit))*(1+(parseFloat(item.margin)/100))).toFixed(2)

        //                 state.costOfUnits = (parseFloat(state.costOfUnits)+(parseFloat(item.cost_per_unit)*parseFloat(item.units_required))).toFixed(2)
        //             }
        //         })
                
        //         //await setMarkup((prev)=>(100*((clientCharge/costOfUnits)-1)).toFixed(1))
        // }
        
        // });


        },
        addToCheckedList : (state , action)=>{
            //event,id,type
            //checkbox onchange this functions tirggers and keep record of Id's of Sections,Items,Labours.
            //this state valuse are to be used upon Tender package onClick event
            if(action.payload.isChecked && billOfQuantities.TYPES.SECTION!==action.payload.type && !state.checkedDataObject.items.includes(action.payload.id)){
                
                state.checkedDataObject.items.push(action.payload.id)
            }
            else if(action.payload.isChecked && billOfQuantities.TYPES.SECTION===action.payload.type && !state.checkedDataObject.sections.includes(action.payload.id)){
                
              state.checkedDataObject.sections.push(action.payload.id)
              state.itemRequestData.forEach(item => {
                if(item.section_id === action.payload.id && !state.checkedDataObject.items.includes(item._id)){
                    state.checkedDataObject.items.push(item._id)
                }
              }); 

            }
            
            else if(!action.payload.isChecked && billOfQuantities.TYPES.SECTION!==action.payload.type && state.checkedDataObject.items.includes(action.payload.id)){
                
               
                state.checkedDataObject.items = state.checkedDataObject.items.filter(itemId=>itemId!==action.payload.id)
               
            }
            else if(!action.payload.isChecked && billOfQuantities.TYPES.SECTION===action.payload.type && state.checkedDataObject.sections.includes(action.payload.id)){
           
          
                state.checkedDataObject.items = state.checkedDataObject.items.filter((item)=> item.section_id === action.payload.id)
                state.checkedDataObject.sections = state.checkedDataObject.sections.filter(sectionId=>sectionId!==action.payload.id)
             
            }
            
            
          },
          changeOverheadsPercentage :(state, action)=>{
                state.overheadsPercentage = action.payload.overheadsPercentage
          }    

    },
    extraReducers: (builder, thunkAPI)=>{
       
       
        builder.addCase(getSections.fulfilled,(state, action)=>{
        //    console.log("get section response",action.payload)
            state.sectionRequestData = action.payload
            state.sectionRequestError =""
            state.sectionRequestLoading = false
        })
        builder.addCase(getSections.pending,(state, action)=>{
            
            state.sectionRequestData =[]
            state.sectionRequestError =""
            state.sectionRequestLoading = true
        })
        builder.addCase(getSections.rejected,(state, action)=>{
            // console.log(action.payload)
            state.sectionRequestData = []
            state.sectionRequestError =action.error.message
            state.sectionRequestLoading = false
        })


        builder.addCase(getItems.fulfilled,(state, action)=>{
            // console.log("get item response",action.payload)
            state.itemRequestData = action.payload
            state.itemRequestError =""
            state.itemRequestLoading = false
            
        })
        builder.addCase(getItems.pending,(state, action)=>{
            
            state.itemRequestData =[]
            state.itemRequestError =""
            state.itemRequestLoading = true
        })
        builder.addCase(getItems.rejected,(state, action)=>{
            // console.log(action.payload)
            state.itemRequestData = []
            state.itemRequestError =action.error.message
            state.itemRequestLoading = false
        })


        builder.addCase(updateSections.fulfilled,(state, action)=>{
            
            state.updateSectionRequestData = action.payload
            state.updateSectionRequestError =""
            state.updateSectionRequestLoading = false

            
        })
        builder.addCase(updateSections.pending,(state, action)=>{
            
            state.updateSectionRequestData =[]
            state.updateSectionRequestError =""
            state.updateSectionRequestLoading = true
        })
        builder.addCase(updateSections.rejected,(state, action)=>{
            // console.log(action.payload)
            state.updateSectionRequestData = []
            state.updateSectionRequestError =action.error.message
            state.updateSectionRequestLoading = false
        })


        builder.addCase(updateItems.fulfilled,(state, action)=>{
            
            state.updateItemRequestData = action.payload
            state.updateItemRequestError =""
            state.updateItemRequestLoading = false
        })
        builder.addCase(updateItems.pending,(state, action)=>{
          
            state.updateItemRequestData =[]
            state.updateItemRequestError =""
            state.updateItemRequestLoading = true
        })
        builder.addCase(updateItems.rejected,(state, action)=>{
            // console.log(action.payload)
            state.updateItemRequestData = []
            state.updateItemRequestError =action.error.message
            state.updateItemRequestLoading = false
        })


        builder.addCase(deleteItems.fulfilled,(state, action)=>{
            
            state.deleteItemRequestData = action.payload
            state.deleteItemRequestError =""
            state.deleteItemRquestLoading = false
        })
        builder.addCase(deleteItems.pending,(state, action)=>{
            
            state.deleteItemRequestData =[]
            state.deleteItemRequestError =""
            state.deleteItemRquestLoading = true
        })
        builder.addCase(deleteItems.rejected,(state, action)=>{
         
            state.deleteItemRequestData = []
            state.deleteItemRequestError =action.error.message
            state.deleteItemRquestLoading = false
        })
        builder.addCase(deleteSections.fulfilled,(state, action)=>{
            
            state.deleteSectionRequestData = action.payload
            state.deleteSectionRequestError =""
            state.deleteSectionRquestLoading = false
        })
        builder.addCase(deleteSections.pending,(state, action)=>{
            
            state.deleteSectionRequestData =[]
            state.deleteSectionRequestError =""
            state.deleteSectionRquestLoading = true
        })
        builder.addCase(deleteSections.rejected,(state, action)=>{
            
            state.deleteSectionRequestData = []
            state.deleteSectionRequestError =action.error.message
            state.deleteSectionRquestLoading = false
        })
        builder.addCase(createSection.fulfilled,(state, action)=>{
            
            state.sectionRequestData = [...state.sectionRequestData, action.payload]
            //state.showSectionModal = !state.showSectionModal
            state.createSectionRequestData =action.payload
            state.createSectionRequestError =""
            state.createSectionRequestLoading = false
            
            pricingSlice.caseReducers.setIdArray(state, action);
        })
        builder.addCase(createSection.pending,(state, action)=>{
            
            state.createSectionRequestData =[]
            state.createSectionRequestError =""
            state.createSectionRequestLoading = true
        })
        builder.addCase(createSection.rejected,(state, action)=>{
            
            state.createSectionRequestData = []
            state.createSectionRequestError =action.error.message
            state.createSectionRequestLoading = false
        })
        builder.addCase(getLabour.fulfilled,(state, action)=>{
            
            state.labourRequestData = action.payload
            state.labourRequestError =""
            state.labourRequestLoading = false
        })
        builder.addCase(getLabour.pending,(state, action)=>{
            
            state.labourRequestData =[]
            state.labourRequestError =""
            state.labourRequestLoading = true
        })
        builder.addCase(getLabour.rejected,(state, action)=>{
         
            state.labourRequestData = []
            state.labourRequestError =action.error.message
            state.labourRequestLoading = false
        })
        builder.addCase(createLabour.fulfilled,(state, action)=>{
            
            state.labourRequestData = [...state.labourRequestData, action.payload]
            // state.showLabourModal = !state.showLabourModal
            state.createLabourRequestData =action.payload
            state.createLabourRequestError =""
            state.createLabourRequestLoading = false
        })
        builder.addCase(createLabour.pending,(state, action)=>{
            
            state.createLabourRequestData =[]
            state.createLabourRequestError =""
            state.createLabourRequestLoading = true
        })
        builder.addCase(createLabour.rejected,(state, action)=>{
            
            state.createLabourRequestData = []
            state.createLabourRequestError =action.error.message
            state.createLabourRequestLoading = false
        })

        builder.addCase(updateItemData.fulfilled,(state, action)=>{
            
            state.updateItemRequestData = action.payload
            state.updateItemRequestError =""
            state.updateItemRequestLoading = false

            state.itemRequestData = state.itemRequestData.map((item)=>{
                if(action.payload._id === item._id){
                    return action.payload
                }
                else{
                    return item
                }
            })
        })
        builder.addCase(updateItemData.pending,(state, action)=>{
          
            state.updateItemRequestData =[]
            state.updateItemRequestError =""
            state.updateItemRequestLoading = true
        })
        builder.addCase(updateItemData.rejected,(state, action)=>{
            
            state.updateItemRequestData = []
            state.updateItemRequestError =action.error.message
            state.updateItemRequestLoading = false
        })

        builder.addCase(updateSectionData.fulfilled,(state, action)=>{
            
            // state.updateItemRequestData = action.payload
            // state.updateItemRequestError =""
            // state.updateItemRequestLoading = false

            state.sectionRequestData = state.sectionRequestData.map((section)=>{
                if(action.payload._id === section._id){
                    return action.payload
                }
                else{
                    return section
                }
            })
        })
        builder.addCase(updateSectionData.pending,(state, action)=>{
          
            // state.updateItemRequestData =[]
            // state.updateItemRequestError =""
            // state.updateItemRequestLoading = true
        })
        builder.addCase(updateSectionData.rejected,(state, action)=>{
            
            // state.updateItemRequestData = []
            // state.updateItemRequestError =action.error.message
            // state.updateItemRequestLoading = false
        })

        builder.addCase(updateLabourData.fulfilled,(state, action)=>{
            
            state.updateItemRequestData = action.payload
            state.updateItemRequestError =""
            state.updateItemRequestLoading = false

            state.labourRequestData = state.labourRequestData.map((labour)=>{
                if(action.payload._id === labour._id){
                    return action.payload
                }
                else{
                    return labour
                }
            })
        })
        builder.addCase(updateLabourData.pending,(state, action)=>{
          
            state.updateItemRequestData =[]
            state.updateItemRequestError =""
            state.updateItemRequestLoading = true
        })
        builder.addCase(updateLabourData.rejected,(state, action)=>{
            
            state.updateItemRequestData = []
            state.updateItemRequestError =action.error.message
            state.updateItemRequestLoading = false
        })

        //Item
        builder.addCase(createItem.fulfilled,(state, action)=>{
            
            // state.updateItemRequestData = action.payload
            // state.updateItemRequestError =""
            // state.updateItemRequestLoading = false
            state.itemRequestData =[...state.itemRequestData, action.payload] 
            pricingSlice.caseReducers.setIdArray(state, action);
        })
        builder.addCase(createItem.pending,(state, action)=>{
          
            // state.updateItemRequestData =[]
            // state.updateItemRequestError =""
            // state.updateItemRequestLoading = true
        })
        builder.addCase(createItem.rejected,(state, action)=>{
            
            // state.updateItemRequestData = []
            // state.updateItemRequestError =action.error.message
            // state.updateItemRequestLoading = false
        })

        builder.addCase(updateUserViewFilter.fulfilled,(state, action)=>{
            
            state.userViewFilterRequestData = action.payload
            state.userViewFilterRequestError  =""
            state.userViewFilterRequestLoading = false
        })
        builder.addCase(updateUserViewFilter.pending,(state, action)=>{
            
            state.userViewFilterRequestData =[]
            state.userViewFilterRequestError  =""
            state.userViewFilterRequestLoading = true
        })
        builder.addCase(updateUserViewFilter.rejected,(state, action)=>{
         
            state.userViewFilterRequestData = []
            state.userViewFilterRequestError  =action.error.message
            state.userViewFilterRequestLoading = false
        })
        builder.addCase(getUserViewFilter.fulfilled,(state, action)=>{
            // console.log("current user view filter",action.payload)
            state.userViewFilterRequestData = action.payload
            state.userViewFilterRequestError  =""
            state.userViewFilterRequestLoading = false
        })
        builder.addCase(getUserViewFilter.pending,(state, action)=>{
            
            state.userViewFilterRequestData =[]
            state.userViewFilterRequestError  =""
            state.userViewFilterRequestLoading = true
        })
        builder.addCase(getUserViewFilter.rejected,(state, action)=>{
         
            state.userViewFilterRequestData = []
            state.userViewFilterRequestError  =action.error.message
            state.userViewFilterRequestLoading = false
        })

        builder.addCase(getLaboursAndItems.fulfilled,(state, action)=>{
            // console.log("get item response",action.payload)
            state.itemRequestData = action.payload
            state.itemRequestError =""
            state.itemRequestLoading = false
            
        })
        builder.addCase(getLaboursAndItems.pending,(state, action)=>{
            
            state.itemRequestData =[]
            state.itemRequestError =""
            state.itemRequestLoading = true
        })
        builder.addCase(getLaboursAndItems.rejected,(state, action)=>{
            console.log(action.payload)
            state.itemRequestData = []
            state.itemRequestError =action.error.message
            state.itemRequestLoading = false
        })
        builder.addCase(getAddUp.fulfilled,(state, action)=>{
            // console.log("get addUp response",action.payload)
            state.pricingListRequestData = action.payload
            state.pricingListRequestError =""
            state.pricingListRequestLoading = false
            
        })
        builder.addCase(getAddUp.pending,(state, action)=>{
            
            state.pricingListRequestData =[]
            state.pricingListRequestError =""
            state.pricingListRequestLoading = true
        })
        builder.addCase(getAddUp.rejected,(state, action)=>{
            // console.log(action.payload)
            state.pricingListRequestData = []
            state.pricingListRequestError =action.error.message
            state.pricingListRequestLoading = false
        })


        builder.addCase(createAddUp.fulfilled,(state, action)=>{
            state.pricingListRequestData =[...state.pricingListRequestData,action.payload]
            state.createAddUpRequestData = action.payload
            state.createAddUpRequestError =""
            state.createAddUpRequestLoading = false
            
        })
        builder.addCase(createAddUp.pending,(state, action)=>{
            
            state.createAddUpRequestData =[]
            state.createAddUpRequestError =""
            state.createAddUpRequestLoading = true
        })
        builder.addCase(createAddUp.rejected,(state, action)=>{
            
            state.createAddUpRequestData = []
            state.createAddUpRequestError =action.error.message
            state.createAddUpRequestLoadingr = false
        })
        builder.addCase(getproject.fulfilled,(state, action)=>{
            state.projectRequestData = action.payload
            state.projectRequestError =""
            state.projectRequestLoading = false
            
        })
        builder.addCase(getproject.pending,(state, action)=>{
            
            state.projectRequestData =[]
            state.projectRequestError =""
            state.projectRequestLoading = true
        })
        builder.addCase(getproject.rejected,(state, action)=>{
            
            state.projectRequestData = []
            state.projectRequestError =action.error.message
            state.projectRequestLoading = false
        })

    }
})



export default pricingSlice.reducer;

export const {setCurrentProjectId} = pricingSlice.actions
export const {setIdArray} = pricingSlice.actions
export const {updateItem} = pricingSlice.actions
export const {updateSection} = pricingSlice.actions
export const {updateNewItem} = pricingSlice.actions
export const {updateNewSection} = pricingSlice.actions
export const {updateLabour} = pricingSlice.actions
export const {initializeNewItem} =pricingSlice.actions
export const {initializeNewSection} =pricingSlice.actions
export const {removeNewItems} =pricingSlice.actions
export const {deleteSection} =pricingSlice.actions
export const {deleteNewItem} = pricingSlice.actions
export const {deleteItem} = pricingSlice.actions
export const {deleteLabour} = pricingSlice.actions
export const {changeSectionModalStatus} = pricingSlice.actions
export const {changeLabourModalStatus} = pricingSlice.actions
export const {changeAddUpModalStatus} = pricingSlice.actions
export const {addToCheckedList} = pricingSlice.actions
export const {changeTenderPackageModalStatus} = pricingSlice.actions
export const {changeCreateTenderPackageModalStatus} = pricingSlice.actions
export const {calculateSummery} = pricingSlice.actions
export const {changeOverheadsPercentage} = pricingSlice.actions