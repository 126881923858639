export const interceptorConstants = {
    JWT_EXPIRED : "jwt expired",
}

export const billOfQuantities  = {
    TYPES  : {
        LABOUR : "LABOUR",
        SECTION : "SECTION",
        ITEM : "ITEM",
        UNSPECIFIED : "UNSPECIFIED",
        SPECIFIED : "SPECIFIED",
        TENDER_PACKAGE : "TENDER_PACKAGE"
    },

    ITEM_TYPES : {
        ASSEMBLY :"Assembly (Global)",
        EQUIPMENT :"Equipment",
        MATERIALS : "Material",
        MATHLAB : "MathLab",
        OTHER :"Other",
        STAFF :"Staff",
        SUB_LABOUR_ONLY :"Sub(Labour only)",
        SUB_MATERIAL_ONLY :"Sub(Material only)",
        SUB_MATHLAB :"Sub(MathLab)",
    },

    DROPPABLE_TYPES : {
        SPECI : "SPECI ",
        UNSPE : "UNSPE ",
    },
    DRAGGABLE_TYPES : {
        LABOUR : "LABO ",
        ITEM : "ITEM ",
    },

    DROPPABLE_TYPE_FOR_NESTED_HIERARCHY : {
        NON_LABOUR : "NON-LABOUR",
        LABOUR : "LABOUR",
    },

    VIEW_FILTERS : {
        
        UNITS_REQUIRED :"UNITS_REQUIRED",
        MARGIN :"MARGIN",
        COST_TYPE : "COST_TYPE",
        STATUS : "STATUS",

        //import to structre(nested)
        TYPE :"TYPE",
        

        
      
       
        
 
        
       
        
        
        ITEM_NAME_FOR_PROPOSAL : "ITEM_NAME_FOR_PROPOSAL",
        ITEM_TYPE: "ITEM_TYPE",
        PARENT : "PARENT",
        COST_CODE : "COST_CODE",
        UNITS_EX_WASTAGE: "UNITS_EX_WASTAGE",
        UNITS_INC_WASTAGE: "UNITS_INC_WASTAGE",
        UOM :"UOM",
        WASTAGE_PERCENTAGE :"WASTAGE_PERCENTAGE",
        WASTAGE_VALUE : "WASTAGE_VALUE",
        SELL_ADJ_UNIT_VALUE : "SELL_ADJ_UNIT_VALUE",
        TOTAL_SELL_PRICE_OF_UNITS : "TOTAL_SELL_PRICE_OF_UNITS",
        PRE_MARKUP_PERCENTAGE : "PRE_MARKUP_PERCENTAGE",
        PRE_MARKUP_VALUE : "PRE_MARKUP_VALUE",
        GMP_BUFFER_PERCENTAGE : "GMP_BUFFER_PERCENTAGE",
        GMP_BUFFER_VALUE : "GMP_BUFFER_VALUE",


        //not in schema
        COST_PER_UNIT : "COST_PER_UNIT",
        BUY_UNIT_PRICE : "BUY_UNIT_PRICE",
        SELL_UNIT_PRICE : "SELL_UNIT_PRICE",
        SUPPLIER_INC_SALES_TAX : "SUPPLIER_INC_SALES_TAX",
        SUPPLIER_ITEM_CODE : "SUPPLIER_ITEM_CODE",

        //not in schema
        COST_OF_UNITS :"COST_OF_UNITS",
        
        CONTINGENCY_PERCENTAGE : "CONTINGENCY_PERCENTAGE",
        CONTINGENCY_VALUE : "CONTINGENCY_VALUE",
        MARKUP_PERCENTAGE : "MARKUP_PERCENTAGE",
        MARKUP_VALUE : "MARKUP_VALUE",
        CLIENT_SALES_TAX_PERCENTAGE : "CLIENT_SALES_TAX_PERCENTAGE",
        CLIENT_SALES_TAX_VALUE : "CLIENT_SALES_TAX_VALUE",
        

        //not in schema
        CLIENT_CHARGE :"CLIENT_CHARGE",
        CLIENT_CHARGE_INC : "CLIENT_CHARGE_INC", 
        OVERHEAD : "OVERHEAD",
        
        MARGIN : "MARGIN",
        SUPPLIER_CONTACT_ID : "SUPPLIER_CONTACT_ID",
        CONTRACTOR_ITEM_NOTE: "CONTRACTOR_ITEM_NOTE",
        TENDER_ITEM_NOTE: "TENDER_ITEM_NOTE",
        IS_PRICELIST_ITEM : "IS_PRICELIST_ITEM",


        //import to structre(nested)
        NESTED : "NESTED",


        //23 Fields according to Spread sheet
    }
}

export const schema = {
    ITEM : {
        id : '_id',
        ITEM_TYPE: "item_type",
        UNITS_EX_WASTAGE: "unit_ex_wastage",
        UOM : "uom",
        WASTAGE_PERCENTAGE :"wastage_percentage",
        BUY_UNIT_PRICE : "buy_unit_price",
        SELL_UNIT_PRICE : "sell_unit_price",
        CONTINGENCY_PERCENTAGE : "contingency_percentage",
        MARKUP_PERCENTAGE : "markup_percentage",
        // CLIENT_CHARGE_INC : "CLIENT_CHARGE_INC", 
        IS_PRICELIST_ITEM : "is_pricelist_item",


        COST_CODE : "cost_code",
        SUPPLIER_INC_SALES_TAX:"supplier_inc_sales_tax",
        SUPPLIER_ITEM_CODE:"supplier_item_code",
        CLIENT_SALES_TAX_PERCENTAGE:"client_sales_tax_percentage",
        CLIENT_SALES_TAX_VALUE:"client_sales_tax",
        OVERHEAD: "overhead",
        SUPPLIER_CONTACT_ID:"supplier_contact_id",
        CONTRACTOR_ITEM_NOTE: "contractor_item_note",
        TENDER_ITEM_NOTE: "tender_item_note",

    },
    PROJECT : {
        ID: "_id",
        CLIENT_NAME:"client_name",
        CLIENT_EMAIL : "client_email",
        CLIENT_PHONE : "client_phone",
        SITE_ADDRESS_ONE : "site_address_one",
        SITE_ADDRESS_TWO : "site_address_two",
        SITE_CITY : "site_city",
        SITE_STATE_TERRITORY_PROVINCE_REGION : "site_state_territory_province_region",
        SITE_ZIP_CODE : "site_zip",
        PROJECT_TYPE :"project_type",
        PROJECT_NAME : "project_name",
        CLIENT_BUDGET : "client_budget",
        IS_DEVELOPMENT_APPROVED : "is_development_approved",
        PROPOSED_START_DATE :"proposed_start_date",
        PROPOSED_DURATION_IN_WEEKS : "proposed_duration_in_weeks",

        PAYMENT_STRUCTURE : "payment_structure",
        CLIENT_RETAINER_PERIOD_IN_WEEKS : "client_retainer_period_in_weeks",
        IS_UNIT_PRICE_WITH_FLEXIBLE_QTY : "is_unit_price_with_flexible_qty",
        UNIT_QTY_MIN : "unit_qty_min",
        UNIT_QTY_MAX : "unit_qty_max",


        INVOICE_FREQUENCY : "invoice_frequency",
        IS_INCLUDE_HIGH_LEVEL_ESTIMATE : "is_include_high_level_estimate",
        COST_PLUS_METHOD : "cost_plus_method",
        CONTINGENCY_PERCENTAGE:"contingency_percentage",
        GMP_PERCENTAGE : "gmp_percentage",
        MARKUP_PERCENTAGE :"markup_percentage",
        CLIENT_SALES_TAX_PERCENTAGE : "client_sales_tax_percentage",
        CLIENT_RETAINER_PERCENTAGE :"client_retainer_percentage",




    }
}

export const pricingList = {
    YES_OR_NO : {
        YES : "YES",
        NO : "NO"
    },
    FIELDS : {
        NAME : "name",
        TYPE : "type",
        SPECIALISM : "specialism",
        COST_PER_UNIT : "cost_per_unit",
        UOM : "uom",
        MARKUP : "markup",
        IS_SEARCHABLE : "is_searchable",
        IS_ASSEMBLIES : "is_assemblies",
        DATE : 'date',
    }
}
export const localStorageConstants = {
    USER : {
        CONTRACTOR_ID : "contractor_id"
    }
}

export const TABLE_LIMIT = 10;

