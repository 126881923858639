import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import './Section.css'
import { margin } from '@mui/system';
import { OpacitySharp, Tune } from '@mui/icons-material';
import { useDispatch , useSelector} from 'react-redux';
import { addToCheckedList, calculateSummery, changeAddUpModalStatus, changeLabourModalStatus, changeSectionModalStatus, createItem, createLabour, createSection, deleteItem, deleteItems, deleteNewItem, deleteSection, deleteSections, initializeNewItem, initializeNewSection, setIdArray, updateItem, updateItemData, updateNewItem, updateSection, updateSectionData } from '../../features/PricingSlice';
import SectionPopupModal from '../sectionPopupModal/SectionPopupModal';
import { useLocation } from 'react-router';
import Labour from '../labour/Labour';
import { billOfQuantities } from '../../constants/Constants';
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import CreatableSelect from 'react-select/creatable';


// import { Form } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';



//icons
import dragIcon from '../../assets/icons/material-symbols_drag-indicator-sharp.png'
import greenEllipseIcon from '../../assets/icons/green_Ellipse_11.png'
import yellowEllipseIcon from '../../assets/icons/yellow-ellipse-icon.png'
import redEllipseIcon from '../../assets/icons/red-ellipse-icon.png'
import blueEllipseIcon from '../../assets/icons/blue-ellipse-icon.png'
import gavelIcon from '../../assets/icons/clarity_gavel-solid.png'
import blueTruckIcon from '../../assets/icons/mdi_truck.png'
import treeSwichterOpenIcon from '../../assets/icons/Tree-Switcher-open.png'
import treeSwichterCloseIcon from '../../assets/icons/Tree-Switcher-Close.png'
import pcIcon from '../../assets/icons/PC.png'
import exIcon from '../../assets/icons/EX-icon.png'
import threeDotIcon from '../../assets/icons/three-dot.png'
import transparentIcon from '../../assets/icons/transparent-icon.png'
import blueTruck from "../../assets/icons/mdi_truck.png"
import { memo } from 'react';
import Item from '../item/Item';
import DummyLabour from '../dummylabour/DummyLabour';
import { Draggable, Droppable } from 'react-beautiful-dnd';
// import { Droppable } from '../react-beautiful-dnd/';


const Section = ({section,setNumOfNewSections,index,userViewFilters}) => {
    // const [numOfNewItems, setNumOfNewItems] = useState(0);
    const [show, setShow] = useState(true);
    const [showUnspecified, setShowUnspecified] = useState(true);
    // const [clientCharge, setClientCharge] = useState(0.0)
    // const [costOfUnits, setCostOfUnits] = useState(0.0)
    // const [markup, setMarkup] = useState(0)
    

    const collapsableRef = useRef()
    const dispatch = useDispatch()
    // const overheadsPercentage = useSelector((state)=>state.pricing.overheadsPercentage)
    // const newItems  = useSelector((state)=>state.pricing.newItems)
    const itemRequestLoading  = useSelector((state)=>state.pricing.itemRequestLoading)
    // const itemRequestData  = useSelector((state)=>state.pricing.itemRequestData)
    
    // const labourRequestLoading  = useSelector((state)=>state.pricing.labourRequestLoading)

    const sectionRequestLoading  = useSelector((state)=>state.pricing.sectionRequestLoading)
    const sectionRequestData  = useSelector((state)=>state.pricing.sectionRequestData)

  //  const checkedDataObject = useSelector((state)=>state.pricing.checkedDataObject)
   const userViewFilter = useSelector((state)=>state.pricing.userViewFilter)
    
    // const items  = useSelector((state)=>state.pricing.itemRequestData)
    // const itemIdArray  = useSelector((state)=>state.pricing.itemIdArray)


    const pricingListRequestData = useSelector((state)=>state.pricing.pricingListRequestData)
    const pricingListRequestLoading = useSelector((state)=>state.pricing.pricingListRequestLoading)

//     const itemRequestData = useSelector((state)=>state.pricing.itemRequestData)
//     const unspecifiedId = useSelector((state)=>state.pricing.unspecifiedId)
//   const newSelectedOptions = [];
//   unspecifiedId[section._id].map(async (itemId,index) =>  {
//     const item = itemRequestData.find((i)=> i._id === itemId)
//     if((item.specification === billOfQuantities.TYPES.UNSPECIFIED ) && item.section_id === section._id && (item.type !== billOfQuantities.ITEM_TYPES.STAFF || item.type !== billOfQuantities.ITEM_TYPES.MATHLAB || item.type !== billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || item.type !== billOfQuantities.ITEM_TYPES.SUB_MATHLAB)){ 
//         const initialValue = { value: item, label: item.name };
//         newSelectedOptions.push(initialValue);
//     }
//   })

//   // After the loop, update the state with the accumulated values
// setSelectedOption(newSelectedOptions);

    const [newSectionName , setNewSectionName] = useState('')
    const [isDuplicateName , setIsDuplicateName] = useState(false)
    const [pricingListCurrent , setPricingListCurrent] = useState(()=>{
        return pricingListRequestData.map((addUp)=>{
          return {...addUp, id : addUp._id,}
        })
      })

      const [pricingListCurrentLatest , setPricingListCurrentLatest] = useState(()=>{
      
        return pricingListRequestData.map((addUp)=>{
          // console.log("inside pricing list current latest")
          // console.log({...addUp, id : addUp._id, value : addUp._id, label : addUp.name})
          return {...addUp, id : addUp._id, value : addUp._id, label : addUp.name}
        })
      })


   
    //=== event.target.value
    const validateSectionName = (event)=>{
        //console.log(section.name.equalsIgnoreCase(event.target.value))
        if(sectionRequestData.some((currentSection)=>currentSection.name.toLowerCase()=== event.target.value.toLowerCase()) || event.target.value==='' ){
            if(section.name.toLowerCase()!== event.target.value.toLowerCase()){
                setIsDuplicateName(true)
            }
            
        }
        else {
            setIsDuplicateName(false)
            
        }
        setNewSectionName((prev)=>event.target.value)
        
           
    }

    const labourss =[
        {
        
            name : "Tiling ",
            margin : "22.5",
            unit_cost :"600",
            cost_of_units : "2000",
            section_id : "63c4e9604c62fc3e23001b2b",
            uom :"Days",
            status :"Prime",

    },
    {
        
        name : "Roofing",
        margin : "22.5",
        unit_cost :"600",
        cost_of_units : "2000",
        section_id : "63c4e9604c62fc3e23001b2b",
        uom :"Days",
        status :"Prime",

}
    ]

    // const [total, setTotal] = useState(0.0)
    // let newTotal = 0;
    
    const friendOptions = [
      {
        key: 'Jenny Hess',
        text: 'Jenny Hess',
        value: 'Jenny Hess',
        image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/jenny.jpg' },
      },
      {
        key: 'Elliot Fu',
        text: 'Elliot Fu',
        value: 'Elliot Fu',
        image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/elliot.jpg' },
      },
      {
        key: 'Stevie Feliciano',
        text: 'Stevie Feliciano',
        value: 'Stevie Feliciano',
        image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/stevie.jpg' },
      },
      {
        key: 'Christian',
        text: 'Christian',
        value: 'Christian',
        image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/christian.jpg' },
      },
      {
        key: 'Matt',
        text: 'Matt',
        value: 'Matt',
        image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/matt.jpg' },
      },
      {
        key: 'Justen Kitsune',
        text: 'Justen Kitsune',
        value: 'Justen Kitsune',
        image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/justen.jpg' },
      },
    ]







    useEffect(() => {
      console.log("SSS", section._id)
    },[])


    const handleOnSelect = (event) => {
      if(event.type ===billOfQuantities.TYPES.LABOUR){

        dispatch(createItem({item : { name : event.name, sectionId : section._id, type : event.type , specification : billOfQuantities.TYPES.SPECIFIED}}))
      }
      else if (event.type === billOfQuantities.TYPES.ITEM){
        dispatch(createItem({item : { name : event.name, sectionId : section._id , type : event.type, specification : billOfQuantities.TYPES.UNSPECIFIED}}))
      }
     
   
     };
     
     const formatResult= (item)=>{
      console.log(item)
      return(
        <div className="result-wrapper">
            <span className="result-span">{item.name} : {item.type}</span>
        </div>
      )
    }
  
  const Labours = ()=>{
  const laboursId = useSelector((state)=>state.pricing.laboursId)
  const nestedLabours = useSelector((state)=>state.pricing.nestedLabours)
  const nestedLabourIdArray = nestedLabours.find((nestedLabour)=> nestedLabour.sectionId === section._id).nestedLabourIdArray
  console.log("Render | Labour",laboursId[section._id],nestedLabourIdArray)
    return(
        <Droppable droppableId={section._id} key={section._id} type={billOfQuantities.DROPPABLE_TYPE_FOR_NESTED_HIERARCHY.LABOUR}  >
                {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}  style={{minHeight : "50px"}}>
                            {     nestedLabourIdArray.map((labourId, index)=>
                                                                {
            
                                                                    return (

                                                                                        <Labour  key={labourId} labourId={labourId} index={index} userViewFilters={userViewFilters} />

                                                                    ) 

                                                                      
                                                                }
                                                          )
                              }
                            {provided.placeholder}       
                </div>
                )}
               
                
              </Droppable>
      
    )

    }


    const SectionColumns = () =>{

      const itemRequestData = useSelector((state)=>state.pricing.itemRequestData)
      const sectionRequestData = useSelector((state)=>state.pricing.sectionRequestData)
      const [isDuplicateName , setIsDuplicateName] = useState(false)
      const [newSectionName , setNewSectionName] = useState('')
      const checkedDataObject = useSelector((state)=>state.pricing.checkedDataObject)
      const overheadsPercentage = useSelector((state)=>state.pricing.overheadsPercentage)

          //=== event.target.value
   const validateSectionName = (event)=>{
      //console.log(section.name.equalsIgnoreCase(event.target.value))
      if(sectionRequestData.some((currentSection)=>currentSection.name.toLowerCase()=== event.target.value.toLowerCase()) || event.target.value==='' ){
          if(section.name.toLowerCase()!== event.target.value.toLowerCase()){
              setIsDuplicateName(true)
          }
          
      }
      else {
          setIsDuplicateName(false)
          
      }
      setNewSectionName((prev)=>event.target.value)
      
         
  }

  
  const isCurrentChecked = (id,type)=>{

      if(type === billOfQuantities.TYPES.SECTION){
          let sectionRelatedItems = itemRequestData.filter((item)=> item.section_id === section._id)
          if(sectionRelatedItems.every((item)=>checkedDataObject.items.includes(item._id)) && sectionRelatedItems.length !== 0){
            dispatch(addToCheckedList({ isChecked: true, id: section._id, type: billOfQuantities.TYPES.SECTION}))
          }
          else if(checkedDataObject.sections.includes(id)) {
            dispatch(addToCheckedList({ isChecked: false, id: section._id, type: billOfQuantities.TYPES.SECTION}))

          }
          else {
            return false
          }
          return sectionRelatedItems.every((item)=>checkedDataObject.items.includes(item._id)) && sectionRelatedItems.length !== 0 
      }
      else if (type !==billOfQuantities.TYPES.SECTION && checkedDataObject.items.includes(id)){
        return true
      }
      else {
        return false
      }
     
    }



    const calculateWastageValue = (item)=>{

        return Math.round(calculateUnitsIncWastage(item) - item.unit_ex_wastage *100)/100
    }
  
    const calculateUnitsIncWastage = (item)=>{
      return Math.round(item.unit_ex_wastage * (1 + (item.wastage_percentage/100)) * 100)/100
    }
  
    const calculateSellAdjesmentUnitValue = (item)=>{
      if (item.sell_unit_price>item.buy_unit_price){
         
        return Math.round(  (item.sell_unit_price - item.buy_unit_price) *100  )/100
      }
      else if (item.sell_unit_price > 0){
       
        return Math.round(  (item.sell_unit_price - item.buy_unit_price) *100 )/100
  
      }
      else {
        return 0
      }
    }
  
    const calculateTotalSellPriceOfUnitsValue = (item)=>{
      if(item.sell_unit_price > 0){
         return Math.round((item.sell_unit_price * parseFloat(calculateUnitsIncWastage(item))) * 100)/100
  
      }
      else {
        return Math.round((item.buy_unit_price * parseFloat(calculateUnitsIncWastage(item))) * 100)/100
  
      }
  
    }
  
    const calculatePreMarkupValue =  (item) => {
      return  Math.round( (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) *(item.pre_markup_percentage/100)) * 100)/ 100
  
      
    }
 
  
    const calculateCostOfUnits = (item)=> {
      let costOfUnits = (item.unit_ex_wastage * (item.cost_per_unit*(1 + item.wastage_percentage/100)))
      
      return Math.round(costOfUnits * 100)/100
    
    }
  
    const calculateContingency = (item)=>{
      let contingencyValue = (calculateTotalSellPriceOfUnitsValue(item)*item.contingency_percentage/100)
   
      return Math.round(contingencyValue *100)/100
      
    }
    const calculateMarkupValue = (item)=>{
      let markupValue = ((parseFloat(calculateTotalSellPriceOfUnitsValue(item))+ parseFloat(calculateContingency(item)))*item.markup_percentage/100)
    
      return Math.round(markupValue*100)/100
    
  
    }
  
  
    const calculateClientSalesTax = (item) =>{
      let clientSalesTax = ((parseFloat(calculateTotalSellPriceOfUnitsValue(item))+parseFloat(calculateContingency(item))+parseFloat(calculateMarkupValue(item)))*item.client_sales_tax_percentage/100)
  
  
      return Math.round(clientSalesTax)
    }
  
  
    const calculateClientCharge = (item)=>{
     let clientCharge = (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)))  
      return Math.ceil(clientCharge)
    }
  
    const calculateOverheads = (item) => {
      let overhead = ((  parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))  ) * overheadsPercentage/100)
      return Math.round(overhead * 100)/100
    }
  
    const calculateMargin = (item) => {
      let marginBeforeTax = (parseFloat(calculateClientCharge(item)) - ( parseFloat(calculateOverheads(item)) + parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateClientSalesTax(item)) ))
      return Math.round(marginBeforeTax * 100)/100
    }
  
    
    const calculateGMPBuffer = (item)=>{
      let gmpBuffer = (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) * (item.gmp_percentage/100))
      return Math.round(gmpBuffer * 100) /100
    }
    
    
      const calculateTotalSellPriceOfUnitsValueForSection = () => {
        const costOfUnitsArray = itemRequestData.map((item)=>{
            if(item.section_id === section._id){
              
              return parseFloat(calculateTotalSellPriceOfUnitsValue(item))
            }
            else{
              return null
            }
        })
        if(costOfUnitsArray.length !== 0){
          return Math.round(costOfUnitsArray.reduce((acc,cur)=> acc+cur) *100)/100
          }
         else{
            return 0
         }
             
  
      }
  
      const calculateTotalContingency = () => {
        const contingencyArray = itemRequestData.map((item)=>{
            if(item.section_id === section._id){
              
              return parseFloat(calculateContingency(item))
            }
            else{
              return null
            }
        })
  
        if(contingencyArray.length !== 0){
          return Math.round(contingencyArray.reduce((acc,cur)=> acc+cur) * 100) /100
          }
         else{
            return 0
         }
      
        
       
  
      }
      const calculateTotalMarkupValue = () => {
        const markupValueArray = itemRequestData.map((item)=>{
            if(item.section_id === section._id){
              
              return parseFloat(calculateMarkupValue(item))
            }
            else{
              return null
            }
        })
       
        if(markupValueArray.length !== 0){
          return Math.round( markupValueArray.reduce((acc,cur)=> acc+cur) *100) / 100
       
          }
         else{
            return 0
         }
  
      }
  
      const calculateTotalClientSalesTax = () => {
        const totalClientSalesTax = itemRequestData.map((item)=>{
            if(item.section_id === section._id){
              
              return parseFloat(calculateClientSalesTax(item))
            }
            else{
              return null
            }
        })
  
        if(totalClientSalesTax.length !== 0){
          
        return Math.round(totalClientSalesTax.reduce((acc,cur)=> acc+cur) * 100) /100
        }
       else{
          return 0
       }
      
       
  
      }
      const calculateTotalClientCharge = () => {
        const totalClientCharge = itemRequestData.map((item)=>{
            if(item.section_id === section._id){
              
              return parseFloat(calculateClientCharge(item))
            }
            else{
              return null
            }
        })
        
        if(totalClientCharge.length !== 0){
          return Math.round(totalClientCharge.reduce((acc,cur)=> acc+cur))
        }
       else{
          return 0
       }
       
       
  
      }
      const calculateTotalOverhead = () => {
        
        // console.log("---------------------------",itemRequestLoading ,"------" )
        
         const totalOverheadArray = itemRequestData.map((item)=>{
            if(item.section_id === section._id){
              
              return parseFloat(calculateOverheads(item))
            }
            else{
              return null
            }
        })
        // console.log("++++++++++++++++++++++++++++",itemRequestLoading ,"------",itemRequestData ,totalOverheadArray )
        if(totalOverheadArray.length !== 0){
          return Math.round( totalOverheadArray.reduce((acc,cur)=> acc+cur) * 100) /100
        }
       else{
          return 0
       }
      
  
       
  
      }
      const calculateTotalMargin = () => {
        const totalMarginBeforeTaxArray = itemRequestData.map((item)=>{
            if(item.section_id === section._id){
              
              return parseFloat(calculateMargin(item))
            }
            else{
              return null
            }
        })
  
        if(totalMarginBeforeTaxArray.length !== 0){
          return Math.round(totalMarginBeforeTaxArray.reduce((acc,cur)=> acc+cur) * 100) / 100
        }
       else{
          return 0
       }
       
  
      }
      const calculateTotalGMPBufferValue = () => {
        const totalArray = itemRequestData.map((item)=>{
            if(item.section_id === section._id){
              
              return parseFloat(calculateGMPBuffer(item))
            }
            else{
              return null
            }
        })
  
        if(totalArray.length !== 0){
          return Math.round(totalArray.reduce((acc,cur)=> acc+cur) * 100) / 100
        }
       else{
          return 0
       }
       
  
      }
  
      const calculateTotalContingencyPercentage = ()=>{
          let total = ((parseFloat(calculateTotalContingency())/ parseFloat(calculateTotalSellPriceOfUnitsValueForSection()))*100)
         
          if(!isNaN(total)){
             return Math.round(total)
          }
          else {
  
            return 0 
          }
  
      }
      const calculateTotalMarkupPercentage = ()=>{
  
        let total=  ((parseFloat(calculateTotalMarkupValue()) / (parseFloat(calculateTotalSellPriceOfUnitsValueForSection()) + parseFloat(calculateTotalContingency()) ) )*100)
        if(!isNaN(total)){
          return Math.round(total)
        }
        else {
  
          return 0 
        }
  
      }
      const calculateTotalClientSalesTaxPercentage = ()=>{
  
        let total= ((parseFloat(calculateTotalClientSalesTax())/(parseFloat(calculateTotalSellPriceOfUnitsValueForSection()) + parseFloat(calculateTotalContingency()) + parseFloat(calculateTotalMarkupValue()) ) )*100)
        if(!isNaN(total)){
          return Math.round(total)
        }
        else {
  
          return 0 
        }
  
      }




      return (
          <div className='section-inner-container  row m-0' >
              <div className='col-3 p-0'>
                              <div className='row row-cols-auto view-pricing-icon-container'>
                                  {/* <div className='col-7   '>
                                    
                                      <img src={dragIcon} className="col " alt='ellipseIcon'/>
                                      <img src={greenEllipseIcon} className="col" alt='settingsIcon'/>
                                      <img src={pcIcon} className="col" alt='hammerIcon'/>
                                      <img src={gavelIcon} className="col" alt='whiteTruckIcon'/>
                                      <img src={blueTruckIcon} className="col" alt='whiteTruckIcon'/>
                                      <img src={treeSwichterOpenIcon} className="col" alt='whiteTruckIcon'/>
                                      <input className="form-check-input m-1 " type="checkbox" ></input> 
                                      
                                  </div>
                                  
                                  <div className='col-5 bg-danger d-flex align-items-center'>
                                     
                                      <h6>Title</h6>
                                  </div> */}
                                    <div className='d-flex align-items-center' >
                                      <img src={dragIcon} className="col " alt='ellipseIcon'/>

                                      <div className="pricing-dropdown-container col d-flex align-items-center">                                                        
                                                          <FormControl  className='pricing-dropdown-icon col' size="small">
                                                            {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                            <Select
                                                              labelId="demo-select-small-label"
                                                              id="demo-select-small-item"
                                                              value={section.status === undefined? "" : section.status}
                                                              label="Age"
                                                              onChange={(e)=> {dispatch(updateSectionData({"updateSection" :{...section , status : e.target.value}}))}}
                                                              IconComponent={()=>null}
                                                              
                                                            >
                                                              <MenuItem value="">
                                                                <em></em>
                                                              </MenuItem>
                                                              <MenuItem value={1}><img  className='ui avatar image pricing-icon col' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={yellowEllipseIcon}/></MenuItem>
                                                              <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={greenEllipseIcon}/></MenuItem>
                                                              <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
                                                              <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem>
                                                              {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                            </Select>
                                                          </FormControl>
                                                        </div>
                                                        <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                          <FormControl  className='pricing-dropdown-icon col'  >
                                                            {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                            <Select
                                                              labelId="demo-select-small-label"
                                                              id="demo-select-small-item"
                                                              value={section.cost_type === undefined? "" : section.cost_type}
                                                              label="Age"
                                                              onChange={(e)=> {dispatch(updateSectionData({"updateSection" :{...section , cost_type : e.target.value}}))}}
                                                              IconComponent={()=>null}
                                                              
                                                            >
                                                              <MenuItem value="">
                                                                <em></em>
                                                              </MenuItem>
                                                              <MenuItem value={1}><img  className='ui avatar image  ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={pcIcon}/></MenuItem>
                                                              <MenuItem value={2}><img  className='ui avatar image unique-icon ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={exIcon}/></MenuItem>
                                                              {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                              <MenuItem value={3}> -- </MenuItem>
                                                              {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                              {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                            </Select>
                                                          </FormControl>
                                                        </div>


                                                        <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                          <FormControl  className='pricing-dropdown-icon col'  >
                                                            {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                            <Select
                                                              labelId="demo-select-small-label"
                                                              id="demo-select-small-item"
                                                              value={section.hammer === undefined? "" : section.hammer}
                                                              label="Age"
                                                              onChange={(e)=> {dispatch(updateSectionData({"updateSection" :{...section , hammer : e.target.value}}))}}
                                                              IconComponent={()=>null}
                                                              
                                                            >
                                                              <MenuItem value="">
                                                                <em></em>
                                                              </MenuItem>
                                                              <MenuItem value={1}><img  className='ui avatar image pricing-icon ' style={{height : "15px", width: "16px",background: "#EFEFEF", border:"none"}} src={gavelIcon}/></MenuItem>
                                                              <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
                                                              {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                              <MenuItem value={3}> || </MenuItem>
                                                              {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                              {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                            </Select>
                                                          </FormControl>
                                                        </div>
                                                        <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                          <FormControl  className='pricing-dropdown-icon col'   >
                                                            {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                            <Select
                                                              labelId="demo-select-small-label"
                                                              id="demo-select-small-item"
                                                              value={section.suppiler === undefined? "" : section.suppiler}
                                                              label="Age"
                                                              onChange={(e)=> {dispatch(updateSectionData({"updateSection" :{...section , suppiler : e.target.value}}))}}
                                                              IconComponent={()=>null}
                                                             
                                                            >
                                                              <MenuItem value="">
                                                                <em></em>
                                                              </MenuItem>
                                                              <MenuItem value={1}  className="pricing-icon-container"><img  className='ui avatar image  unique-icon' style={{height : "11px", width: "15px",background: "#EFEFEF", border:"none"}} src={blueTruck}/></MenuItem>
                                                              {/* <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem> */}
                                                              {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                              <MenuItem value={3}> -- </MenuItem>
                                                              {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                              {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                            </Select>
                                                          </FormControl>
                                                        </div>
                                      {/* <img src={greenEllipseIcon} className="col" alt='settingsIcon'/>
                                      <img src={pcIcon} className="col" alt='hammerIcon'/> */}
                                      {/* <img src={gavelIcon} className="col" alt='whiteTruckIcon'/> */}
                            
                                      {/* <img  className='col bg-white' style={{width: "16px",height : "16px"}}/> */}
                                      {/* <img src={blueTruckIcon} className="col" alt='whiteTruckIcon'/> */}
                                      {/* <select id="test-select"  className='labour-select-input  m-0 p-0' >
                                              <option value="Prime" style={{width:"24px",height:"24px"}}>ICC</option>
                                              <option className='d-flex justify-content-center' value="Provitional" style={{width:"34px",height:"34px", backgroundImage :("blueTruckIcon"),}}><img style={{width:"34px",height:"34px"}} className=' ' src={blueTruckIcon}></img></option>
                                              <option value="Excluded" className='test-select-option'  style={{width:"24px",height:"24px"}} data-img_src="https://data.world/api/datadotworld-apps/dataset/python/file/raw/logo.png" ></option>
                                          </select> */}
                                      {show?
                                        <img src={treeSwichterOpenIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>

                                      :<img src={treeSwichterCloseIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>
                                    }
                                                                              {/* <input className="form-check-input m-1 " type="checkbox" ></input> */}
                                      {isCurrentChecked(section._id , billOfQuantities.TYPES.SECTION)  ?
                                            <input className="form-check-input pricing-checkbox m-1 align-items-center" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked, id: section._id, type: billOfQuantities.TYPES.SECTION}))}} defaultChecked></input>   
                                          :
                                          
                                          <input className="form-check-input pricing-checkbox m-1 align-items-center " type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({ isChecked:e.target.checked, id: section._id, type: billOfQuantities.TYPES.SECTION}))}} ></input>   
                                        }    

                                    </div>
                                    {/* <h3><AddIcon  onClick={()=>{
                                        // dispatch(changeSectionModalStatus())
                                        dispatch(createSection())
                                        }} /></h3> */}
                                    {/* <p className='col-5  p-0'>{section.name}</p> */}
                                    <p className='col-5 p-0 '><input  className='section-name-input' type='text' defaultValue={section.name} onBlur={(e)=>{
                                          if(!isDuplicateName && section.name.toLowerCase()!==e.target.value.toLowerCase()){
                                              
                                              dispatch(updateSectionData({"updateSection" :{...section , name : e.target.value}}))
                                          }
                                    }} onChange={(e)=>{
                                      validateSectionName(e)
                                    }} ></input></p>
                                      {isDuplicateName? <p>Please Enter a unique section name</p> : null}
                                  
                              </div>

                </div>

                <div className='col-9'>
                        <div className='row row-cols-10'>

                            {/*--------- Item Name for Proposal Column---------- */}

                            {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_NAME_FOR_PROPOSAL))?
                                                  <div className='col justify-content-start'></div>
                                              :null}                                                              

                                              
                                              {/*--------- ITEM_TYPE Column---------- */}
                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_TYPE))?
                                                <div className='col justify-content-start'>
                                                      

                                                </div>
                                                                :null}

                                                                          

                                                                        


                                              {/*---------Parent Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PARENT))?
                                                   <div className='col justify-content-start'></div>
                                              :null}                                                              


                                              {/*---------Cost Code Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_CODE))?
                                                   <div className='col justify-content-start'></div>
                                              :null}
                                              
                                                {/*--------- Units exc Wastage Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_EX_WASTAGE))?
                                                <div className='col justify-content-start'></div>
                                                :null}

                                              
                                                {/*--------- UOM Column---------- */}


                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                                                  <div className='col justify-content-start'>
                                                       
                                                  </div>
                                                :null}

                                              

                                                {/*--------- Wastage % Column---------- */}



                                                
                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_PERCENTAGE))?
                                                <div className='col justify-content-start'></div>
                                                :null}

                                                {/*--------- Wastage Value---------- */}




                                                
                                             {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_VALUE))?
                                                 <div className='col justify-content-start'></div>
                                              :null} 



                                            {/*--------- Units inc Wastage---------- */}


                                                
                                             {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_INC_WASTAGE))?
                                                   <div className='col justify-content-start'></div>
                                              :null}


                                              
                                                {/*--------- BUY Unit $ Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.BUY_UNIT_PRICE))?
                                                <div className='col justify-content-start'></div>
                                                :null}



                                                {/*--------- Sell Unit $ Column---------- */}

                                              
                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_UNIT_PRICE))?
                                                  <div className='col justify-content-start'></div>
                                                :null}


                                                {/*--------- Sell Adjestment Unit Value---------- */}


                                                
                                             {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_ADJ_UNIT_VALUE))?
                                                   <div className='col justify-content-start'></div>
                                              :null} 
                                                                          


                                              {/*---------Supplier Inc Sales Tax Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_INC_SALES_TAX))?
                                                  <div className='col justify-content-start'>
                                                  
                                             </div>
                                              :null}                                                              


                                              {/*--------- Supplier Item Code Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_ITEM_CODE))?
                                                  <div className='col justify-content-start'></div>
                                              :null}
                                              






                                                
                                                
                                                 {/*--------- Total Sell Price Of Units---------- */}


                                                
                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TOTAL_SELL_PRICE_OF_UNITS))?
                                              <div className="col justify-content-start"> <span class="section-input-container"><p>$</p>
                                              <span >{calculateTotalSellPriceOfUnitsValueForSection()}</span>
                                              {/* <span >{""}</span> */}
                                              </span> </div>
                                                  // <p className="col"> { calculateTotalSellPriceOfUnitsValueForSection()} </p>
                                              :null} 

                                              
                                                {/*--------- Contingency % Column---------- */}


                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_PERCENTAGE))?
                                                <div className='col justify-content-start'><span class="section-input-container">
                                                  <span >{calculateTotalContingencyPercentage()}</span>
                                                  {/* <span >{"()"}</span> */}
                                                <p>%</p> </span></div>
                                                :null}


                                              
                                              {/*--------- Contingency Value Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_VALUE))?
                                                  <div className="col justify-content-start"> <span class="section-input-container"><p>$</p>
                                                  <span >{calculateTotalContingency()}</span>
                                                  {/* <span >{""}</span> */}
                                                  </span> </div>
                                                  // <p className="col"> {calculateTotalContingency()} </p>
                                              :null}   

                                              
                                                {/*--------- Markup percentage % Column---------- */}


                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_PERCENTAGE))?
                                                  <div className='col justify-content-start'><span class="section-input-container">
                                                    <span >{calculateTotalMarkupPercentage()}</span>
                                                    {/* <span >{"A"}</span> */}
                                                  <p>%</p> </span></div>

                                                :null}
                                              
                                              {/*--------- Markup Value Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_VALUE))?
                                                  <div className="col justify-content-start"> <span class="section-input-container"><p>$</p>
                                                  <span >{calculateTotalMarkupValue()}</span>
                                                  {/* <span >{"calculateTotal"}</span> */}
                                                  </span> </div>
                                                //   <div className='col justify-content-start'>
                                                //     <p className="col"> {calculateTotalMarkupValue()} </p>

                                                // </div>
                                              :null}                                                                


                                              {/*--------- Client Sales Tax Percentage  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_PERCENTAGE))?
                                                  <div className='col justify-content-start'><span class="section-input-container">
                                                    <span >{calculateTotalClientSalesTaxPercentage()}</span>
                                                    {/* <span >{"calcula"}</span> */}
                                                  <p>%</p> </span></div>
                                              :null}                                                              


                                              {/*--------- Client Sales Tax Value  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_VALUE))?
                                                  <div className="col justify-content-start"> <span class="section-input-container"><p>$</p>
                                                  <span >{calculateTotalClientSalesTax()}</span>
                                                  {/* <span >{"cal"}</span> */}
                                                  </span> </div>
                                                //   <div className='col justify-content-start'>
                                                //     <p className="col"> {calculateTotalClientSalesTax()}  </p>

                                                // </div>
                                              :null}
 

                                              
                                                {/*--------- Client Charge Inc Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE_INC))?
                                                    <div className="col justify-content-start"> <span class="section-input-container"><p>$</p>
                                                    <span >{calculateTotalClientCharge()}</span>
                                                    {/* <span >{""}</span> */}
                                                    </span> </div>
                                                  // <div className='col justify-content-start'>{calculateTotalClientCharge()}</div>
                                                :null}
                                                                          


                                              {/*--------- Overheads  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.OVERHEAD))?
                                              <div className="col justify-content-start"> <span class="section-input-container"><p>$</p>
                                              <span >{calculateTotalOverhead()}</span>
                                              {/* <span >{""}</span> */}
                                              </span> </div>
                                                //   <div className='col justify-content-start'>
                                                //     <p className="col"> {calculateTotalOverhead()}  </p>

                                                // </div>
                                              :null}                                                              


                                              {/*--------- Margin   Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
                                                  <div className="col justify-content-start"> <span class="section-input-container"><p>$</p>
                                                  <span >{calculateTotalMargin()}</span>
                                                  {/* <span >{"( )"}</span> */}
                                                  </span> </div>
                                                  // <div className='col justify-content-start'>

                                                  //   <p className="col"> {calculateTotalMargin()} </p>
                                                  // </div>
                                              :null}

                                             {/*--------- GMP Buffer %   Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_PERCENTAGE))?
                                                        <div className='col justify-content-start'> <div className='col justify-content-start'>

                                                        <p className="col"> </p>
                                                      </div>
                                                        
                                                        </div>
              
                                                  
                                                  

                                              :null}                                                              
                                              {/*--------- GMP Buffer V   Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_VALUE))?
                                              <div className='col justify-content-start'><span class="section-input-container"><p>$</p>
                                              <span >{calculateTotalGMPBufferValue()}</span>
                                              {/* <span >{" "}</span> */}
                                              </span></div>
                                                    
                                              :null}                                                                  


                                              {/*--------- Supplier Contact Id  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_CONTACT_ID))?
                                                   <div className='col justify-content-start'></div>
                                              :null}                                                              


                                              {/*--------- Contractor Item Note  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTRACTOR_ITEM_NOTE))?
                                                   <div className='col justify-content-start'></div>
                                              :null}                                                              


                                              {/*--------- Tender Item Note  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TENDER_ITEM_NOTE))?
                                                    <div className='col justify-content-start'></div>
                                              :null}  

                                              {/*--------- Pricinglist ? Column---------- */}

                                              
                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.IS_PRICELIST_ITEM))?
                                                  <div className='col justify-content-start'>
                                                        
                                                    </div>
                                                :null}


                                              {/*--------- NESTED  Column---------- */}

                                      {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.NESTED))?
                                        <p className="col"></p>
                                      :null}


                            <div className='view-pricing-user-filter-icon'>

                                <img  src={threeDotIcon}></img>
                            </div>



                        </div>
                  
                  
                
                
                </div> 







            {/* <div className='col-1 section-icon-container'>
                   <img src={dragIcon} className="prcing-drag-icon"/>
                   <img src={greenEllipseIcon} className="pricing-ellipse-icon"/>
                   <img src={pcIcon} className="pricing-settings-icon"/>
                   <img src={gavelIcon} className="pricing-gavel-icon"/>
                   <img src={blueTruckIcon} className="pricing-blue-truck-icon"/>
            </div> */}


            {/* ------------Old Sections-------------------- */}



            {/* <div className='col-2 d-flex align-items-center justify-content-start'>
                <h3><AddIcon  onClick={()=>{
                   // dispatch(changeSectionModalStatus())
                   dispatch(createSection())
                   }} /></h3>

                {isCurrentChecked(section._id , billOfQuantities.TYPES.SECTION)  ?
                         <input className="form-check-input pricing-checkbox m-0 align-items-center" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked, id: section._id, type: billOfQuantities.TYPES.SECTION}))}} defaultChecked></input>   
                       :
                       
                       <input className="form-check-input pricing-checkbox m-0 align-items-center" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({ isChecked:e.target.checked, id: section._id, type: billOfQuantities.TYPES.SECTION}))}} ></input>   
                     }
                <h3><input  className='section-input section-name-input ' type='text' defaultValue={section.name} onBlur={(e)=>{
                       if(!isDuplicateName && section.name.toLowerCase()!==e.target.value.toLowerCase()){
                           
                           dispatch(updateSectionData({"updateSection" :{...section , name : e.target.value}}))
                       }
                }} onChange={(e)=>{
                   validateSectionName(e)
                }} ></input></h3>
                {isDuplicateName? <p>Please Enter a unique section name</p> : null}
           </div> */}








            {/* <h3 className='col-3'> <AddIcon  onClick={()=>{
               dispatch(changeSectionModalStatus()
                            
            )}} /><input  className='section-input' type='text' defaultValue={section.name}   
           
   ></input></h3> */}





           {/* {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED)&& userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
               <p className='col-1'></p>
           :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
               <p className='col-1'></p>
               

           :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
               <p className='col-1'></p>
       :null}
           {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
               <p className='col-1'></p>
           :null}
           {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
           <div className='col-1 justify-content-start'><input value={costOfUnits} type='number' className='section-input' readOnly={true}/></div>
           :null}
           {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
           <div className='col-1 justify-content-start'><input value={markup} type='number' className='section-input' readOnly={true}/></div>
           :null}
           {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
           <div className='col-1 justify-content-start'><input value={clientCharge} type='number' readOnly={true} className='section-input'/></div>
           :null}
           {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
           <p className='col-2'></p>
           :null}
           {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
            <p className='col-1'></p>
           :null} */}





           {/* <p className='col-1'></p>
           <p className='col-1'></p>
           <div className='col-1 justify-content-start'><input value={costOfUnits} type='number' className='section-input' readOnly={true}/></div>
           <div className='col-1 justify-content-start'><input value={markup} type='number' className='section-input' readOnly={true}/></div>
           <div className='col-1 justify-content-start'><input value={clientCharge} type='number' readOnly={true} className='section-input'/></div>

           <p className='col-2'></p>
           <p className='col-1'>TENDER</p> */}
           {/* <div className='col-1 section-inner-util align-items-center '><KeyboardArrowDownIcon  fontSize="large" onClick={()=>{setShow((prev)=>!prev)}} /><CancelPresentationTwoToneIcon color='warning' onClick={()=>{dispatch(deleteSections({index,section}))}} />
           </div>
            */}
      </div>
      )
  }



const Unspecified = memo(()=>{
    // console.log("section",section.name)
    const labour= {
        name:"UNSPECIFIED",
        cost_per_unit:"0",
        units_required:"0",
        margin:"0",
        total:"0",
        
        uom:"",
        status:"",
        type:"UNSPECIFIED"}
        
      const itemRequestData=useSelector((state)=>state.pricing.itemRequestData)
      const checkedDataObject = useSelector((state)=>state.pricing.checkedDataObject)

        const checkAllUnspecified = ()=>{
          let unspecifiedArray = itemRequestData.filter((item)=>item.section_id ===section._id && item.specification === billOfQuantities.TYPES.UNSPECIFIED)
            // console.log("HERE IS ",unspecifiedArray.every((item)=>checkedDataObject.items.includes(item._id)))
          return unspecifiedArray.every((item)=>checkedDataObject.items.includes(item._id)) && unspecifiedArray.length !== 0
        }


        const isCurrentChecked = (id,type)=>{
          // if(type === billOfQuantities.TYPES.ITEM && checkedDataObject.items.includes(id)){
          //     return true
          // }
          // if(type === billOfQuantities.TYPES.LABOUR && checkedDataObject.labours.includes(id)){
          //     return true
          // }
          if(type === billOfQuantities.TYPES.SECTION){
              let sectionRelatedItems = itemRequestData.filter((item)=> item.section_id === section._id)
              if(sectionRelatedItems.every((item)=>checkedDataObject.items.includes(item._id)) && sectionRelatedItems.length !== 0){
                dispatch(addToCheckedList({ isChecked: true, id: section._id, type: billOfQuantities.TYPES.SECTION}))
              }
              else if(checkedDataObject.sections.includes(id)) {
                dispatch(addToCheckedList({ isChecked: false, id: section._id, type: billOfQuantities.TYPES.SECTION}))
    
              }
              else {
                return false
              }
              return sectionRelatedItems.every((item)=>checkedDataObject.items.includes(item._id)) && sectionRelatedItems.length !== 0 
          }
          else if (type !==billOfQuantities.TYPES.SECTION && checkedDataObject.items.includes(id)){
            return true
          }
          else {
            return false
          }
         
        }
    
    return(
    <>
        
        <div  className={"row m-0"}>
                <div className='col-3 p-0'>
                          <div className='row row-col-auto view-pricing-icon-container'>
                          <div >
                                            <img src={dragIcon} className="col" alt='ellipseIcon'/>
                                            {/* <img src={greenEllipseIcon} className="col" alt='settingsIcon'/>
                                            <img src={pcIcon} className="col" alt='hammerIcon'/>
                                            <img src={gavelIcon} className="col" alt='whiteTruckIcon'/> */}
                                            <img  className='col ' style={{width: "13px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                                            <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                                            <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                                            <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                                            <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
                                            {/* <select  className='labour-select-input w-25 m-0 p-0' >
                                                    <option value="Prime">IC2</option>
                                                    <option value="Provitional"><img src={blueTruckIcon}></img></option>
                                                    <option value="Excluded">Excluded</option>
                                                </select> */}
                                            {/* <img src={treeSwichterOpenIcon} className="col"  onClick={() => {setShow((prev) => !prev)}}  alt='whiteTruckIcon'/> */}
                                          

                                          {/* working */}
                                            {showUnspecified?
                                              <img src={treeSwichterOpenIcon} className="col"  onClick={() => {setShowUnspecified((prev) => !prev)}}  alt='whiteTruckIcon'/>

                                            :<img src={treeSwichterCloseIcon} className="col"  onClick={() => {setShowUnspecified((prev) => !prev)}}  alt='whiteTruckIcon'/>
                                          }
                                                                                    {/* <input className="form-check-input m-1 " type="checkbox" ></input> */}
                                            {/* {isCurrentChecked(labour._id , billOfQuantities.TYPES.LABOUR)?
                                                  <input className="form-check-input pricing-checkbox m-1 align-items-center" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} defaultChecked></input>   
                                                :
                                                
                                                <input className="form-check-input pricing-checkbox m-1 align-items-center " type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}}  ></input>   
                                              }     */}

                                              {checkAllUnspecified()?
                                                                        <input className="form-check-input  pricing-checkbox col" type="checkbox" onChange={(e)=>{
                                                                          itemRequestData.forEach((item)=>{
                                                                            if(!isCurrentChecked(item._id, item.type) && section._id === item.section_id && e.target.checked && item.specification === billOfQuantities.TYPES.UNSPECIFIED){
                                                            
                                                                              dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: item.type}))
                                                                            }
                                                                          })
                                                                        }} defaultChecked></input>   
                                                                      :
                                                                      
                                                                      <input className="form-check-input pricing-checkbox col" type="checkbox"  onChange={(e)=>{
                                                                        itemRequestData.forEach((item)=>{
                                                                          if(!isCurrentChecked(item._id, item.type) && section._id === item.section_id && e.target.checked && item.specification === billOfQuantities.TYPES.UNSPECIFIED){
                                            
                                                                            dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: item.type}))
                                                                          }
                                                                        })
                                                                      }} ></input>   
                                                                    }
                                        <input
                                              type="text"
                                              defaultValue={labour.name}
                                              className='col'
                                              style={{maxWidth:"120px"}}
                                              // onBlur={(e) => {
                                              //   if(e.target.value.toLowerCase()!==labour.name.toLowerCase() && !isDuplicateName){
                                    
                                              //     dispatch(updateItemData({item : { ...labour, name: e.target.value }}));
                                              //   }
                                              // }}
                                              readOnly={true}
                                            ></input> 

                          </div>


                               
                                     
                          </div>   

                </div>
                <div className='col-9'>
                    <div className='row row-cols-10'>
                          <div className='col'></div>
                          <div className='col'></div>
                          <div className='col'></div>
                          <div className='col'></div>
                          <div className='col'></div>

                          <div className='col'></div>
                          <div className='col'></div>
                          <div className='col'></div>
                          <div className='col'></div>
                          <div className='col'></div>

                          <div className='col'></div>
                          <div className='col'></div>



                          <div className='view-pricing-user-filter-icon'>

                            <img  src={threeDotIcon}></img>
                          </div>
                    </div>                                                  
                </div>
      
   </div>
  

          <Droppable droppableId={billOfQuantities.DROPPABLE_TYPES.UNSPE+section._id} specification={billOfQuantities.TYPES.UNSPECIFIED} key={billOfQuantities.TYPES.UNSPECIFIED} type={billOfQuantities.DROPPABLE_TYPE_FOR_NESTED_HIERARCHY.NON_LABOUR} >
          {provided=>(
              <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight : "25px"}}>
              <UnspecifiedItems/>
              {provided.placeholder}
              </div>
          )}
          </Droppable>
   
    </>
    )
})


const UnspecifiedItems = memo(()=>{
  const unspecifiedId = useSelector((state)=>state.pricing.unspecifiedId)
  // const item = useSelector((state)=>state.pricing.itemRequestData) 
  const itemRequestData = useSelector((state)=>state.pricing.itemRequestData)

  // console.log("Render | undefied ")
  const overheadsPercentage = useSelector((state)=>state.pricing.overheadsPercentage)
  const checkedDataObject = useSelector((state)=>state.pricing.checkedDataObject)

  const itemsId = useSelector((state)=>state.pricing.itemsId)
  const laboursId = useSelector((state)=>state.pricing.laboursId)


    
  const isCurrentChecked = (id,type)=>{
    // if(type === billOfQuantities.TYPES.ITEM && checkedDataObject.items.includes(id)){
    //     return true
    // }
    // if(type === billOfQuantities.TYPES.LABOUR && checkedDataObject.labours.includes(id)){
    //     return true
    // }
    if(type === billOfQuantities.TYPES.SECTION){
        let sectionRelatedItems = itemRequestData.filter((item)=> item.section_id === section._id)
        if(sectionRelatedItems.every((item)=>checkedDataObject.items.includes(item._id)) && sectionRelatedItems.length !== 0){
          dispatch(addToCheckedList({ isChecked: true, id: section._id, type: billOfQuantities.TYPES.SECTION}))
        }
        else if(checkedDataObject.sections.includes(id)) {
          dispatch(addToCheckedList({ isChecked: false, id: section._id, type: billOfQuantities.TYPES.SECTION}))

        }
        else {
          return false
        }
        return sectionRelatedItems.every((item)=>checkedDataObject.items.includes(item._id)) && sectionRelatedItems.length !== 0 
    }
    else if (type !==billOfQuantities.TYPES.SECTION && checkedDataObject.items.includes(id)){
      return true
    }
    else {
      return false
    }
   
  }

  
  // new calculations - 5 

  const calculateWastageValue = (item)=>{

    // console.log(calculateUnitsIncWastage(item), " - ",item.unit_ex_wastage, " = " ,calculateUnitsIncWastage(item) - parseFloat(item.unit_ex_wastage))
      return Math.round(calculateUnitsIncWastage(item) - item.unit_ex_wastage *100)/100
      // return Math.floor((calculateUnitsIncWastage(item) - item.unit_ex_wastage )* 100)/100
  }

  const calculateUnitsIncWastage = (item)=>{
    // return Math.floor( (item.unit_ex_wastage * (1 + (item.wastage_percentage/100))) * 100)/100
    return Math.round(item.unit_ex_wastage * (1 + (item.wastage_percentage/100)) * 100)/100
  }

  const calculateSellAdjesmentUnitValue = (item)=>{
    if (item.sell_unit_price>item.buy_unit_price){
       
      // return Math.floor( (  item.sell_unit_price - item.buy_unit_price  ) * 100)/100
      return Math.round(  (item.sell_unit_price - item.buy_unit_price) *100  )/100
    }
    else if (item.sell_unit_price > 0){
     
      return Math.round(  (item.sell_unit_price - item.buy_unit_price) *100 )/100

    }
    else {
      return 0
    }
  }

  const calculateTotalSellPriceOfUnitsValue = (item)=>{
    if(item.sell_unit_price > 0){
       return Math.round((item.sell_unit_price * parseFloat(calculateUnitsIncWastage(item))) * 100)/100
      //  return Math.floor( (  item.sell_unit_price * parseFloat(calculateUnitsIncWastage(item))  ) * 100)/100

    }
    else {
      return Math.round((item.buy_unit_price * parseFloat(calculateUnitsIncWastage(item))) * 100)/100
      // return Math.floor( (  item.buy_unit_price * parseFloat(calculateUnitsIncWastage(item))  ) * 100)/100

    }

  }

  const calculatePreMarkupValue =  (item) => {
    return  Math.round( (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) *(item.pre_markup_percentage/100)) * 100)/ 100
    // return Math.floor( ( parseFloat(calculateTotalSellPriceOfUnitsValue(item)) *(item.pre_markup_percentage/100)  ) * 100)/100

    
  }



  






  const calculateCostOfUnits = (item)=> {
    let costOfUnits = (item.unit_ex_wastage * (item.cost_per_unit*(1 + item.wastage_percentage/100)))
    // if(item._id === "6455d407c9b5d4f7613360a6"){
    //   console.log(typeof item.wastage_percentage)
    //   console.log(1 + item.wastage_percentage/100)
    //   console.log((item.cost_per_unit*(1 + item.wastage_percentage/100)).toFixed(2))
    //   console.log("costOfUnits  : ",item.name," - ",costOfUnits.toFixed(2))
    // }
    return Math.round(costOfUnits * 100)/100
    // return Math.floor( (  costOfUnits  ) * 100)/100


  }

  const calculateContingency = (item)=>{
    let contingencyValue = (calculateTotalSellPriceOfUnitsValue(item)*item.contingency_percentage/100)
    //  if(item._id === "6455d407c9b5d4f7613360a6"){
    //   console.log(typeof item.contingency_percentage)
    //   // console.log(1 + item.wastage_percentage/100)
    //   // console.log((item.cost_per_unit*(1 + item.wastage_percentage/100)).toFixed(2))
    //   console.log("ContingencyValue  : ",item.name," - ",contingencyValue)
    // }
    return Math.round(contingencyValue *100)/100
    // return Math.floor( (  contingencyValue  ) * 100)/100
  }
  const calculateMarkupValue = (item)=>{
    let markupValue = ((parseFloat(calculateTotalSellPriceOfUnitsValue(item))+ parseFloat(calculateContingency(item)))*item.markup_percentage/100)
    //  if(item._id === "6455d407c9b5d4f7613360a6"){
    //   console.log(typeof item.contingency_percentage)
    //   console.log(calculateCostOfUnits(item)+calculateContingency(item))
    //   // console.log((item.cost_per_unit*(1 + item.wastage_percentage/100)).toFixed(2))
    //   console.log("Mark Up Value  : ",item.name," - ",markupValue)
    // }
    // return markupValue.toFixed(2)
    return Math.round(markupValue*100)/100
    // return Math.floor( (  markupValue  ) * 100)/100

  }


  const calculateClientSalesTax = (item) =>{
    let clientSalesTax = ((parseFloat(calculateTotalSellPriceOfUnitsValue(item))+parseFloat(calculateContingency(item))+parseFloat(calculateMarkupValue(item)))*item.client_sales_tax_percentage/100)

    // if(item._id === "6455d407c9b5d4f7613360a6"){
      
    //   console.log("JUst another",)
    //   console.log("Client Sales Tax", clientSalesTax)
    // }
    return Math.round(clientSalesTax)
  }


  const calculateClientCharge = (item)=>{
    // let clientCharge = (parseFloat(calculateCostOfUnits(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)) ).toFixed(2)
    // let clientCharge = Math.ceil((parseFloat(calculateCostOfUnits(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)) ))  
    let clientCharge = (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)))  
    // return Math.floor(clientCharge)
    return Math.ceil(clientCharge)
  }

  const calculateOverheads = (item) => {
    let overhead = ((  parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))  ) * overheadsPercentage/100)
    return Math.round(overhead * 100)/100
  }

  const calculateMargin = (item) => {
    let marginBeforeTax = (parseFloat(calculateClientCharge(item)) - ( parseFloat(calculateOverheads(item)) + parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateClientSalesTax(item)) ))
    return Math.round(marginBeforeTax * 100)/100
  }

  
  const calculateGMPBuffer = (item)=>{
    let gmpBuffer = (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) * (item.gmp_percentage/100))
    return Math.round(gmpBuffer * 100) /100
  }
  
  
    const calculateTotalSellPriceOfUnitsValueForSection = () => {
      const costOfUnitsArray = itemRequestData.map((item)=>{
          if(item.section_id === section._id){
            
            return parseFloat(calculateTotalSellPriceOfUnitsValue(item))
          }
          else{
            return null
          }
      })
      if(costOfUnitsArray.length !== 0){
        return Math.round(costOfUnitsArray.reduce((acc,cur)=> acc+cur) *100)/100
        }
       else{
          return 0
       }
     
      // return 11
     
     

    }

    const calculateTotalContingency = () => {
      const contingencyArray = itemRequestData.map((item)=>{
          if(item.section_id === section._id){
            
            return parseFloat(calculateContingency(item))
          }
          else{
            return null
          }
      })

      if(contingencyArray.length !== 0){
        return Math.round(contingencyArray.reduce((acc,cur)=> acc+cur) * 100) /100
        }
       else{
          return 0
       }
    
      
     

    }
    const calculateTotalMarkupValue = () => {
      const markupValueArray = itemRequestData.map((item)=>{
          if(item.section_id === section._id){
            
            return parseFloat(calculateMarkupValue(item))
          }
          else{
            return null
          }
      })
     
      if(markupValueArray.length !== 0){
        return Math.round( markupValueArray.reduce((acc,cur)=> acc+cur) *100) / 100
     
        }
       else{
          return 0
       }

    }

    const calculateTotalClientSalesTax = () => {
      const totalClientSalesTax = itemRequestData.map((item)=>{
          if(item.section_id === section._id){
            
            return parseFloat(calculateClientSalesTax(item))
          }
          else{
            return null
          }
      })

      if(totalClientSalesTax.length !== 0){
        
      return Math.round(totalClientSalesTax.reduce((acc,cur)=> acc+cur) * 100) /100
      }
     else{
        return 0
     }
    
     

    }
    const calculateTotalClientCharge = () => {
      const totalClientCharge = itemRequestData.map((item)=>{
          if(item.section_id === section._id){
            
            return parseFloat(calculateClientCharge(item))
          }
          else{
            return null
          }
      })
      
      if(totalClientCharge.length !== 0){
        return Math.round(totalClientCharge.reduce((acc,cur)=> acc+cur))
      }
     else{
        return 0
     }
     
     

    }
    const calculateTotalOverhead = () => {
      
      // console.log("---------------------------",itemRequestLoading ,"------" )
      
       const totalOverheadArray = itemRequestData.map((item)=>{
          if(item.section_id === section._id){
            
            return parseFloat(calculateOverheads(item))
          }
          else{
            return null
          }
      })
      // console.log("++++++++++++++++++++++++++++",itemRequestLoading ,"------",itemRequestData ,totalOverheadArray )
      if(totalOverheadArray.length !== 0){
        return Math.round( totalOverheadArray.reduce((acc,cur)=> acc+cur) * 100) /100
      }
     else{
        return 0
     }
    

     

    }
    const calculateTotalMargin = () => {
      const totalMarginBeforeTaxArray = itemRequestData.map((item)=>{
          if(item.section_id === section._id){
            
            return parseFloat(calculateMargin(item))
          }
          else{
            return null
          }
      })

      if(totalMarginBeforeTaxArray.length !== 0){
        return Math.round(totalMarginBeforeTaxArray.reduce((acc,cur)=> acc+cur) * 100) / 100
      }
     else{
        return 0
     }
     

    }
    const calculateTotalGMPBufferValue = () => {
      const totalArray = itemRequestData.map((item)=>{
          if(item.section_id === section._id){
            
            return parseFloat(calculateGMPBuffer(item))
          }
          else{
            return null
          }
      })

      if(totalArray.length !== 0){
        return Math.round(totalArray.reduce((acc,cur)=> acc+cur) * 100) / 100
      }
     else{
        return 0
     }
     

    }

    const calculateTotalContingencyPercentage = ()=>{
        let total = ((parseFloat(calculateTotalContingency())/ parseFloat(calculateTotalSellPriceOfUnitsValueForSection()))*100)
       
        if(!isNaN(total)){
           return Math.round(total)
        }
        else {

          return 0 
        }

    }
    const calculateTotalMarkupPercentage = ()=>{

      let total=  ((parseFloat(calculateTotalMarkupValue()) / (parseFloat(calculateTotalSellPriceOfUnitsValueForSection()) + parseFloat(calculateTotalContingency()) ) )*100)
      if(!isNaN(total)){
        return Math.round(total)
      }
      else {

        return 0 
      }

    }
    const calculateTotalClientSalesTaxPercentage = ()=>{

      let total= ((parseFloat(calculateTotalClientSalesTax())/(parseFloat(calculateTotalSellPriceOfUnitsValueForSection()) + parseFloat(calculateTotalContingency()) + parseFloat(calculateTotalMarkupValue()) ) )*100)
      if(!isNaN(total)){
        return Math.round(total)
      }
      else {

        return 0 
      }

    }
//     const newSelectedOptions = [];
//     unspecifiedId[section._id].map(async (itemId,index) =>  {
//       const item = itemRequestData.find((i)=> i._id === itemId)
//       if((item.specification === billOfQuantities.TYPES.UNSPECIFIED ) && item.section_id === section._id && (item.type !== billOfQuantities.ITEM_TYPES.STAFF || item.type !== billOfQuantities.ITEM_TYPES.MATHLAB || item.type !== billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || item.type !== billOfQuantities.ITEM_TYPES.SUB_MATHLAB)){ 
//           const initialValue = { value: item, label: item.name };
//           newSelectedOptions.push(initialValue);
//       }
//     })

//     // After the loop, update the state with the accumulated values
// setSelectedOption(newSelectedOptions);

    //  if( unspecifiedId[section._id].length > 0 ){
    //   const item = itemRequestData.find((i)=> i._id === unspecifiedId[section._id][0] )
    //   console.log("looking at unspecified item with section id")
    //   console.log(item)
    //   if((item.specification === billOfQuantities.TYPES.UNSPECIFIED ) && item.section_id === section._id && (item.type !== billOfQuantities.ITEM_TYPES.STAFF || item.type !== billOfQuantities.ITEM_TYPES.MATHLAB || item.type !== billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || item.type !== billOfQuantities.ITEM_TYPES.SUB_MATHLAB)){
    //           const initialValue = { value: item, label: item.name };
    //           setSelectedOption(initialValue)
    //   }
    //  }


    // useEffect(() => {
    //   // Fetch your API data and set the initial value only once
    //   const fetchData = async () => {
    //     const newSelectedOptions = [];
    //     unspecifiedId[section._id].map((itemId,index)=>{
    //       const item = itemRequestData.find((i) => i._id === itemId);
    //       if (
    //         item.specification === billOfQuantities.TYPES.UNSPECIFIED &&
    //         item.section_id === section._id &&
    //         ![billOfQuantities.ITEM_TYPES.STAFF, billOfQuantities.ITEM_TYPES.MATHLAB, billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY, billOfQuantities.ITEM_TYPES.SUB_MATHLAB].includes(item.type)
    //       ) {
    //         const initialValue = { value: item, label: item.name };
    //         newSelectedOptions.push(initialValue);
    //       }
    //     })
    //     setSelectedOption(newSelectedOptions);
    //   };
  
    //   fetchData(); // Call the fetchData function when the component mounts
    // }, []); 

    
    // unspecifiedId[section._id].map(async(itemId,index)=>{
    //   itemIdsForCreatable.push(itemId)
    // })
    // itemsUnspecifiedList = []
    // itemIdsForCreatable.map(async(itemId,index) => {
    //   const item = itemRequestData.find((i)=> i._id === itemId)
    //   itemsUnspecifiedList.push(item)
    // })
    
    // useEffect(() => {
    //   // Fetch your API data and set the initial value only once
    //   const fetchData = async () => {
    //     const newSelectedOptions = [];
    //     unspecifiedId[section._id].map((itemId,index)=>{
    //       const item = itemRequestData.find((i) => i._id === itemId);
    //       if (
    //         item.specification === billOfQuantities.TYPES.UNSPECIFIED &&
    //         item.section_id === section._id &&
    //         ![billOfQuantities.ITEM_TYPES.STAFF, billOfQuantities.ITEM_TYPES.MATHLAB, billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY, billOfQuantities.ITEM_TYPES.SUB_MATHLAB].includes(item.type)
    //       ) {
    //         const initialValue = { value: item, label: item.name };
    //         newSelectedOptions.push(initialValue);
    //       }
    //     })
    //     setSelectedOption(newSelectedOptions);
    //   };
  
    //   fetchData(); // Call the fetchData function when the component mounts
    // }, []); 

      // Define inline styles
  const customStyles = {
    option: (provided, state) => ({
      padding: '8px',
      backgroundColor: state.isFocused ? '#f0f0f0' : 'transparent', // Change background color on hover
      cursor: 'pointer',
    })
  };
    const [selectedOption, setSelectedOption] = useState(null);
    return unspecifiedId[section._id].map((itemId,index)=>{
     
      const item = itemRequestData.find((i)=> i._id === itemId)
        if((item.specification === billOfQuantities.TYPES.UNSPECIFIED ) && item.section_id === section._id && (item.type !== billOfQuantities.ITEM_TYPES.STAFF || item.type !== billOfQuantities.ITEM_TYPES.MATHLAB || item.type !== billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || item.type !== billOfQuantities.ITEM_TYPES.SUB_MATHLAB)){

          // <Draggable draggableId={itemId} index={index} key={itemId}>     
           return(
            <Draggable draggableId={itemId} index={index} key={itemId} >
            {
             
            (provided)=>(
                  <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={"row m-0"}>
                  <div className='col-3 p-0'>
                      <div className='row row-cols-auto view-pricing-icon-container'>
                              <div className='d-flex align-items-center' >

                                              <img src={dragIcon} className="col " alt='ellipseIcon'/>

                                              <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                          <FormControl  className='pricing-dropdown-icon col' size="small">
                                                            {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}

                                                            <Select
                                                              labelId="demo-select-small-label"
                                                              id="demo-select-small-item"
                                                              defaultValue={item.status === undefined? "" : item.status}
                                                              label="Age"
                                                              onChange={(e)=> {dispatch(updateItemData({item :  {...item, status :e.target.value}}))}}
                                                              IconComponent={()=>null}
                                                              
                                                            >
                                                              <MenuItem value="">
                                                                <em></em>
                                                              </MenuItem>
                                                              <MenuItem value={1}><img  className='ui avatar image pricing-icon col' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={yellowEllipseIcon}/></MenuItem>
                                                              <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={greenEllipseIcon}/></MenuItem>
                                                              <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
                                                              <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem>
                                                              {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                            </Select>
                                                          </FormControl>
                                                        </div>
                                                        <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                          <FormControl  className='pricing-dropdown-icon col'  >
                                                            {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                            <Select
                                                              labelId="demo-select-small-label"
                                                              id="demo-select-small-item"
                                                              defaultValue={item.cost_type === undefined? "" : item.cost_type}
                                                              label="Age"
                                                              onChange={(e)=> {dispatch(updateItemData({item :  {...item, cost_type :e.target.value}}))}}
                                                              IconComponent={()=>null}
                                                              
                                                            >
                                                              <MenuItem value="">
                                                                <em></em>
                                                              </MenuItem>
                                                              <MenuItem value={1}><img  className='ui avatar image  ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={pcIcon}/></MenuItem>
                                                              <MenuItem value={2}><img  className='ui avatar image unique-icon ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={exIcon}/></MenuItem>
                                                              {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                              <MenuItem value={3}> -- </MenuItem>
                                                              {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                              {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                            </Select>
                                                          </FormControl>
                                                        </div>


                                                        <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                          <FormControl  className='pricing-dropdown-icon col'  >
                                                            {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                            <Select
                                                              labelId="demo-select-small-label"
                                                              id="demo-select-small-item"
                                                              defaultValue={item.hammer === undefined? "" : item.hammer}
                                                              label="Age"
                                                              onChange={(e)=> {dispatch(updateItemData({item :  {...item, hammer :e.target.value}}))}}
                                                              IconComponent={()=>null}
                                                              
                                                            >
                                                              <MenuItem value="">
                                                                <em></em>
                                                              </MenuItem>
                                                              <MenuItem value={1}><img  className='ui avatar image pricing-icon ' style={{height : "15px", width: "16px",background: "#EFEFEF", border:"none"}} src={gavelIcon}/></MenuItem>
                                                              <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
                                                              {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                              <MenuItem value={3}> || </MenuItem>
                                                              {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                              {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                            </Select>
                                                          </FormControl>
                                                        </div>
                                                        <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                          <FormControl  className='pricing-dropdown-icon col'   >
                                                            {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                            <Select
                                                              labelId="demo-select-small-label"
                                                              id="demo-select-small-item"
                                                              defaultValue={item.suppiler === undefined? "" : item.suppiler}
                                                              label="Age"
                                                              onChange={(e)=> {dispatch(updateItemData({item :  {...item, suppiler :e.target.value}}))}}
                                                              IconComponent={()=>null}
                                                             
                                                            >
                                                              <MenuItem value="">
                                                                <em></em>
                                                              </MenuItem>
                                                              <MenuItem value={1}  className="pricing-icon-container"><img  className='ui avatar image  unique-icon' style={{height : "11px", width: "15px",background: "#EFEFEF", border:"none"}} src={blueTruck}/></MenuItem>
                                                              {/* <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem> */}
                                                              {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                              <MenuItem value={3}> -- </MenuItem>
                                                              {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                              {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                            </Select>
                                                          </FormControl>
                                                        </div>

                                                    


                                              {/* <img src={greenEllipseIcon} className="col" alt='settingsIcon'/>
                                              <img src={pcIcon} className="col" alt='hammerIcon'/>
                                              <img src={gavelIcon} className="col" alt='whiteTruckIcon'/> */}
                                              {/* <img  className='col bg-white' style={{width: "16px",height : "16px"}}/> */}
                                              {/* <img src={blueTruckIcon} className="col" alt='whiteTruckIcon'/> */}
                                              {/* <select  className='labour-select-input w-25 m-0 p-0' >
                                                      <option value="Prime">IC2</option>
                                                      <option value="Provitional"><img src={blueTruckIcon}></img></option>
                                                      <option value="Excluded">Excluded</option>
                                                  </select> */}

                                              <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
                                              <img  className='col ' style={{width: "24px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
                                              <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
                                              {/* {show?
                                                <img src={treeSwichterOpenIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>

                                              :<img src={treeSwichterCloseIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>
                                            } */}
                                                                                      {/* <input className="form-check-input m-1 " type="checkbox" ></input> */}
                                              {isCurrentChecked(item._id , billOfQuantities.TYPES.ITEM)?
                                                    <input className="form-check-input pricing-checkbox m-1 align-items-center" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}}  defaultChecked></input>   
                                                  :
                                                  
                                                  <input className="form-check-input pricing-checkbox m-1 align-items-center " type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}} ></input>   
                                                } 
                                                {}   

                              </div>
                              <div style={{ width: 120}}>
                                  {/* <ReactSearchAutocomplete
                                    onFocus={handleOnFocus}
                                    placeholder={item.name}
                                    fuseOptions={{keys : ["name","type"]}}
                                    items={pricingListCurrent.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.ITEM)} 
                                    showIcon= {false}
                                    onSelect = {(event)=>{dispatch(updateItemData({item : {...item, name : event.name, type : event.type}}))}}
                                    styling={{
                                      height: "34px",
                                      border: "none",
                                      // border: "1px solid darkgreen",
                                      borderRadius: "4px",
                                      backgroundColor: "#EFEFEF",
                                      boxShadow: "none",
                                      hoverBackgroundColor: "lightgreen",
                                      color: "black",
                                      // fontSize: "12px",
                                      // fontFamily: "Courier",
                                      iconColor: "green",
                                      lineColor: "lightgreen",
                                      placeholderColor: "darkgreen",
                                      clearIconMargin: "3px 8px 0 0",
                                      zIndex :"auto",
                                      // zIndex: {labourZIndex},
                                    }}
                                  /> */}

<CreatableSelect
   styles={customStyles}
   onChange= {(event)=>{
     const selectedValue = { value: event, label: event.name };
     setSelectedOption(selectedValue);
     dispatch(updateItemData({item : {...item, name : event.name, type : event.type}}))}}
   options={pricingListCurrentLatest.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.ITEM)}  
   value= {{ value: item, label: item.name }}//{selectedOption}
 /> 
                              </div>
                      </div>
                  </div> 
                  

                  <div className="col-9">
                              <div className="row row-cols-10">
                                              
                                                                                              {/*--------- Item Name for Proposal Column---------- */}

                                                                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_NAME_FOR_PROPOSAL))?
                                                  <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.item_names_for_proposal}  onBlur={(e)=>{
                                                                            
                                                    dispatch(updateItem({item :{...item, item_names_for_proposal: e.target.value}}))
                                                }}  /></div>
                                              :null}                                                              

                                              
                                              {/*--------- ITEM_TYPE Column---------- */}
                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_TYPE))?
                                                <div className='col justify-content-start'>
                                                      <select className='w-100 section-input' defaultValue={item.item_type} onBlur={(e)=>{
                                                         // dispatch(updateItem({...item, status :e.target.value}))
                                                          if (e.target.value === billOfQuantities.ITEM_TYPES.STAFF || e.target.value === billOfQuantities.ITEM_TYPES.MATHLAB || e.target.value === billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || e.target.value === billOfQuantities.ITEM_TYPES.SUB_MATHLAB)
                                                            {
                                                              // let itemObject = item
                                                              // delete itemObject.labour_id
                                                              

                                                              dispatch(updateItem({...item, item_type :e.target.value, specification : billOfQuantities.TYPES.SPECIFIED}))
                                                              dispatch(setIdArray())
                                                            }
                                                          else {
                                                            dispatch(updateItem({...item, item_type :e.target.value}))
                                                          }
                                                        
                                                         }} >
                                                             <option value="Assembly (Global)">Assembly (Global)</option>
                                                             <option value="Equipment">Equipment</option>
                                                             <option value="Material">Material</option>
                                                             <option value="MathLab">MatLab</option>
                                                             <option value="Other">Other</option>
                                                             <option value="Staff">Staff</option>
                                                             <option value="Sub(Labour only)">Sub(Labour only)</option>
                                                             <option value="Sub(Material only)">Sub(Material only)</option>
                                                             <option value="Sub(MathLab)">Sub(MatLab)</option>
                                                         </select>

                                                </div>
                                                                :null}

                                                                          

                                                                        


                                              {/*---------Parent Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PARENT))?
                                                   <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.parent}  onBlur={(e)=>{
                                                                            
                                                    dispatch(updateItem({item :{...item, parent: e.target.value}}))
                                                }}  /></div>
                                              :null}                                                              


                                              {/*---------Cost Code Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_CODE))?
                                                   <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.cost_code}  onBlur={(e)=>{
                                                                            
                                                    dispatch(updateItem({item :{...item, cost_code: e.target.value}}))
                                                }}  /></div>
                                              :null}
                                              
                                                {/*--------- Units exc Wastage Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_EX_WASTAGE))?
                                                <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.unit_ex_wastage}  onBlur={(e)=>{
                                                                            
                                                  dispatch(updateItem({item :{...item, unit_ex_wastage: e.target.value}}))
                                              }}  /></div>
                                                :null}

                                              
                                                {/*--------- UOM Column---------- */}


                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                                                  <div className='col justify-content-start'>
                                                        <select className='w-100 section-input' defaultValue={item.uom} onBlur={(e)=>{
                                                         // dispatch(updateItem({...item, status :e.target.value}))
                                                         dispatch(updateItem({item : {...item, uom :e.target.value}}))
                                                    
                                                         }} >
                                                             <option value="Ea">Ea</option>
                                                             <option value="M2"><p>M<sup>2</sup></p></option>
                                                             <option value="M3">M<sup>3</sup></option>
                                                             <option value="Hrs">Hrs</option>
                                                             <option value="Hrs">Days</option>
                                                             <option value="Im">Im</option>
                                                             <option value="Kg">Kg</option>
                                                             
                                                         </select>
                                                  </div>
                                                :null}

                                              

                                                {/*--------- Wastage % Column---------- */}



                                                
                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_PERCENTAGE))?
                                                <div className='col justify-content-start'><span class="currency-input">
                                               <input  type='text' className='section-input ' defaultValue={item.wastage_percentage}  onBlur={(e)=>{
                                                                            
                                                                            dispatch(updateItem({item :{...item, wastage_percentage: e.target.value}}))
                                                                        }}   /><p>%</p> </span>
                                                  </div>
                                                :null}



                                             {/*--------- Wastage Value---------- */}




                                                
                                             {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_VALUE))?
                                                  <p className="col"> { calculateWastageValue(item)} </p>
                                              :null} 



                                            {/*--------- Units inc Wastage---------- */}


                                                
                                             {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_INC_WASTAGE))?
                                                  <p className="col"> { calculateUnitsIncWastage(item)} </p>
                                              :null} 


                                              
                                                {/*--------- BUY Unit $ Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.BUY_UNIT_PRICE))?
                                                <div className='col justify-content-start'><span class="currency-input"><p>$</p><input  type='text' className='section-input ' defaultValue={item.buy_unit_price}  onBlur={(e)=>{
                                                                            
                                                  dispatch(updateItem({item :{...item, buy_unit_price: e.target.value}}))
                                              }}  /></span></div>
                                                :null}



                                                {/*--------- Sell Unit $ Column---------- */}

                                              
                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_UNIT_PRICE))?
                                                  <div className='col justify-content-start'><span class="currency-input"><p>$</p><input  type='text' className='section-input ' defaultValue={item.sell_unit_price}  onBlur={(e)=>{
                                                                            
                                                    dispatch(updateItem({item :{...item, sell_unit_price: e.target.value}}))
                                                }}  /></span></div>
                                                :null}


                                                    {/*--------- Sell Adjestment Unit Value---------- */}


                                                
                                             {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_ADJ_UNIT_VALUE))?
                                                  <p className="col"> { calculateSellAdjesmentUnitValue(item)} </p>
                                              :null} 
                                                                          


                                              {/*---------Supplier Inc Sales Tax Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_INC_SALES_TAX))?
                                                  <div className='col justify-content-start'>
                                                  <select className='w-100 section-input' defaultValue={item.supplier_inc_sales_tax} onBlur={(e)=>{
                                                  // dispatch(updateItem({...item, status :e.target.value}))
                                                  dispatch(updateItem({item : {...item, supplier_inc_sales_tax :e.target.value}}))
                                             
                                                  }} >
                                                      <option value="Yes">Yes</option>
                                                      <option value="No">No</option>
                                                     
                                                      
                                                  </select>
                                             </div>
                                              :null}                                                              


                                              {/*--------- Supplier Item Code Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_ITEM_CODE))?
                                                  <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.supplier_item_code}  onBlur={(e)=>{
                                                                            
                                                    dispatch(updateItem({item :{...item, supplier_item_code: e.target.value}}))
                                                }}  /></div>
                                              :null}
                                              



                                               {/*--------- Total Sell Price Of Units---------- */}


                                                
                                             {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TOTAL_SELL_PRICE_OF_UNITS))?
                                             <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateTotalSellPriceOfUnitsValue(item)}</span>
                                             </span> </div>
                                                  // <p className="col"> { calculateTotalSellPriceOfUnitsValue(item)} </p>
                                              :null} 
                                                 
                                              
                                                {/*--------- Contingency % Column---------- */}


                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_PERCENTAGE))?
                                                <div className='col justify-content-start'><span class="currency-input">
                                               <input  type='text' className='section-input ' defaultValue={item.contingency_percentage}  onBlur={(e)=>{
                                                                            
                                                                            dispatch(updateItem({item :{...item, contingency_percentage: e.target.value}}))
                                                                        }} /><p>%</p> </span>
                                                  </div>
                                                :null}


                                              
                                              {/*--------- Contingency Value Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_VALUE))?
                                              <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateContingency(item)}</span>
                                              </span> </div>
                                                  // <p className="col"> {calculateContingency(item)}  </p>
                                              :null}   

                                              
                                                {/*--------- Markup percentage % Column---------- */}


                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_PERCENTAGE))?
                                                  <div className='col justify-content-start'><span class="currency-input">
                                                  <input  type='number' className='section-input' defaultValue={item.markup_percentage}  onBlur={(e)=>{
                                                                            
                                                                            dispatch(updateItem({item :{...item, markup_percentage: e.target.value}}))
                                                                        }} /><p>%</p> </span>
                                                   </div>

                                                :null}
                                              
                                              {/*--------- Markup Value Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_VALUE))?
                                                  <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateMarkupValue(item)}</span>
                                                  </span> </div>
                                                  // <p className="col"> {calculateMarkupValue(item)}  </p>
                                              :null}                                                                


                                              {/*--------- Client Sales Tax Percentage  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_PERCENTAGE))?
                                                  <div className='col justify-content-start'><span class="currency-input">
                                                 <input  type='number' className='section-input ' defaultValue={item.client_sales_tax_percentage}  onBlur={(e)=>{
                                                                            
                                                                            dispatch(updateItem({item :{...item, client_sales_tax_percentage: e.target.value}}))
                                                                        }}  /><p>%</p> </span></div>
                                              :null}                                                              


                                              {/*--------- Client Sales Tax Value  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_VALUE))?
                                                  <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateClientSalesTax(item)}</span>
                                                  </span> </div>
                                                  // <p className="col"> {calculateClientSalesTax(item)}  </p>
                                              :null}
                                              


                                              
                                                {/*--------- Client Charge Inc Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE_INC))?
                                                    <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateClientCharge(item)}</span>
                                                    </span> </div>
                                                  // <div className='col justify-content-start'>{calculateClientCharge(item)}</div>
                                                :null}
                                                                          


                                              {/*--------- Overhead  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.OVERHEAD))?
                                                  <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateOverheads(item)}</span>
                                                  </span> </div>
                                                  // <p className="col"> {calculateOverheads(item)}  </p>
                                              :null}                                                              


                                              {/*--------- Margin  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
                                                    <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateMargin(item)}</span>
                                                    </span> </div>
                                                  // <p className="col"> {calculateMargin(item)}  </p>
                                              :null}

                                            {/*--------- GMP Buffer %   Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_PERCENTAGE))?
                                                        <div className='col justify-content-start'><span class="currency-input">
                                                        <input  type='number' className='section-input' defaultValue={item.gmp_percentage}  onBlur={(e)=>{
                                                                                
                                                                                dispatch(updateItem({item :{...item, gmp_percentage: e.target.value}}))
                                                                            }} /><p>%</p> </span>
                                                        
                                                        </div>
              
                                                  
                                                  

                                              :null}                                                              
                                              {/*--------- GMP Buffer V   Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_VALUE))?
                                              <div className='col justify-content-start'><span class="section-input-container"><p>$</p><span >{calculateGMPBuffer(item)}</span>
                                              </span></div>
                                                    
                                              :null}                                                                  


                                              {/*--------- Supplier Contact Id  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_CONTACT_ID))?
                                                   <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.suppiler}  onBlur={(e)=>{
                                                                            
                                                    dispatch(updateItem({item :{...item, suppiler: e.target.value}}))
                                                }} /></div>
                                              :null}                                                              


                                              {/*--------- Contractor Item Note  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTRACTOR_ITEM_NOTE))?
                                                   <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.contractor_item_note}  onBlur={(e)=>{
                                                                            
                                                    dispatch(updateItem({item :{...item, contractor_item_note: e.target.value}}))
                                                }} /></div>
                                              :null}                                                              


                                              {/*--------- Tender Item Note  Column---------- */}

                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TENDER_ITEM_NOTE))?
                                                    <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.tender_item_note}  onBlur={(e)=>{
                                                                            
                                                      dispatch(updateItem({item :{...item, tender_item_note: e.target.value}}))
                                                  }} /></div>
                                              :null}  

                                              {/*--------- Pricinglist ? Column---------- */}

                                              
                                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.IS_PRICELIST_ITEM))?
                                                  <div className='col justify-content-start'>
                                                         <select className='w-100 section-input' defaultValue={item.is_pricelist_item} onBlur={(e)=>{
                                                         // dispatch(updateItem({...item, status :e.target.value}))
                                                         dispatch(updateItem({item : {...item, is_pricelist_item :e.target.value}}))
                                                    
                                                         }} >
                                                             <option value="Yes">Yes</option>
                                                             <option value="No">No</option>
                                                            
                                                             
                                                         </select>
                                                    </div>
                                                :null}


                                              {/*--------- NESTED  Column---------- */}

                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.NESTED))?
                 //     <div className='col-1'> 
                 //     <input type="text" defaultValue={item.type} className='section-input form-control m-0 p-0'  onBlur={(e)=>{
                 //     // dispatch(updateItem({...item, type :e.target.value}))
                 //     dispatch(updateItemData({item :{...item, type :e.target.value}}))
                  
                 //     }} ></input>
                 // </div>
                   <div className="col-1">
                   <select
                     className="section-input form-select m-0 p-0"
                     defaultValue={item.specification}
                     onChange={(e) => {
                       let itemObject = {...item}
                       // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
                       if(e.target.selectedOptions[0].getAttribute("specification") ===billOfQuantities.TYPES.SPECIFIED && e.target.selectedOptions[0].getAttribute("labourid"))
                       {
                         console.log("under current labour",e.target.selectedOptions[0].getAttribute("labourid"))
                      
                         dispatch(updateItem({...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}))
                       }
                       // else if (e.target.selectedOptions[0].getAttribute("specialism") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){
                       //   console.log("new labour",e.target.value)
                       //   delete itemObject.labour_id
                       //   dispatch(updateItemData({item :{...itemObject, specialism :e.target.selectedOptions[0].getAttribute("specialism")}}))
                       // }
                       else if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.UNSPECIFIED){
                         delete itemObject.labour_id
                      
                         dispatch(updateItem({...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}))
      
                       }
                       dispatch(setIdArray())
                       // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
                                            }}
                   > 
                   
                 {laboursId[section._id].map((itemId)=>{
                   let currentLabour = itemRequestData.find((i)=>i._id === itemId)
                     if((currentLabour.item_type === billOfQuantities.ITEM_TYPES.MATHLAB || currentLabour.item_type === billOfQuantities.ITEM_TYPES.STAFF || currentLabour.item_type === billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY ||currentLabour.item_type === billOfQuantities.ITEM_TYPES.SUB_MATHLAB) && currentLabour.section_id === section._id && currentLabour.specification === billOfQuantities.TYPES.SPECIFIED)
                     return <option key={currentLabour._id} value={currentLabour.name} labourid={currentLabour._id}
                     specification={billOfQuantities.TYPES.SPECIFIED} 
                     >{currentLabour.name}</option>
                   })}
                
                  

                     <hr className="dropdown-divider"/>
                     {/* <option className="m-1" value={billOfQuantities.TYPES.LABOUR} 
                     specialism={billOfQuantities.TYPES.LABOUR} 
                     >Add New Labour</option> */}
                     <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option>
                     {/* <option value="Provitional">Provitional</option>
                     <option value="Excluded">Excluded</option> */}
                   </select>
                   </div>
                 :null}

                                              <div className='view-pricing-user-filter-icon'>

                                                  <img  src={threeDotIcon}></img>
                                              </div>
                              </div>

                      </div>

              </div>
              )
            }
            </Draggable>
                
        ) 
        }
       
    })
})

// const UnspecifiedDraggableItem = (item)=>{

// const initialValue = { value: item, label: item.name };
//   return  <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={"row m-0"}>
//   <div className='col-3 p-0'>
//       <div className='row row-cols-auto view-pricing-icon-container'>
//               <div className='d-flex align-items-center' >

//                               <img src={dragIcon} className="col " alt='ellipseIcon'/>

//                               <div className="pricing-dropdown-container col d-flex align-items-center"> 
//                                           <FormControl  className='pricing-dropdown-icon col' size="small">
//                                             {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}

//                                             <Select
//                                               labelId="demo-select-small-label"
//                                               id="demo-select-small-item"
//                                               defaultValue={item.status === undefined? "" : item.status}
//                                               label="Age"
//                                               onChange={(e)=> {dispatch(updateItemData({item :  {...item, status :e.target.value}}))}}
//                                               IconComponent={()=>null}
                                              
//                                             >
//                                               <MenuItem value="">
//                                                 <em></em>
//                                               </MenuItem>
//                                               <MenuItem value={1}><img  className='ui avatar image pricing-icon col' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={yellowEllipseIcon}/></MenuItem>
//                                               <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={greenEllipseIcon}/></MenuItem>
//                                               <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
//                                               <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem>
//                                               {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
//                                             </Select>
//                                           </FormControl>
//                                         </div>
//                                         <div className="pricing-dropdown-container col d-flex align-items-center"> 
//                                           <FormControl  className='pricing-dropdown-icon col'  >
//                                             {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
//                                             <Select
//                                               labelId="demo-select-small-label"
//                                               id="demo-select-small-item"
//                                               defaultValue={item.cost_type === undefined? "" : item.cost_type}
//                                               label="Age"
//                                               onChange={(e)=> {dispatch(updateItemData({item :  {...item, cost_type :e.target.value}}))}}
//                                               IconComponent={()=>null}
                                              
//                                             >
//                                               <MenuItem value="">
//                                                 <em></em>
//                                               </MenuItem>
//                                               <MenuItem value={1}><img  className='ui avatar image  ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={pcIcon}/></MenuItem>
//                                               <MenuItem value={2}><img  className='ui avatar image unique-icon ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={exIcon}/></MenuItem>
//                                               {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
//                                               <MenuItem value={3}> -- </MenuItem>
//                                               {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
//                                               {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
//                                             </Select>
//                                           </FormControl>
//                                         </div>


//                                         <div className="pricing-dropdown-container col d-flex align-items-center"> 
//                                           <FormControl  className='pricing-dropdown-icon col'  >
//                                             {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
//                                             <Select
//                                               labelId="demo-select-small-label"
//                                               id="demo-select-small-item"
//                                               defaultValue={item.hammer === undefined? "" : item.hammer}
//                                               label="Age"
//                                               onChange={(e)=> {dispatch(updateItemData({item :  {...item, hammer :e.target.value}}))}}
//                                               IconComponent={()=>null}
                                              
//                                             >
//                                               <MenuItem value="">
//                                                 <em></em>
//                                               </MenuItem>
//                                               <MenuItem value={1}><img  className='ui avatar image pricing-icon ' style={{height : "15px", width: "16px",background: "#EFEFEF", border:"none"}} src={gavelIcon}/></MenuItem>
//                                               <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
//                                               {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
//                                               <MenuItem value={3}> || </MenuItem>
//                                               {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
//                                               {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
//                                             </Select>
//                                           </FormControl>
//                                         </div>
//                                         <div className="pricing-dropdown-container col d-flex align-items-center"> 
//                                           <FormControl  className='pricing-dropdown-icon col'   >
//                                             {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
//                                             <Select
//                                               labelId="demo-select-small-label"
//                                               id="demo-select-small-item"
//                                               defaultValue={item.suppiler === undefined? "" : item.suppiler}
//                                               label="Age"
//                                               onChange={(e)=> {dispatch(updateItemData({item :  {...item, suppiler :e.target.value}}))}}
//                                               IconComponent={()=>null}
                                             
//                                             >
//                                               <MenuItem value="">
//                                                 <em></em>
//                                               </MenuItem>
//                                               <MenuItem value={1}  className="pricing-icon-container"><img  className='ui avatar image  unique-icon' style={{height : "11px", width: "15px",background: "#EFEFEF", border:"none"}} src={blueTruck}/></MenuItem>
//                                               {/* <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem> */}
//                                               {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
//                                               <MenuItem value={3}> -- </MenuItem>
//                                               {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
//                                               {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
//                                             </Select>
//                                           </FormControl>
//                                         </div>

                                    


//                               {/* <img src={greenEllipseIcon} className="col" alt='settingsIcon'/>
//                               <img src={pcIcon} className="col" alt='hammerIcon'/>
//                               <img src={gavelIcon} className="col" alt='whiteTruckIcon'/> */}
//                               {/* <img  className='col bg-white' style={{width: "16px",height : "16px"}}/> */}
//                               {/* <img src={blueTruckIcon} className="col" alt='whiteTruckIcon'/> */}
//                               {/* <select  className='labour-select-input w-25 m-0 p-0' >
//                                       <option value="Prime">IC2</option>
//                                       <option value="Provitional"><img src={blueTruckIcon}></img></option>
//                                       <option value="Excluded">Excluded</option>
//                                   </select> */}

//                               <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
//                               <img  className='col ' style={{width: "24px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
//                               <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
//                               {/* {show?
//                                 <img src={treeSwichterOpenIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>

//                               :<img src={treeSwichterCloseIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>
//                             } */}
//                                                                       {/* <input className="form-check-input m-1 " type="checkbox" ></input> */}
//                               {isCurrentChecked(item._id , billOfQuantities.TYPES.ITEM)?
//                                     <input className="form-check-input pricing-checkbox m-1 align-items-center" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}}  defaultChecked></input>   
//                                   :
                                  
//                                   <input className="form-check-input pricing-checkbox m-1 align-items-center " type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}} ></input>   
//                                 } 
//                                 {}   

//               </div>
//               <div style={{ width: 120}}>
//                   {/* <ReactSearchAutocomplete
//                     onFocus={handleOnFocus}
//                     placeholder={item.name}
//                     fuseOptions={{keys : ["name","type"]}}
//                     items={pricingListCurrent.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.ITEM)} 
//                     showIcon= {false}
//                     onSelect = {(event)=>{dispatch(updateItemData({item : {...item, name : event.name, type : event.type}}))}}
//                     styling={{
//                       height: "34px",
//                       border: "none",
//                       // border: "1px solid darkgreen",
//                       borderRadius: "4px",
//                       backgroundColor: "#EFEFEF",
//                       boxShadow: "none",
//                       hoverBackgroundColor: "lightgreen",
//                       color: "black",
//                       // fontSize: "12px",
//                       // fontFamily: "Courier",
//                       iconColor: "green",
//                       lineColor: "lightgreen",
//                       placeholderColor: "darkgreen",
//                       clearIconMargin: "3px 8px 0 0",
//                       zIndex :"auto",
//                       // zIndex: {labourZIndex},
//                     }}
//                   /> */}

// <CreatableSelect

// onChange= {(event)=>{
// const selectedValue = { value: event, label: event.name };
// setSelectedOption(selectedValue);
// dispatch(updateItemData({item : {...item, name : event.name, type : event.type}}))}}
// options={pricingListCurrentLatest.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.ITEM)}  
// value= {selectedOption}
// /> 
//               </div>
//       </div>
//   </div> 
  

//   <div className="col-9">
//               <div className="row row-cols-10">
                              
//                                                                               {/*--------- Item Name for Proposal Column---------- */}

//                                                                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_NAME_FOR_PROPOSAL))?
//                                   <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.item_names_for_proposal}  onBlur={(e)=>{
                                                            
//                                     dispatch(updateItem({item :{...item, item_names_for_proposal: e.target.value}}))
//                                 }}  /></div>
//                               :null}                                                              

                              
//                               {/*--------- ITEM_TYPE Column---------- */}
//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_TYPE))?
//                                 <div className='col justify-content-start'>
//                                       <select className='w-100 section-input' defaultValue={item.item_type} onBlur={(e)=>{
//                                          // dispatch(updateItem({...item, status :e.target.value}))
//                                           if (e.target.value === billOfQuantities.ITEM_TYPES.STAFF || e.target.value === billOfQuantities.ITEM_TYPES.MATHLAB || e.target.value === billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || e.target.value === billOfQuantities.ITEM_TYPES.SUB_MATHLAB)
//                                             {
//                                               // let itemObject = item
//                                               // delete itemObject.labour_id
                                              

//                                               dispatch(updateItem({...item, item_type :e.target.value, specification : billOfQuantities.TYPES.SPECIFIED}))
//                                               dispatch(setIdArray())
//                                             }
//                                           else {
//                                             dispatch(updateItem({...item, item_type :e.target.value}))
//                                           }
                                        
//                                          }} >
//                                              <option value="Assembly (Global)">Assembly (Global)</option>
//                                              <option value="Equipment">Equipment</option>
//                                              <option value="Material">Material</option>
//                                              <option value="MathLab">MatLab</option>
//                                              <option value="Other">Other</option>
//                                              <option value="Staff">Staff</option>
//                                              <option value="Sub(Labour only)">Sub(Labour only)</option>
//                                              <option value="Sub(Material only)">Sub(Material only)</option>
//                                              <option value="Sub(MathLab)">Sub(MatLab)</option>
//                                          </select>

//                                 </div>
//                                                 :null}

                                                          

                                                        


//                               {/*---------Parent Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PARENT))?
//                                    <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.parent}  onBlur={(e)=>{
                                                            
//                                     dispatch(updateItem({item :{...item, parent: e.target.value}}))
//                                 }}  /></div>
//                               :null}                                                              


//                               {/*---------Cost Code Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_CODE))?
//                                    <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.cost_code}  onBlur={(e)=>{
                                                            
//                                     dispatch(updateItem({item :{...item, cost_code: e.target.value}}))
//                                 }}  /></div>
//                               :null}
                              
//                                 {/*--------- Units exc Wastage Column---------- */}

//                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_EX_WASTAGE))?
//                                 <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.unit_ex_wastage}  onBlur={(e)=>{
                                                            
//                                   dispatch(updateItem({item :{...item, unit_ex_wastage: e.target.value}}))
//                               }}  /></div>
//                                 :null}

                              
//                                 {/*--------- UOM Column---------- */}


//                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
//                                   <div className='col justify-content-start'>
//                                         <select className='w-100 section-input' defaultValue={item.uom} onBlur={(e)=>{
//                                          // dispatch(updateItem({...item, status :e.target.value}))
//                                          dispatch(updateItem({item : {...item, uom :e.target.value}}))
                                    
//                                          }} >
//                                              <option value="Ea">Ea</option>
//                                              <option value="M2"><p>M<sup>2</sup></p></option>
//                                              <option value="M3">M<sup>3</sup></option>
//                                              <option value="Hrs">Hrs</option>
//                                              <option value="Hrs">Days</option>
//                                              <option value="Im">Im</option>
//                                              <option value="Kg">Kg</option>
                                             
//                                          </select>
//                                   </div>
//                                 :null}

                              

//                                 {/*--------- Wastage % Column---------- */}



                                
//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_PERCENTAGE))?
//                                 <div className='col justify-content-start'><span class="currency-input">
//                                <input  type='text' className='section-input ' defaultValue={item.wastage_percentage}  onBlur={(e)=>{
                                                            
//                                                             dispatch(updateItem({item :{...item, wastage_percentage: e.target.value}}))
//                                                         }}   /><p>%</p> </span>
//                                   </div>
//                                 :null}



//                              {/*--------- Wastage Value---------- */}




                                
//                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_VALUE))?
//                                   <p className="col"> { calculateWastageValue(item)} </p>
//                               :null} 



//                             {/*--------- Units inc Wastage---------- */}


                                
//                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_INC_WASTAGE))?
//                                   <p className="col"> { calculateUnitsIncWastage(item)} </p>
//                               :null} 


                              
//                                 {/*--------- BUY Unit $ Column---------- */}

//                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.BUY_UNIT_PRICE))?
//                                 <div className='col justify-content-start'><span class="currency-input"><p>$</p><input  type='text' className='section-input ' defaultValue={item.buy_unit_price}  onBlur={(e)=>{
                                                            
//                                   dispatch(updateItem({item :{...item, buy_unit_price: e.target.value}}))
//                               }}  /></span></div>
//                                 :null}



//                                 {/*--------- Sell Unit $ Column---------- */}

                              
//                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_UNIT_PRICE))?
//                                   <div className='col justify-content-start'><span class="currency-input"><p>$</p><input  type='text' className='section-input ' defaultValue={item.sell_unit_price}  onBlur={(e)=>{
                                                            
//                                     dispatch(updateItem({item :{...item, sell_unit_price: e.target.value}}))
//                                 }}  /></span></div>
//                                 :null}


//                                     {/*--------- Sell Adjestment Unit Value---------- */}


                                
//                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_ADJ_UNIT_VALUE))?
//                                   <p className="col"> { calculateSellAdjesmentUnitValue(item)} </p>
//                               :null} 
                                                          


//                               {/*---------Supplier Inc Sales Tax Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_INC_SALES_TAX))?
//                                   <div className='col justify-content-start'>
//                                   <select className='w-100 section-input' defaultValue={item.supplier_inc_sales_tax} onBlur={(e)=>{
//                                   // dispatch(updateItem({...item, status :e.target.value}))
//                                   dispatch(updateItem({item : {...item, supplier_inc_sales_tax :e.target.value}}))
                             
//                                   }} >
//                                       <option value="Yes">Yes</option>
//                                       <option value="No">No</option>
                                     
                                      
//                                   </select>
//                              </div>
//                               :null}                                                              


//                               {/*--------- Supplier Item Code Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_ITEM_CODE))?
//                                   <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.supplier_item_code}  onBlur={(e)=>{
                                                            
//                                     dispatch(updateItem({item :{...item, supplier_item_code: e.target.value}}))
//                                 }}  /></div>
//                               :null}
                              



//                                {/*--------- Total Sell Price Of Units---------- */}


                                
//                              {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TOTAL_SELL_PRICE_OF_UNITS))?
//                              <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateTotalSellPriceOfUnitsValue(item)}</span>
//                              </span> </div>
//                                   // <p className="col"> { calculateTotalSellPriceOfUnitsValue(item)} </p>
//                               :null} 
                                 
                              
//                                 {/*--------- Contingency % Column---------- */}


//                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_PERCENTAGE))?
//                                 <div className='col justify-content-start'><span class="currency-input">
//                                <input  type='text' className='section-input ' defaultValue={item.contingency_percentage}  onBlur={(e)=>{
                                                            
//                                                             dispatch(updateItem({item :{...item, contingency_percentage: e.target.value}}))
//                                                         }} /><p>%</p> </span>
//                                   </div>
//                                 :null}


                              
//                               {/*--------- Contingency Value Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_VALUE))?
//                               <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateContingency(item)}</span>
//                               </span> </div>
//                                   // <p className="col"> {calculateContingency(item)}  </p>
//                               :null}   

                              
//                                 {/*--------- Markup percentage % Column---------- */}


//                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_PERCENTAGE))?
//                                   <div className='col justify-content-start'><span class="currency-input">
//                                   <input  type='number' className='section-input' defaultValue={item.markup_percentage}  onBlur={(e)=>{
                                                            
//                                                             dispatch(updateItem({item :{...item, markup_percentage: e.target.value}}))
//                                                         }} /><p>%</p> </span>
//                                    </div>

//                                 :null}
                              
//                               {/*--------- Markup Value Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_VALUE))?
//                                   <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateMarkupValue(item)}</span>
//                                   </span> </div>
//                                   // <p className="col"> {calculateMarkupValue(item)}  </p>
//                               :null}                                                                


//                               {/*--------- Client Sales Tax Percentage  Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_PERCENTAGE))?
//                                   <div className='col justify-content-start'><span class="currency-input">
//                                  <input  type='number' className='section-input ' defaultValue={item.client_sales_tax_percentage}  onBlur={(e)=>{
                                                            
//                                                             dispatch(updateItem({item :{...item, client_sales_tax_percentage: e.target.value}}))
//                                                         }}  /><p>%</p> </span></div>
//                               :null}                                                              


//                               {/*--------- Client Sales Tax Value  Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_VALUE))?
//                                   <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateClientSalesTax(item)}</span>
//                                   </span> </div>
//                                   // <p className="col"> {calculateClientSalesTax(item)}  </p>
//                               :null}
                              


                              
//                                 {/*--------- Client Charge Inc Column---------- */}

//                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE_INC))?
//                                     <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateClientCharge(item)}</span>
//                                     </span> </div>
//                                   // <div className='col justify-content-start'>{calculateClientCharge(item)}</div>
//                                 :null}
                                                          


//                               {/*--------- Overhead  Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.OVERHEAD))?
//                                   <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateOverheads(item)}</span>
//                                   </span> </div>
//                                   // <p className="col"> {calculateOverheads(item)}  </p>
//                               :null}                                                              


//                               {/*--------- Margin  Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
//                                     <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateMargin(item)}</span>
//                                     </span> </div>
//                                   // <p className="col"> {calculateMargin(item)}  </p>
//                               :null}

//                             {/*--------- GMP Buffer %   Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_PERCENTAGE))?
//                                         <div className='col justify-content-start'><span class="currency-input">
//                                         <input  type='number' className='section-input' defaultValue={item.gmp_percentage}  onBlur={(e)=>{
                                                                
//                                                                 dispatch(updateItem({item :{...item, gmp_percentage: e.target.value}}))
//                                                             }} /><p>%</p> </span>
                                        
//                                         </div>

                                  
                                  

//                               :null}                                                              
//                               {/*--------- GMP Buffer V   Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_VALUE))?
//                               <div className='col justify-content-start'><span class="section-input-container"><p>$</p><span >{calculateGMPBuffer(item)}</span>
//                               </span></div>
                                    
//                               :null}                                                                  


//                               {/*--------- Supplier Contact Id  Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_CONTACT_ID))?
//                                    <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.suppiler}  onBlur={(e)=>{
                                                            
//                                     dispatch(updateItem({item :{...item, suppiler: e.target.value}}))
//                                 }} /></div>
//                               :null}                                                              


//                               {/*--------- Contractor Item Note  Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTRACTOR_ITEM_NOTE))?
//                                    <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.contractor_item_note}  onBlur={(e)=>{
                                                            
//                                     dispatch(updateItem({item :{...item, contractor_item_note: e.target.value}}))
//                                 }} /></div>
//                               :null}                                                              


//                               {/*--------- Tender Item Note  Column---------- */}

//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TENDER_ITEM_NOTE))?
//                                     <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.tender_item_note}  onBlur={(e)=>{
                                                            
//                                       dispatch(updateItem({item :{...item, tender_item_note: e.target.value}}))
//                                   }} /></div>
//                               :null}  

//                               {/*--------- Pricinglist ? Column---------- */}

                              
//                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.IS_PRICELIST_ITEM))?
//                                   <div className='col justify-content-start'>
//                                          <select className='w-100 section-input' defaultValue={item.is_pricelist_item} onBlur={(e)=>{
//                                          // dispatch(updateItem({...item, status :e.target.value}))
//                                          dispatch(updateItem({item : {...item, is_pricelist_item :e.target.value}}))
                                    
//                                          }} >
//                                              <option value="Yes">Yes</option>
//                                              <option value="No">No</option>
                                            
                                             
//                                          </select>
//                                     </div>
//                                 :null}


//                               {/*--------- NESTED  Column---------- */}

//   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.NESTED))?
//  //     <div className='col-1'> 
//  //     <input type="text" defaultValue={item.type} className='section-input form-control m-0 p-0'  onBlur={(e)=>{
//  //     // dispatch(updateItem({...item, type :e.target.value}))
//  //     dispatch(updateItemData({item :{...item, type :e.target.value}}))
  
//  //     }} ></input>
//  // </div>
//    <div className="col-1">
//    <select
//      className="section-input form-select m-0 p-0"
//      defaultValue={item.specification}
//      onChange={(e) => {
//        let itemObject = {...item}
//        // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
//        if(e.target.selectedOptions[0].getAttribute("specification") ===billOfQuantities.TYPES.SPECIFIED && e.target.selectedOptions[0].getAttribute("labourid"))
//        {
//          console.log("under current labour",e.target.selectedOptions[0].getAttribute("labourid"))
      
//          dispatch(updateItem({...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}))
//        }
//        // else if (e.target.selectedOptions[0].getAttribute("specialism") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){
//        //   console.log("new labour",e.target.value)
//        //   delete itemObject.labour_id
//        //   dispatch(updateItemData({item :{...itemObject, specialism :e.target.selectedOptions[0].getAttribute("specialism")}}))
//        // }
//        else if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.UNSPECIFIED){
//          delete itemObject.labour_id
      
//          dispatch(updateItem({...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}))

//        }
//        dispatch(setIdArray())
//        // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
//                             }}
//    > 
   
//  {laboursId[section._id].map((itemId)=>{
//    let currentLabour = itemRequestData.find((i)=>i._id === itemId)
//      if((currentLabour.item_type === billOfQuantities.ITEM_TYPES.MATHLAB || currentLabour.item_type === billOfQuantities.ITEM_TYPES.STAFF || currentLabour.item_type === billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY ||currentLabour.item_type === billOfQuantities.ITEM_TYPES.SUB_MATHLAB) && currentLabour.section_id === section._id && currentLabour.specification === billOfQuantities.TYPES.SPECIFIED)
//      return <option key={currentLabour._id} value={currentLabour.name} labourid={currentLabour._id}
//      specification={billOfQuantities.TYPES.SPECIFIED} 
//      >{currentLabour.name}</option>
//    })}

  

//      <hr className="dropdown-divider"/>
//      {/* <option className="m-1" value={billOfQuantities.TYPES.LABOUR} 
//      specialism={billOfQuantities.TYPES.LABOUR} 
//      >Add New Labour</option> */}
//      <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option>
//      {/* <option value="Provitional">Provitional</option>
//      <option value="Excluded">Excluded</option> */}
//    </select>
//    </div>
//  :null}

//                               <div className='view-pricing-user-filter-icon'>

//                                   <img  src={threeDotIcon}></img>
//                               </div>
//               </div>

//       </div>

// </div>

// }

    
  return (
    <>

<section className='section-container' id="accordian">
        
<SectionColumns/>
        { show &&itemRequestLoading  ?
           
           <div className="spinner-border" style={{"width": "2rem", "height": "2rem","marginLeft":"2vw"}} role="status">
               {/* <span className="sr-only">Loading...</span> */}
           </div>                      
         
        :  show &&!itemRequestLoading   ? 
        <>
             <Labours/>
             <Unspecified/>
             
             <div className='row '>
               <div className='col-3 d-flex'>
                   <div className="section-container-labour-name  ">
                   {/* <button className='btn btn-outline-secondary btn-light' data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"> Filters <div className="dropdown-arrow"></div></button> */}
                   
                     <AddIcon  data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"  onClick={()=>{
                       // dispatch(changeLabourModalStatus(section._id))
                       // dispatch(createLabour({sectionId : section._id}))
                   // dispatch(createItem({ sectionId : section._id , type : billOfQuantities.TYPES.LABOUR}))
 
                       
                       }}  />
                       <ul className="dropdown-menu">
                         {/* {
                           pricingListRequestData.map((addUp)=>{
                             if(addUp.type === billOfQuantities.TYPES.LABOUR && !pricingListRequestLoading){
                               return (
                                 <li onClick={()=>{ dispatch(createItem({ name :addUp.name,sectionId : section._id , type : billOfQuantities.TYPES.LABOUR}))}}><a className="dropdown-item" >{addUp.name}</a></li>
                               )
                             }
                           })
                         }
                         <li><hr className="dropdown-divider"/></li> */}
                         <li onClick={()=>{ dispatch(changeAddUpModalStatus({sectionId : section._id, labourSpecification : billOfQuantities.TYPES.SPECIFIED, itemSpecification : billOfQuantities.TYPES.UNSPECIFIED}))}}><a className="dropdown-item" >New labour/Item</a></li>
                       </ul>
 
                     </div>
                     <div  style={{ width: 400}}>
                                     <ReactSearchAutocomplete
                                       placeholder={"Add Item"}
                                       fuseOptions={{keys : ["name","type"]}}
                                       items={pricingListCurrent.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.LABOUR || addUp.type === billOfQuantities.TYPES.ITEM)} 
                                       showIcon= {false}
                                       formatResult ={formatResult}
                                       onSelect = {(event)=>{
                                         if(event.type ===billOfQuantities.TYPES.LABOUR){
 
                                           dispatch(createItem({item : { name : event.name, sectionId : section._id, type : event.type , specification : billOfQuantities.TYPES.SPECIFIED, uom : event.uom, sell_unit_price : event.sell_unit_price, buy_unit_price: event.buy_unit_price, item_type : event.item_type}}))
                                         }
                                         else if (event.type === billOfQuantities.TYPES.ITEM){
                                           dispatch(createItem({item : { name : event.name, sectionId : section._id , type : event.type, specification : billOfQuantities.TYPES.UNSPECIFIED, uom : event.uom, sell_unit_price : event.sell_unit_price, buy_unit_price: event.buy_unit_price, item_type : event.item_type}}))
                                         }
                                       }}
                                       styling={{
                                         height: "34px",
                                         border: "none",
                                         // border: "1px solid darkgreen",
                                         borderRadius: "4px",
                                         backgroundColor: "#EFEFEF",
                                         boxShadow: "none",
                                         hoverBackgroundColor: "lightgreen",
                                         color: "black",
                                         // fontSize: "12px",
                                         // fontFamily: "Courier",
                                         iconColor: "green",
                                         lineColor: "lightgreen",
                                         placeholderColor: "#002D62",
                                         clearIconMargin: "3px 8px 0 0",
                                         zIndex : "auto",
                                         // zIndex: {labourZIndex},
                                       }}
                                     />
                     </div>
                     {/* <Dropdown
                          placeholder='Select Friend'
                          fluid
                          selection
                          options={friendOptions}
                        /> */}








                                      
               </div>
             </div>
             
             
        {/* <DisplayItems/>  */}
        </>
        : null }

 
        
        {show? 
         <>
         
         </>: null}
        
        
     
        
                
    

       
        
     </section>












 </>














   
    
  )
}















export default memo(Section)





