import React from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import { updateContacts } from '../../features/ContactSlice'
import './ViewContacts.css'
const ViewContacts = () => {

    const contactRequestData = useSelector((state)=>state.contact.contactRequestData)
    const dispatch = useDispatch()
    const contactJSON = [
        {
            "_id": "641ff6f666d9f203194c684b",
            "name": "Tharuka",
            "contact_type": "client",
            "email_address": "tharuka@gmail.com",
            "first_name": "tharuka",
            "specialism": "specialism",
            "home_address": "home_address",
            "mailing_address": "mailing_address",
            "landline_telephone": "landline_telephone",
            "mobile_telephone": "mobile_telephone",
            "title": "title",
            "role": "role",
            "rating": "rating",
            "account_reset_questions": "account_reset_questions",
            "other_MFA_data": "other_MFA_data",
            "permitted_system_user": "permitted_system_user",
            "budget": "budget",
            "past_projects": "past_projects",
            "roles": "roles",
            "reviews": "reviews",
            "__v": 0
        }
    ]
    const DisplayContacts = ()=>{
        return contactRequestData.map((contact)=>{
            return (
                <div key={contact._id} className="contact-inner-container row p-2">
                      <div className='col-1'>
                        <input defaultValue={contact.title} className="form-control contact-input m-0 p-0" onBlur={(e)=>{
                            dispatch(updateContacts({contactData : {...contact, title : e.target.value}}))

                        }}></input>
                    </div>
                    <div className='col-1'>
                        <input defaultValue={contact.first_name} className="form-control contact-input m-0 p-0" onBlur={(e)=>{
                            dispatch(updateContacts({contactData : {...contact, first_name : e.target.value}}))
                        }}></input>
                        
                    </div>
                    <div className='col-1'>
                        <input defaultValue={contact.last_name} className="form-control contact-input m-0 p-0" onBlur={(e)=>{
                            dispatch(updateContacts({contactData : {...contact, last_name : e.target.value}}))
                        }}></input>
                    </div>
                    <div className='col-1'>
                        <input defaultValue={contact.contact_type} className="form-control contact-input m-0 p-0" onBlur={(e)=>{
                            dispatch(updateContacts({contactData : {...contact, contact_type : e.target.value}}))
                        }}></input>
                    </div>
                    <div className='col-1'>
                        <input defaultValue={contact.specialism} className="form-control contact-input m-0 p-0" onBlur={(e)=>{
                            dispatch(updateContacts({contactData : {...contact, specialism : e.target.value}}))
                        }}></input>
                    </div>
                    <div className='col-1'>
                        <input defaultValue={contact.email_address} className="form-control contact-input m-0 p-0" onBlur={(e)=>{
                            dispatch(updateContacts({contactData : {...contact, email_address : e.target.value}}))
                        }}></input>
                    </div>
                  

                    <div className='col-1'>
                        <input defaultValue={contact.company_id} className="form-control contact-input m-0 p-0" onBlur={(e)=>{
                            dispatch(updateContacts({contactData : {...contact, company_id : e.target.value}}))
                        }}></input>
                    </div>
                    <div className='col-1'>
                        <input defaultValue={contact.mailing_address} className="form-control contact-input m-0 p-0" onBlur={(e)=>{
                            dispatch(updateContacts({contactData : {...contact, mailing_address : e.target.value}}))
                        }}></input>
                    </div>
                    <div className='col-1'>
                        <input defaultValue={contact.home_address} className="form-control contact-input m-0 p-0" onBlur={(e)=>{
                            dispatch(updateContacts({contactData : {...contact, home_address : e.target.value}}))
                        }}></input>
                    </div>
                    <div className='col-1'>
                        <input defaultValue={contact.mobile_tel_number} className="form-control contact-input m-0 p-0" onBlur={(e)=>{
                            dispatch(updateContacts({contactData : {...contact, mobile_tel_number : e.target.value}}))
                        }}></input>
                    </div>
                    <div className='col-1'>
                        <input defaultValue={contact.land_tel_number} className="form-control contact-input m-0 p-0" onBlur={(e)=>{
                            dispatch(updateContacts({contactData : {...contact, land_tel_number : e.target.value}}))
                        }}></input>
                    </div>
                    <div className='col-1'>
                        <input defaultValue={contact.reviews} className="form-control contact-input m-0 p-0" onBlur={(e)=>{
                            dispatch(updateContacts({contactData : {...contact, reviews : e.target.value}}))
                        }}></input>
                    </div>
                </div>
            )
        })
    }
  return (
    <div>
        <div className='row'>
                    <div className='col-1'>
                        <h6>Title </h6>
                    </div>
                    <div className='col-1'>
                        <h6>First Name</h6>
                    </div>
                    <div className='col-1'>
                        <h6>Last Name </h6>
                    </div>
                    <div className='col-1'>
                        <h6>Contact type</h6>
                    </div>
                    <div className='col-1'>
                        <h6>Specialism</h6>
                    </div>
                    <div className='col-1'>
                        <h6>Email </h6>
                    </div>
                    <div className='col-1'>
                        <h6>Company Id </h6>
                    </div>
                    <div className='col-1'>
                        <h6>Mailing address </h6>
                    </div>
                    <div className='col-1'>
                        <h6>Home address </h6>
                    </div>
                    <div className='col-1'>
                        <h6>Mobile No </h6>
                    </div>
                    <div className='col-1'>
                        <h6>Landline No </h6>
                    </div>
                    <div className='col-1'>
                        <h6>reviews </h6>
                    </div>
                    
        </div>
            <DisplayContacts/>
    </div>
  )
}

export default ViewContacts