import React, { useEffect, useState } from 'react'
import { billOfQuantities } from '../../constants/Constants'
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { addToCheckedList, setIdArray, updateItem, updateItemData, updatedEstimateIdDataOnBackend } from '../../features/PricingSlice';
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import CreatableSelect from 'react-select/creatable';
import './Item.css' 


//Icon 


import greenEllipseIcon from '../../assets/icons/green_Ellipse_11.png'
import yellowEllipseIcon from '../../assets/icons/yellow-ellipse-icon.png'
import redEllipseIcon from '../../assets/icons/red-ellipse-icon.png'
import blueEllipseIcon from '../../assets/icons/blue-ellipse-icon.png'
import exIcon from '../../assets/icons/EX-icon.png'


  // Image imports
  import threeDotIcon from "../../assets/icons/three-dot.png"
  import dragIcon from "../../assets/icons/material-symbols_drag-indicator-sharp.png"
  // import greenEllipseIcon from "../../assets/icons/green_Ellipse_11.png"
  import pcIcon from "../../assets/icons/PC.png"
  import gavelIcon from "../../assets/icons/clarity_gavel-solid.png"
  import treeSwichterOpenIcon from "../../assets/icons/Tree-Switcher-open.png"
  import treeSwichterCloseIcon from "../../assets/icons/Tree-Switcher-Close.png"
  import transparentIcon from "../../assets/icons/transparent-icon.png"
  import blueTruck from "../../assets/icons/mdi_truck.png"
import { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';

const Item = ({index,itemId , labourId, overheadsPercentage,pricingListCurrent,pricingListCurrentLatest}) => {

    const dispatch = useDispatch()
    const checkedDataObject = useSelector((state)=>state.pricing.checkedDataObject)
    const userViewFilters = useSelector((state)=>state.pricing.userViewFilterRequestData)
    
    // const updateItemRequestLoading = useSelector((state)=> state.pricing.updateItemRequestLoading)
    const itemRequestData = useSelector((state)=>state.pricing.itemRequestData)
    const laboursId = useSelector((state)=>state.pricing.laboursId)
    const projectRequestData = useSelector((state)=>state.pricing.projectRequestData)

    const item = itemRequestData.find((i)=>i._id === itemId)
    const labour = itemRequestData.find((i)=>i._id ===labourId)
    const initialValue = { value: item, label: item.name };
    const [selectedOption, setSelectedOption] = useState(initialValue);
    // const [first, setFirst] = useState("")

    // useEffect(() => {
    //   console.log("WWW",first)
    
     
    // }, [])
    // const updatedEstimateIdData = useSelector((state)=>state.pricing.updatedEstimateIdData)

   
    // useEffect(() => {
    //     // console.log("Render | Items", item.name, item.unit_ex_wastage,updateItemRequestLoading)
    //     // console.log("III", item.name, item.unit_ex_wastage,updateItemRequestLoading)
    //     console.log("III", updatedEstimateIdData)
    // }, [updatedEstimateIdData])
    
 
    const updateEstimateData = (event)=>{
      if(event.key === "Enter"){
        
        dispatch(updatedEstimateIdDataOnBackend())
      }
    }
    

    const isCurrentChecked = (id,type)=>{
        // if(type === billOfQuantities.TYPES.ITEM && checkedDataObject.items.includes(id)){
        //     return true
        // }
        // if(type === billOfQuantities.TYPES.LABOUR && checkedDataObject.labours.includes(id)){
        //     return true
        // }
        if(type === billOfQuantities.TYPES.SECTION && checkedDataObject.sections.includes(id)){
            return true
        }
        else if (type !==billOfQuantities.TYPES.SECTION && checkedDataObject.items.includes(id)){
          return true
        }
       
      }



      const formatResult= (item)=>{
    
        return(
          <div className="result-wrapper">
              <span className="result-span">{item.name} : {item.type}</span>
          </div>
        )
      }




       // new calculations - 5 

  const calculateWastageValue = (item)=>{

    // console.log(calculateUnitsIncWastage(item), " - ",item.unit_ex_wastage, " = " ,calculateUnitsIncWastage(item) - parseFloat(item.unit_ex_wastage))
      return Math.round(calculateUnitsIncWastage(item) - item.unit_ex_wastage *100)/100
      // return Math.floor((calculateUnitsIncWastage(item) - item.unit_ex_wastage )* 100)/100
  }

  const calculateUnitsIncWastage = (item)=>{
    // return Math.floor( (item.unit_ex_wastage * (1 + (item.wastage_percentage/100))) * 100)/100
    return Math.round(item.unit_ex_wastage * (1 + (item.wastage_percentage/100)) * 100)/100
  }

  const calculateSellAdjesmentUnitValue = (item)=>{
    if (item.sell_unit_price>item.buy_unit_price){
       
      // return Math.floor( (  item.sell_unit_price - item.buy_unit_price  ) * 100)/100
      return Math.round(  (item.sell_unit_price - item.buy_unit_price) *100  )/100
    }
    else if (item.sell_unit_price > 0){
     
      return Math.round(  (item.sell_unit_price - item.buy_unit_price) *100 )/100

    }
    else {
      return 0
    }
  }

  const calculateTotalSellPriceOfUnitsValue = (item)=>{
    if(item.sell_unit_price > 0){
       return Math.round((item.sell_unit_price * parseFloat(calculateUnitsIncWastage(item))) * 100)/100
      //  return Math.floor( (  item.sell_unit_price * parseFloat(calculateUnitsIncWastage(item))  ) * 100)/100

    }
    else {
      return Math.round((item.buy_unit_price * parseFloat(calculateUnitsIncWastage(item))) * 100)/100
      // return Math.floor( (  item.buy_unit_price * parseFloat(calculateUnitsIncWastage(item))  ) * 100)/100

    }

  }

  const calculatePreMarkupValue =  (item) => {
    return  Math.round( (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) *(item.pre_markup_percentage/100)) * 100)/ 100
    // return Math.floor( ( parseFloat(calculateTotalSellPriceOfUnitsValue(item)) *(item.pre_markup_percentage/100)  ) * 100)/100

    
  }



  






  const calculateCostOfUnits = (item)=> {
    let costOfUnits = (item.unit_ex_wastage * (item.cost_per_unit*(1 + item.wastage_percentage/100)))
    // if(item._id === "6455d407c9b5d4f7613360a6"){
    //   console.log(typeof item.wastage_percentage)
    //   console.log(1 + item.wastage_percentage/100)
    //   console.log((item.cost_per_unit*(1 + item.wastage_percentage/100)).toFixed(2))
    //   console.log("costOfUnits  : ",item.name," - ",costOfUnits.toFixed(2))
    // }
    return Math.round(costOfUnits * 100)/100
    // return Math.floor( (  costOfUnits  ) * 100)/100


  }

  const calculateContingency = (item)=>{
    let contingencyValue = (calculateTotalSellPriceOfUnitsValue(item)*item.contingency_percentage/100)
    //  if(item._id === "6455d407c9b5d4f7613360a6"){
    //   console.log(typeof item.contingency_percentage)
    //   // console.log(1 + item.wastage_percentage/100)
    //   // console.log((item.cost_per_unit*(1 + item.wastage_percentage/100)).toFixed(2))
    //   console.log("ContingencyValue  : ",item.name," - ",contingencyValue)
    // }
    return Math.round(contingencyValue *100)/100
    // return Math.floor( (  contingencyValue  ) * 100)/100
  }
  const calculateMarkupValue = (item)=>{
    let markupValue = ((parseFloat(calculateTotalSellPriceOfUnitsValue(item))+ parseFloat(calculateContingency(item)))*item.markup_percentage/100)
    //  if(item._id === "6455d407c9b5d4f7613360a6"){
    //   console.log(typeof item.contingency_percentage)
    //   console.log(calculateCostOfUnits(item)+calculateContingency(item))
    //   // console.log((item.cost_per_unit*(1 + item.wastage_percentage/100)).toFixed(2))
    //   console.log("Mark Up Value  : ",item.name," - ",markupValue)
    // }
    // return markupValue.toFixed(2)
    return Math.round(markupValue*100)/100
    // return Math.floor( (  markupValue  ) * 100)/100

  }


  const calculateClientSalesTax = (item) =>{
    let clientSalesTax = ((parseFloat(calculateTotalSellPriceOfUnitsValue(item))+parseFloat(calculateContingency(item))+parseFloat(calculateMarkupValue(item)))*item.client_sales_tax_percentage/100)

    // if(item._id === "6455d407c9b5d4f7613360a6"){
      
    //   console.log("JUst another",)
    //   console.log("Client Sales Tax", clientSalesTax)
    // }
    return Math.round(clientSalesTax)
  }


  const calculateClientCharge = (item)=>{
    // let clientCharge = (parseFloat(calculateCostOfUnits(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)) ).toFixed(2)
    // let clientCharge = Math.ceil((parseFloat(calculateCostOfUnits(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)) ))  
    let clientCharge = (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)))  
    // return Math.floor(clientCharge)
    return Math.ceil(clientCharge)
  }

  const calculateOverheads = (item) => {
    let overhead = ((  parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))  ) * overheadsPercentage/100)
    return Math.round(overhead * 100)/100
  }

  const calculateMargin = (item) => {
    let marginBeforeTax = (parseFloat(calculateClientCharge(item)) - ( parseFloat(calculateOverheads(item)) + parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateClientSalesTax(item)) ))
    return Math.round(marginBeforeTax * 100)/100
  }

  const calculateGMPBuffer = (item)=>{
    let gmpBuffer = (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) * (item.gmp_percentage/100))
    return Math.round(gmpBuffer * 100) /100
  }

      
  


    // const DisplayItems = ()=>{
    //     console.log("Render | Items ")
    //     return items.map((item)=>{
    //         if(item.labour_id === labour._id && item.type === billOfQuantities.TYPES.ITEM && item.specification === billOfQuantities.TYPES.SPECIFIED ){
             
    
    //            return(
                  
    //                 //  <div key={item.id} className={" multi-collapse   collapse row "+show+" "+section.name}> 
    //                 <div key={item._id} className="row m-0"> 
    //                     <div className="col-3 p-0">
    //                         <div className="row row-cols-auto view-pricing-icon-container">
    //                                     <div className="d-flex align-items-center" >
    
                                                            
    //                                                         <img src={dragIcon} className="col" alt='dragIcon'/>
    //                                                         <div className="pricing-dropdown-container col d-flex align-items-center"> 
    //                                                             <FormControl  className='pricing-dropdown-icon col' size="small">
    //                                                               {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
    
    //                                                               <Select
    //                                                                 labelId="demo-select-small-label"
    //                                                                 id="demo-select-small-item"
    //                                                                 defaultValue={item.status === undefined? "" : item.status}
    //                                                                 label="Age"
    //                                                                 onChange={(e)=> {dispatch(updateItemData({item :  {...item, status :e.target.value}}))}}
    //                                                                 IconComponent={()=>null}
                                                                    
    //                                                               >
    //                                                                 <MenuItem value="">
    //                                                                   <em></em>
    //                                                                 </MenuItem>
    //                                                                 <MenuItem value={1}><img  className='ui avatar image pricing-icon col' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={yellowEllipseIcon}/></MenuItem>
    //                                                                 <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={greenEllipseIcon}/></MenuItem>
    //                                                                 <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
    //                                                                 <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem>
    //                                                                 {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
    //                                                               </Select>
    //                                                             </FormControl>
    //                                                           </div>
    //                                                           <div className="pricing-dropdown-container col d-flex align-items-center"> 
    //                                                             <FormControl  className='pricing-dropdown-icon col'  >
    //                                                               {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
    //                                                               <Select
    //                                                                 labelId="demo-select-small-label"
    //                                                                 id="demo-select-small-item"
    //                                                                 defaultValue={item.cost_type === undefined? "" : item.cost_type}
    //                                                                 label="Age"
    //                                                                 onChange={(e)=> {dispatch(updateItemData({item :  {...item, cost_type :e.target.value}}))}}
    //                                                                 IconComponent={()=>null}
                                                                    
    //                                                               >
    //                                                                 <MenuItem value="">
    //                                                                   <em></em>
    //                                                                 </MenuItem>
    //                                                                 <MenuItem value={1}><img  className='ui avatar image  ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={pcIcon}/></MenuItem>
    //                                                                 <MenuItem value={2}><img  className='ui avatar image unique-icon ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={exIcon}/></MenuItem>
    //                                                                 {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
    //                                                                 <MenuItem value={3}> -- </MenuItem>
    //                                                                 {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
    //                                                                 {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
    //                                                               </Select>
    //                                                             </FormControl>
    //                                                           </div>
    
    
    //                                                           <div className="pricing-dropdown-container col d-flex align-items-center"> 
    //                                                             <FormControl  className='pricing-dropdown-icon col'  >
    //                                                               {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
    //                                                               <Select
    //                                                                 labelId="demo-select-small-label"
    //                                                                 id="demo-select-small-item"
    //                                                                 defaultValue={item.hammer === undefined? "" : item.hammer}
    //                                                                 label="Age"
    //                                                                 onChange={(e)=> {dispatch(updateItemData({item :  {...item, hammer :e.target.value}}))}}
    //                                                                 IconComponent={()=>null}
                                                                    
    //                                                               >
    //                                                                 <MenuItem value="">
    //                                                                   <em></em>
    //                                                                 </MenuItem>
    //                                                                 <MenuItem value={1}><img  className='ui avatar image pricing-icon ' style={{height : "15px", width: "16px",background: "#EFEFEF", border:"none"}} src={gavelIcon}/></MenuItem>
    //                                                                 <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
    //                                                                 {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
    //                                                                 <MenuItem value={3}> || </MenuItem>
    //                                                                 {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
    //                                                                 {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
    //                                                               </Select>
    //                                                             </FormControl>
    //                                                           </div>
    //                                                           <div className="pricing-dropdown-container col d-flex align-items-center"> 
    //                                                             <FormControl  className='pricing-dropdown-icon col'   >
    //                                                               {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
    //                                                               <Select
    //                                                                 labelId="demo-select-small-label"
    //                                                                 id="demo-select-small-item"
    //                                                                 defaultValue={item.suppiler === undefined? "" : item.suppiler}
    //                                                                 label="Age"
    //                                                                 onChange={(e)=> {dispatch(updateItemData({item :  {...item, suppiler :e.target.value}}))}}
    //                                                                 IconComponent={()=>null}
                                                                   
    //                                                               >
    //                                                                 <MenuItem value="">
    //                                                                   <em></em>
    //                                                                 </MenuItem>
    //                                                                 <MenuItem value={1}  className="pricing-icon-container"><img  className='ui avatar image  unique-icon' style={{height : "11px", width: "15px",background: "#EFEFEF", border:"none"}} src={blueTruck}/></MenuItem>
    //                                                                 {/* <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem> */}
    //                                                                 {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
    //                                                                 <MenuItem value={3}> -- </MenuItem>
    //                                                                 {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
    //                                                                 {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
    //                                                               </Select>
    //                                                             </FormControl>
    //                                                           </div>
    
                                                          
    
                                                                
    
    //                                                         {/* <img src={greenEllipseIcon} className="col" alt='settingsIcon'/> */}
    //                                                         {/* <img src={pcIcon} className="col" alt='hammerIcon'/> */}
    //                                                         {/* <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/> */}
    //                                                         <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
    //                                                         <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
    //                                                         <img  className='col ' style={{width: "24px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
    //                                                         <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
    //                                                         {/* <select  className='labour-select-input w-25 m-0 p-0' >
    //                                                                 <option value="Prime">IC2</option>
    //                                                                 <option value="Provitional"><img src={blueTruckIcon}></img></option>
    //                                                                 <option value="Excluded">Excluded</option>
    //                                                             </select> */}
    //                                                         {/* <img src={treeSwichterOpenIcon} className="col"  onClick={() => {setShow((prev) => !prev)}}  alt='whiteTruckIcon'/> */}
                        
    //                                                     {/* {show?
    //                                                       <img src={treeSwichterOpenIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>
    
    //                                                     :<img src={treeSwichterCloseIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>
    //                                                   } */}
    //                                                                                             {/* <input className="form-check-input m-1 " type="checkbox" ></input> */}
    //                                                     {isCurrentChecked(item._id , billOfQuantities.TYPES.ITEM)?
    //                                                           <input className="form-check-input pricing-checkbox m-1 align-items-center" type="checkbox"  onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}}  defaultChecked></input>   
    //                                                         :
                                                            
    //                                                         <input className="form-check-input pricing-checkbox m-1 align-items-center " type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}} ></input>   
    //                                                       }    
    
    //                                       </div>
    //                                       <div style={{ width: 120}}>
    //                                     <ReactSearchAutocomplete
    //                                       placeholder={item.name}
    //                                       formatResult = {formatResult}
    //                                       items={pricingListCurrent.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.ITEM)} 
    //                                       fuseOptions={{keys : ["name","type"]}}
    //                                       showIcon= {false}
    //                                       onSelect = {(event)=>{dispatch(updateItemData({item : {...item, name : event.name, type : event.type}}))}}
    //                                       styling={{
    //                                         height: "34px",
    //                                         border: "none",
    //                                         // border: "1px solid darkgreen",
    //                                         borderRadius: "4px",
    //                                         backgroundColor: "#EFEFEF",
    //                                         boxShadow: "none",
    //                                         hoverBackgroundColor: "lightgreen",
    //                                         color: "black",
    //                                         // fontSize: "12px",
    //                                         // fontFamily: "Courier",
    //                                         iconColor: "green",
    //                                         lineColor: "lightgreen",
    //                                         placeholderColor: "darkgreen",
    //                                         clearIconMargin: "3px 8px 0 0",
    //                                         zIndex : "auto",
    //                                         // zIndex: {labourZIndex},
    //                                       }}
    //                                     />
    //                                 </div>
    
    //                         </div>
    
    
    
    //                   </div>
    
    
    
    //                   <div className="col-9">
    //                                 <div className="row row-cols-10">
                                                    
    
    
    
    
    //                                                 {/*--------- Item Name for Proposal Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_NAME_FOR_PROPOSAL))?
    //                                                     <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.item_names_for_proposal}  onBlur={(e)=>{
                                                                                  
    //                                                       dispatch(updateItemData({item :{...item, item_names_for_proposal: e.target.value}}))
    //                                                   }}  /></div>
    //                                                 :null}                                                              
    
                                                    
    //                                                 {/*--------- ITEM_TYPE Column---------- */}
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_TYPE))?
    //                                                   <div className='col justify-content-start'>
    //                                                         <select className='w-100' defaultValue={item.item_type} onBlur={(e)=>{
    //                                                            // dispatch(updateItem({...item, status :e.target.value}))
    //                                                            dispatch(updateItemData({item : {...item, item_type :e.target.value}}))
                                                          
    //                                                            }} >
    //                                                                <option value="Assembly (Global)">Assembly (Global)</option>
    //                                                                <option value="Equipment">Equipment</option>
    //                                                                <option value="Material">Material</option>
    //                                                                <option value="MathLab">MathLab</option>
    //                                                                <option value="Other">Other</option>
    //                                                                <option value="Staff">Staff</option>
    //                                                                <option value="Sub(Labour only)">Sub(Labour only)</option>
    //                                                                <option value="Sub(Material only)">Sub(Material only)</option>
    //                                                                <option value="Sub(MathLab)">Sub(MathLab)</option>
    //                                                            </select>
    
    //                                                   </div>
    //                                                                   :null}
    
                                                                                
    
                                                                              
    
    
    //                                                 {/*---------Parent Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PARENT))?
    //                                                      <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.parent}  onBlur={(e)=>{
                                                                                  
    //                                                       dispatch(updateItemData({item :{...item, parent: e.target.value}}))
    //                                                   }}  /></div>
    //                                                 :null}                                                              
    
    
    //                                                 {/*---------Cost Code Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_CODE))?
    //                                                      <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.cost_code}  onBlur={(e)=>{
                                                                                  
    //                                                       dispatch(updateItemData({item :{...item, cost_code: e.target.value}}))
    //                                                   }}  /></div>
    //                                                 :null}
                                                    
    //                                                   {/*--------- Units exc Wastage Column---------- */}
    
    //                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_EX_WASTAGE))?
    //                                                   <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.unit_ex_wastage}  onBlur={(e)=>{
                                                                                  
    //                                                     dispatch(updateItemData({item :{...item, unit_ex_wastage: e.target.value}}))
    //                                                 }}  /></div>
    //                                                   :null}
    
                                                    
    //                                                   {/*--------- UOM Column---------- */}
    
    
    //                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
    //                                                     <div className='col justify-content-start'>
    //                                                           <select className='w-100' defaultValue={item.uom} onBlur={(e)=>{
    //                                                            // dispatch(updateItem({...item, status :e.target.value}))
    //                                                            dispatch(updateItemData({item : {...item, uom :e.target.value}}))
                                                          
    //                                                            }} >
    //                                                                <option value="ea">ea</option>
    //                                                                <option value="m2">m2</option>
    //                                                                <option value="hrs">hrs</option>
    //                                                                <option value="im">im</option>
                                                                   
    //                                                            </select>
    //                                                     </div>
    //                                                   :null}
    
                                                    
    
    //                                                   {/*--------- Wastage % Column---------- */}
    
    
    
                                                      
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_PERCENTAGE))?
    //                                                   <div className='col justify-content-start'><input  type='number' className='section-input ' defaultValue={item.wastage_percentage}  onBlur={(e)=>{
                                                                                  
    //                                                     dispatch(updateItemData({item :{...item, wastage_percentage: e.target.value}}))
    //                                                 }}   /></div>
    //                                                   :null}
    
    //                                               {/*--------- Wastage Value---------- */}
    
    
    
    
                                                      
    //                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_VALUE))?
    //                                                     <p className="col"> { calculateWastageValue(item)} </p>
    //                                                 :null} 
    
    
    
    //                                               {/*--------- Units inc Wastage---------- */}
    
    
                                                      
    //                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_INC_WASTAGE))?
    //                                                     <p className="col"> { calculateUnitsIncWastage(item)} </p>
    //                                                 :null} 
    
    
    
    //                                                 {/*---------Cost Per Unit Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
    //                                                     <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.cost_per_unit}  onBlur={(e)=>{
                                                                                  
    //                                                       dispatch(updateItemData({item :{...item, cost_per_unit: e.target.value}}))
    //                                                   }}   /></div>
    //                                                 :null} 
                                                    
    //                                                   {/*--------- BUY Unit $ Column---------- */}
    
    //                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.BUY_UNIT_PRICE))?
    //                                                   <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.buy_unit_price}  onBlur={(e)=>{
                                                                                  
    //                                                     dispatch(updateItemData({item :{...item, buy_unit_price: e.target.value}}))
    //                                                 }}  /></div>
    //                                                   :null}
    
    
    
    //                                                   {/*--------- Sell Unit $ Column---------- */}
    
                                                    
    //                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_UNIT_PRICE))?
    //                                                     <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.sell_unit_price}  onBlur={(e)=>{
                                                                                  
    //                                                       dispatch(updateItemData({item :{...item, sell_unit_price: e.target.value}}))
    //                                                   }}  /></div>
    //                                                   :null}
    
    //                                                {/*--------- Sell Adjestment Unit Value---------- */}
    
    
                                                      
    //                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_ADJ_UNIT_VALUE))?
    //                                                     <p className="col"> { calculateSellAdjesmentUnitValue(item)} </p>
    //                                                 :null} 
                                                                                
    
    
    //                                                 {/*---------Supplier Inc Sales Tax Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_INC_SALES_TAX))?
    //                                                     <div className='col justify-content-start'>
    //                                                     <select className='w-100' defaultValue={item.supplier_inc_sales_tax} onBlur={(e)=>{
    //                                                     // dispatch(updateItem({...item, status :e.target.value}))
    //                                                     dispatch(updateItemData({item : {...item, supplier_inc_sales_tax :e.target.value}}))
                                                   
    //                                                     }} >
    //                                                         <option value="Yes">Yes</option>
    //                                                         <option value="No">No</option>
                                                           
                                                            
    //                                                     </select>
    //                                                </div>
    //                                                 :null}                                                              
    
    
    //                                                 {/*--------- Supplier Item Code Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_ITEM_CODE))?
    //                                                     <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.supplier_item_code}  onBlur={(e)=>{
                                                                                  
    //                                                       dispatch(updateItemData({item :{...item, supplier_item_code: e.target.value}}))
    //                                                   }}  /></div>
    //                                                 :null}
                                                    
    
    //                                                 {/*--------- Cost of Units Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
    //                                                     <p className="col"> { calculateCostOfUnits(item)} </p>
    //                                                 :null}
    
    
                                                    
    //                                                {/*--------- Total Sell Price Of Units---------- */}
    
    
                                                      
    //                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TOTAL_SELL_PRICE_OF_UNITS))?
    //                                                     <p className="col"> { calculateTotalSellPriceOfUnitsValue(item)} </p>
    //                                                 :null} 
    
    
    
    //                                                  {/*--------- Pre-markup % Column---------- */}
    
    
    //                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PRE_MARKUP_PERCENTAGE))?
    //                                                   <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.pre_markup_percentage}  onBlur={(e)=>{
                                                                                  
    //                                                     dispatch(updateItemData({item :{...item, pre_markup_percentage: e.target.value}}))
    //                                                 }} /></div>
    //                                                   :null}
    
    
    //                                                  {/*--------- Pre-markup Value---------- */}
    
    
                                                      
    //                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PRE_MARKUP_VALUE))?
    //                                                     <p className="col"> { calculatePreMarkupValue(item)} </p>
    //                                                 :null} 
    
    
                                                    
    //                                                   {/*--------- Contingency % Column---------- */}
    
    
    //                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_PERCENTAGE))?
    //                                                   <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.contingency_percentage}  onBlur={(e)=>{
                                                                                  
    //                                                     dispatch(updateItemData({item :{...item, contingency_percentage: e.target.value}}))
    //                                                 }} /></div>
    //                                                   :null}
    
    
                                                    
    //                                                 {/*--------- Contingency Value Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_VALUE))?
    //                                                     <p className="col"> {calculateContingency(item)} </p>
    //                                                 :null}   
    
                                                    
    //                                                   {/*--------- Marup percentage % Column---------- */}
    
    
    //                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_PERCENTAGE))?
    //                                                     <div className='col justify-content-start'><input  type='number' className='section-input' defaultValue={item.markup_percentage}  onBlur={(e)=>{
                                                                                  
    //                                                       dispatch(updateItemData({item :{...item, markup_percentage: e.target.value}}))
    //                                                   }} /></div>
    
    //                                                   :null}
                                                    
    //                                                 {/*--------- Markup Value Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_VALUE))?
    //                                                     <p className="col"> {calculateMarkupValue(item)} </p>
    //                                                 :null}                                                                
    
    
    //                                                 {/*--------- Client Sales Tax Percentage  Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_PERCENTAGE))?
    //                                                     <div className='col justify-content-start'><input  type='number' className='section-input ' defaultValue={item.client_sales_tax_percentage}  onBlur={(e)=>{
                                                                                  
    //                                                       dispatch(updateItemData({item :{...item, client_sales_tax_percentage: e.target.value}}))
    //                                                   }}  /></div>
    //                                                 :null}                                                              
    
    
    //                                                 {/*--------- Client Sales Tax Value  Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_VALUE))?
    //                                                     <p className="col"> {calculateClientSalesTax(item)} </p>
    //                                                 :null}
                                                    
    //                                                 {/*--------- Client Charge  Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
    //                                                     <p className="col"> {calculateClientCharge(item)} </p>
    //                                                 :null}     
    
                                                    
    //                                                   {/*--------- Client Charge Inc Column---------- */}
    
    //                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE_INC))?
    //                                                     <div className='col justify-content-start'><input  type='text' className='section-input'/></div>
    //                                                   :null}
                                                                                
    
    
    //                                                 {/*--------- Overhead  Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.OVERHEAD))?
    //                                                     <p className="col"> {calculateOverheads(item)}  </p>
    //                                                 :null}                                                              
    
    
    //                                                 {/*--------- Margin Before Tax  Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN_BEFORE_TAX))?
    //                                                     <p className="col"> {calculateMargin(item)}  </p>
    //                                                 :null}                                                              
    
    
    //                                                 {/*--------- Supplier Contact Id  Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_CONTACT_ID))?
    //                                                      <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.supplier_contact_id}  onBlur={(e)=>{
                                                                                  
    //                                                       dispatch(updateItemData({item :{...item, supplier_contact_id: e.target.value}}))
    //                                                   }} /></div>
    //                                                 :null}                                                              
    
    
    //                                                 {/*--------- Contractor Item Note  Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTRACTOR_ITEM_NOTE))?
    //                                                      <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.contractor_item_note}  onBlur={(e)=>{
                                                                                  
    //                                                       dispatch(updateItemData({item :{...item, contractor_item_note: e.target.value}}))
    //                                                   }} /></div>
    //                                                 :null}                                                              
    
    
    //                                                 {/*--------- Tender Item Note  Column---------- */}
    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TENDER_ITEM_NOTE))?
    //                                                       <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.tender_item_note}  onBlur={(e)=>{
                                                                                  
    //                                                         dispatch(updateItemData({item :{...item, tender_item_note: e.target.value}}))
    //                                                     }} /></div>
    //                                                 :null}  
    
    //                                                 {/*--------- Pricinglist ? Column---------- */}
    
                                                    
    //                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.IS_PRICELIST_ITEM))?
    //                                                     <div className='col justify-content-start'>
    //                                                            <select className='w-100' defaultValue={item.is_pricelist_item} onBlur={(e)=>{
    //                                                            // dispatch(updateItem({...item, status :e.target.value}))
    //                                                            dispatch(updateItemData({item : {...item, is_pricelist_item :e.target.value}}))
                                                          
    //                                                            }} >
    //                                                                <option value="Yes">Yes</option>
    //                                                                <option value="No">No</option>
                                                                  
                                                                   
    //                                                            </select>
    //                                                       </div>
    //                                                   :null}
    
    
    //                                                 {/*--------- NESTED  Column---------- */}
    
    //                                             {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.NESTED))?
    //                    //     <div className='col-1'> 
    //                    //     <input type="text" defaultValue={item.type} className='section-input form-control m-0 p-0'  onBlur={(e)=>{
    //                    //     // dispatch(updateItem({...item, type :e.target.value}))
    //                    //     dispatch(updateItemData({item :{...item, type :e.target.value}}))
                          
    //                  //     }} ></input>
    //                    // </div>
    
    //                      <div className="col">
    //                      <select
    //                        className="section-input form-select  labour-select-input m-0 p-0"
    //                        defaultValue={labour.name}
    //                        onChange={(e) => {
    //                          let itemObject = {...item}
    //                          // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
    //                          if(e.target.selectedOptions[0].getAttribute("specification") ===billOfQuantities.TYPES.SPECIFIED && e.target.selectedOptions[0].getAttribute("labourid"))
    //                          {
    //                            console.log("under current labour",e.target.value)
                              
    //                           dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}}))
    //                         }
    //                         // else if (e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){                       
    //                         //   delete itemObject.labour_id
    //                         //   console.log("new labour",itemObject)
    //                         //   dispatch(updateItemData({item :{...itemObject, specialism :e.target.selectedOptions[0].getAttribute("type")}}))
    //                         // }
    //                         else if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.UNSPECIFIED){
    //                           delete itemObject.labour_id                       
    //                           dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}}))
    
    //                          }
                            
    //                         // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
    //                                              }}
    //                     > 
    //                     {items.map((currentLabour)=>{
    //                       if(currentLabour.type === billOfQuantities.TYPES.LABOUR && currentLabour.section_id === labour.section_id && currentLabour.specification === billOfQuantities.TYPES.SPECIFIED)
    //                       return <option key={currentLabour._id} value={currentLabour.name} labourid={currentLabour._id}
    //                       specification={billOfQuantities.TYPES.SPECIFIED} 
    //                       >{currentLabour.name}</option>
    //                     })}
    //                       <hr className="dropdown-divider"/>
    //                       {/* <option className="m-1" value={billOfQuantities.TYPES.LABOUR} 
    //                       type={billOfQuantities.TYPES.LABOUR} 
    //                       >Add New Labour</option> */}
    //                       <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option>
    //                       {/* <option value="Provitional">Provitional</option>
    //                       <option value="Excluded">Excluded</option> */}
    //                     </select>
    //                     </div>
    
    //                  :null}
    
                                                                                                            
    
                                                    
    
    
    //                                                 <div className='view-pricing-user-filter-icon'>
    //                                                 {/* <CancelPresentationTwoToneIcon onClick={()=>{dispatch(deleteItems({item}))}} color='warning'/> */}
    //                                                     <img  src={threeDotIcon}></img>
    //                                                 </div>
    //                                 </div>
    
    //                         </div>
     
    //                 </div>
                    
    //         ) 
    //         }
    //     })
    // }

    
    const handleOnFocus = () => {
      console.log('Focused')
    }

  return (
    <Draggable draggableId={itemId} index={index} key={itemId}>
      {(provided)=>(              
    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="row m-0" > 
        <div className="col-3 p-0">
            <div className="row row-cols-auto view-pricing-icon-container">
                        <div className="d-flex align-items-center" >

                                            
                                            <img src={dragIcon} className="col" alt='dragIcon'/>
                                            <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                <FormControl  className='pricing-dropdown-icon col' size="small">
                                                  {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}

                                                  <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small-item"
                                                    defaultValue={item.status === undefined? "" : item.status}
                                                    label="Age"
                                                    onChange={(e)=> {dispatch(updateItemData({item :  {...item, status :e.target.value}}))}}
                                                    IconComponent={()=>null}
                                                    
                                                  >
                                                    <MenuItem value="">
                                                      <em></em>
                                                    </MenuItem>
                                                    <MenuItem value={1}><img  className='ui avatar image pricing-icon col' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={yellowEllipseIcon}/></MenuItem>
                                                    <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={greenEllipseIcon}/></MenuItem>
                                                    <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
                                                    <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem>
                                                    {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                  </Select>
                                                </FormControl>
                                              </div>
                                              <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                <FormControl  className='pricing-dropdown-icon col'  >
                                                  {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                  <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small-item"
                                                    defaultValue={item.cost_type === undefined? "" : item.cost_type}
                                                    label="Age"
                                                    onChange={(e)=> {dispatch(updateItemData({item :  {...item, cost_type :e.target.value}}))}}
                                                    IconComponent={()=>null}
                                                    
                                                  >
                                                    <MenuItem value="">
                                                      <em></em>
                                                    </MenuItem>
                                                    <MenuItem value={1}><img  className='ui avatar image  ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={pcIcon}/></MenuItem>
                                                    <MenuItem value={2}><img  className='ui avatar image unique-icon ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={exIcon}/></MenuItem>
                                                    {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                    <MenuItem value={3}> -- </MenuItem>
                                                    {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                    {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                  </Select>
                                                </FormControl>
                                              </div>


                                              <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                <FormControl  className='pricing-dropdown-icon col'  >
                                                  {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                  <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small-item"
                                                    defaultValue={item.hammer === undefined? "" : item.hammer}
                                                    label="Age"
                                                    onChange={(e)=> {dispatch(updateItemData({item :  {...item, hammer :e.target.value}}))}}
                                                    IconComponent={()=>null}
                                                    
                                                  >
                                                    <MenuItem value="">
                                                      <em></em>
                                                    </MenuItem>
                                                    <MenuItem value={1}><img  className='ui avatar image pricing-icon ' style={{height : "15px", width: "16px",background: "#EFEFEF", border:"none"}} src={gavelIcon}/></MenuItem>
                                                    <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
                                                    {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                    <MenuItem value={3}> || </MenuItem>
                                                    {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                    {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                  </Select>
                                                </FormControl>
                                              </div>
                                              <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                <FormControl  className='pricing-dropdown-icon col'   >
                                                  {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                  <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small-item"
                                                    defaultValue={item.suppiler === undefined? "" : item.suppiler}
                                                    label="Age"
                                                    onChange={(e)=> {dispatch(updateItemData({item :  {...item, suppiler :e.target.value}}))}}
                                                    IconComponent={()=>null}
                                                   
                                                  >
                                                    <MenuItem value="">
                                                      <em></em>
                                                    </MenuItem>
                                                    <MenuItem value={1}  className="pricing-icon-container"><img  className='ui avatar image  unique-icon' style={{height : "11px", width: "15px",background: "#EFEFEF", border:"none"}} src={blueTruck}/></MenuItem>
                                                    {/* <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem> */}
                                                    {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                    <MenuItem value={3}> -- </MenuItem>
                                                    {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                    {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                  </Select>
                                                </FormControl>
                                              </div>

                                          

                                                

                                            {/* <img src={greenEllipseIcon} className="col" alt='settingsIcon'/> */}
                                            {/* <img src={pcIcon} className="col" alt='hammerIcon'/> */}
                                            {/* <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/> */}
                                            <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                                            <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
                                            <img  className='col ' style={{width: "24px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
                                            <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
                                            {/* <select  className='labour-select-input w-25 m-0 p-0' >
                                                    <option value="Prime">IC2</option>
                                                    <option value="Provitional"><img src={blueTruckIcon}></img></option>
                                                    <option value="Excluded">Excluded</option>
                                                </select> */}
                                            {/* <img src={treeSwichterOpenIcon} className="col"  onClick={() => {setShow((prev) => !prev)}}  alt='whiteTruckIcon'/> */}
        
                                        {/* {show?
                                          <img src={treeSwichterOpenIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>

                                        :<img src={treeSwichterCloseIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>
                                      } */}
                                                                                {/* <input className="form-check-input m-1 " type="checkbox" ></input> */}
                                        {isCurrentChecked(item._id , billOfQuantities.TYPES.ITEM)?
                                              <input className="form-check-input pricing-checkbox m-1 align-items-center" type="checkbox"  onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}}  defaultChecked></input>   
                                            :
                                            
                                            <input className="form-check-input pricing-checkbox m-1 align-items-center " type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}} ></input>   
                                          }    

                          </div>
                      <div style={{ width: 120}}>
                        {/* <ReactSearchAutocomplete
                          onFocus={handleOnFocus}
                          placeholder={item.name}
                          formatResult = {formatResult}
                          items={pricingListCurrent.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.ITEM )} 
                          fuseOptions={{keys : ["name","type"]}}
                          showIcon= {false}
                          onSelect = {(event)=>{dispatch(updateItemData({item : {...item, name : event.name, type : event.type , uom:event.uom,   type:item.type , specialism :item.specialism, specification : item.specification , buy_unit_price : event.buy_unit_price, sell_unit_price : event.sell_unit_price, markup_percentage : projectRequestData.markup_percentage,contingency_percentage : projectRequestData.contingency_percentage, gmp_percentage: projectRequestData.gmp_percentage, client_sales_tax_percentage : projectRequestData.client_sales_tax_percentage, client_retainer_percentage : projectRequestData.client_retainer_percentage}}))}}
                          styling={{
                            height: "34px",
                            border: "none",
                            // border: "1px solid darkgreen",
                            borderRadius: "4px",
                            backgroundColor: "#EFEFEF",
                            boxShadow: "none",
                            hoverBackgroundColor: "lightgreen",
                            color: "black",
                            // fontSize: "12px",
                            // fontFamily: "Courier",
                            iconColor: "green",
                            lineColor: "lightgreen",
                            placeholderColor: "darkgreen",
                            clearIconMargin: "3px 8px 0 0",
                            zIndex : "auto",
                            // zIndex: {labourZIndex},
                          }}
                        /> */}

<CreatableSelect
   
   onChange= {(event)=>{
     const selectedValue = { value: event, label: event.name };
     setSelectedOption(selectedValue);
     dispatch(updateItemData({item : {...item, name : event.name, type : event.type , uom:event.uom,   type:item.type , specialism :item.specialism, specification : item.specification , buy_unit_price : event.buy_unit_price, sell_unit_price : event.sell_unit_price, markup_percentage : projectRequestData.markup_percentage,contingency_percentage : projectRequestData.contingency_percentage, gmp_percentage: projectRequestData.gmp_percentage, client_sales_tax_percentage : projectRequestData.client_sales_tax_percentage, client_retainer_percentage : projectRequestData.client_retainer_percentage}}))}}
   
   options={pricingListCurrentLatest.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.ITEM)} 
   value= {selectedOption}
 />  
                      </div>

            </div>



      </div>



      <div className="col-9">
      <div className="row row-cols-10">
                                    




                                    {/*--------- Item Name for Proposal Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_NAME_FOR_PROPOSAL))?
                                        <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.item_name_for_proposal}  onBlur={(e)=>{
                                            if(e.target.value !== item.item_name_for_proposal){

                                              // dispatch(updateItemData({item :{...item, item_name_for_proposal: e.target.value}}))
                                              dispatch(updateItem({...item, item_name_for_proposal : e.target.value}))
                                            
                                            }        
                                      }}
                                        onKeyDown={(e)=>{
                                          if(e.key === "Enter"){

                                              if(e.target.value !== item.item_name_for_proposal){
                                                
                                                // dispatch(updateItemData({item :{...item, item_name_for_proposal: e.target.value}}))
                                                dispatch(updateItem({...item, item_name_for_proposal : e.target.value}))
                                              
                                              }
                                              console.log('eee',e.target.value)
                                            
            
                                              dispatch(updatedEstimateIdDataOnBackend())
                                          }
                                        }}
                                        /></div>
                                    :null}                                                              

                                    
                                    {/*--------- ITEM_TYPE Column---------- */}
                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_TYPE))?
                                      <div className='col justify-content-start'>
                                            <select className='w-100 section-input' defaultValue={item.item_type} onBlur={(e)=>{
                                               // dispatch(updateItem({...item, status :e.target.value}))
                                              //  dispatch(updateItemData({item : {...item, item_type :e.target.value}}))
                                               if(e.target.value !== item.item_type && (e.target.value === "Staff" || e.target.value === "MathLab" || e.target.value === "Sub(Labour only)" || e.target.value === "Sub(MathLab)")){
                                                let itemObject = {...item}
                                                delete itemObject.labour_id
                                                // dispatch(updateItemData({item :{...item, item_name_for_proposal: e.target.value}}))
                                                dispatch(updateItem({...itemObject, item_type : e.target.value}))
                                                dispatch(setIdArray())
                                              } else {
                                                dispatch(updateItem({...item, item_type : e.target.value}))

                                              }
                                          
                                               }} >
                                                   <option value="Assembly (Global)">Assembly (Global)</option>
                                                   <option value="Equipment">Equipment</option>
                                                   <option value="Material">Material</option>
                                                   <option value="MathLab">MatLab</option>
                                                   <option value="Other">Other</option>
                                                   <option value="Staff">Staff</option>
                                                   <option value="Sub(Labour only)">Sub(Labour only)</option>
                                                   <option value="Sub(Material only)">Sub(Material only)</option>
                                                   <option value="Sub(MathLab)">Sub(MatLab)</option>
                                               </select>

                                      </div>
                                                      :null}

                                                                

                                                              


                                    {/*---------Parent Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PARENT))?
                                         <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.parent}  onBlur={(e)=>{
                                                                  
                                          // dispatch(updateItemData({item :{...item, parent: e.target.value}}))
                                          // dispatch(updateItem({...item, parent : e.target.value}))

                                          if(e.target.value !== item.parent){

                                            dispatch(updateItem({...item, parent : e.target.value}))
                                          } 

                                      }}  /></div>
                                    :null}                                                              


                                    {/*---------Cost Code Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_CODE))?
                                         <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.cost_code}  onBlur={(e)=>{
                                                                  
                                          // dispatch(updateItemData({item :{...item, cost_code: e.target.value}}))
                                          if(e.target.value !== item.cost_code){

                                            dispatch(updateItem({...item, cost_code : e.target.value}))
                                          } 
                                      }}  /></div>
                                    :null}
                                    
                                      {/*--------- Units exc Wastage Column---------- */}

                                      {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_EX_WASTAGE))?
                                      <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.unit_ex_wastage}  onBlur={(e)=>{
                                                                  
                                        // dispatch(updateItemData({item :{...item, unit_ex_wastage: e.target.value}}))
                                        if(e.target.value !== item.unit_ex_wastage){

                                          dispatch(updateItem({...item, unit_ex_wastage : e.target.value}))
                                        } 
                                    }}  /></div>
                                      :null}

                                    
                                      {/*--------- UOM Column---------- */}


                                      {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                                        <div className='col justify-content-start'>
                                              <select className='w-100 section-input' defaultValue={item.uom} onBlur={(e)=>{
                                               // dispatch(updateItem({...item, status :e.target.value}))
                                              //  dispatch(updateItemData({item : {...item, uom :e.target.value}}))
                                               if(e.target.value !== item.uom){

                                                dispatch(updateItem({...item, uom : e.target.value}))
                                              } 
                                          
                                               }} >
                                                  <option value="Ea">Ea</option>
                                                  <option value="M2"><p>M<sup>2</sup></p></option>
                                                  <option value="M3">M<sup>3</sup></option>
                                                  <option value="Hrs">Hrs</option>
                                                  <option value="Hrs">Days</option>
                                                  <option value="Im">Im</option>
                                                  <option value="Kg">Kg</option>
                                                   
                                               </select>
                                        </div>
                                      :null}

                                    

                                      {/*--------- Wastage % Column---------- */}



                                      
                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_PERCENTAGE))?
                                      <div className='col justify-content-start'><span class="currency-input">
                                      <input  type='number' className='section-input ' defaultValue={item.wastage_percentage}  onBlur={(e)=>{
                                                                
                                      // dispatch(updateItemData({item :{...item, wastage_percentage: e.target.value}}))
                                      if(e.target.value !== item.wastage_percentage){

                                        dispatch(updateItem({...item, wastage_percentage : e.target.value}))
                                      } 
                                  
                                  }}   /><p>%</p> </span>
                                       </div>
                                      :null}

                                  {/*--------- Wastage Value---------- */}




                                      
                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_VALUE))?
                                        <p className="col"> { calculateWastageValue(item)} </p>
                                    :null} 



                                  {/*--------- Units inc Wastage---------- */}


                                      
                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_INC_WASTAGE))?
                                        <p className="col"> { calculateUnitsIncWastage(item)} </p>
                                    :null} 



                                    
                                      {/*--------- BUY Unit $ Column---------- */}

                                      {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.BUY_UNIT_PRICE))?
                                      <div className='col justify-content-start '><span class="currency-input"><p>$</p><input  type='number' defaultValue={item.buy_unit_price}  onBlur={(e)=>{
                                                                  
                                        // dispatch(updateItemData({item :{...item, buy_unit_price: e.target.value}}))
                                        if(e.target.value !== item.buy_unit_price){

                                          dispatch(updateItem({...item, buy_unit_price : e.target.value}))
                                        } 
                                    }}  /></span></div>
                                      :null}



                                      {/*--------- Sell Unit $ Column---------- */}

                                    
                                      {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_UNIT_PRICE))?
                                        <div className='col justify-content-start'><span class="currency-input"><p>$</p><input  type='number'  defaultValue={item.sell_unit_price}  onBlur={(e)=>{
                                                                  
                                          // dispatch(updateItemData({item :{...item, sell_unit_price: e.target.value}}))
                                          if(e.target.value !== item.sell_unit_price){

                                            dispatch(updateItem({...item, sell_unit_price : e.target.value}))
                                          } 
                                      }}  /></span>

                                          
                                          </div>
                                      :null}

                                   {/*--------- Sell Adjestment Unit Value---------- */}


                                      
                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_ADJ_UNIT_VALUE))?
                                        <p className="col"> { calculateSellAdjesmentUnitValue(item)} </p>
                                    :null} 
                                                                


                                    {/*---------Supplier Inc Sales Tax Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_INC_SALES_TAX))?
                                        <div className='col justify-content-start'>
                                        <select className='w-100 section-input' defaultValue={item.supplier_inc_sales_tax} onBlur={(e)=>{
                                        // dispatch(updateItem({...item, status :e.target.value}))
                                        // dispatch(updateItemData({item : {...item, supplier_inc_sales_tax :e.target.value}}))
                                        if(e.target.value !== item.supplier_inc_sales_tax){

                                          dispatch(updateItem({...item, supplier_inc_sales_tax : e.target.value}))
                                        } 
                                        }} >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                           
                                            
                                        </select>
                                   </div>
                                    :null}                                                              


                                    {/*--------- Supplier Item Code Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_ITEM_CODE))?
                                        <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.supplier_item_code}  onBlur={(e)=>{
                                                                  
                                          // dispatch(updateItemData({item :{...item, supplier_item_code: e.target.value}}))
                                          if(e.target.value !== item.supplier_item_code){

                                            dispatch(updateItem({...item, supplier_item_code : e.target.value}))
                                          } 
                                      }}  /></div>
                                    :null}
                                    


                                    
                                   {/*--------- Total Sell Price Of Units---------- */}


                                      
                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TOTAL_SELL_PRICE_OF_UNITS))?
                                        <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateTotalSellPriceOfUnitsValue(item)}</span>
                                        </span> </div>
                                    :null} 



                                     {/*--------- Pre-markup % Column---------- */}


                                     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PRE_MARKUP_PERCENTAGE))?
                                      <div className='col justify-content-start'> <span class="currency-input">
                                        <input  type='text' className='section-input ' defaultValue={item.pre_markup_percentage}  onBlur={(e)=>{
                                                                  
                                                                  // dispatch(updateItemData({item :{...item, pre_markup_percentage: e.target.value}}))
                                                                  if(e.target.value !== item.pre_markup_percentage){

                                                                    dispatch(updateItem({...item, pre_markup_percentage : e.target.value}))
                                                                  } 
                                                              }} /><p>%</p> </span>
                                      </div>
                                      :null}


                                     {/*--------- Pre-markup Value---------- */}


                                      
                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PRE_MARKUP_VALUE))?
                                        <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculatePreMarkupValue(item)}</span>
                                        </span> </div>
                                        // <p className="col"> { calculatePreMarkupValue(item)} </p>
                                    :null} 


                                    
                                      {/*--------- Contingency % Column---------- */}


                                      {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_PERCENTAGE))?
                                      <div className='col justify-content-start'><span class="currency-input">
                                      <input  type='number' className='section-input ' defaultValue={item.contingency_percentage}  onBlur={(e)=>{
                                                                  
                                                                  // dispatch(updateItemData({item :{...item, contingency_percentage: e.target.value}}))
                                                                  if(e.target.value !== item.contingency_percentage){

                                                                    dispatch(updateItem({...item, contingency_percentage : e.target.value}))
                                                                  } 
                                                              }} /><p>%</p> </span></div>
                                      :null}


                                    
                                    {/*--------- Contingency Value Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_VALUE))?
                                        <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculatePreMarkupValue(item)}</span>
                                        </span> </div>
                                        // <p className="col"> {calculateContingency(item)} </p>
                                    :null}   

                                    
                                      {/*--------- Marup percentage % Column---------- */}


                                      {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_PERCENTAGE))?
                                        <div className='col justify-content-start'><span class="currency-input">
                                         <input  type='number' className='section-input' defaultValue={item.markup_percentage}  onBlur={(e)=>{
                                                                  
                                                                  // dispatch(updateItemData({item :{...item, markup_percentage: e.target.value}}))
                                                                  if(e.target.value !== item.markup_percentage){

                                                                    dispatch(updateItem({...item, markup_percentage : e.target.value}))
                                                                  } 
                                                              }} /><p>%</p> </span>
                                          
                                         </div>

                                      :null}
                                    
                                    {/*--------- Markup Value Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_VALUE))?
                                    <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateMarkupValue(item)}</span>
                                    </span> </div>
                                        // <p className="col"> {calculateMarkupValue(item)} </p>
                                    :null}                                                                


                                    {/*--------- Client Sales Tax Percentage  Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_PERCENTAGE))?
                                        <div className='col justify-content-start'><input  type='number' className='section-input ' defaultValue={item.client_sales_tax_percentage}  onBlur={(e)=>{
                                                                  
                                          // dispatch(updateItemData({item :{...item, client_sales_tax_percentage: e.target.value}}))
                                          if(e.target.value !== item.client_sales_tax_percentage){

                                            dispatch(updateItem({...item, client_sales_tax_percentage : e.target.value}))
                                          } 
                                      }}  /></div>
                                    :null}                                                              


                                    {/*--------- Client Sales Tax Value  Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_VALUE))?
                                    <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateClientSalesTax(item)}</span>
                                    </span> </div>
                                        // <p className="col"> {calculateClientSalesTax(item)} </p>
                                    :null}
    

                                    
                                      {/*--------- Client Charge Inc Column---------- */}

                                      {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE_INC))?
                                           <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateClientCharge(item)}</span>
                                           </span> </div>
                                        // <div className='col justify-content-start'>{calculateClientCharge(item)}</div>
                                      :null}
                                                                


                                    {/*--------- Overhead  Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.OVERHEAD))?
                                      <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateOverheads(item)}</span>
                                      </span> </div>
                                        // <p className="col"> {calculateOverheads(item)}  </p>
                                    :null}                                                              


                                    {/*--------- Margin Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
                                        <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateMargin(item)}</span>
                                        </span> </div>
                                        
                                        // <p className="col"> {calculateMargin(item)}  </p>
                                    :null}

                                    {/*--------- GMP Buffer %   Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_PERCENTAGE))?
                                              <div className='col justify-content-start'><span class="currency-input">
                                              <input  type='number' className='section-input' defaultValue={item.gmp_percentage}  onBlur={(e)=>{
                                                                       
                                                                      //  dispatch(updateItemData({item :{...item, gmp_percentage: e.target.value}}))
                                                                       if(e.target.value !== item.gmp_percentage){

                                                                        dispatch(updateItem({...item, gmp_percentage : e.target.value}))
                                                                      } 
                                                                   }} /><p>%</p> </span>
                                               
                                              </div>
     
                                        
                                         

                                    :null}                                                              
                                    {/*--------- GMP Buffer V   Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_VALUE))?
                                    <div className='col justify-content-start'><span class="section-input-container"><p>$</p><span >{calculateGMPBuffer(item)}</span>
                                     </span></div>
                                          
                                    :null}                                                                 


                                    {/*--------- Supplier Contact Id  Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_CONTACT_ID))?
                                         <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.suppiler}  onBlur={(e)=>{
                                                                  
                                          // dispatch(updateItemData({item :{...item, suppiler: e.target.value}}))
                                          if(e.target.value !== item.suppiler){

                                            dispatch(updateItem({...item, suppiler : e.target.value}))
                                          } 
                                      }} /></div>
                                    :null}                                                              


                                    {/*--------- Contractor Item Note  Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTRACTOR_ITEM_NOTE))?
                                         <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.contractor_item_note}  onBlur={(e)=>{
                                                                  
                                          // dispatch(updateItemData({item :{...item, contractor_item_note: e.target.value}}))
                                          if(e.target.value !== item.contractor_item_note){

                                            dispatch(updateItem({...item, contractor_item_note : e.target.value}))
                                          } 
                                      }} /></div>
                                    :null}                                                              


                                    {/*--------- Tender Item Note  Column---------- */}

                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TENDER_ITEM_NOTE))?
                                          <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.tender_item_note}  onBlur={(e)=>{
                                                                  
                                            // dispatch(updateItemData({item :{...item, tender_item_note: e.target.value}}))
                                            if(e.target.value !== item.tender_item_note){

                                              dispatch(updateItem({...item, tender_item_note : e.target.value}))
                                            } 
                                        }} /></div>
                                    :null}  

                                    {/*--------- Pricinglist ? Column---------- */}

                                    
                                    {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.IS_PRICELIST_ITEM))?
                                        <div className='col justify-content-start'>
                                               <select className='w-100 section-input' defaultValue={item.is_pricelist_item} onBlur={(e)=>{
                                               // dispatch(updateItem({...item, status :e.target.value}))
                                              //  dispatch(updateItemData({item : {...item, is_pricelist_item :e.target.value}}))
                                              if(e.target.value !== item.is_pricelist_item){

                                                dispatch(updateItem({...item, is_pricelist_item : e.target.value}))
                                              } 
                                          
                                               }} >
                                                   <option value="Yes">Yes</option>
                                                   <option value="No">No</option>
                                                  
                                                   
                                               </select>
                                          </div>
                                      :null}


                                    {/*--------- NESTED  Column---------- */}

                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.NESTED))?
       //     <div className='col-1'> 
       //     <input type="text" defaultValue={item.type} className='section-input form-control m-0 p-0'  onBlur={(e)=>{
       //     // dispatch(updateItem({...item, type :e.target.value}))
       //     dispatch(updateItemData({item :{...item, type :e.target.value}}))
          
     //     }} ></input>
       // </div>

         <div className="col">
         <select
           className="section-input form-select  labour-select-input m-0 p-0"
          
         
           onChange={(e) => {
             let itemObject = {...item}
             // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
             if(e.target.selectedOptions[0].getAttribute("specification") ===billOfQuantities.TYPES.SPECIFIED && e.target.selectedOptions[0].getAttribute("labourid"))
             {
               console.log("under current labour",e.target.value)
              
              dispatch(updateItem({...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}))
            }
            // else if (e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){                       
            //   delete itemObject.labour_id
            //   console.log("new labour",itemObject)
            //   dispatch(updateItemData({item :{...itemObject, specialism :e.target.selectedOptions[0].getAttribute("type")}}))
            // }
            else if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.UNSPECIFIED){
              delete itemObject.labour_id                       
              dispatch(updateItem({...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}))

             }
             dispatch(setIdArray())
            // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
                                 }}
        > 
        {laboursId[item.section_id].map((itemId)=>{
          let currentLabour = itemRequestData.find(i=>i._id ===itemId)
          if( currentLabour._id !== labour._id &&(currentLabour.item_type === billOfQuantities.ITEM_TYPES.STAFF || currentLabour.item_type === billOfQuantities.ITEM_TYPES.MATHLAB || currentLabour.item_type === billOfQuantities.ITEM_TYPES.SUB_LABOUR_ONLY || currentLabour.item_type === billOfQuantities.ITEM_TYPES.SUB_MATHLAB) && currentLabour.section_id === labour.section_id && currentLabour.specification === billOfQuantities.TYPES.SPECIFIED)
          {
            return <option key={currentLabour._id} value={currentLabour.name} labourid={currentLabour._id}
            specification={billOfQuantities.TYPES.SPECIFIED} 
            >{currentLabour.name}</option>
          }
    
        })}
          <option value="" disabled  hidden>{labour.name}</option>
          <hr className="dropdown-divider"/>
          {/* <option className="m-1" value={billOfQuantities.TYPES.LABOUR} 
          type={billOfQuantities.TYPES.LABOUR} 
          >Add New Labour</option> */}
          <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option>
          {/* <option value="Provitional">Provitional</option>
          <option value="Excluded">Excluded</option> */}
        </select>
        </div>

     :null}

                                                                                            

                                    


                                    <div className='view-pricing-user-filter-icon'>
                                    {/* <CancelPresentationTwoToneIcon onClick={()=>{dispatch(deleteItems({item}))}} color='warning'/> */}
                                        <img  src={threeDotIcon}></img>
                                    </div>
                    </div>
            </div>

    </div>
      )}
    </Draggable>
    
) 
}

export default memo(Item)