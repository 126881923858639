import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { localStorageConstants } from "../constants/Constants";
import { apiCodes, urls } from "../constants/Url";
import { interceptedHttpGet, interceptedHttpPut } from "../service/service";

const initialState = {

    pricingListRequestData : [],
    pricingListRequestLoading : false,
    pricingListRequestError : '',

    updatePricingListRequestData : [],
    updatePricingListRequestLoading : false,
    updatePricingListRequestError : ''
}

export const getPricingList = createAsyncThunk('/get', (args,{getState,dispatch})=>{
const contractorId = localStorage.getItem(localStorageConstants.USER.CONTRACTOR_ID)

    const pricingListResponse = interceptedHttpGet(urls.BASE_URL+urls.GET_PRICING_LIST+`/${contractorId}`)

    return pricingListResponse.then(
        (response)=>{
            return response.data

    })

})


export const updatePricingList = createAsyncThunk('/update',(args,{getState,dispatch})=>{
    const {pricingListData} = args
    const updateResponse = interceptedHttpPut(urls.BASE_URL+urls.UPDATE_PRICING_LIST, {
        pricingListData : pricingListData
    })

    return updateResponse.then(
        (response)=>{
            if(response.status === apiCodes.STATUS_SUCCESS_CODE){
                return pricingListData
            }
        }
    )
})


const pricingList = createSlice({
    name : "pricingList",
    initialState,
    reducers : {

    },
    extraReducers : (builder,thunkAPI)=>{

        builder.addCase(getPricingList.fulfilled,(state,action)=>{
            state.pricingListRequestData = action.payload
            state.pricingListRequestLoading = false
            state.pricingListRequestError = ''
        })
        builder.addCase(getPricingList.pending,(state,action)=>{
            state.pricingListRequestData = []
            state.pricingListRequestLoading = true
            state.pricingListRequestError = ''
        })
        builder.addCase(getPricingList.rejected,(state,action)=>{
            state.pricingListRequestData = []
            state.pricingListRequestLoading = false
            state.pricingListRequestError = action.error.message
        })
        builder.addCase(updatePricingList.fulfilled,(state,action)=>{
            state.updatePricingListRequestData = action.payload
            state.updatePricingListRequestLoading = false
            state.updatePricingListRequestError = ''

            state.pricingListRequestData = state.pricingListRequestData.map((pricingListData)=>{
                if(pricingListData._id === action.payload._id){
                    return action.payload
                }
                else {
                    return pricingListData
                }
            })
        })
        builder.addCase(updatePricingList.pending,(state,action)=>{
            state.updatePricingListRequestData = []
            state.updatePricingListRequestLoading = true
            state.updatePricingListRequestError = ''
        })
        builder.addCase(updatePricingList.rejected,(state,action)=>{
            state.updatePricingListRequestData = []
            state.updatePricingListRequestLoading = false
            state.updatePricingListRequestError = action.error.message
        })
        


    }
})

export default pricingList.reducer;