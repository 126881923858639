import "./Labour.css"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelPresentationTwoToneIcon from "@mui/icons-material/CancelPresentationTwoTone";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCheckedList, changeAddUpModalStatus, createItem, deleteItems, deleteLabours, deleteNewItem, initializeNewItem, setIdArray, updateItem, updateItemData, updateLabour, updateLabourData, updateNewItem } from "../../features/PricingSlice";
import { billOfQuantities,schema } from "../../constants/Constants";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import CreatableSelect from 'react-select/creatable';
import MiniSearch from 'minisearch'



  // Image imports
  import threeDotIcon from "../../assets/icons/three-dot.png"
  import dragIcon from "../../assets/icons/material-symbols_drag-indicator-sharp.png"
  // import greenEllipseIcon from "../../assets/icons/green_Ellipse_11.png"
  import pcIcon from "../../assets/icons/PC.png"
  import gavelIcon from "../../assets/icons/clarity_gavel-solid.png"
  import treeSwichterOpenIcon from "../../assets/icons/Tree-Switcher-open.png"
  import treeSwichterCloseIcon from "../../assets/icons/Tree-Switcher-Close.png"
  import transparentIcon from "../../assets/icons/transparent-icon.png"
  import blueTruck from "../../assets/icons/mdi_truck.png"



  //Meterial UI
  // import { Form } from 'react-bootstrap';
  import { Dropdown } from 'semantic-ui-react'
  import InputLabel from '@mui/material/InputLabel';
  import MenuItem from '@mui/material/MenuItem';
  import FormHelperText from '@mui/material/FormHelperText';
  import FormControl from '@mui/material/FormControl';
  import Select, { SelectChangeEvent } from '@mui/material/Select';


  import greenEllipseIcon from '../../assets/icons/green_Ellipse_11.png'
  import yellowEllipseIcon from '../../assets/icons/yellow-ellipse-icon.png'
  import redEllipseIcon from '../../assets/icons/red-ellipse-icon.png'
  import blueEllipseIcon from '../../assets/icons/blue-ellipse-icon.png'
  import exIcon from '../../assets/icons/EX-icon.png'
  import dashIcon from '../../assets/icons/dash-icon.png'
import Item from "../item/Item";
import { memo } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import zIndex from "@mui/material/styles/zIndex";


const Labour = ({ provided,labourId,userViewFilters, index }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);

  // const items = useSelector((state)=>{
  //   state.pricing.itemRequestData.map((item)=>{
  //      if (item.labour_id === labour._id){
  //         return item
  //      }
  //   })
  // })
  
  // const labourRequestData = useSelector((state)=>state.pricing.labourRequestData)
  
  // const newItems = useSelector((state)=>state.pricing.newItems)
  const checkedDataObject = useSelector((state)=>state.pricing.checkedDataObject)

  const pricingListRequestData = useSelector((state)=> state.pricing.pricingListRequestData)
  // const pricingListRequestLoading = useSelector((state)=> state.pricing.pricingListRequestLoading)
  // const userViewFilter = useSelector((state)=> state.pricing.userViewFilter)
  const overheadsPercentage = useSelector((state)=>state.pricing.overheadsPercentage)

  const projectRequestData = useSelector((state)=>state.pricing.projectRequestData)
  // const items = useSelector((state)=>state.pricing.itemRequestData)
  // const itemIdArray = useSelector((state)=>state.pricing.itemIdArray)

  // const labour = items.find((i)=>i._id ===labourId)
  // const searchIndex = new MiniSearch({
  //   fields : ["name"],
  //   storeFields : ['name']
  // })
  // searchIndex.addAll(addUp)

  // const [results, setResults] = useState ([]);
  // const [inputValue, setInputValue] = useState("");
  // const [searchIndex, setSearchIndex] = useState(null);
  const [pricingListCurrent , setPricingListCurrent] = useState(()=>{
      return pricingListRequestData.map((addUp)=>{
        return {...addUp, id : addUp._id,}
      })
    })

    const [pricingListCurrentLatest , setPricingListCurrentLatest] = useState(()=>{
      
      return pricingListRequestData.map((addUp)=>{
        // console.log("inside pricing list current latest")
        // console.log({...addUp, id : addUp._id, value : addUp._id, label : addUp.name})
        return {...addUp, id : addUp._id, value : addUp._id, label : addUp.name}
      })
    })
  

  // const [checkedObj, setCheckedObj] = useState({sections : [], labours : [], items:[]})
  
  // const [newLabourName , setNewLabourName] = useState('')
  // const [isDuplicateName , setIsDuplicateName] = useState(false)

  


  useEffect(() => {
    // setPricingListCurrent(()=>{
    //   return pricingListRequestData.map((addUp)=>{
    //     return {...addUp, id : addUp._id,}
    //   })
    // })
    console.log("LLL", )
   
  }, []); 

  // new calculations - 5 

  const calculateWastageValue = (item)=>{

    // console.log(calculateUnitsIncWastage(item), " - ",item.unit_ex_wastage, " = " ,calculateUnitsIncWastage(item) - parseFloat(item.unit_ex_wastage))
      return Math.round(calculateUnitsIncWastage(item) - item.unit_ex_wastage *100)/100
      // return Math.floor((calculateUnitsIncWastage(item) - item.unit_ex_wastage )* 100)/100
  }

  const calculateUnitsIncWastage = (item)=>{
    // return Math.floor( (item.unit_ex_wastage * (1 + (item.wastage_percentage/100))) * 100)/100
    return Math.round(item.unit_ex_wastage * (1 + (item.wastage_percentage/100)) * 100)/100
  }

  const calculateSellAdjesmentUnitValue = (item)=>{
    if (item.sell_unit_price>item.buy_unit_price){
       
      // return Math.floor( (  item.sell_unit_price - item.buy_unit_price  ) * 100)/100
      return Math.round(  (item.sell_unit_price - item.buy_unit_price) *100  )/100
    }
    else if (item.sell_unit_price > 0){
     
      return Math.round(  (item.sell_unit_price - item.buy_unit_price) *100 )/100

    }
    else {
      return 0
    }
  }

  const calculateTotalSellPriceOfUnitsValue = (item)=>{
    if(item.sell_unit_price > 0){
       return Math.round((item.sell_unit_price * parseFloat(calculateUnitsIncWastage(item))) * 100)/100
      //  return Math.floor( (  item.sell_unit_price * parseFloat(calculateUnitsIncWastage(item))  ) * 100)/100

    }
    else {
      return Math.round((item.buy_unit_price * parseFloat(calculateUnitsIncWastage(item))) * 100)/100
      // return Math.floor( (  item.buy_unit_price * parseFloat(calculateUnitsIncWastage(item))  ) * 100)/100

    }

  }

  const calculatePreMarkupValue =  (item) => {
    return  Math.round( (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) *(item.pre_markup_percentage/100)) * 100)/ 100
    // return Math.floor( ( parseFloat(calculateTotalSellPriceOfUnitsValue(item)) *(item.pre_markup_percentage/100)  ) * 100)/100

    
  }



  






  const calculateCostOfUnits = (item)=> {
    let costOfUnits = (item.unit_ex_wastage * (item.cost_per_unit*(1 + item.wastage_percentage/100)))
    // if(item._id === "6455d407c9b5d4f7613360a6"){
    //   console.log(typeof item.wastage_percentage)
    //   console.log(1 + item.wastage_percentage/100)
    //   console.log((item.cost_per_unit*(1 + item.wastage_percentage/100)).toFixed(2))
    //   console.log("costOfUnits  : ",item.name," - ",costOfUnits.toFixed(2))
    // }
    return Math.round(costOfUnits * 100)/100
    // return Math.floor( (  costOfUnits  ) * 100)/100


  }

  const calculateContingency = (item)=>{
    let contingencyValue = (calculateTotalSellPriceOfUnitsValue(item)*item.contingency_percentage/100)
    //  if(item._id === "6455d407c9b5d4f7613360a6"){
    //   console.log(typeof item.contingency_percentage)
    //   // console.log(1 + item.wastage_percentage/100)
    //   // console.log((item.cost_per_unit*(1 + item.wastage_percentage/100)).toFixed(2))
    //   console.log("ContingencyValue  : ",item.name," - ",contingencyValue)
    // }
    return Math.round(contingencyValue *100)/100
    // return Math.floor( (  contingencyValue  ) * 100)/100
  }
  const calculateMarkupValue = (item)=>{
    let markupValue = ((parseFloat(calculateTotalSellPriceOfUnitsValue(item))+ parseFloat(calculateContingency(item)))*item.markup_percentage/100)
    //  if(item._id === "6455d407c9b5d4f7613360a6"){
    //   console.log(typeof item.contingency_percentage)
    //   console.log(calculateCostOfUnits(item)+calculateContingency(item))
    //   // console.log((item.cost_per_unit*(1 + item.wastage_percentage/100)).toFixed(2))
    //   console.log("Mark Up Value  : ",item.name," - ",markupValue)
    // }
    // return markupValue.toFixed(2)
    return Math.round(markupValue*100)/100
    // return Math.floor( (  markupValue  ) * 100)/100

  }


  const calculateClientSalesTax = (item) =>{
    let clientSalesTax = ((parseFloat(calculateTotalSellPriceOfUnitsValue(item))+parseFloat(calculateContingency(item))+parseFloat(calculateMarkupValue(item)))*item.client_sales_tax_percentage/100)

    // if(item._id === "6455d407c9b5d4f7613360a6"){
      
    //   console.log("JUst another",)
    //   console.log("Client Sales Tax", clientSalesTax)
    // }
    return Math.round(clientSalesTax)
  }


  const calculateClientCharge = (item)=>{
    // let clientCharge = (parseFloat(calculateCostOfUnits(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)) ).toFixed(2)
    // let clientCharge = Math.ceil((parseFloat(calculateCostOfUnits(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)) ))  
    let clientCharge = (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))+parseFloat(calculateClientSalesTax(item)))  
    // return Math.floor(clientCharge)
    return Math.ceil(clientCharge)
  }

  const calculateOverheads = (item) => {
    let overhead = ((  parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateContingency(item)) + parseFloat(calculateMarkupValue(item))  ) * overheadsPercentage/100)
    return Math.round(overhead * 100)/100
  }

  const calculateMargin = (item) => {
    let marginBeforeTax = (parseFloat(calculateClientCharge(item)) - ( parseFloat(calculateOverheads(item)) + parseFloat(calculateTotalSellPriceOfUnitsValue(item)) + parseFloat(calculateClientSalesTax(item)) ))
    return Math.round(marginBeforeTax * 100)/100
  }

  
  const calculateGMPBuffer = (item)=>{
    let gmpBuffer = (parseFloat(calculateTotalSellPriceOfUnitsValue(item)) * (item.gmp_percentage/100))
    return Math.round(gmpBuffer * 100) /100
  }


  //=== validate for old formate unique Labour name
  // const validateLabourName = (event)=>{
  //     //console.log(section.name.equalsIgnoreCase(event.target.value))
  //     if(items.some((currentLabour)=>currentLabour.name.toLowerCase()=== event.target.value.toLowerCase()) || event.target.value==='' ){
  //         if(labour.name.toLowerCase()!== event.target.value.toLowerCase()){
  //             setIsDuplicateName(true)
  //         }
          
  //     }
  //     else {
  //         setIsDuplicateName(false)
          
  //     }
  //     setNewLabourName((prev)=>event.target.value)
      
         
  // }

  // const validateLabourName = (event)=>{
  //     //console.log(section.name.equalsIgnoreCase(event.target.value))
  //     if(items.some((currentLabour)=>currentLabour.name.toLowerCase()=== event.target.value.toLowerCase()) || event.target.value==='' ){
  //         if(labour.name.toLowerCase()!== event.target.value.toLowerCase()){
  //             setIsDuplicateName(true)
  //         }
          
  //     }
  //     else {
  //         setIsDuplicateName(false)
          
  //     }
  //     setNewLabourName((prev)=>event.target.value)
      
         
  // }

  // const addtocheckList = (event,id,type)=>{

  //   if(event.target.checked && billOfQuantities.TYPES.ITEM===type && !checkedObj.items.includes(id)){
  //       console.log("here it comes item")
  //       setCheckedObj(prev=>{
  //         prev.items.push(id)
  //         return prev
  //       })
        
  //   }
  //   else if(event.target.checked && billOfQuantities.TYPES.SECTION===type && !checkedObj.sections.includes(id)){
  //     console.log("here it comes section")
  //     setCheckedObj(prev=>{
  //       prev.sections.push(id)
  //       return prev
  //     })
  //    // checkedObj.sections.push(id)
  //   }
  //   else if (event.target.checked && billOfQuantities.TYPES.LABOUR===type && !checkedObj.labours.includes(id)){
  //     console.log("here it comes labour")
  //     setCheckedObj(prev=>{
  //       prev.labours.push(id)
  //       return prev
  //     })
  //     //.labours.push(id)
  //   }
  //   else if(!event.target.checked && billOfQuantities.TYPES.ITEM===type && checkedObj.items.includes(id)){
  //       console.log("here it comes remove item")
  //       //filter
  //       //checkedObj.items.push(id)
  //       setCheckedObj(prev=>{
  //         prev.items =prev.items.filter(itemId=>{
  //           return itemId!==id
  //         })
          
  //         return prev
  //       })
        
  //   }
  //   else if(!event.target.checked && billOfQuantities.TYPES.SECTION===type && checkedObj.sections.includes(id)){
  //     console.log("here it comes section")
  //     //checkedObj.sections.push(id)
  //     setCheckedObj(prev=>{
  //       prev.sections =prev.sections.filter(sectionId=>sectionId!==id)
  //       return prev
  //     })
  //   }
  //   else if (!event.target.checked && billOfQuantities.TYPES.LABOUR===type && checkedObj.labours.includes(id)){
  //     console.log("here it comes remove labour")
  //    // checkedObj.labours.push(id)
  //    setCheckedObj(prev=>{
  //     prev.labours =prev.labours.filter(laboursId=>laboursId!==id)
  //     return prev
  //   })
  //   }
  //   console.log("checkedObj in change",checkedObj)
  // }

  // const isCurrentChecked = (id,type)=>{
  //   if(type === billOfQuantities.TYPES.ITEM && checkedDataObject.items.includes(id)){
  //       return true
  //   }
  //   else if(type === billOfQuantities.TYPES.LABOUR && checkedDataObject.labours.includes(id)){
  //       return true
  //   }
  //   else if(type === billOfQuantities.TYPES.SECTION && checkedDataObject.sections.includes(id)){
  //       return true
  //   }
  
  // }

  const isCurrentChecked = (id,type)=>{
    // if(type === billOfQuantities.TYPES.ITEM && checkedDataObject.items.includes(id)){
    //     return true
    // }
    // if(type === billOfQuantities.TYPES.LABOUR && checkedDataObject.labours.includes(id)){
    //     return true
    // }
    if(type === billOfQuantities.TYPES.SECTION && checkedDataObject.sections.includes(id)){
        return true
    }
    else if (type !==billOfQuantities.TYPES.SECTION && checkedDataObject.items.includes(id)){
      return true
    }
   
  }
  // const createOnSelect = (event,) => {

  //   // if(event.type ===billOfQuantities.TYPES.LABOUR){

  //   //   dispatch(createItem({item : { name : event.name, sectionId : labour.section_id, type : event.type}}))
  //   // }
  //   // else if (event.type === billOfQuantities.TYPES.ITEM){
  //   //   dispatch(createItem({item : { name : event.name, sectionId : labour.section_id , type : event.type, labourId : labour._id ,specialism : billOfQuantities.TYPES.ITEM}}))
  //   // }
  //   // if(event.name === "Add New Item"){
  //   //   dispatch(createItem({item :{section_id : labour.section_id,labour_id : labour._id, labour_specification : billOfQuantities.TYPES.SPECIFIED, item_specificaiton : billOfQuantities.TYPES.SPECIFIED}}))
  //   // }

  //   if(event.type ===billOfQuantities.TYPES.LABOUR){

  //     dispatch(createItem({item : {name : event.name, sectionId : labour.section_id, type : event.type , specification : billOfQuantities.TYPES.SPECIFIED, uom : event.uom ,sell_unit_price : event.sell_unit_price, buy_unit_price: event.buy_unit_price, item_type : event.item_type}}))
  //   }
  //   else if (event.type === billOfQuantities.TYPES.ITEM){
  //     dispatch(createItem({item : {name : event.name, sectionId : labour.section_id , type : event.type, labourId : labour._id, specification : billOfQuantities.TYPES.SPECIFIED, uom : event.uom, sell_unit_price : event.sell_unit_price, buy_unit_price: event.buy_unit_price, item_type : event.item_type}}))
  //   }
  

  // };

  const formatResult= (item)=>{
    
    return(
      <div className="result-wrapper">
          <span className="result-span">{item.name} : {item.type}</span>
      </div>
    )
  }
  
  // const DisplayItems = () =>{
  //   return itemIdArray.map((itemId)=>{
  //     // item , labour, overheadsPercentage
  //      let item = items.find((i)=> i._id ===itemId)
  //       if(item.labour_id === labour._id && item.type === billOfQuantities.TYPES.ITEM && item.specification === billOfQuantities.TYPES.SPECIFIED ){
  //          return ( <Item item={item} labour={labour} overheadsPercentage={overheadsPercentage} itemRequestData={items} pricingListCurrent={pricingListCurrent}/> )
  //       }
  //   })
  // }

  const DisplayItems = () =>{
    // const items = useSelector((state)=>state.pricing.itemRequestData)
    const itemsId = useSelector((state)=>state.pricing.itemsId)
    const nestedItems = useSelector((state)=>state.pricing.nestedItems)
    const nestedItemIdArray =nestedItems.find((nestedItem)=>nestedItem.labourId === labourId).nestedItemIdArray
    console.log("CCC",itemsId[labourId], nestedItemIdArray)
    return nestedItemIdArray.map((itemId,index )=>{
    
                   return ( 
                
                                      
                    <Item key={itemId} index={index}  itemId={itemId} labourId={labourId} overheadsPercentage={overheadsPercentage}  pricingListCurrent={pricingListCurrent} pricingListCurrentLatest={pricingListCurrentLatest}/>
                   )

    })
    // return itemsId[labourId].map((itemId)=>{
    //   // item , labour, overheadsPercentage
    // //    let item = items.find((i)=> i._id ===itemId)

    //     // if(item.labour_id === labour._id && item.type === billOfQuantities.TYPES.ITEM && item.specification === billOfQuantities.TYPES.SPECIFIED ){
    //     //    return ( <Item item={item} labour={labour} overheadsPercentage={overheadsPercentage} itemRequestData={items} pricingListCurrent={pricingListCurrent}/> )
    //     // }
    //                return ( <Item itemId={itemId} labourId={labourId} overheadsPercentage={overheadsPercentage}  pricingListCurrent={pricingListCurrent}/> )

    // })
  }


  const LabourColumn = ()=>{
    const items = useSelector((state)=>state.pricing.itemRequestData)
    const labour = items.find((i)=>i._id ===labourId)
    const initialValue = { value: labour, label: labour.name };
    const [selectedOption, setSelectedOption] = useState(initialValue);
    return(
        <div  className="row m-0">
      <div className="col-3 p-0">
      <div className='row row-cols-auto view-pricing-icon-container'>
          <div className="d-flex align-items-center">
                                            <img src={dragIcon} className="col" alt='ellipseIcon'/>

                                            <div className="pricing-dropdown-container col d-flex align-items-center">   
                                                        <FormControl  className='pricing-dropdown-icon col' size="small">
                                                              {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                              <Select
                                                                labelId="demo-select-small-label"
                                                                id="demo-select-small-item"
                                                                // defaultValue={labour.status === undefined? " " : labour.status}
                                                                defaultValue={labour.status === undefined? "" : labour.status}
                                                                label="Age"
                                                                onChange={(e)=> {dispatch(updateItemData({item :  {...labour, status :e.target.value}}))}}
                                                                IconComponent={()=>null}
                                                                
                                                              >
                                                                <MenuItem value="">
                                                                  <em></em>
                                                                </MenuItem>
                                                                <MenuItem value={1}><img  className='ui avatar image pricing-icon col' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={yellowEllipseIcon}/></MenuItem>
                                                                <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={greenEllipseIcon}/></MenuItem>
                                                                <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
                                                                <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem>
                                                                {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                              </Select>
                                                            </FormControl>
                                                          </div>
                                                          <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                            <FormControl  className='pricing-dropdown-icon col'  >
                                                              {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                              <Select
                                                                labelId="demo-select-small-label"
                                                                id="demo-select-small-item"
                                                                // defaultValue={labour.cost_type === undefined? " " : labour.cost_type}
                                                                defaultValue={labour.cost_type === undefined? "" : labour.cost_type}
                                                                label="Age"
                                                                onChange={(e)=> {dispatch(updateItemData({item :  {...labour, cost_type :e.target.value}}))}}
                                                                IconComponent={()=>null}
                                                                
                                                              >
                                                                <MenuItem value="">
                                                                  <em></em>
                                                                </MenuItem>
                                                                <MenuItem value={1}><img  className='ui avatar image  ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={pcIcon}/></MenuItem>
                                                                <MenuItem value={2}><img  className='ui avatar image pricing-icon ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={exIcon}/></MenuItem>
                                                                {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                                <MenuItem value={3}> -- </MenuItem>
                                                                {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                                {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                              </Select>
                                                            </FormControl>
                                                          </div>


                                                          <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                            <FormControl  className='pricing-dropdown-icon col'  >
                                                              {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                              <Select
                                                                labelId="demo-select-small-label"
                                                                id="demo-select-small-item"
                                                                defaultValue={labour.hammer === undefined? "" : labour.hammer}
                                                                label="Age"
                                                                onChange={(e)=> {dispatch(updateItemData({item :  {...labour, hammer :e.target.value}}))}}
                                                                IconComponent={()=>null}
                                                                
                                                              >
                                                                <MenuItem value="">
                                                                  <em></em>
                                                                </MenuItem>
                                                                <MenuItem value={1}><img  className='ui avatar image pricing-icon ' style={{height : "15px", width: "16px",background: "#EFEFEF", border:"none"}} src={gavelIcon}/></MenuItem>
                                                                <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
                                                                {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                                <MenuItem value={3}> || </MenuItem>
                                                                {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                                {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                              </Select>
                                                            </FormControl>
                                                          </div>
                                                          <div className="pricing-dropdown-container col d-flex align-items-center"> 
                                                            <FormControl  className='pricing-dropdown-icon col'   >
                                                              {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                              <Select
                                                                labelId="demo-select-small-label"
                                                                id="demo-select-small-item"
                                                                defaultValue={labour.suppiler === undefined? "" : labour.suppiler}
                                                                label="Age"
                                                                onChange={(e)=> {dispatch(updateItemData({item :  {...labour, suppiler :e.target.value}}))}}
                                                                IconComponent={()=>null}
                                                               
                                                              >
                                                                <MenuItem value="">
                                                                  <em></em>
                                                                </MenuItem>
                                                                <MenuItem value={1}  className="pricing-icon-container"><img  className='ui avatar image  unique-icon' style={{height : "11px", width: "15px",background: "#EFEFEF", border:"none"}} src={blueTruck}/></MenuItem>
                                                                {/* <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem> */}
                                                                {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                                <MenuItem value={3}> -- </MenuItem>
                                                                {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                                {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                              </Select>
                                                            </FormControl>
                                                          </div>

                                            {/* <img src={greenEllipseIcon} className="col" alt='settingsIcon'/>
                                            <img src={pcIcon} className="col" alt='hammerIcon'/>
                                            <img src={gavelIcon} className="col" alt='whiteTruckIcon'/> */}
                                            <img  className='col '  border="0" style={{width: "16px",height : "16px", background: "#EFEFEF"}} src={transparentIcon}/>
                                            <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
                                            {/* <select  className='labour-select-input w-25 m-0 p-0' >
                                                    <option value="Prime">IC2</option>
                                                    <option value="Provitional"><img src={blueTruckIcon}></img></option>
                                                    <option value="Excluded">Excluded</option>
                                                </select> */}
                                            {/* <img src={treeSwichterOpenIcon} className="col"  onClick={() => {setShow((prev) => !prev)}}  alt='whiteTruckIcon'/> */}
                                            {show?
                                              <img src={treeSwichterOpenIcon} className="col"  onClick={() => {setShow((prev) => !prev)}}  alt='whiteTruckIcon'/>

                                            :<img src={treeSwichterCloseIcon} className="col"  onClick={() => {setShow((prev) => !prev)}}  alt='whiteTruckIcon'/>
                                          }
                                                                                    {/* <input className="form-check-input m-1 " type="checkbox" ></input> */}
                                            {/* {isCurrentChecked(labour._id , billOfQuantities.TYPES.LABOUR)?
                                                  <input className="form-check-input pricing-checkbox m-1 align-items-center" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} defaultChecked></input>   
                                                :
                                                
                                                <input className="form-check-input pricing-checkbox m-1 align-items-center " type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}}  ></input>   
                                              }     */}

                                              {isCurrentChecked(labour._id , billOfQuantities.TYPES.LABOUR)?
                                                                        <input className="form-check-input  pricing-checkbox col" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} defaultChecked></input>   
                                                                      :
                                                                      
                                                                      <input className="form-check-input pricing-checkbox col" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} ></input>   
                                                                    }

          </div>
                
                <div style={{ width: 150}}>
                {/* <ReactSearchAutocomplete
                  // onFocus={(e) => handleOnFocus(e.target.id)}
                  placeholder={labour.name}
                  fuseOptions={{keys : ["name","type"]}}
                  items={pricingListCurrent.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.LABOUR)} 
                  showIcon= {false}
                  formatResult = {formatResult}
                  onSelect = {(event)=>{dispatch(updateItemData({item : {...labour, name : event.name, type : event.type,  uom:event.uom , specialism :labour.specialism, specification : labour.specification , buy_unit_price : event.buy_unit_price, sell_unit_price : event.sell_unit_price, markup_percentage : projectRequestData.markup_percentage,contingency_percentage : projectRequestData.contingency_percentage, gmp_percentage: projectRequestData.gmp_percentage, client_sales_tax_percentage : projectRequestData.client_sales_tax_percentage, client_retainer_percentage : projectRequestData.client_retainer_percentage}}))}}
                  styling={{
                    height: "34px",
                    border: "none",
                    // border: "1px solid darkgreen",
                    borderRadius: "4px",
                    backgroundColor: "#EFEFEF",
                    boxShadow: "none",
                    hoverBackgroundColor: "lightgreen",
                    color: "black",
                    // fontSize: "12px",
                    // fontFamily: "Courier",
                    iconColor: "green",
                    lineColor: "lightgreen",
                    placeholderColor: "darkgreen",
                    clearIconMargin: "3px 8px 0 0",
                    zIndex :"auto",
                    // zIndex: {labourZIndex},
                  }}
                  
                /> */}
              <CreatableSelect
   
      onChange= {(event)=>{
        const selectedValue = { value: event, label: event.name };
        setSelectedOption(selectedValue);
        dispatch(updateItemData({item : {...labour, name : event.name, type : event.type,  uom:event.uom , specialism :labour.specialism, specification : labour.specification , buy_unit_price : event.buy_unit_price, sell_unit_price : event.sell_unit_price, markup_percentage : projectRequestData.markup_percentage,contingency_percentage : projectRequestData.contingency_percentage, gmp_percentage: projectRequestData.gmp_percentage, client_sales_tax_percentage : projectRequestData.client_sales_tax_percentage, client_retainer_percentage : projectRequestData.client_retainer_percentage}}))}}
      
      options={pricingListCurrentLatest.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.LABOUR)} 
      value= {selectedOption}
    />    
          </div>
        </div>
      </div>


      {/* <div className="section-container-labour-name col-3 d-flex "> */}
        {/* <input className="form-check-input" type="checkbox"></input> */}
        {/* {isCurrentChecked(labour._id , billOfQuantities.TYPES.LABOUR)?
                          <input className="form-check-input pricing-checkbox" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} defaultChecked></input>   
                        :
                        
                        <input className="form-check-input pricing-checkbox" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} ></input>   
                      } */}
        {/* <input
          type="text"
          list="labourDataList"
          defaultValue={labour.name}
          className=" section-input form-control m-0 p-0"
         
          onBlur={(e) => {
            if(e.target.value.toLowerCase()!==labour.name.toLowerCase() && !isDuplicateName && pricingListRequestData.some((addUp)=>addUp.name=== e.target.value)){

              dispatch(updateItemData({item : { ...labour, name: e.target.value }}));
            }
          }}
          // onChange ={(e)=>{validateLabourName(e)}}
        ></input>
          <datalist id="labourDataList">
            {
              pricingListRequestData.map((addUp)=>{
                if(addUp.type === billOfQuantities.TYPES.LABOUR && !pricingListRequestLoading){
                  return (
                    <option key={addUp._id} value={addUp.name}>{addUp.name}</option>
                  )
                }
              })
            }
          </datalist> */}
           

          {/* <div style={{ width: 400}}>
          <ReactSearchAutocomplete
            placeholder={labour.name}
            fuseOptions={{keys : ["name","type"]}}
            items={pricingListCurrent.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.LABOUR)} 
            showIcon= {false}
            formatResult = {formatResult}
            onSelect = {(event)=>{dispatch(updateItemData({item : {...labour, name : event.name, type : event.type}}))}}
            styling={{
              height: "34px",
              border: "none",
              // border: "1px solid darkgreen",
              borderRadius: "4px",
              backgroundColor: "#EFEFEF",
              boxShadow: "none",
              hoverBackgroundColor: "lightgreen",
              color: "black",
              // fontSize: "12px",
              // fontFamily: "Courier",
              iconColor: "green",
              lineColor: "lightgreen",
              placeholderColor: "darkgreen",
              clearIconMargin: "3px 8px 0 0",
              zIndex : "auto",
              // zIndex: {labourZIndex},
            }}
          />
        </div> */}
        {/* {isDuplicateName? <p>Please Enter a unique Labour name</p> : null} */}
      {/* </div> */}

      
      <div className="col-9">
              <div className="row row-cols-10">
                              
                                               {/*--------- Item Name for Proposal Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_NAME_FOR_PROPOSAL))?
                                                    <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={labour.item_names_for_proposal}  onBlur={(e)=>{
                                                                              
                                                    //   dispatch(updateItemData({item :{...labour, item_names_for_proposal: e.target.value}}))
                                                      if(e.target.value !== labour.item_names_for_proposal){

                                                        dispatch(updateItem({...labour, item_names_for_proposal : e.target.value}))
                                                      } 
                                                  }}  /></div>
                                                :null}                                                              

                                                
                                                {/*--------- ITEM_TYPE Column---------- */}
                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_TYPE))?
                                                  <div className='col justify-content-start'>
                                                        <select className='w-100 section-input' defaultValue={labour.item_type} onBlur={(e)=>{
                                                           // dispatch(updateItem({...item, status :e.target.value}))
                                                        //    dispatch(updateItemData({item : {...labour, item_type :e.target.value}}))
                                                           if(e.target.value !== labour.item_type && (e.target.value === "Staff" || e.target.value === "MathLab" || e.target.value === "Sub(Labour only)" || e.target.value === "Sub(MathLab)")){

                                                            dispatch(updateItem({...labour, item_type : e.target.value}))
                                                          }
                                                          else if ( e.target.value !== labour.item_type){
                                                            const itemsArray =items.filter((item)=>item.labour_id === labour._id)
                                                            console.log("iii",itemsArray)

                                                            itemsArray.forEach(item => {
                                                                let itemObject = {...item}
                                                                delete itemObject.labour_id

                                                                dispatch(updateItem({...itemObject, specification : billOfQuantities.TYPES.UNSPECIFIED}))
                                                            });
                                                            dispatch(updateItem({...labour, item_type : e.target.value, specification : billOfQuantities.TYPES.UNSPECIFIED}))

                                                            dispatch(setIdArray())
                                                          }
                                                           }} >
                                                               <option value="Assembly (Global)">Assembly (Global)</option>
                                                               <option value="Equipment">Equipment</option>
                                                               <option value="Material">Material</option>
                                                               <option value="MathLab">MatLab</option>
                                                               <option value="Other">Other</option>
                                                               <option value="Staff">Staff</option>
                                                               <option value="Sub(Labour only)">Sub(Labour only)</option>
                                                               <option value="Sub(Material only)">Sub(Material only)</option>
                                                               <option value="Sub(MathLab)">Sub(MatLab)</option>
                                                           </select>

                                                  </div>
                                                                  :null}

                                                                            

                                                                          


                                                {/*---------Parent Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PARENT))?
                                                     <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={labour.parent}  onBlur={(e)=>{
                                                                              
                                                    //   dispatch(updateItemData({item :{...labour, parent: e.target.value}}))
                                                      if(e.target.value !== labour.parent){

                                                        dispatch(updateItem({...labour, parent : e.target.value}))
                                                      } 
                                                  }}  /></div>
                                                :null}                                                              


                                                {/*---------Cost Code Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_CODE))?
                                                     <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={labour.cost_code}  onBlur={(e)=>{
                                                                              
                                                    //   dispatch(updateItemData({item :{...labour, cost_code: e.target.value}}))
                                                      if(e.target.value !== labour.cost_code){

                                                        dispatch(updateItem({...labour, cost_code : e.target.value}))
                                                      } 
                                                  }}  /></div>
                                                :null}
                                                
                                                  {/*--------- Units exc Wastage Column---------- */}

                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_EX_WASTAGE))?
                                                  <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={labour.unit_ex_wastage}  onBlur={(e)=>{
                                                                              
                                                    // dispatch(updateItemData({item :{...labour, unit_ex_wastage: e.target.value}}))
                                                    if(e.target.value !== labour.unit_ex_wastage){

                                                        dispatch(updateItem({...labour, unit_ex_wastage : e.target.value}))
                                                      } 
                                                }}  /></div>
                                                  :null}

                                                
                                                  {/*--------- UOM Column---------- */}


                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
                                                    <div className='col justify-content-start'>
                                                          <select className='w-100 section-input' defaultValue={labour.uom} onBlur={(e)=>{
                                                           // dispatch(updateItem({...item, status :e.target.value}))
                                                        //    dispatch(updateItemData({item : {...labour, uom :e.target.value}}))
                                                           if(e.target.value !== labour.uom){

                                                            dispatch(updateItem({...labour, uom : e.target.value}))
                                                          } 
                                                      
                                                           }} >
                                                               <option value="Ea">Ea</option>
                                                               <option value="M2"><p>M<sup>2</sup></p></option>
                                                               <option value="M3">M<sup>3</sup></option>
                                                               <option value="Hrs">Hrs</option>
                                                               <option value="Hrs">Days</option>
                                                               <option value="Im">Im</option>
                                                               <option value="Kg">Kg</option>
                                                               
                                                               
                                                           </select>
                                                    </div>
                                                  :null}

                                                

                                                  {/*--------- Wastage % Column---------- */}



                                                  
                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_PERCENTAGE))?
                                                  <div className='col justify-content-start'><span class="currency-input">
                                                    <input  type='number' className='section-input ' defaultValue={labour.wastage_percentage}  onBlur={(e)=>{
                                                                              
                                                                            //   dispatch(updateItemData({item :{...labour, wastage_percentage: e.target.value}}))
                                                                            if(e.target.value !== labour.wastage_percentage){

                                                                                dispatch(updateItem({...labour, wastage_percentage : e.target.value}))
                                                                              } 
                                                                          }}   /><p>%</p> </span>
                                                                    </div>
                                                  :null}

                                                  {/*--------- Wastage Value---------- */}




                                                  
                                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_VALUE))?
                                                    <p className="col"> { calculateWastageValue(labour)} </p>
                                                :null} 



                                              {/*--------- Units inc Wastage---------- */}


                                                  
                                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_INC_WASTAGE))?
                                                    <p className="col"> { calculateUnitsIncWastage(labour)} </p>
                                                :null} 




                                                
                                                  {/*--------- BUY Unit $ Column---------- */}

                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.BUY_UNIT_PRICE))?
                                                  <div className='col justify-content-start'><span class="currency-input"><p>$</p><input  type='text' className='section-input ' defaultValue={labour.buy_unit_price}  onBlur={(e)=>{
                                                                              
                                                    // dispatch(updateItemData({item :{...labour, buy_unit_price: e.target.value}}))
                                                    if(e.target.value !== labour.buy_unit_price){

                                                        dispatch(updateItem({...labour, buy_unit_price : e.target.value}))
                                                      } 
                                                }}  /></span></div>
                                                  :null}



                                                  {/*--------- Sell Unit $ Column---------- */}

                                                
                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_UNIT_PRICE))?
                                                    <div className='col justify-content-start'><span class="currency-input"><p>$</p><input  type='text' className='section-input ' defaultValue={labour.sell_unit_price}  onBlur={(e)=>{
                                                                              
                                                    //   dispatch(updateItemData({item :{...labour, sell_unit_price: e.target.value}}))
                                                      if(e.target.value !== labour.sell_unit_price){

                                                        dispatch(updateItem({...labour, sell_unit_price : e.target.value}))
                                                      } 
                                                  }}  /></span>
                                                      </div>
                                                  :null}


                                                    {/*--------- Sell Adjestment Unit Value---------- */}


                                                  
                                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_ADJ_UNIT_VALUE))?
                                                    <p className="col"> { calculateSellAdjesmentUnitValue(labour)} </p>
                                                :null}                           


                                                {/*---------Supplier Inc Sales Tax Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_INC_SALES_TAX))?
                                                    <div className='col justify-content-start'>
                                                    <select className='w-100 section-input' defaultValue={labour.supplier_inc_sales_tax} onBlur={(e)=>{
                                                    // dispatch(updateItem({...item, status :e.target.value}))
                                                    // dispatch(updateItemData({item : {...labour, supplier_inc_sales_tax :e.target.value}}))
                                                    if(e.target.value !== labour.supplier_inc_sales_tax){

                                                        dispatch(updateItem({...labour, supplier_inc_sales_tax : e.target.value}))
                                                      } 
                                               
                                                    }} >
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                       
                                                        
                                                    </select>
                                               </div>
                                                :null}                                                              


                                                {/*--------- Supplier Item Code Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_ITEM_CODE))?
                                                    <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={labour.supplier_item_code}  onBlur={(e)=>{
                                                                              
                                                    //   dispatch(updateItemData({item :{...labour, supplier_item_code: e.target.value}}))
                                                      if(e.target.value !== labour.supplier_item_code){

                                                        dispatch(updateItem({...labour, supplier_item_code : e.target.value}))
                                                      } 
                                                  }}  /></div>
                                                :null}
                                                




                                                
                                               {/*--------- Total Sell Price Of Units---------- */}


                                                  
                                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TOTAL_SELL_PRICE_OF_UNITS))?
                                                           <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateTotalSellPriceOfUnitsValue(labour)}</span>
                                                           </span> </div>
                                                    // <p className="col"> { calculateTotalSellPriceOfUnitsValue(labour)} </p>
                                                :null} 



                                                 {/*--------- Pre-markup % Column---------- */}


                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PRE_MARKUP_PERCENTAGE))?
                                                  <div className='col justify-content-start'><span class="currency-input">
                                                  <input  type='number' className='section-input ' defaultValue={labour.pre_markup_percentage}  onBlur={(e)=>{
                                                                              
                                                                            //   dispatch(updateItemData({item :{...labour, pre_markup_percentage: e.target.value}}))
                                                                            if(e.target.value !== labour.pre_markup_percentage){

                                                                                dispatch(updateItem({...labour, pre_markup_percentage : e.target.value}))
                                                                              } 
                                                                          }} /><p>%</p> </span>
                                                    
                                                    </div>
                                                  :null}


                                                 {/*--------- Pre-markup Value---------- */}


                                                  
                                               {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PRE_MARKUP_VALUE))?
                                                    <p className="col"> { calculatePreMarkupValue(labour)} </p>
                                                :null} 



                                                
                                                  {/*--------- Contingency % Column---------- */}


                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_PERCENTAGE))?
                                                  <div className='col justify-content-start'><span class="currency-input">
                                                 <input  type='text' className='section-input ' defaultValue={labour.contingency_percentage}  onBlur={(e)=>{
                                                                              
                                                                            //   dispatch(updateItemData({item :{...labour, contingency_percentage: e.target.value}}))
                                                                            if(e.target.value !== labour.contingency_percentage){

                                                                                dispatch(updateItem({...labour, contingency_percentage : e.target.value}))
                                                                              } 
                                                                          }} /><p>%</p> </span>
                                                    </div>
                                                  :null}


                                                
                                                {/*--------- Contingency Value Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_VALUE))?
                                                  <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateContingency(labour)}</span>
                                                  </span> </div>
                                                    // <p className="col"> {calculateContingency(labour)} </p>
                                                :null}   

                                                
                                                  {/*--------- Markup percentage % Column---------- */}


                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_PERCENTAGE))?
                                                    <div className='col justify-content-start'><span class="currency-input">
                                                    <input  type='number' className='section-input' defaultValue={labour.markup_percentage}  onBlur={(e)=>{
                                                                              
                                                                            //   dispatch(updateItemData({item :{...labour, markup_percentage: e.target.value}}))
                                                                              if(e.target.value !== labour.markup_percentage){

                                                                                dispatch(updateItem({...labour, markup_percentage : e.target.value}))
                                                                              } 
                                                                          }} /><p>%</p> </span></div>

                                                  :null}
                                                
                                                {/*--------- Markup Value Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_VALUE))?
                                                    <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateMarkupValue(labour)}</span>
                                                    </span> </div>
                                                  //   <div className='col justify-content-start'>
                                                  //     <p className="col"> {calculateMarkupValue(labour)} </p>

                                                  // </div>
                                                :null}                                                                


                                                {/*--------- Client Sales Tax Percentage  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_PERCENTAGE))?
                                                    <div className='col justify-content-start'><span class="currency-input">
                                                    <input  type='text' className='section-input ' defaultValue={labour.client_sales_tax_percentage}  onBlur={(e)=>{
                                                                              
                                                                            //   dispatch(updateItemData({item :{...labour, client_sales_tax_percentage: e.target.value}}))
                                                                              if(e.target.value !== labour.client_sales_tax_percentage){

                                                                                dispatch(updateItem({...labour, client_sales_tax_percentage : e.target.value}))
                                                                              } 
                                                                          }}  /><p>%</p> </span></div>
                                                :null}                                                              


                                                {/*--------- Client Sales Tax Value  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_VALUE))?
                                                    <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateClientSalesTax(labour)}</span>
                                                    </span> </div>
                                                  //   <div className='col justify-content-start'>
                                                  //     <p className="col"> {calculateClientSalesTax(labour)}  </p>

                                                  // </div>
                                                :null}
                                                


                                                
                                                  {/*--------- Client Charge Inc Column---------- */}

                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE_INC))?
                                                    <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateClientCharge(labour)}</span>
                                                    </span> </div>
                                                    // <div className='col justify-content-start'>{calculateClientCharge(labour)}</div>
                                                  :null}
                                                                            


                                                {/*--------- Overhead  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.OVERHEAD))?
                                                    <div className='col justify-content-start'>
                                                      <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateOverheads(labour)}</span>
                                                    </span> </div>
                                                      {/* <p className="col"> {calculateOverheads(labour)}  </p> */}

                                                  </div>
                                                :null}                                                              


                                                {/*--------- Margin  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
                                                    <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateMargin(labour)}</span>
                                                    </span> </div>

                                                    // <div className='col justify-content-start'>

                                                    //   <p className="col"> {calculateMargin(labour)}  </p>
                                                    // </div>
                                                :null}      

                                              {/*--------- GMP Buffer %   Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_PERCENTAGE))?
                                                          <div className='col justify-content-start'><span class="currency-input">
                                                          <input  type='number' className='section-input' defaultValue={labour.gmp_percentage}  onBlur={(e)=>{
                                                                                  
                                                                                //   dispatch(updateItemData({item :{...labour, gmp_percentage: e.target.value}}))
                                                                                  if(e.target.value !== labour.gmp_percentage){

                                                                                    dispatch(updateItem({...labour, gmp_percentage : e.target.value}))
                                                                                  } 
                                                                              }} /><p>%</p> </span>
                                                          
                                                          </div>
                
                                                    
                                                    

                                                :null}                                                              
                                                {/*--------- GMP Buffer V   Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_VALUE))?
                                                <div className='col justify-content-start'><span class="section-input-container"><p>$</p><span >{calculateGMPBuffer(labour)}</span>
                                                </span></div>
                                                      
                                                :null}  


                                                {/*--------- Supplier Contact Id  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_CONTACT_ID))?
                                                     <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={labour.suppiler}  onBlur={(e)=>{
                                                                              
                                                    //   dispatch(updateItemData({item :{...labour, suppiler: e.target.value}}))
                                                    if(e.target.value !== labour.suppiler){

                                                        dispatch(updateItem({...labour, suppiler : e.target.value}))
                                                      } 
                                                  }} /></div>
                                                :null}                                                              


                                                {/*--------- Contractor Item Note  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTRACTOR_ITEM_NOTE))?
                                                     <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={labour.contractor_item_note}  onBlur={(e)=>{
                                                                              
                                                    //   dispatch(updateItemData({item :{...labour, contractor_item_note: e.target.value}}))
                                                      if(e.target.value !== labour.contractor_item_note){

                                                        dispatch(updateItem({...labour, contractor_item_note : e.target.value}))
                                                      } 
                                                  }} /></div>
                                                :null}                                                              


                                                {/*--------- Tender Item Note  Column---------- */}

                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TENDER_ITEM_NOTE))?
                                                      <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={labour.tender_item_note}  onBlur={(e)=>{
                                                                              
                                                        // dispatch(updateItemData({item :{...labour, tender_item_note: e.target.value}}))
                                                        if(e.target.value !== labour.tender_item_note){

                                                            dispatch(updateItem({...labour, tender_item_note : e.target.value}))
                                                          } 
                                                    }} /></div>
                                                :null}  

                                                {/*--------- Pricinglist ? Column---------- */}

                                                
                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.IS_PRICELIST_ITEM))?
                                                    <div className='col justify-content-start'>
                                                           <select className='w-100 section-input' defaultValue={labour.is_pricelist_item} onBlur={(e)=>{
                                                           // dispatch(updateItem({...item, status :e.target.value}))
                                                        //    dispatch(updateItemData({item : {...labour, is_pricelist_item :e.target.value}}))
                                                           if(e.target.value !== labour.is_pricelist_item){

                                                            dispatch(updateItem({...labour, is_pricelist_item : e.target.value}))
                                                          } 
                                                      
                                                           }} >
                                                               <option value="Yes">Yes</option>
                                                               <option value="No">No</option>
                                                              
                                                               
                                                           </select>
                                                      </div>
                                                  :null}


                                                {/*--------- NESTED  Column---------- */}

                                        {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.NESTED))?
                                          <p className="col"></p>
                                        :null}

                              <div className='view-pricing-user-filter-icon'>

                                  <img  src={threeDotIcon}></img>
                              </div>
              </div>

      </div>

     
      </div>
    )

}


const AddNewItem = ()=> {
  const items = useSelector((state)=>state.pricing.itemRequestData)
  const labour = items.find((i)=>i._id ===labourId)
  const initialValue = { value: labour, label: labour.name };
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const createOnSelect = (event,) => {

      // if(event.type ===billOfQuantities.TYPES.LABOUR){
  
      //   dispatch(createItem({item : { name : event.name, sectionId : labour.section_id, type : event.type}}))
      // }
      // else if (event.type === billOfQuantities.TYPES.ITEM){
      //   dispatch(createItem({item : { name : event.name, sectionId : labour.section_id , type : event.type, labourId : labour._id ,specialism : billOfQuantities.TYPES.ITEM}}))
      // }
      // if(event.name === "Add New Item"){
      //   dispatch(createItem({item :{section_id : labour.section_id,labour_id : labour._id, labour_specification : billOfQuantities.TYPES.SPECIFIED, item_specificaiton : billOfQuantities.TYPES.SPECIFIED}}))
      // }
  
      if(event.type ===billOfQuantities.TYPES.LABOUR){
  
        dispatch(createItem({item : {name : event.name, sectionId : labour.section_id, type : event.type , specification : billOfQuantities.TYPES.SPECIFIED, uom : event.uom ,sell_unit_price : event.sell_unit_price, buy_unit_price: event.buy_unit_price, item_type : event.item_type}}))
      }
      else if (event.type === billOfQuantities.TYPES.ITEM){
        dispatch(createItem({item : {name : event.name, sectionId : labour.section_id , type : event.type, labourId : labour._id, specification : billOfQuantities.TYPES.SPECIFIED, uom : event.uom, sell_unit_price : event.sell_unit_price, buy_unit_price: event.buy_unit_price, item_type : event.item_type}}))
      }
    
  
    };

  return (
      <div className="row m-0" >
       <div className="col-3 p-0">
          <div className="row row-cols-auto view-pricing-icon-container">
                <div>
                  <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                  <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                  <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                  <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                  <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                  <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                  <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                  <img  className='col ' style={{width: "24px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                  <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
                  {/* <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/> */}
                </div>

                <div className="d-flex">
       <div className="" >
         {/* <AddIcon  onClick={()=>{
        
           //dispatch(initializeNewItem(labour._id))
           dispatch(createItem({labourId :labour._id, sectionId : labour.section_id, type :billOfQuantities.TYPES.ITEM}))
         }}  /> */}
           {/* <button className='btn btn-outline-secondary btn-light' data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"> Filters <div className="dropdown-arrow"></div></button> */}
        
           <AddIcon  data-bs-toggle="dropdown" role="button" aria-expanded="false"  onClick={()=>{
               // dispatch(changeLabourModalStatus(section._id))
               // dispatch(createLabour({sectionId : section._id}))
           // dispatch(createItem({ sectionId : section._id , type : billOfQuantities.TYPES.LABOUR}))
           
               }}  />
         <ul className="dropdown-menu">
           {/* {
             pricingListRequestData.map((addUp)=>{
               if(addUp.type === billOfQuantities.TYPES.ITEM && !pricingListRequestLoading){
                 return (
                   <li key={addUp._id} onClick={()=>{ dispatch(createItem({ name :addUp.name, sectionId : labour.section_id , type : billOfQuantities.TYPES.ITEM, labourId :labour._id, specialism : billOfQuantities.TYPES.ITEM} ))}}><a className="dropdown-item" >{addUp.name}</a></li>
                 )
               }
             })
          
           } */}
             {/* <li><hr className="dropdown-divider"/></li> */}
           <li onClick={()=>{ dispatch(changeAddUpModalStatus({sectionId : labour.section_id,labourId : labour._id, labourSpecification : billOfQuantities.TYPES.SPECIFIED, itemSpecification : billOfQuantities.TYPES.SPECIFIED}))}}><a className="dropdown-item" >New labour/Item</a></li>
         </ul>
      </div>
       <div  style={{ width: 110}}>
                           {/* <ReactSearchAutocomplete
                            //  onFocus={handleOnFocus}
                             placeholder={"Add Item"}
                             fuseOptions={{keys : ["name","type"]}}
                              items={pricingListCurrent} 
                             showIcon= {false}
                             formatResult = {formatResult}
                             onSelect = {(event)=>{createOnSelect(event)}}
                             styling={{
                               height: "34px",
                               border: "none",
                               // border: "1px solid darkgreen",
                               borderRadius: "4px",
                               backgroundColor: "#FFFFFF",
                               boxShadow: "none",
                               hoverBackgroundColor: "lightgreen",
                               color: "black",
                               // fontSize: "12px",
                               // fontFamily: "Courier",
                               iconColor: "green",
                               lineColor: "lightgreen",
                               placeholderColor: "#002D62",
                               clearIconMargin: "3px 8px 0 0",
                               zIndex :"auto",
                               // zIndex: {labourZIndex},
                             }}
                           /> */}
                               <CreatableSelect
   
   onChange= {(event)=>{
     const selectedValue = { value: event, label: event.name };
     setSelectedOption(selectedValue);
     createOnSelect(event)}}
   options={pricingListCurrentLatest} 
   value= {selectedOption}
 />   
                       
     </div>
     </div>
     
          </div>
      </div>
    </div>                             

  )
}

//   const DisplayItem = ()=>{
//     console.log("Render | Items ")
//     return items.map((item)=>{
//         if(item.labour_id === labour._id && item.type === billOfQuantities.TYPES.ITEM && item.specification === billOfQuantities.TYPES.SPECIFIED ){
         

//            return(
              
//                 //  <div key={item.id} className={" multi-collapse   collapse row "+show+" "+section.name}> 
//                 <div key={item._id} className="row m-0"> 
//                     <div className="col-3 p-0">
//                         <div className="row row-cols-auto view-pricing-icon-container">
//                                     <div className="d-flex align-items-center" >

                                                        
//                                                         <img src={dragIcon} className="col" alt='dragIcon'/>
//                                                         <div className="pricing-dropdown-container col d-flex align-items-center"> 
//                                                             <FormControl  className='pricing-dropdown-icon col' size="small">
//                                                               {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}

//                                                               <Select
//                                                                 labelId="demo-select-small-label"
//                                                                 id="demo-select-small-item"
//                                                                 defaultValue={item.status === undefined? "" : item.status}
//                                                                 label="Age"
//                                                                 onChange={(e)=> {dispatch(updateItemData({item :  {...item, status :e.target.value}}))}}
//                                                                 IconComponent={()=>null}
                                                                
//                                                               >
//                                                                 <MenuItem value="">
//                                                                   <em></em>
//                                                                 </MenuItem>
//                                                                 <MenuItem value={1}><img  className='ui avatar image pricing-icon col' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={yellowEllipseIcon}/></MenuItem>
//                                                                 <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={greenEllipseIcon}/></MenuItem>
//                                                                 <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
//                                                                 <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "13px", width: "13px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem>
//                                                                 {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
//                                                               </Select>
//                                                             </FormControl>
//                                                           </div>
//                                                           <div className="pricing-dropdown-container col d-flex align-items-center"> 
//                                                             <FormControl  className='pricing-dropdown-icon col'  >
//                                                               {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
//                                                               <Select
//                                                                 labelId="demo-select-small-label"
//                                                                 id="demo-select-small-item"
//                                                                 defaultValue={item.cost_type === undefined? "" : item.cost_type}
//                                                                 label="Age"
//                                                                 onChange={(e)=> {dispatch(updateItemData({item :  {...item, cost_type :e.target.value}}))}}
//                                                                 IconComponent={()=>null}
                                                                
//                                                               >
//                                                                 <MenuItem value="">
//                                                                   <em></em>
//                                                                 </MenuItem>
//                                                                 <MenuItem value={1}><img  className='ui avatar image  ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={pcIcon}/></MenuItem>
//                                                                 <MenuItem value={2}><img  className='ui avatar image unique-icon ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={exIcon}/></MenuItem>
//                                                                 {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
//                                                                 <MenuItem value={3}> -- </MenuItem>
//                                                                 {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
//                                                                 {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
//                                                               </Select>
//                                                             </FormControl>
//                                                           </div>


//                                                           <div className="pricing-dropdown-container col d-flex align-items-center"> 
//                                                             <FormControl  className='pricing-dropdown-icon col'  >
//                                                               {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
//                                                               <Select
//                                                                 labelId="demo-select-small-label"
//                                                                 id="demo-select-small-item"
//                                                                 defaultValue={item.hammer === undefined? "" : item.hammer}
//                                                                 label="Age"
//                                                                 onChange={(e)=> {dispatch(updateItemData({item :  {...item, hammer :e.target.value}}))}}
//                                                                 IconComponent={()=>null}
                                                                
//                                                               >
//                                                                 <MenuItem value="">
//                                                                   <em></em>
//                                                                 </MenuItem>
//                                                                 <MenuItem value={1}><img  className='ui avatar image pricing-icon ' style={{height : "15px", width: "16px",background: "#EFEFEF", border:"none"}} src={gavelIcon}/></MenuItem>
//                                                                 <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem>
//                                                                 {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
//                                                                 <MenuItem value={3}> || </MenuItem>
//                                                                 {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
//                                                                 {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
//                                                               </Select>
//                                                             </FormControl>
//                                                           </div>
//                                                           <div className="pricing-dropdown-container col d-flex align-items-center"> 
//                                                             <FormControl  className='pricing-dropdown-icon col'   >
//                                                               {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
//                                                               <Select
//                                                                 labelId="demo-select-small-label"
//                                                                 id="demo-select-small-item"
//                                                                 defaultValue={item.suppiler === undefined? "" : item.suppiler}
//                                                                 label="Age"
//                                                                 onChange={(e)=> {dispatch(updateItemData({item :  {...item, suppiler :e.target.value}}))}}
//                                                                 IconComponent={()=>null}
                                                               
//                                                               >
//                                                                 <MenuItem value="">
//                                                                   <em></em>
//                                                                 </MenuItem>
//                                                                 <MenuItem value={1}  className="pricing-icon-container"><img  className='ui avatar image  unique-icon' style={{height : "11px", width: "15px",background: "#EFEFEF", border:"none"}} src={blueTruck}/></MenuItem>
//                                                                 {/* <MenuItem value={2}><img  className='ui avatar image  ' style={{height : "12px", width: "16px",background: "#EFEFEF", border:"none"}} src={redEllipseIcon}/></MenuItem> */}
//                                                                 {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
//                                                                 <MenuItem value={3}> -- </MenuItem>
//                                                                 {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
//                                                                 {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
//                                                               </Select>
//                                                             </FormControl>
//                                                           </div>

                                                      

                                                            

//                                                         {/* <img src={greenEllipseIcon} className="col" alt='settingsIcon'/> */}
//                                                         {/* <img src={pcIcon} className="col" alt='hammerIcon'/> */}
//                                                         {/* <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/> */}
//                                                         <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF", border:"none"}} src={transparentIcon}/>
//                                                         <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
//                                                         <img  className='col ' style={{width: "24px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
//                                                         <img  className='col ' style={{width: "16px",height : "16px", background: "#EFEFEF",}} src={transparentIcon}/>
//                                                         {/* <select  className='labour-select-input w-25 m-0 p-0' >
//                                                                 <option value="Prime">IC2</option>
//                                                                 <option value="Provitional"><img src={blueTruckIcon}></img></option>
//                                                                 <option value="Excluded">Excluded</option>
//                                                             </select> */}
//                                                         {/* <img src={treeSwichterOpenIcon} className="col"  onClick={() => {setShow((prev) => !prev)}}  alt='whiteTruckIcon'/> */}
                    
//                                                     {/* {show?
//                                                       <img src={treeSwichterOpenIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>

//                                                     :<img src={treeSwichterCloseIcon} className="col" onClick={()=>{setShow((prev)=>!prev)}}  alt='whiteTruckIcon'/>
//                                                   } */}
//                                                                                             {/* <input className="form-check-input m-1 " type="checkbox" ></input> */}
//                                                     {isCurrentChecked(item._id , billOfQuantities.TYPES.ITEM)?
//                                                           <input className="form-check-input pricing-checkbox m-1 align-items-center" type="checkbox"  onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}}  defaultChecked></input>   
//                                                         :
                                                        
//                                                         <input className="form-check-input pricing-checkbox m-1 align-items-center " type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}} ></input>   
//                                                       }    

//                                       </div>
//                                       <div style={{ width: 120}}>
//                                     <ReactSearchAutocomplete
//                                       placeholder={item.name}
//                                       formatResult = {formatResult}
//                                       items={pricingListCurrent.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.ITEM)} 
//                                       fuseOptions={{keys : ["name","type"]}}
//                                       showIcon= {false}
//                                       onSelect = {(event)=>{dispatch(updateItemData({item : {...item, name : event.name, type : event.type}}))}}
//                                       styling={{
//                                         height: "34px",
//                                         border: "none",
//                                         // border: "1px solid darkgreen",
//                                         borderRadius: "4px",
//                                         backgroundColor: "#EFEFEF",
//                                         boxShadow: "none",
//                                         hoverBackgroundColor: "lightgreen",
//                                         color: "black",
//                                         // fontSize: "12px",
//                                         // fontFamily: "Courier",
//                                         iconColor: "green",
//                                         lineColor: "lightgreen",
//                                         placeholderColor: "darkgreen",
//                                         clearIconMargin: "3px 8px 0 0",
//                                         zIndex : "auto",
//                                         // zIndex: {labourZIndex},
//                                       }}
//                                     />
//                                 </div>

//                         </div>



//                   </div>



//                   <div className="col-9">
//                                 <div className="row row-cols-10">
                                                




//                                                 {/*--------- Item Name for Proposal Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_NAME_FOR_PROPOSAL))?
//                                                     <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.item_names_for_proposal}  onBlur={(e)=>{
                                                                              
//                                                       dispatch(updateItemData({item :{...item, item_names_for_proposal: e.target.value}}))
//                                                   }}  /></div>
//                                                 :null}                                                              

                                                
//                                                 {/*--------- ITEM_TYPE Column---------- */}
//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.ITEM_TYPE))?
//                                                   <div className='col justify-content-start'>
//                                                         <select className='w-100 section-input' defaultValue={item.item_type} onBlur={(e)=>{
//                                                            // dispatch(updateItem({...item, status :e.target.value}))
//                                                            dispatch(updateItemData({item : {...item, item_type :e.target.value}}))
                                                      
//                                                            }} >
//                                                                <option value="Assembly (Global)">Assembly (Global)</option>
//                                                                <option value="Equipment">Equipment</option>
//                                                                <option value="Material">Material</option>
//                                                                <option value="MathLab">MatLab</option>
//                                                                <option value="Other">Other</option>
//                                                                <option value="Staff">Staff</option>
//                                                                <option value="Sub(Labour only)">Sub(Labour only)</option>
//                                                                <option value="Sub(Material only)">Sub(Material only)</option>
//                                                                <option value="Sub(MathLab)">Sub(MatLab)</option>
//                                                            </select>

//                                                   </div>
//                                                                   :null}

                                                                            

                                                                          


//                                                 {/*---------Parent Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PARENT))?
//                                                      <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.parent}  onBlur={(e)=>{
                                                                              
//                                                       dispatch(updateItemData({item :{...item, parent: e.target.value}}))
//                                                   }}  /></div>
//                                                 :null}                                                              


//                                                 {/*---------Cost Code Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_CODE))?
//                                                      <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.cost_code}  onBlur={(e)=>{
                                                                              
//                                                       dispatch(updateItemData({item :{...item, cost_code: e.target.value}}))
//                                                   }}  /></div>
//                                                 :null}
                                                
//                                                   {/*--------- Units exc Wastage Column---------- */}

//                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_EX_WASTAGE))?
//                                                   <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.unit_ex_wastage}  onBlur={(e)=>{
                                                                              
//                                                     dispatch(updateItemData({item :{...item, unit_ex_wastage: e.target.value}}))
//                                                 }}  /></div>
//                                                   :null}

                                                
//                                                   {/*--------- UOM Column---------- */}


//                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
//                                                     <div className='col justify-content-start'>
//                                                           <select className='w-100 section-input' defaultValue={item.uom} onBlur={(e)=>{
//                                                            // dispatch(updateItem({...item, status :e.target.value}))
//                                                            dispatch(updateItemData({item : {...item, uom :e.target.value}}))
                                                      
//                                                            }} >
//                                                                <option value="ea">ea</option>
//                                                                <option value="m2">m<sup>2</sup></option>
//                                                                <option value="m2">m<sup>3</sup></option>
//                                                                <option value="hrs">hrs</option>
//                                                                <option value="im">im</option>
                                                               
//                                                            </select>
//                                                     </div>
//                                                   :null}

                                                

//                                                   {/*--------- Wastage % Column---------- */}



                                                  
//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_PERCENTAGE))?
//                                                   <div className='col justify-content-start'><span class="currency-input">
//                                                   <input  type='number' className='section-input ' defaultValue={item.wastage_percentage}  onBlur={(e)=>{
                                                                              
//                                                                               dispatch(updateItemData({item :{...item, wastage_percentage: e.target.value}}))
//                                                                           }}   /><p>%</p> </span>
//                                                     </div>
//                                                   :null}

//                                               {/*--------- Wastage Value---------- */}




                                                  
//                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.WASTAGE_VALUE))?
//                                                     <p className="col"> { calculateWastageValue(item)} </p>
//                                                 :null} 



//                                               {/*--------- Units inc Wastage---------- */}


                                                  
//                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_INC_WASTAGE))?
//                                                     <p className="col"> { calculateUnitsIncWastage(item)} </p>
//                                                 :null} 



   
                                                
//                                                   {/*--------- BUY Unit $ Column---------- */}

//                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.BUY_UNIT_PRICE))?
//                                                   <div className='col justify-content-start'><span class="currency-input"><p>$</p><input  type='text' className='section-input ' defaultValue={item.buy_unit_price}  onBlur={(e)=>{
                                                                              
//                                                     dispatch(updateItemData({item :{...item, buy_unit_price: e.target.value}}))
//                                                 }}  /></span>
//                                                     </div>
//                                                   :null}



//                                                   {/*--------- Sell Unit $ Column---------- */}

                                                
//                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_UNIT_PRICE))?
//                                                     <div className='col justify-content-start'><span class="currency-input"><p>$</p><input  type='text' className='section-input ' defaultValue={item.sell_unit_price}  onBlur={(e)=>{
                                                                              
//                                                       dispatch(updateItemData({item :{...item, sell_unit_price: e.target.value}}))
//                                                   }}  /></span>
//                                                       </div>
//                                                   :null}

//                                                {/*--------- Sell Adjestment Unit Value---------- */}


                                                  
//                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SELL_ADJ_UNIT_VALUE))?
//                                                     <p className="col"> { calculateSellAdjesmentUnitValue(item)} </p>
//                                                 :null} 
                                                                            


//                                                 {/*---------Supplier Inc Sales Tax Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_INC_SALES_TAX))?
//                                                     <div className='col justify-content-start'>
//                                                     <select className='w-100 section-input' defaultValue={item.supplier_inc_sales_tax} onBlur={(e)=>{
//                                                     // dispatch(updateItem({...item, status :e.target.value}))
//                                                     dispatch(updateItemData({item : {...item, supplier_inc_sales_tax :e.target.value}}))
                                               
//                                                     }} >
//                                                         <option value="Yes">Yes</option>
//                                                         <option value="No">No</option>
                                                       
                                                        
//                                                     </select>
//                                                </div>
//                                                 :null}                                                              


//                                                 {/*--------- Supplier Item Code Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_ITEM_CODE))?
//                                                     <div className='col justify-content-start'><input  type='text' className='section-input ' defaultValue={item.supplier_item_code}  onBlur={(e)=>{
                                                                              
//                                                       dispatch(updateItemData({item :{...item, supplier_item_code: e.target.value}}))
//                                                   }}  /></div>
//                                                 :null}
                                                


                                                
//                                                {/*--------- Total Sell Price Of Units---------- */}


                                                  
//                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TOTAL_SELL_PRICE_OF_UNITS))?
//                                                   <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateTotalSellPriceOfUnitsValue(item)}</span>
//                                                   </span> </div>
                                                  
//                                                 :null} 



//                                                  {/*--------- Pre-markup % Column---------- */}


//                                                  {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PRE_MARKUP_PERCENTAGE))?
//                                                   <div className='col justify-content-start'><span class="currency-input">
//                                                    <input  type='number' className='section-input ' defaultValue={item.pre_markup_percentage}  onBlur={(e)=>{
                                                                              
//                                                                               dispatch(updateItemData({item :{...item, pre_markup_percentage: e.target.value}}))
//                                                                           }} /><p>%</p> </span>
                                                    
//                                                    </div>
//                                                   :null}


//                                                  {/*--------- Pre-markup Value---------- */}


                                                  
//                                                {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.PRE_MARKUP_VALUE))?
//                                                    <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculatePreMarkupValue(item)}</span>
//                                                    </span> </div>
//                                                    // <p className="col"> { calculatePreMarkupValue(item)} </p>
//                                                 :null} 


                                                
//                                                   {/*--------- Contingency % Column---------- */}


//                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_PERCENTAGE))?
//                                                   <div className='col justify-content-start'><span class="currency-input">
//                                                   <input  type='text' className='section-input ' defaultValue={item.contingency_percentage}  onBlur={(e)=>{
                                                                              
//                                                                               dispatch(updateItemData({item :{...item, contingency_percentage: e.target.value}}))
//                                                                           }} /><p>%</p> </span>
                                                    
//                                                     </div>
//                                                   :null}


                                                
//                                                 {/*--------- Contingency Value Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTINGENCY_VALUE))?
//                                                     <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateContingency(item)}</span>
//                                                     </span> </div>
//                                                     // <p className="col"> {calculateContingency(item)} </p>
//                                                 :null}   

                                                
//                                                   {/*--------- Markup percentage % Column---------- */}


//                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_PERCENTAGE))?
//                                                     <div className='col justify-content-start'><span class="currency-input">
//                                                      <input  type='number' className='section-input' defaultValue={item.markup_percentage}  onBlur={(e)=>{
                                                                              
//                                                                               dispatch(updateItemData({item :{...item, markup_percentage: e.target.value}}))
//                                                                           }} /><p>%</p> </span>
//                                                      </div>

//                                                   :null}
                                                
//                                                 {/*--------- Markup Value Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARKUP_VALUE))?
//                                                   <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateMarkupValue(item)}</span>
//                                                   </span></div>
//                                                 :null}                                                                


//                                                 {/*--------- Client Sales Tax Percentage  Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_PERCENTAGE))?
//                                                     <div className='col justify-content-start'><span class="currency-input">
//                                                     <input  type='number' className='section-input ' defaultValue={item.client_sales_tax_percentage}  onBlur={(e)=>{
                                                                              
//                                                                               dispatch(updateItemData({item :{...item, client_sales_tax_percentage: e.target.value}}))
//                                                                           }}  /><p>%</p> </span></div>
//                                                 :null}                                                              


//                                                 {/*--------- Client Sales Tax Value  Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_SALES_TAX_VALUE))?
//                                                     <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateClientSalesTax(item)}</span>
//                                                     </span> </div>
//                                                     // <p className="col"> {calculateClientSalesTax(item)} </p>
//                                                 :null}
                                                
              
                                                
//                                                   {/*--------- Client Charge Inc Column---------- */}

//                                                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE_INC))?
//                                                     <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateClientCharge(item)}</span>
//                                                     </span> </div>
//                                                     // <div className='col justify-content-start'>{calculateClientCharge(item)}</div>
//                                                   :null}
                                                                            


//                                                 {/*--------- Overhead  Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.OVERHEAD))?
//                                                     <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateOverheads(item)}</span>
//                                                     </span> </div>
//                                                     // <p className="col"> {calculateOverheads(item)}  </p>
//                                                 :null}                                                              


//                                                 {/*--------- Margin   Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
//                                                   <div className="col justify-content-start"> <span class="section-input-container"><p>$</p><span >{calculateMargin(item)}</span>
//                                                   </span> </div>
//                                                     // <p className="col"> {calculateMargin(item)}  </p>
//                                                 :null} 
//                                                 {/*--------- GMP Buffer %   Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_PERCENTAGE))?
//                                                           <div className='col justify-content-start'><span class="currency-input">
//                                                           <input  type='number' className='section-input' defaultValue={item.gmp_percentage}  onBlur={(e)=>{
                                                                                  
//                                                                                   dispatch(updateItemData({item :{...item, gmp_percentage: e.target.value}}))
//                                                                               }} /><p>%</p> </span>
                                                          
//                                                           </div>
                
                                                    
                                                    

//                                                 :null}                                                              
//                                                 {/*--------- GMP Buffer V   Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.GMP_BUFFER_VALUE))?
//                                                 <div className='col justify-content-start'><span class="section-input-container"><p>$</p><span >{calculateGMPBuffer(item)}</span>
//                                                 </span></div>
                                                      
//                                                 :null}                                                                                                               


//                                                 {/*--------- Supplier Contact Id  Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.SUPPLIER_CONTACT_ID))?
//                                                      <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.suppiler}  onBlur={(e)=>{
                                                                              
//                                                       dispatch(updateItemData({item :{...item, suppiler: e.target.value}}))
//                                                   }} /></div>
//                                                 :null}                                                              


//                                                 {/*--------- Contractor Item Note  Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CONTRACTOR_ITEM_NOTE))?
//                                                      <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.contractor_item_note}  onBlur={(e)=>{
                                                                              
//                                                       dispatch(updateItemData({item :{...item, contractor_item_note: e.target.value}}))
//                                                   }} /></div>
//                                                 :null}                                                              


//                                                 {/*--------- Tender Item Note  Column---------- */}

//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TENDER_ITEM_NOTE))?
//                                                       <div className='col justify-content-start'><input  type='text' className='section-input' defaultValue={item.tender_item_note}  onBlur={(e)=>{
                                                                              
//                                                         dispatch(updateItemData({item :{...item, tender_item_note: e.target.value}}))
//                                                     }} /></div>
//                                                 :null}  

//                                                 {/*--------- Pricinglist ? Column---------- */}

                                                
//                                                 {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.IS_PRICELIST_ITEM))?
//                                                     <div className='col justify-content-start'>
//                                                            <select className='w-100 section-input' defaultValue={item.is_pricelist_item} onBlur={(e)=>{
//                                                            // dispatch(updateItem({...item, status :e.target.value}))
//                                                            dispatch(updateItemData({item : {...item, is_pricelist_item :e.target.value}}))
                                                      
//                                                            }} >
//                                                                <option value="Yes">Yes</option>
//                                                                <option value="No">No</option>
                                                              
                                                               
//                                                            </select>
//                                                       </div>
//                                                   :null}


//                                                 {/*--------- NESTED  Column---------- */}

//                                             {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.NESTED))?
//                    //     <div className='col-1'> 
//                    //     <input type="text" defaultValue={item.type} className='section-input form-control m-0 p-0'  onBlur={(e)=>{
//                    //     // dispatch(updateItem({...item, type :e.target.value}))
//                    //     dispatch(updateItemData({item :{...item, type :e.target.value}}))
                      
//                  //     }} ></input>
//                    // </div>

//                      <div className="col">
//                      <select
//                        className="section-input form-select  labour-select-input m-0 p-0"
//                        defaultValue={labour.name}
//                        onChange={(e) => {
//                          let itemObject = {...item}
//                          // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
//                          if(e.target.selectedOptions[0].getAttribute("specification") ===billOfQuantities.TYPES.SPECIFIED && e.target.selectedOptions[0].getAttribute("labourid"))
//                          {
//                            console.log("under current labour",e.target.value)
                          
//                           dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}}))
//                         }
//                         // else if (e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){                       
//                         //   delete itemObject.labour_id
//                         //   console.log("new labour",itemObject)
//                         //   dispatch(updateItemData({item :{...itemObject, specialism :e.target.selectedOptions[0].getAttribute("type")}}))
//                         // }
//                         else if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.UNSPECIFIED){
//                           delete itemObject.labour_id                       
//                           dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}}))

//                          }
                        
//                         // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
//                                              }}
//                     > 
//                     {itemIdArray.map((itemId)=>{
//                         let currentLabour = items.find((i)=> i._id ===currentLabour)
//                       if(currentLabour.type === billOfQuantities.TYPES.LABOUR && currentLabour.section_id === labour.section_id && currentLabour.specification === billOfQuantities.TYPES.SPECIFIED)
//                       return <option key={currentLabour._id} value={currentLabour.name} labourid={currentLabour._id}
//                       specification={billOfQuantities.TYPES.SPECIFIED} 
//                       >{currentLabour.name}</option>
//                     })}
//                       <hr className="dropdown-divider"/>
//                       {/* <option className="m-1" value={billOfQuantities.TYPES.LABOUR} 
//                       type={billOfQuantities.TYPES.LABOUR} 
//                       >Add New Labour</option> */}
//                       <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option>
//                       {/* <option value="Provitional">Provitional</option>
//                       <option value="Excluded">Excluded</option> */}
//                     </select>
//                     </div>

//                  :null}

                                                                                                        

                                                


//                                                 <div className='view-pricing-user-filter-icon'>
//                                                 {/* <CancelPresentationTwoToneIcon onClick={()=>{dispatch(deleteItems({item}))}} color='warning'/> */}
//                                                     <img  src={threeDotIcon}></img>
//                                                 </div>
//                                 </div>

//                         </div>
 
//                 </div>
                
//         ) 
//         }
//     })
// }




  return (
   
       
        <Draggable draggableId={labourId} index={index} key={labourId} >
          {provided => (
              <span {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                     <LabourColumn/>
                    {show?
                      <Droppable droppableId={billOfQuantities.DROPPABLE_TYPES.SPECI+labourId} key={billOfQuantities.DROPPABLE_TYPES.SPECI+labourId} type={billOfQuantities.DROPPABLE_TYPE_FOR_NESTED_HIERARCHY.NON_LABOUR}>
                          {provided=>(
                              <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight : "25px"}}>
                              <DisplayItems/>
                              {provided.placeholder}
                              </div>
                          )}
                      </Droppable>
                              
                    :null}

                  <AddNewItem/>
   
              </span>
          )}
       
        </Draggable>
   
   



    
  );
};

export default memo(Labour);



// const DisplayItems = ()=>{
        
//   return items.map((item)=>{
//       if(item.labour_id === labour._id && item.type === billOfQuantities.TYPES.ITEM && item.specification === billOfQuantities.TYPES.SPECIFIED ){
              

//          return(
            
//               //  <div key={item.id} className={" multi-collapse   collapse row "+show+" "+section.name}> 
//               <div key={item._id} className={"section-item-container row"}> 
              
//                   <div className='section-container-item-name col-3 d-flex' > 
//                       {isCurrentChecked(item._id , billOfQuantities.TYPES.ITEM)?
//                         <input className="form-check-input pricing-checkbox" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}} defaultChecked></input>   
//                       :
                      
//                       <input className="form-check-input pricing-checkbox" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({isChecked:e.target.checked,id: item._id, type: billOfQuantities.TYPES.ITEM}))}} ></input>   
//                     }
//                     {/* <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": item._id, "type": billOfQuantities.TYPES.ITEM}))}} ></input>    */}
//                       {/* <input type="text" defaultValue={item.name} className='section-input form-control ' 
//                       list="meterialDataList"
//                       onBlur={(e)=>{
//                       // dispatch(updateItem({...item, name :e.target.value}))
//                       if(pricingListRequestData.some((addUp)=>addUp.name===e.target.value && addUp.type ===billOfQuantities.TYPES.ITEM && item.name !== e.target.value)){
//                         dispatch(updateItemData({item:{...item, name :e.target.value}}))
//                       }
//                       }}></input>

//                     <datalist id="meterialDataList">
//                                 {
//                                   pricingListRequestData.map((addUp)=>{
//                                     if(addUp.type === billOfQuantities.TYPES.ITEM && !pricingListRequestLoading){
//                                       return (
//                                         <option key={addUp._id} value={addUp.name}>{addUp.name}</option>
//                                       )
//                                     }
//                                   })
//                                 }
//                               </datalist> */}
//                              <div style={{ width: 400}}>
//                                   <ReactSearchAutocomplete
//                                     placeholder={item.name}
//                                     formatResult = {formatResult}
//                                     items={pricingListCurrent.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.ITEM)} 
//                                     fuseOptions={{keys : ["name","type"]}}
//                                     showIcon= {false}
//                                     onSelect = {(event)=>{dispatch(updateItemData({item : {...item, name : event.name, type : event.type}}))}}
//                                     styling={{
//                                       height: "34px",
//                                       border: "none",
//                                       // border: "1px solid darkgreen",
//                                       borderRadius: "4px",
//                                       backgroundColor: "#EFEFEF",
//                                       boxShadow: "none",
//                                       hoverBackgroundColor: "lightgreen",
//                                       color: "black",
//                                       // fontSize: "12px",
//                                       // fontFamily: "Courier",
//                                       iconColor: "green",
//                                       lineColor: "lightgreen",
//                                       placeholderColor: "darkgreen",
//                                       clearIconMargin: "3px 8px 0 0",
//                                       zIndex : "auto",
//                                       // zIndex: {labourZIndex},
//                                     }}
//                                   />
//                               </div>
//                   </div>
//                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED)&& (userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)))?
//                       <div className='col-1 d-flex'> 
//                       {/* Qty */}
//                       <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0' onBlur={(e)=>{
//                           // dispatch(updateItem({...item, units_required: e.target.value}))
//                           dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
//                       }}></input>
                     
//                      {/* UOM */}
//                       <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom}  onBlur={(e)=>{
//                       // dispatch(updateItem({...item, uom :e.target.value}))
//                       dispatch(updateItemData({item :{...item, uom :e.target.value}}))
                      
//                       }}></input>
//                   </div>
//                       :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
//                           <div className='col-1 d-flex'> 
//                           {/* Qty */}
//                           <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0' onBlur={(e)=>{
//                               // dispatch(updateItem({...item, units_required: e.target.value}))
//                               dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
//                           }}></input>
                        
//                         {/* UOM */}
//                           {/* <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom}  onBlur={(e)=>{
//                           // dispatch(updateItem({...item, uom :e.target.value}))
//                           dispatch(updateItemData({item :{...item, uom :e.target.value}}))
                          
//                           }}></input> */}
//                       </div>

//                   :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
//                       <div className='col-1 d-flex'> 
//                       {/* Qty */}
//                       {/* <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0' onBlur={(e)=>{
//                           // dispatch(updateItem({...item, units_required: e.target.value}))
//                           dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
//                       }}></input> */}
                    
//                     {/* UOM */}
//                       <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom}  onBlur={(e)=>{
//                       // dispatch(updateItem({...item, uom :e.target.value}))
//                       dispatch(updateItemData({item :{...item, uom :e.target.value}}))
                      
//                       }}></input>
//                   </div>

//                   :null}
//                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
//                     <div className='col-1'> 
//                       {/* unit_cost */}
//                       <input type="text"  className='section-input form-control  m-0 p-0' defaultValue={item.cost_per_unit}  onBlur={(e)=>{
//                       // dispatch(updateItem({...item, cost_per_unit :e.target.value}))
//                       dispatch(updateItemData({item :{...item, cost_per_unit :e.target.value}}))
                      
//                       }}></input>
//                     </div>
//                   :null}
//                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
//                      <div className='col-1'> 
//                      {/* cost_of_units */}
                  
//                      <input type="number" defaultValue={(parseFloat(item.cost_per_unit)*parseFloat(item.units_required)).toFixed(2)} className='section-input'  
//                        readOnly={true}></input>
//                  </div>
//                   :null}

//                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
//                      <div className='col-1'>
//                      {/* markup  */}
//                      <input type="text" defaultValue={item.margin} className='section-input form-control  m-0 p-0' onBlur={(e)=>{
//                      // dispatch(updateItem({...item, margin :e.target.value}))
//                      dispatch(updateItemData({item :{...item, margin :e.target.value}}))
                     
//                      }}></input>
//                  </div>
//                   :null}
//                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
//                       <div className='col-1'> 
//                       {/* Client Charge */}
//                       <input type="number" defaultValue={((parseFloat(item.cost_per_unit)*parseFloat(item.units_required))*(1+(parseFloat(item.margin)/100))).toFixed(2)} className='section-input '  readOnly={true}
//                       ></input>
//                   </div>
//                   :null}


//                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
//                         <div className='col-1 d-flex flex-row' > 

//                         <select className=' labour-select-input w-75 m-0 p-0' defaultValue={item.status} onBlur={(e)=>{
//                         // dispatch(updateItem({...item, status :e.target.value}))
//                         dispatch(updateItemData({item : {...item, status :e.target.value}}))
                        
//                         }} >
//                             <option value="Prime">Prime</option>
//                             <option value="Provitional">Provitional</option>
//                             <option value="Excluded">Excluded</option>
//                         </select>
                        
//                             <select  className='form-select section-input labour-select-input w-25 m-0 p-0' defaultValue={item.status} onBlur={(e)=>{
//                         // dispatch(updateItem({...item, status :e.target.value}))
//                         // dispatch(updateItemData({item : {...item, status :e.target.value}}))
                        
//                         }} >
//                             <option value="Prime">IC2</option>
//                             <option value="Provitional"><HelpOutlineIcon/></option>
//                             <option value="Excluded">Excluded</option>
//                         </select>
                            
//                     </div>
//                   :null}
//                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_TYPE))?
//                         <div className='col-1 d-flex flex-row' > 
                            
//                             <select className=' labour-select-input w-50 m-0 p-0' defaultValue={item.status} onBlur={(e)=>{
//                         // dispatch(updateItem({...item, status :e.target.value}))
//                         // dispatch(updateItemData({item : {...item, status :e.target.value}}))
                        
//                         }} >
//                             <option value="Prime">?</option>
//                             <option value="Provitional"><HelpOutlineIcon/></option>
//                             <option value="Excluded">Excluded</option>
//                         </select>
                           
                            
//                             <select className='labour-select-input w-50 m-0 p-0' defaultValue={item.status} onBlur={(e)=>{
//                         // dispatch(updateItem({...item, status :e.target.value}))
//                         // dispatch(updateItemData({item : {...item, status :e.target.value}}))
                        
//                         }} >
//                             <option value="Prime">IC</option>
//                             <option value="Provitional"><HelpOutlineIcon/></option>
//                             <option value="Excluded">Excluded</option>
//                         </select>
                           

                           
//                     </div>
//                   :null}


//                   {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
//                   //     <div className='col-1'> 
//                   //     <input type="text" defaultValue={item.type} className='section-input form-control m-0 p-0'  onBlur={(e)=>{
//                   //     // dispatch(updateItem({...item, type :e.target.value}))
//                   //     dispatch(updateItemData({item :{...item, type :e.target.value}}))
                      
//                   //     }} ></input>
//                   // </div>

//                     <div className="col-1">
//                     <select
//                       className="section-input form-select  labour-select-input m-0 p-0"
//                       defaultValue={labour.name}
//                       onChange={(e) => {
//                         let itemObject = {...item}
//                         // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
//                         if(e.target.selectedOptions[0].getAttribute("specification") ===billOfQuantities.TYPES.SPECIFIED && e.target.selectedOptions[0].getAttribute("labourid"))
//                         {
//                           console.log("under current labour",e.target.value)
                          
//                           dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}}))
//                         }
//                         // else if (e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){
                          
//                         //   delete itemObject.labour_id
//                         //   console.log("new labour",itemObject)
//                         //   dispatch(updateItemData({item :{...itemObject, specialism :e.target.selectedOptions[0].getAttribute("type")}}))
//                         // }
//                         else if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.UNSPECIFIED){
//                           delete itemObject.labour_id
                          
//                           dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}}))

//                         }
                        
//                         // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
//                                              }}
//                     > 
//                     {items.map((currentLabour)=>{
//                       if(currentLabour.type === billOfQuantities.TYPES.LABOUR && currentLabour.section_id === labour.section_id && currentLabour.specification === billOfQuantities.TYPES.SPECIFIED)
//                       return <option key={currentLabour._id} value={currentLabour.name} labourid={currentLabour._id}
//                       specification={billOfQuantities.TYPES.SPECIFIED} 
//                       >{currentLabour.name}</option>
//                     })}
//                       <hr className="dropdown-divider"/>
//                       {/* <option className="m-1" value={billOfQuantities.TYPES.LABOUR} 
//                       type={billOfQuantities.TYPES.LABOUR} 
//                       >Add New Labour</option> */}
//                       <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option>
//                       {/* <option value="Provitional">Provitional</option>
//                       <option value="Excluded">Excluded</option> */}
//                     </select>
//                     </div>

//                   :null}
                  
                 
                 
                
                
                  
//                   <div className='col-1'><CancelPresentationTwoToneIcon onClick={()=>{dispatch(deleteItems({item}))}} color='warning'/></div>
                  
//               </div>
              
//       ) 
//       }
//   })
// }






































//-----------------------------------------------------------------LABOUR        INITIAL LOOK (OLD UI) -----------------------------------------------

  // <hr/>

  // {/*------------------------------------------------------------ Initial LOOk-------------------------------------------------------------- */}


  // <div  className={"section-labour-container row"}>
  //     <div className="section-container-labour-name col-3 d-flex">
  //       {/* <input className="form-check-input" type="checkbox"></input> */}
  //       {isCurrentChecked(labour._id , billOfQuantities.TYPES.LABOUR)?
  //                         <input className="form-check-input pricing-checkbox" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} defaultChecked></input>   
  //                       :
                        
  //                       <input className="form-check-input pricing-checkbox" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": labour._id, "type": billOfQuantities.TYPES.LABOUR}))}} ></input>   
  //                     }
  //       {/* <input
  //         type="text"
  //         list="labourDataList"
  //         defaultValue={labour.name}
  //         className=" section-input form-control m-0 p-0"
        
  //         onBlur={(e) => {
  //           if(e.target.value.toLowerCase()!==labour.name.toLowerCase() && !isDuplicateName && pricingListRequestData.some((addUp)=>addUp.name=== e.target.value)){

  //             dispatch(updateItemData({item : { ...labour, name: e.target.value }}));
  //           }
  //         }}
  //         // onChange ={(e)=>{validateLabourName(e)}}
  //       ></input>
  //         <datalist id="labourDataList">
  //           {
  //             pricingListRequestData.map((addUp)=>{
  //               if(addUp.type === billOfQuantities.TYPES.LABOUR && !pricingListRequestLoading){
  //                 return (
  //                   <option key={addUp._id} value={addUp.name}>{addUp.name}</option>
  //                 )
  //               }
  //             })
  //           }
  //         </datalist> */}
  //         <div style={{ width: 400}}>
  //         <ReactSearchAutocomplete
  //           placeholder={labour.name}
  //           fuseOptions={{keys : ["name","type"]}}
  //           items={pricingListCurrent.filter((addUp)=> addUp.type ===billOfQuantities.TYPES.LABOUR)} 
  //           showIcon= {false}
  //           formatResult = {formatResult}
  //           onSelect = {(event)=>{dispatch(updateItemData({item : {...labour, name : event.name, type : event.type}}))}}
  //           styling={{
  //             height: "34px",
  //             border: "none",
  //             // border: "1px solid darkgreen",
  //             borderRadius: "4px",
  //             backgroundColor: "#EFEFEF",
  //             boxShadow: "none",
  //             hoverBackgroundColor: "lightgreen",
  //             color: "black",
  //             // fontSize: "12px",
  //             // fontFamily: "Courier",
  //             iconColor: "green",
  //             lineColor: "lightgreen",
  //             placeholderColor: "darkgreen",
  //             clearIconMargin: "3px 8px 0 0",
  //             zIndex : "auto",
  //             // zIndex: {labourZIndex},
  //           }}
  //         />
  //       </div>

      
        

  //       {isDuplicateName? <p>Please Enter a unique Labour name</p> : null}
  //     </div>
  //       {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED)&& (userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)))?
  //           <div className="col-1 d-flex">
  //           {/* Qty */}
  //           <input
  //             type="text"
  //             defaultValue={labour.units_required}
  //             className="section-input form-control m-0 p-0"
  //             onBlur={(e) => {
  //               // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
  //               dispatch(updateItemData({item : { ...labour, units_required: e.target.value }}));
  //             }}
  //           ></input>
    
  //           {/* UOM */}
  //           <input
  //             type="text"
  //             className="section-input form-control m-0 p-0"
  //             defaultValue={labour.uom}
  //             onBlur={(e) => {
  //               // dispatch(updateLabour({ ...labour, uom: e.target.value }));
  //               dispatch(updateItemData({item :{ ...labour, uom: e.target.value }}));
  //             }}
  //           ></input>
  //         </div>           
          
  //         :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
  //         <div className="col-1 d-flex">
  //         {/* Qty */}
  //         <input
  //           type="text"
  //           defaultValue={labour.units_required}
  //           className="section-input form-control m-0 p-0"
  //           onBlur={(e) => {
  //             // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
  //             dispatch(updateItemData({item : { ...labour, units_required: e.target.value }}));
  //           }}
  //         ></input>

  //         {/* UOM */}
  //         {/* <input
  //           type="text"
  //           className="section-input form-control m-0 p-0"
  //           defaultValue={labour.uom}
  //           onBlur={(e) => {
  //             // dispatch(updateLabour({ ...labour, uom: e.target.value }));
  //             dispatch(updateLabourData({labour :{ ...labour, uom: e.target.value }}));
  //           }}
  //         ></input> */}
  //       </div>

  //     :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
  //     <div className="col-1 d-flex">
  //     {/* Qty */}
  //     {/* <input
  //       type="text"
  //       defaultValue={labour.units_required}
  //       className="section-input form-control m-0 p-0"
  //       onBlur={(e) => {
  //         // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
  //         dispatch(updateLabourData({labour : { ...labour, units_required: e.target.value }}));
  //       }}
  //     ></input> */}

  //     {/* UOM */}
  //     <input
  //       type="text"
  //       className="section-input form-control m-0 p-0"
  //       defaultValue={labour.uom}
  //       onBlur={(e) => {
  //         // dispatch(updateLabour({ ...labour, uom: e.target.value }));
  //         dispatch(updateItemData({item :{ ...labour, uom: e.target.value }}));
  //       }}
  //     ></input>
  //   </div>

  //     :null}
      
  //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
  //         <div className="col-1">
  //         {/* unit_cost */}
  //         <input
  //           type="text"
  //           className="section-input form-control m-0 p-0"
  //           defaultValue={labour.cost_per_unit}
  //           onBlur={(e) => {
  //             // dispatch(updateLabour({ ...labour, cost_per_unit: e.target.value }));
  //             dispatch(updateItemData({item :{ ...labour, cost_per_unit: e.target.value }}));
  //           }}
  //         ></input>
  //       </div>  
  //   :null}
  //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
  //       <div className="col-1">
  //       {/* cost_of_units */}
  //       <input
  //         type="number"
  //         defaultValue={(parseFloat(labour.cost_per_unit)*parseFloat(labour.units_required)).toFixed(2)} 
  //         className="section-input"
  //         readOnly={true}
  //       ></input>
  //     </div>  
  //   :null}
  //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
  //     <div className="col-1">
  //     {/* markup  */}
  //     <input
  //       type="text"
  //       defaultValue={labour.margin}
  //       className="section-input form-control m-0 p-0"
  //       onBlur={(e) => {
  //         // dispatch(updateLabour({ ...labour, margin: e.target.value }));
  //         dispatch(updateItemData({item :{ ...labour, margin: e.target.value }}));
  //       }}
  //     ></input>
  //   </div>
  //   :null}
  //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
  //       <div className="col-1">
  //       {/* Client Charge */}
  //       <input
  //         type="number"
  //         defaultValue={(parseFloat(labour.cost_per_unit)*parseFloat(labour.units_required)*(1+(parseFloat(labour.margin)/100))).toFixed(2)} 
  //         className="section-input"
  //         readOnly={true}
  //       ></input>
  //     </div>
  //   :null}

  //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
  //     <div className="col-2">
  //     <select
  //       className="section-input form-select m-0 p-0"
  //       defaultValue={labour.status}
  //       onBlur={(e) => {
  //         // dispatch(updateLabour({ ...labour, status: e.target.value }));
  //         dispatch(updateItemData({item :{ ...labour, status: e.target.value }}));
  //       }}
  //     >
  //       <option value="Prime">Prime</option>
  //       <option value="Provitional">Provitional</option>
  //       <option value="Excluded">Excluded</option>
  //     </select>
  //   </div>
  //   :null}
    

  //     {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
  //     //   <div className="col-1">
  //     //   <input
  //     //     type="text"
  //     //     defaultValue={labour.type}
  //     //     className="section-input form-control m-0 p-0"
  //     //     onBlur={(e) => {
  //     //       // dispatch(updateLabour({ ...labour, type: e.target.value }));
  //     //       dispatch(updateLabourData({labour :{ ...labour, type: e.target.value }}));
  //     //     }}
  //     //   ></input>
  //     // </div>

  //         <div className="col-1">
  //         <select
  //           className="section-input form-select m-0 p-0"
  //           defaultValue={labour.name}
  //           onChange={(e) => {
  //             let itemObject = {...labour}
  //             // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
  //             // if(e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.ITEM && e.target.selectedOptions[0].getAttribute("labourid") && labour._id !==  e.target.selectedOptions[0].getAttribute("labourid"))
  //             // {
  //             //   console.log("under current labour",e.target.value)
                
  //             //   items.forEach(element => {
  //             //     if(element.labour_id === labour._id){
  //             //       let elementCurrent = {...element}
  //             //       delete elementCurrent.labour_id
  //             //       dispatch(updateItemData({item : {...elementCurrent, specification : billOfQuantities.TYPES.UNSPECIFIED}}))
  //             //     }
  //             //   });

  //             //   dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("type"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}}))
  //             // }
  //             // else if (e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){
  //             //   console.log("new labour",e.target.value)
  //             //   delete itemObject.labour_id
  //             //   dispatch(updateItemData({item :{...itemObject, type :e.target.selectedOptions[0].getAttribute("type")}}))
  //             // }
  //             if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.UNSPECIFIED){
                
  //               items.forEach(element => {
  //                 if(element.labour_id === labour._id){
  //                   let elementCurrent = {...element}
  //                   delete elementCurrent.labour_id
  //                   dispatch(updateItemData({item : {...elementCurrent, specification : billOfQuantities.TYPES.UNSPECIFIED}}))
  //                 }
  //               });
  //               delete itemObject.labour_id
  //               dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}}))

  //             }
              
  //             // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
  //                                 }}
  //         >
  //       {/* {items.map((currentLabour)=>{
  //                       if(currentLabour.section_id === labour.section_id)
  //                       return <option value={currentLabour.name}>{currentLabour.name}</option>
  //                     })}
  //                       <hr className="dropdown-divider"/>
  //                       <option className="m-1" value="newLabour">Add New Labour</option>
  //                       <option className="m-1" value="unspecified">unspecified</option>
  //                   */}

  //                 {/* {items.map((currentLabour)=>{
  //                       if(currentLabour.type === billOfQuantities.TYPES.LABOUR && currentLabour.section_id ===labour.section_id)
  //                       return <option key={currentLabour._id} value={currentLabour.name} labourid={currentLabour._id}
  //                       type={billOfQuantities.TYPES.ITEM} 
  //                       >{currentLabour.name}</option>
  //                     })}
  //                       <hr className="dropdown-divider"/> */}
  //                       {/* <option className="m-1" value={billOfQuantities.TYPES.LABOUR} 
  //                       type={billOfQuantities.TYPES.LABOUR} 
  //                       >Add New Labour</option> */}

  //                       <option className="m-1" value={billOfQuantities.TYPES.SPECIFIED} specification={billOfQuantities.TYPES.LABOUR}>{labour.name}</option>
  //                       <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option>
  //                       {/* <option value="Provitional">Provitional</option>
  //                       <option value="Excluded">Excluded</option> */}
  //         </select>
  //         </div>
  //   :null}


    
      
      

    
  //     <div className="col-1">
  //       <KeyboardArrowDownIcon
  //         fontSize="large"
  //         onClick={() => {
  //           setShow((prev) => !prev);
  //         }}
  //       />
  //       <CancelPresentationTwoToneIcon
  //         onClick={() => {
  //           console.log("delete Items ", labour)
  //           dispatch(deleteItems({item :labour}))
  //         }}
  //         color="warning"
  //       />
  //     </div>
      
  //   </div>
  //     {show?
  //       <>
  //     <DisplayItems/>
      
  //       </>:null}
  //     <div className="row">
  //       <div className="col-3 d-flex">
  //       <div className="section-container-item-name " >
  //         {/* <AddIcon  onClick={()=>{
            
  //           //dispatch(initializeNewItem(labour._id))
  //           dispatch(createItem({labourId :labour._id, sectionId : labour.section_id, type :billOfQuantities.TYPES.ITEM}))
  //         }}  /> */}
  //           {/* <button className='btn btn-outline-secondary btn-light' data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"> Filters <div className="dropdown-arrow"></div></button> */}
            
  //           <AddIcon  data-bs-toggle="dropdown" role="button" aria-expanded="false"  onClick={()=>{
  //               // dispatch(changeLabourModalStatus(section._id))
  //               // dispatch(createLabour({sectionId : section._id}))
  //           // dispatch(createItem({ sectionId : section._id , type : billOfQuantities.TYPES.LABOUR}))

                
  //               }}  />
  //         <ul className="dropdown-menu">
  //           {/* {
  //             pricingListRequestData.map((addUp)=>{
  //               if(addUp.type === billOfQuantities.TYPES.ITEM && !pricingListRequestLoading){
  //                 return (
  //                   <li key={addUp._id} onClick={()=>{ dispatch(createItem({ name :addUp.name, sectionId : labour.section_id , type : billOfQuantities.TYPES.ITEM, labourId :labour._id, specialism : billOfQuantities.TYPES.ITEM} ))}}><a className="dropdown-item" >{addUp.name}</a></li>
  //                 )
  //               }
  //             })
              
  //           } */}
  //             {/* <li><hr className="dropdown-divider"/></li> */}
  //           <li onClick={()=>{ dispatch(changeAddUpModalStatus({sectionId : labour.section_id,labourId : labour._id, labourSpecification : billOfQuantities.TYPES.SPECIFIED, itemSpecification : billOfQuantities.TYPES.SPECIFIED}))}}><a className="dropdown-item" >New labour/Item</a></li>
  //         </ul>


  //       </div>
  //       <div  style={{ width: 400}}>
  //                           <ReactSearchAutocomplete
  //                             placeholder={"Add Item"}
  //                             fuseOptions={{keys : ["name","type"]}}

  //                             items={pricingListCurrent} 
  //                             showIcon= {false}
  //                             formatResult = {formatResult}
  //                             onSelect = {(event)=>{createOnSelect(event)}}
  //                             styling={{
  //                               height: "34px",
  //                               border: "none",
  //                               // border: "1px solid darkgreen",
  //                               borderRadius: "4px",
  //                               backgroundColor: "#EFEFEF",
  //                               boxShadow: "none",
  //                               hoverBackgroundColor: "lightgreen",
  //                               color: "black",
  //                               // fontSize: "12px",
  //                               // fontFamily: "Courier",
  //                               iconColor: "green",
  //                               lineColor: "lightgreen",
  //                               placeholderColor: "#002D62",
  //                               clearIconMargin: "3px 8px 0 0",
  //                               zIndex : "auto",
  //                               // zIndex: {labourZIndex},
  //                             }}
  //                           />
  //     </div>
  //     </div>
  //     </div>









































// -----------------------------------------------------------BUTTOM HALF OF CODE (OLD ui)-----------------------------

// {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED)&& (userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM)))?
//   <div className="col-1 d-flex">
//   {/* Qty */}
//   <input
//     type="text"
//     defaultValue={labour.units_required}
//     className="section-input form-control m-0 p-0"
//     onBlur={(e) => {
//       // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
//       dispatch(updateItemData({item : { ...labour, units_required: e.target.value }}));
//     }}
//   ></input>

//   {/* UOM */}
//   <input
//     type="text"
//     className="section-input form-control m-0 p-0"
//     defaultValue={labour.uom}
//     onBlur={(e) => {
//       // dispatch(updateLabour({ ...labour, uom: e.target.value }));
//       dispatch(updateItemData({item :{ ...labour, uom: e.target.value }}));
//     }}
//   ></input>
// </div>           

// :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UNITS_REQUIRED))?
// <div className="col-1 d-flex">
// {/* Qty */}
// <input
//   type="text"
//   defaultValue={labour.units_required}
//   className="section-input form-control m-0 p-0"
//   onBlur={(e) => {
//     // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
//     dispatch(updateItemData({item : { ...labour, units_required: e.target.value }}));
//   }}
// ></input>

// {/* UOM */}
// {/* <input
//   type="text"
//   className="section-input form-control m-0 p-0"
//   defaultValue={labour.uom}
//   onBlur={(e) => {
//     // dispatch(updateLabour({ ...labour, uom: e.target.value }));
//     dispatch(updateLabourData({labour :{ ...labour, uom: e.target.value }}));
//   }}
// ></input> */}
// </div>

// :(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.UOM))?
// <div className="col-1 d-flex">
// {/* Qty */}
// {/* <input
// type="text"
// defaultValue={labour.units_required}
// className="section-input form-control m-0 p-0"
// onBlur={(e) => {
// // dispatch(updateLabour({ ...labour, units_required: e.target.value }));
// dispatch(updateLabourData({labour : { ...labour, units_required: e.target.value }}));
// }}
// ></input> */}

// {/* UOM */}
// <input
// type="text"
// className="section-input form-control m-0 p-0"
// defaultValue={labour.uom}
// onBlur={(e) => {
// // dispatch(updateLabour({ ...labour, uom: e.target.value }));
// dispatch(updateItemData({item :{ ...labour, uom: e.target.value }}));
// }}
// ></input>
// </div>

// :null}

// {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_PER_UNIT))?
// <div className="col-1">
// {/* unit_cost */}
// <input
//   type="text"
//   className="section-input form-control m-0 p-0"
//   defaultValue={labour.cost_per_unit}
//   onBlur={(e) => {
//     // dispatch(updateLabour({ ...labour, cost_per_unit: e.target.value }));
//     dispatch(updateItemData({item :{ ...labour, cost_per_unit: e.target.value }}));
//   }}
// ></input>
// </div>  
// :null}
// {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.COST_OF_UNITS))?
// <div className="col-1">
// {/* cost_of_units */}
// <input
//  type="number"
//  defaultValue={(parseFloat(labour.cost_per_unit)*parseFloat(labour.units_required)).toFixed(2)} 
//  className="section-input"
//  readOnly={true}
// ></input>
// </div>  
// :null}
// {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.MARGIN))?
// <div className="col-1">
// {/* markup  */}
// <input
// type="text"
// defaultValue={labour.margin}
// className="section-input form-control m-0 p-0"
// onBlur={(e) => {
//  // dispatch(updateLabour({ ...labour, margin: e.target.value }));
//  dispatch(updateItemData({item :{ ...labour, margin: e.target.value }}));
// }}
// ></input>
// </div>
// :null}
// {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.CLIENT_CHARGE))?
// <div className="col-1">
// {/* Client Charge */}
// <input
// type="number"
// defaultValue={(parseFloat(labour.cost_per_unit)*parseFloat(labour.units_required)*(1+(parseFloat(labour.margin)/100))).toFixed(2)} 
// className="section-input"
// readOnly={true}
// ></input>
// </div>
// :null}

// {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.STATUS))?
// <div className="col-2">
// <select
// className="section-input form-select m-0 p-0"
// defaultValue={labour.status}
// onBlur={(e) => {
//  // dispatch(updateLabour({ ...labour, status: e.target.value }));
//  dispatch(updateItemData({item :{ ...labour, status: e.target.value }}));
// }}
// >
// <option value="Prime">Prime</option>
// <option value="Provitional">Provitional</option>
// <option value="Excluded">Excluded</option>
// </select>
// </div>
// :null}


// {(userViewFilters.includes(billOfQuantities.VIEW_FILTERS.TYPE))?
// //   <div className="col-1">
// //   <input
// //     type="text"
// //     defaultValue={labour.type}
// //     className="section-input form-control m-0 p-0"
// //     onBlur={(e) => {
// //       // dispatch(updateLabour({ ...labour, type: e.target.value }));
// //       dispatch(updateLabourData({labour :{ ...labour, type: e.target.value }}));
// //     }}
// //   ></input>
// // </div>

// <div className="col-1">
// <select
//   className="section-input form-select m-0 p-0"
//   defaultValue={labour.name}
//   onChange={(e) => {
//     let itemObject = {...labour}
//     // console.log("items",e.target.selectedOptions[0].getAttribute("type"))
//     // if(e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.ITEM && e.target.selectedOptions[0].getAttribute("labourid") && labour._id !==  e.target.selectedOptions[0].getAttribute("labourid"))
//     // {
//     //   console.log("under current labour",e.target.value)
      
//     //   items.forEach(element => {
//     //     if(element.labour_id === labour._id){
//     //       let elementCurrent = {...element}
//     //       delete elementCurrent.labour_id
//     //       dispatch(updateItemData({item : {...elementCurrent, specification : billOfQuantities.TYPES.UNSPECIFIED}}))
//     //     }
//     //   });

//     //   dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("type"), labour_id : e.target.selectedOptions[0].getAttribute("labourid")}}))
//     // }
//     // else if (e.target.selectedOptions[0].getAttribute("type") ===billOfQuantities.TYPES.LABOUR && e.target.selectedOptions[0].getAttribute("labourid")==null){
//     //   console.log("new labour",e.target.value)
//     //   delete itemObject.labour_id
//     //   dispatch(updateItemData({item :{...itemObject, type :e.target.selectedOptions[0].getAttribute("type")}}))
//     // }
//     if (e.target.selectedOptions[0].getAttribute("specification")===billOfQuantities.TYPES.UNSPECIFIED){
      
//       items.forEach(element => {
//         if(element.labour_id === labour._id){
//           let elementCurrent = {...element}
//           delete elementCurrent.labour_id
//           dispatch(updateItemData({item : {...elementCurrent, specification : billOfQuantities.TYPES.UNSPECIFIED}}))
//         }
//       });
//       delete itemObject.labour_id
//       dispatch(updateItemData({item :{...itemObject, specification :e.target.selectedOptions[0].getAttribute("specification")}}))

//     }
    
//     // dispatch(updateItemData({item :{...item, type :e.target.value}})) 
//                          }}
// >
// {/* {items.map((currentLabour)=>{
//               if(currentLabour.section_id === labour.section_id)
//               return <option value={currentLabour.name}>{currentLabour.name}</option>
//             })}
//               <hr className="dropdown-divider"/>
//               <option className="m-1" value="newLabour">Add New Labour</option>
//               <option className="m-1" value="unspecified">unspecified</option>
//           */}

//         {/* {items.map((currentLabour)=>{
//               if(currentLabour.type === billOfQuantities.TYPES.LABOUR && currentLabour.section_id ===labour.section_id)
//               return <option key={currentLabour._id} value={currentLabour.name} labourid={currentLabour._id}
//               type={billOfQuantities.TYPES.ITEM} 
//               >{currentLabour.name}</option>
//             })}
//               <hr className="dropdown-divider"/> */}
//               {/* <option className="m-1" value={billOfQuantities.TYPES.LABOUR} 
//               type={billOfQuantities.TYPES.LABOUR} 
//               >Add New Labour</option> */}

//               <option className="m-1" value={billOfQuantities.TYPES.SPECIFIED} specification={billOfQuantities.TYPES.LABOUR}>{labour.name}</option>
//               <option className="m-1" value={billOfQuantities.TYPES.UNSPECIFIED} specification={billOfQuantities.TYPES.UNSPECIFIED}>unspecified</option>
//               {/* <option value="Provitional">Provitional</option>
//               <option value="Excluded">Excluded</option> */}
// </select>
// </div>
// :null}







// <div className="col-1">
// <KeyboardArrowDownIcon
// fontSize="large"
// onClick={() => {
//   setShow((prev) => !prev);
// }}
// />
// <CancelPresentationTwoToneIcon
// onClick={() => {
//   console.log("delete Items ", labour)
//   dispatch(deleteItems({item :labour}))
// }}
// color="warning"
// />
// </div>

// </div>
// {show?
// <>
// <DisplayItems/>

// </>:null}
// <div className="row">
// <div className="col-3 d-flex">
// <div className="section-container-item-name " >
// {/* <AddIcon  onClick={()=>{
  
//   //dispatch(initializeNewItem(labour._id))
//   dispatch(createItem({labourId :labour._id, sectionId : labour.section_id, type :billOfQuantities.TYPES.ITEM}))
// }}  /> */}
//   {/* <button className='btn btn-outline-secondary btn-light' data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"> Filters <div className="dropdown-arrow"></div></button> */}
  
//   <AddIcon  data-bs-toggle="dropdown" role="button" aria-expanded="false"  onClick={()=>{
//       // dispatch(changeLabourModalStatus(section._id))
//       // dispatch(createLabour({sectionId : section._id}))
//    // dispatch(createItem({ sectionId : section._id , type : billOfQuantities.TYPES.LABOUR}))

      
//       }}  />
// <ul className="dropdown-menu">
//   {/* {
//     pricingListRequestData.map((addUp)=>{
//       if(addUp.type === billOfQuantities.TYPES.ITEM && !pricingListRequestLoading){
//         return (
//           <li key={addUp._id} onClick={()=>{ dispatch(createItem({ name :addUp.name, sectionId : labour.section_id , type : billOfQuantities.TYPES.ITEM, labourId :labour._id, specialism : billOfQuantities.TYPES.ITEM} ))}}><a className="dropdown-item" >{addUp.name}</a></li>
//         )
//       }
//     })
    
//   } */}
//     {/* <li><hr className="dropdown-divider"/></li> */}
//   <li onClick={()=>{ dispatch(changeAddUpModalStatus({sectionId : labour.section_id,labourId : labour._id, labourSpecification : billOfQuantities.TYPES.SPECIFIED, itemSpecification : billOfQuantities.TYPES.SPECIFIED}))}}><a className="dropdown-item" >New labour/Item</a></li>
// </ul>


// </div>
// <div  style={{ width: 400}}>
//                   <ReactSearchAutocomplete
//                     placeholder={"Add Item"}
//                     fuseOptions={{keys : ["name","type"]}}

//                     items={pricingListCurrent} 
//                     showIcon= {false}
//                     formatResult = {formatResult}
//                     onSelect = {(event)=>{createOnSelect(event)}}
//                     styling={{
//                       height: "34px",
//                       border: "none",
//                       // border: "1px solid darkgreen",
//                       borderRadius: "4px",
//                       backgroundColor: "#EFEFEF",
//                       boxShadow: "none",
//                       hoverBackgroundColor: "lightgreen",
//                       color: "black",
//                       // fontSize: "12px",
//                       // fontFamily: "Courier",
//                       iconColor: "green",
//                       lineColor: "lightgreen",
//                       placeholderColor: "#002D62",
//                       clearIconMargin: "3px 8px 0 0",
//                       zIndex : "auto",
//                       // zIndex: {labourZIndex},
//                     }}
//                   />
// </div>
// </div>








// const CreateNewItem =()=>{
//   return(
//       newItems.map((item,index)=>{
          
//           if(item.labour_id === labour._id){
            
           
          
//           return(
//               // <div key={index} className={"collapse multi-collapse  row "+show+" "+section.name}  >
//               <div key={index} className={"row"}  >

              
//               <div className='section-container-item-name col-3 d-flex'>  
//                   <input className="form-check-input" type="checkbox"></input>   
//                   <input type="text" defaultValue={item.name} className='section-input new-items-container form-control m-0 p-0'   onBlur={(e)=>{
//                    dispatch(updateNewItem({item: {...item, name :e.target.value }, index: index}))
  
//                   }}></input>
//               </div>
             
//               <div className='col-1 d-flex'>    
//                   <input type="text" defaultValue={item.units_required} className='section-input new-items-container form-control m-0 p-0 '  onBlur={(e)=>{
//                    dispatch(updateNewItem({item: {...item, units_required :e.target.value }, index: index}))
  
//                   }} ></input>
//                   <input type="text"  defaultValue={item.uom}  className='section-input new-items-container form-control m-0 p-0' onBlur={(e)=>{
//                    dispatch(updateNewItem({item: {...item, uom :e.target.value }, index: index}))
  
//                   }} ></input>
//               </div>
//               <div className='col-1'> 
//               <input type="text"  defaultValue={item.cost_per_unit}  className='section-input new-items-container form-control m-0 p-0 ' onBlur={(e)=>{
//                    dispatch(updateNewItem({item: {...item, cost_per_unit :e.target.value }, index: index}))
  
//                   }} ></input>
//               </div>
//               <div className='col-1'> 
//                   {/* defaultValue={item.cost_of_units} */}
//                   {/* onBlur={(e)=>{
//                    dispatch(updateNewItem({item: {...item, cost_of_units :e.target.value }, index: index}))
  
//                   }} */}
//               <input type="number" defaultValue={(parseFloat(item.cost_per_unit)*parseFloat(item.units_required)).toFixed(2)} className='section-input ' readOnly={true} ></input>
//               </div>
//               <div className='col-1'> 
//               <input type="text" defaultValue={item.margin} className='section-input new-items-container form-control m-0 p-0 ' onBlur={(e)=>{
//                    dispatch(updateNewItem({item: {...item, margin :e.target.value }, index: index}))
  
//                   }}></input>
//               </div>
//               {/* <p className='col-1' defaultValue={item.total} onBlur={(e)=>{
//                    dispatch(updateNewItem({...item, total :e.target.value}))
  
//                   }} >$1,499 to</p> */}
//                 <div className='col-1'> 
//               <input type="number" defaultValue={(parseFloat(item.cost_per_unit)*parseFloat(item.units_required)*(1+(parseFloat(item.margin)/100))).toFixed(2)}  className='section-input ' readOnly={true}></input>
//               </div>
              
//               <div className='col-2' defaultValue={item.status} onBlur={(e)=>{
//                    dispatch(updateNewItem({item: {...item, status :e.target.value }, index: index}))
  
//                   }}> 
//                   <select className='section-input new-items-container form-control m-0 p-0'>
//                           <option value="Prime">Prime</option>
//                           <option value="Excluded">Excluded</option>
//                           <option value="Provitional">Provitional</option>
//                   </select>
//               </div>
//               <div className='col-1'> 
//                   <input type="text" defaultValue={item.type}  className='section-input new-items-container form-control m-0 p-0'  onBlur={(e)=>{
//                   dispatch(updateItem({item: {...item, type :e.target.value }, index: index}))
  
//                   }} ></input>
//               </div>
//               <div className='col-1'><CancelPresentationTwoToneIcon color='warning' onClick={()=>{
//                   dispatch(deleteNewItem(index))
//               }}/></div>
                      
//           </div>
//           )
//       }
//   })
      
//   )
// }












// const DisplayItem = ()=>{
        
//   return items.map((item)=>{
//       if(item.labour_id === labour._id){
              

//          return(
            
//               //  <div key={item.id} className={" multi-collapse   collapse row "+show+" "+section.name}> 
//               <div key={item._id} className={"section-item-container row"}> 
              
//                   <div className='section-container-item-name col-3 d-flex' > 
//                       {isCurrentChecked(item._id , billOfQuantities.TYPES.ITEM)?
//                         <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": item._id, "type": billOfQuantities.TYPES.ITEM}))}} defaultChecked></input>   
//                       :
                      
//                       <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": item._id, "type": billOfQuantities.TYPES.ITEM}))}} ></input>   
//                     }
//                     {/* <input className="form-check-input" type="checkbox" onChange={(e)=>{dispatch(addToCheckedList({"isChecked":e.target.checked,"id": item._id, "type": billOfQuantities.TYPES.ITEM}))}} ></input>    */}
//                       <input type="text" defaultValue={item.name} className='section-input form-control ' onBlur={(e)=>{
//                       // dispatch(updateItem({...item, name :e.target.value}))
//                       dispatch(updateItemData({item:{...item, name :e.target.value}}))
//                       }}></input>
//                   </div>
//                   <div className='col-1 d-flex'> 
//                       {/* Qty */}
//                       <input type="text" defaultValue={item.units_required} className='section-input form-control  m-0 p-0' onBlur={(e)=>{
//                           // dispatch(updateItem({...item, units_required: e.target.value}))
//                           dispatch(updateItemData({item :{...item, units_required: e.target.value}}))
//                       }}></input>
                     
//                      {/* UOM */}
//                       <input type="text"  className='section-input form-control m-0 p-0 ' defaultValue={item.uom}  onBlur={(e)=>{
//                       // dispatch(updateItem({...item, uom :e.target.value}))
//                       dispatch(updateItemData({item :{...item, uom :e.target.value}}))
                      
//                       }}></input>
//                   </div>
//                   <div className='col-1'> 
//                       {/* unit_cost */}
//                       <input type="text"  className='section-input form-control  m-0 p-0' defaultValue={item.cost_per_unit}  onBlur={(e)=>{
//                       // dispatch(updateItem({...item, cost_per_unit :e.target.value}))
//                       dispatch(updateItemData({item :{...item, cost_per_unit :e.target.value}}))
                      
//                       }}></input>
//                   </div>
//                   <div className='col-1'> 
//                       {/* cost_of_units */}
                   
//                       <input type="number" defaultValue={(parseFloat(item.cost_per_unit)*parseFloat(item.units_required)).toFixed(2)} className='section-input'  
//                         readOnly={true}></input>
//                   </div>
//                   <div className='col-1'>
//                       {/* markup  */}
//                       <input type="text" defaultValue={item.margin} className='section-input form-control  m-0 p-0' onBlur={(e)=>{
//                       // dispatch(updateItem({...item, margin :e.target.value}))
//                       dispatch(updateItemData({item :{...item, margin :e.target.value}}))
                      
//                       }}></input>
//                   </div>
//                   <div className='col-1'> 
//                       {/* Client Charge */}
//                       <input type="number" defaultValue={((parseFloat(item.cost_per_unit)*parseFloat(item.units_required))*(1+(parseFloat(item.margin)/100))).toFixed(2)} className='section-input '  readOnly={true}
//                       ></input>
//                   </div>
//                   {/* <p className='col-1'>{items.length}</p> */}
//                   {/* <p className='col-2'>Provitional</p> */}
//                   <div className='col-2' > 
//                       <select className='section-input form-select m-0 p-0' defaultValue={item.status} onBlur={(e)=>{
//                       // dispatch(updateItem({...item, status :e.target.value}))
//                       dispatch(updateItemData({item : {...item, status :e.target.value}}))
                      
//                       }} >
//                           <option value="Prime">Prime</option>
//                           <option value="Provitional">Provitional</option>
//                           <option value="Excluded">Excluded</option>
//                       </select>
//                   </div>
//                   <div className='col-1'> 
//                       <input type="text" defaultValue={item.type} className='section-input form-control m-0 p-0'  onBlur={(e)=>{
//                       // dispatch(updateItem({...item, type :e.target.value}))
//                       dispatch(updateItemData({item :{...item, type :e.target.value}}))
                      
//                       }} ></input>
//                   </div>
//                   <div className='col-1'><CancelPresentationTwoToneIcon onClick={()=>{dispatch(deleteItems({item}))}} color='warning'/></div>
                  
//               </div>
              
//       ) 
//       }
//   })
// }