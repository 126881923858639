import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getProjects } from '../../features/ProjectSlice'

const FilterCriteria = ({ onBudgetChange, onContractTypeChange }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        getAllProjects()
    }, [])

    const getAllProjects = async () => {
        await dispatch(getProjects())
    }

    return (
        <div className='container-fluid mt-3 p-0'>
            <div className='row justify-content-start'>
                <div className='col-lg-3'>
                    <select className="form-select" placeholder='Project Type' id="project-budget-filter" name="project-budget" defaultValue={0} onChange={(e) => onBudgetChange(e.target.value)}>
                        <option value="0" selected>Select Budget</option>
                        <option value="1">$ 0 - 10000</option>
                        <option value="2">$ 10001 - 20000</option>
                        <option value="3">$ 20000+</option>
                    </select>
                </div>

                <div className='col-lg-3'>
                    <select className="form-select" placeholder='Project Type' id="project-budget-filter" name="project-budget" defaultValue={0} onChange={(e) => onContractTypeChange(e.target.value)}>
                        <option value="0" selected>Select Contract Type</option>
                        <option value="1">Contract Price</option>
                        <option value="2">Cost Plus Price</option>
                    </select>
                </div>
            </div>
        </div>
    )
}

export default FilterCriteria