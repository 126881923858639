import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { httpGet, httpPost } from "../service/service";
import {urls} from '../constants/Url';

const initialState = {
  loginRequestData: [],
  loginRequestLoading: false,
  loginRequestError: "",
  logoutRequestData: [],
  logoutRequestLoading: false,
  logoutRequestError: "",
};
//httpPost("https://jsonplaceholder.typicode.com/todos/1",loginRequest)
export const login = createAsyncThunk("auth/login", ({username,password,isRememberMeChecked}) => {
    const loginRequest = 
       {
            "username":username,
            "password":password
          }
          // "role": "1"
    
  
  const responePromise = httpPost(urls.BASE_URL+urls.LOGIN_URL,loginRequest)
  return responePromise.then(
    (response) => {
     
      // localStorage.setItem("access_token",response.data.accessToken)
      localStorage.setItem("access_token",response.data.tokens.access.token)
      // localStorage.setItem("refresh_token",response.data.refreshToken)
      localStorage.setItem("refresh_token",response.data.tokens.refresh.token)
      localStorage.setItem("username",response.data.username)
      localStorage.setItem("contractor_id",response.data.user._doc.contractor_id)
      localStorage.setItem("is_remember_me_checked", isRememberMeChecked)

    return response.data.user;
  }

  )



  // return axios.get("https://jsonplaceholder.typicode.com/todos/1")
  // .then(response=>{
  //    // console.log(response.data)
  //    return response.data;
  // })
});
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logged: (state) => {
      state.loginRequestLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.loginRequestLoading =false;
      state.loginRequestData = [action.payload];
    });
    builder.addCase(login.pending, (state) => {
        state.loginRequestData = [];
        state.loginRequestLoading =true;
      });
      builder.addCase(login.rejected, (state, action) => {
        state.loginRequestError = action.error.message;
        state.loginRequestData = [];
        state.loginRequestLoading =false;
      });
  },
});

export default authSlice.reducer;

export const { logged } = authSlice.actions;





















// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import { httpGet, httpPost } from "../service/service";

// const initialState = {
//   loginRequestData: {},
//   loginRequestLoading: false,
//   loginRequestError: "",
//   logoutRequestData: [],
//   logoutRequestLoading: false,
//   logoutRequestError: "",
// };

// export const login = createAsyncThunk("auth/login", (username,password) => {
//     const loginRequest = {
//         "requestBody":{
//             "username":username,
//             "password":password
//         }
//     }
//   //const responePromise = httpGet("https://jsonplaceholder.typicode.com/todos/1");
//   const responePromise = httpPost("https://jsonplaceholder.typicode.com/todos/1",loginRequest)
//   return responePromise.then((respone) => {
   
//     return respone.data;
//   });

//   // return axios.get("https://jsonplaceholder.typicode.com/todos/1")
//   // .then(response=>{
//   //    // console.log(response.data)
//   //    return response.data;
//   // })
// });
// const authSlice = createSlice({
//   name: "auth",
//   initialState,
//   reducers: {
//     logged: (state) => {
//       state.loginRequestLoading = true;
//     },
//   },
//   extraReducers: (builder) => {
//     builder.addCase(login.fulfilled, (state, action) => {
//       //console.log(action.payload)
//       state.loginRequestLoading =false;
//       state.loginRequestData = action.payload;
//     });
//     builder.addCase(login.pending, (state) => {
//         state.loginRequestData = [];
//         state.loginRequestLoading =true;
        
        
//       });
//       builder.addCase(login.rejected, (state, action) => {
     
//         state.loginRequestError = action.error.message;
//         state.loginRequestData = [];
//         state.loginRequestLoading =false;
//       });
//   },
// });

// export default authSlice.reducer;

// export const { logged } = authSlice.actions;
