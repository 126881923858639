import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';

const SelectedFileTable = ({ documents, onRemove }) => {
  return <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead style={{ backgroundColor: 'lightgray' }}>
        <TableRow>
          <TableCell>No.</TableCell>
          <TableCell align="left">File Name</TableCell>
          <TableCell align="left">Type</TableCell>
          <TableCell align="left">Status</TableCell>
          <TableCell align="left">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {documents.map((document, index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {index + 1}
            </TableCell>
            <TableCell align="left">{document.documentName}</TableCell>
            <TableCell align="left">{document.documentType}</TableCell>
            <TableCell align="left">{document.documentStatus}</TableCell>
            <TableCell align="left"><DeleteIcon style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => onRemove(document)} /></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
}

export default SelectedFileTable