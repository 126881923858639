import React, { useState, useEffect } from 'react'
import { Button, Divider } from '@mui/material';
import UploadedFileTable from './UploadedFileTable';
import SelectedFileTable from './SelectedFileTable';

const DocumentUploadForm = ({ onAddFile, onRemoveFile, uploadedFiles }) => {
    const [file, setFile] = useState(null)
    // const [files, setFiles] = useState([])
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedFileType, setSelectedFileType] = useState('');
    const [selectedFileStatus, setSelectedFileStatus] = useState('');
    const [documents, setDocuments] = useState([])

    useEffect(() => {
        if (file) {
            setSelectedFileName(file.name)
        }
    }, [file]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    }

    const handleDocumentTypeChange = (value) => {
        setSelectedFileType(value)
    }

    const handleDocumentNameChange = (value) => {
        setSelectedFileName(value)
    }

    const handleDocumentStatusChange = (value) => {
        setSelectedFileStatus(value)
    }

    const addFile = () => {
        // setFiles(files => [...files, file])
        const document = {
            file: file,
            documentName: selectedFileName,
            documentType: selectedFileType,
            documentStatus: selectedFileStatus
        }
        setDocuments(documents => [...documents, document])
        onAddFile(document)
    }

    const removeFile = (removedFile) => {
        setDocuments(documents.filter(f => f.documentName !== removedFile.documentName))
        onRemoveFile(removedFile)
    }



    return (
        <>
            <div className='row'>
                <div className='col-lg-2'>
                    <p className=''>Browse</p>
                </div>
                <div className='col-lg-3'>
                    <div className="d-flex align-items-center mb-3 mt-3">
                        <input type='file' onChange={handleFileChange} />
                    </div>
                </div>
                {/* <div className='col-lg-3'>
                    <div className="d-flex align-items-center mb-3 mt-3">
                        <button onClick={uploadFiles}>Upload</button>
                    </div>
                </div> */}
            </div>

            <div className='row'>
                <div className='col-lg-2'>
                    <p className=''>Document Type</p>
                </div>
                <div className='col-lg-3'>
                    <select className="form-select" placeholder='Document Type' id="document-type" name="document-type" defaultValue={null} value={selectedFileType} onChange={(e) => handleDocumentTypeChange(e.target.value)}>
                        <option value="" selected>Select Document Type</option>
                        <option value="legal">Legal</option>
                        <option value="financial">Financial</option>
                    </select>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-2'>
                    <p className=''>Document Name</p>
                </div>
                <div className='col-lg-3'>
                    <input type="text" className="form-control" placeholder="Document Name" id='document-name' defaultValue={''} value={selectedFileName} onChange={e => handleDocumentNameChange(e.target.value)} disabled></input>

                </div>
            </div>
            <div className='row'>
                <div className='col-lg-2'>
                    <p className=''>Status</p>
                </div>
                <div className='col-lg-3'>
                    <select className="form-select" placeholder='Document Status' id="document-status" name="document-status" defaultValue={0} value={selectedFileStatus} onChange={(e) => handleDocumentStatusChange(e.target.value)}>
                        <option value="" selected>Select Document Status</option>
                        <option value="submitted">Submitted</option>
                        <option value="approved">Approved</option>
                    </select>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-3'>
                    <div className="d-flex align-items-center mb-3 mt-3">
                        <Button variant='outlined' onClick={addFile} disabled={!file}>Add File</Button>
                    </div>
                </div>
            </div>

            {documents?.length > 0 &&
                <SelectedFileTable documents={documents} onRemove={(file) => removeFile(file)}/>
            }

            {uploadedFiles?.length > 0 &&
                <>
                    <Divider sx={{ backgroundColor: 'black' }} />

                    <p className='mt-3'><strong>Uploaded Files</strong></p>
                    <UploadedFileTable files={uploadedFiles} />
                </>
            }
        </>
    )
}

export default DocumentUploadForm;