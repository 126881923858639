// export const urls = {
//     BASE_URL :"http://localhost:8080",
//     LOGIN_URL : "/authenticate",
//     REFRESH_URL : "/refreshtoken"
// }

export const urls = {
    // BASE_URL :"http://ec2-52-23-168-141.compute-1.amazonaws.com:3000/api/v1",
    // BASE_URL :"http://localhost:5001/api/v1",

    //BASE_URL :"http://192.168.8.103:5000/api/v1",
    BASE_URL :"https://builderninja.pro:8443/api/v1",
    // BASE_URL :"http://192.168.43.224:5000/api/v1",
    LOGIN_URL : "/auth/authenticate",
    REGISTER_URL : "/auth/create-user",
    REFRESH_URL : "/auth/refreshtoken",
    POST_PROJECT :"/projects/new",
    PUT_PROJECT :"/projects/update",
    ARCHIVE_PROJECT :"/projects/archive",
    DELETE_PROJECT :"/projects/delete",
    GET_PROJECTS :"/projects",
    GET_PROJECT : "/projects/project",
    GET_PRICING_SECTIONS:"/pricing/sections",
    UPDATE_PRICING_SECTIONS : "/pricing/sections",
    UPDATE_PRICING_SECTION : "/pricing/section",
    DELETE_PRICING_SECTIONS : "/pricing/sections",
    POST_PRICING_SECTION: "/pricing/section",
    
    POST_PRICING_ITEM: "/pricing/Item",
    GET_PRICING_ITEMS:"/pricing/items",
    UPDATE_PRICING_ITEMS : "/pricing/items",
    UPDATE_PRICING_ITEM : "/pricing/item",
    DELETE_PRICING_ITEMS : "/pricing/items",

    GET_PRICING_LABOUR :"/pricing/labours",
    POST_PRICING_LABOUR:"/pricing/labour",
    UPDATE_PRICING_LABOURS :"/pricing/labours",
    UPDATE_PRICING_LABOUR :"/pricing/labour",
    DELETE_PRICING_LABOUR:"/pricing/labours",

    GET_PRICING_LABOURS_AND_ITEMS :"/pricing/laboursAndItems",

    GET_PRICING_USER_VIEW_FILTER:"/pricing/userViewFilter",
    UPDATE_PRICING_USER_VIEW_FILTER : "/pricing/userViewFilter",

    POST_PRICING_ADDUP: "/pricing/addUp",
    GET_PRICING_ADDUP:"/pricing/addUp",


    GET_PRICING_LIST:"/pricingList",
    POST_PRICING_LIST:"/pricingList",
    UPDATE_PRICING_LIST:"/pricingList",

    

    GET_CONTACT : "/contact",
    GET_ALL_CONTACTS : "/contact",
    UPDATE_CONTACT : "/contact",
    POST_CONTACT : "/contact",

    UPLOAD_FILES:"/file/upload",
    GET_FILES: "/file/all",
    DELETE_FILE: "/file/delete",
    ARCHIVE_FILE: "/file/archive",
    UPDATE_FILE: "/file/update"
}

export const apiCodes = {
    STATUS_SUCCESS_CODE : 200,
    RESPONSE_CODE_UNAUTHORIZED : 401,
    RESPONSE_CODE_TOKEN_INVALID : 4501,
    RESPONSE_CODE_TOKEN_EXPIRE : 4502,
    RESPONSE_CODE_FORBIDDEN : 403

};