import React, { useState } from 'react'
import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { billOfQuantities, pricingList } from '../../constants/Constants'
import { changeAddUpModalStatus, createAddUp, createItem } from '../../features/PricingSlice'
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


//icons
import pcIcon from "../../assets/icons/PC.png"
import exIcon from '../../assets/icons/EX-icon.png'

const PricingListPopupModel = () => {

    const dispatch = useDispatch()
    const pricing = useSelector((state)=>state.pricing)
    const [isError, setIsError] = useState(false)
    const [isOneTimeUseChecked, setIsOneTimeUseChecked] = useState(false)
    const [buyUnitPrice, setBuyUnitPrice] = useState(0.0)
    const [sellUnitPrice, setSellUnitPrice] = useState(0.0)

    const [uom, setUom] = useState("")
    const [margin, setMargin] = useState("0.0")
    const [specialism, setSpecialism] = useState("")
    const [project, setProject] = useState("")
    const [company, setCompany] = useState("")
    const [isSearchable, setIsSearchable] = useState(pricingList.YES_OR_NO.YES)
    const [inAssemblies, setInAssemblies] = useState(pricingList.YES_OR_NO.NO)
    const [date, setDate] = useState("")
    const [name   , setName ] = useState("")
    const [itemType   , setitemType ] = useState("Other")
    const [costCode, setCostCode] = useState("")
    const [costType, setCostType] = useState(1)
    const [wastagePercentage, setWastagePercentage] = useState(0)
    const [supplier, setSupplier] = useState("")
    const [itemNameForProposal, setitemNameForProposal] = useState("")


    let addName =""
    const [type, setType] = useState(billOfQuantities.TYPES.ITEM)
    const pricingListRequestLoading = useSelector((state)=>state.pricing.pricingListRequestLoading)
    const pricingListRequestData = useSelector((state)=>state.pricing.pricingListRequestData)
    const showAddUpModal = useSelector((state)=>state.pricing.showAddUpModal)
    const pricingListModalData = useSelector((state)=>state.pricing.pricingListModalData)
    const validateSectionName = (event)=>{
        if(pricingListRequestData.some((labour)=>labour.name.toLowerCase()=== event.target.value.toLowerCase()) || event.target.value===''){
            setIsError(true)
            
        }
        else {
            setIsError(false)
            
        }
        setName((prev)=>event.target.value)
        addName = event.target.value   
    }
    
    useEffect(() => {
      
      setName("")
      setIsError(true)
      setIsOneTimeUseChecked(false)
      setType(billOfQuantities.TYPES.ITEM)
     
      setCompany("")
      setDate("")
      setInAssemblies(pricingList.YES_OR_NO.YES)
      setIsSearchable(pricingList.YES_OR_NO.YES)
      setMargin("0")
      setProject("")
      setUom("")
      setSpecialism("")
      setitemType("Other")
      setCostCode("")
      setCostType(1)
      setWastagePercentage(0)
      setSupplier("")
      setitemNameForProposal("")

      setBuyUnitPrice(0.00)
      setSellUnitPrice(0.00)
      
    }, [showAddUpModal])
    


  return (
    <div><Modal show={showAddUpModal} >
    {/* <Modal.Header>
      <h3>new section</h3>
      <button
        className="btn btn-danger"
        onClick={() => {
          dispatch(changeModalStatus())
        }}
      >
        Close
      </button>
    </Modal.Header> */}
    <Modal.Body>
      <div>
        <form>
          <div className="mb-3">
            <label htmlFor="sectionName" className="form-label">
              Item Name
            </label>
            <input
              type="text"
              className="form-control"
              id="sectionName"
                onChange={(e)=>{validateSectionName(e)}}
            />
            {isError ? <p>Please Enter a unique Item name</p> : null}
            
            <div>    
                <label>Select prefered Type </label>
                  <div className='d-flex gap-1'>
                      <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={billOfQuantities.TYPES.LABOUR} onChange={(e)=>{setType(e.target.value)}}/>
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                              Labour
                          </label>
                      </div>
                      <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value={billOfQuantities.TYPES.ITEM} defaultChecked onChange={(e)=>{setType(e.target.value)}}/>
                          <label className="form-check-label" htmlFor="flexRadioDefault2">
                              Item
                          </label>
                      </div>
                  </div>
            </div>
            <div className="mb-3">
                <label htmlFor="item-type-input" className="form-label">Item Type</label>
                <select 
                  id="item-type-input"
                  defaultValue={itemType}
                  className="form-select"
                  onChange={(e)=>{
                    setitemType(e.target.value)
                  }}
                  >
                    <option value="Assembly (Global)">Assembly (Global)</option>
                    <option value="Equipment">Equipment</option>
                    <option value="Material">Material</option>
                    <option value="MathLab">MathLab</option>
                    <option value="Other">Other</option>
                    <option value="Staff">Staff</option>
                    <option value="Sub(Labour only)">Sub(Labour only)</option>
                    <option value="Sub(Material only)">Sub(Material only)</option>
                    <option value="Sub(MathLab)">Sub(MathLab)</option>
                </select>
            </div> 

            <div className="mb-3">
                <label htmlFor="unit-price" className="form-label">Buy unit Price</label>
                <input className="form-control" type="number" id="unit-price" defaultValue={buyUnitPrice} onChange={(e)=>{setBuyUnitPrice(e.target.value)}} />
            </div>    
            <div className="mb-3">
                <label htmlFor="unit-price" className="form-label">Sell unit Price</label>
                <input className="form-control" type="number" id="unit-price" defaultValue={sellUnitPrice} onChange={(e)=>{setSellUnitPrice(e.target.value)}} />
            </div>    
            {/* <div className="mb-3">
                <label htmlFor="unit-price" className="form-label">Unit Price</label>
                <input className="form-control" type="number" id="unit-price" defaultValue={unitCost} onChange={(e)=>{setUnitCost(e.target.value)}} />
            </div>     */}
            {/* <div className="mb-3">
                <label htmlFor="project-input" className="form-label">Project</label>
                <input className="form-control" type="text" id="project-input" defaultValue={project} onChange={(e)=>{setProject(e.target.value)}} />
            </div>   */}
            {/* <div className="mb-3">
                <label htmlFor="specialism-input" className="form-label">Specialism</label>
                <input className="form-control" type="text" id="specialism-input" defaultValue={specialism} onChange={(e)=>{setSpecialism(e.target.value)}} />
            </div>   */}
            {/* <div className="mb-3">
                <label htmlFor="company-input" className="form-label">Company</label>
                <input className="form-control" type="text" id="company-input" defaultValue={company} onChange={(e)=>{setCompany(e.target.value)}} />
            </div>   */}
            <div className="mb-3">
                <label htmlFor="uom-input" className="form-label">UOM</label>
                {/* <input  type="text" defaultValue={uom} onChange={(e)=>{setUom(e.target.value)}} /> */}
                <select className="form-select" id="uom-input" defaultValue={uom} onBlur={(e)=>{
                                              setUom(e.target.value)
                                          
                                               }} >
                                                  <option value="Ea">Ea</option>
                                                  <option value="M2"><p>M<sup>2</sup></p></option>
                                                  <option value="M3">M<sup>3</sup></option>
                                                  <option value="Hrs">Hrs</option>
                                                  <option value="Hrs">Days</option>
                                                  <option value="Im">Im</option>
                                                  <option value="Kg">Kg</option>
                                                   
                                               </select>
            </div>  
            {/* <div className="mb-3">
                <label htmlFor="markup-input" className="form-label">Markup</label>
                <input className="form-control" type="text" id="markup-input" defaultValue={margin} onChange={(e)=>{setMargin(e.target.value)}} />
            </div>   */}
            {/* <div className="mb-3">
                <label htmlFor="searchable-input" className="form-label">Is Searchable</label>
                <select 
                  id="searchable-input"
                  defaultValue={isSearchable}
                  className="form-select"
                  onChange={(e)=>{
                    setIsSearchable(e.target.value)
                    }}
                  >
                    <option value ={pricingList.YES_OR_NO.YES}>{pricingList.YES_OR_NO.YES}</option>
                    <option value ={pricingList.YES_OR_NO.NO}>{pricingList.YES_OR_NO.NO}</option>
                </select>
            </div>   */}
            <div className="mb-3">
                <label htmlFor="date-input" className="form-label">Cost Code</label>
                <input  type="text" id="date-input" className="form-control" 
                  defaultValue={costCode}
                onBlur={(e)=>{
                    setCostCode(e.target.value)                  
                }}
                ></input>
            </div> 

            <div className="mb-3">
                <label htmlFor="date-input" className="form-label">Wastage Percentage</label>
                <input  type="number" id="date-input" className="form-control"
                  defaultValue={wastagePercentage} 
                onBlur={(e)=>{
                    setWastagePercentage(e.target.value)
                }}
                ></input>
            </div>
            <div className="mb-3">
                <label htmlFor="uom-input" className="form-label">Cost Type</label>
                {/* <input  type="text" defaultValue={uom} onChange={(e)=>{setUom(e.target.value)}} /> */}
                <FormControl  className='d-flex justify-content-center'  >
                                                  {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
                                                  <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small-item"
                                                    defaultValue={costType=== undefined? "" : costType}
                                                    label="Age"
                                                    onChange={(e)=>{setCostType(e.target.value)}}
                                                    IconComponent={()=>null}
                                                    
                                                  >
                                                    <MenuItem value="">
                                                      <em></em>
                                                    </MenuItem>
                                                    <MenuItem value={1}><img  className='ui avatar image  ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={pcIcon}/></MenuItem>
                                                    <MenuItem value={2}><img  className='ui avatar image unique-icon ' style={{height : "10px", width: "16px",background: "#EFEFEF", border:"none"}} src={exIcon}/></MenuItem>
                                                    {/* <MenuItem value={3}><img  className='ui avatar image  ' style={{height : "12px", width: "5px",background: "#EFEFEF", border:"none"}} src={dashIcon}/></MenuItem> */}
                                                    <MenuItem value={3}> -- </MenuItem>
                                                    {/* <MenuItem value={4}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}}src={blueEllipseIcon}/></MenuItem> */}
                                                    {/* <MenuItem value={5}><img  className='ui avatar image  ' style={{height : "16px", width: "16px",background: "#EFEFEF", border:"none"}} src={blueEllipseIcon}/></MenuItem> */}
                                                  </Select>
                                                </FormControl>
            </div>     
            <div className="mb-3">
                <label htmlFor="date-input" className="form-label">supplier</label>
                <input  type="text" id="date-input" className="form-control"
                    defaultValue={supplier}
                  onBlur={(e)=>{
                    setSupplier(e.target.value)                  
                }}
                ></input>
            </div>  
            <div className="mb-3">
                <label htmlFor="date-input" className="form-label">Item Name for Proposal</label>
                <input  type="text" id="date-input" className="form-control"
                    defaultValue={itemNameForProposal}
                  onBlur={(e)=>{
                    setitemNameForProposal(e.target.value)                  
                }}
                ></input>
            </div>  

            <div className="mb-3">
                <label htmlFor="date-input" className="form-label">Date</label>
                <input  type="date" id="date-input" className="form-control" 
                onBlur={(e)=>{
                    setDate(e.target.value)                  
                }}
                ></input>
            </div> 
           
            <hr/>
            <div className="form-check">
            <label className="form-check-label" htmlFor="oneTimeUse">
                One Time Use
              </label>
              <input className="form-check-input" type="checkbox"id="oneTimeUse" onChange={(event)=>{
                setIsOneTimeUseChecked(event.target.checked)
              }}/>
              
            </div>
           
          </div>
         
          <div className='d-flex justify-content-between'>
          <button
            className="btn btn-primary"
            disabled={isError}
            onClick = {(e)=>{
                        e.preventDefault()
                        if(!isOneTimeUseChecked){
                          dispatch(createAddUp({pricingListData:{name :name , type : type, one_time_use:isOneTimeUseChecked,specialism : specialism, buy_unit_price : buyUnitPrice, sell_unit_price : sellUnitPrice, uom : uom, margin : margin, date : date ,item_type : itemType, cost_code : costCode, cost_type : costType, wastage_percentage : wastagePercentage, suppiler:supplier ,item_name_for_proposal : itemNameForProposal.trim() ===""? name : itemNameForProposal  }}))  
                        }
                        else if(type === billOfQuantities.TYPES.LABOUR && isOneTimeUseChecked){
                          dispatch(createItem({item : { name : name, type : type , sectionId : pricing.pricingListModalData.sectionId, specification : pricing.pricingListModalData.labourSpecification , buy_unit_price : buyUnitPrice , sell_unit_price : sellUnitPrice, uom : uom, margin : margin,item_type : itemType , cost_code : costCode, cost_type : costType, wastage_percentage : wastagePercentage, suppiler:supplier, item_name_for_proposal : itemNameForProposal.trim() ===""? name : itemNameForProposal   }}))
                        }
                        else if (type === billOfQuantities.TYPES.ITEM && pricing.pricingListModalData.itemSpecification === billOfQuantities.TYPES.SPECIFIED  && isOneTimeUseChecked){
                            dispatch(createItem({item : { name : name, type : type , sectionId : pricing.pricingListModalData.sectionId ,labourId : pricing.pricingListModalData.labourId, specification : pricing.pricingListModalData.itemSpecification, buy_unit_price : buyUnitPrice, sell_unit_price : sellUnitPrice, uom : uom, margin : margin,item_type : itemType, cost_code : costCode, cost_type : costType, wastage_percentage : wastagePercentage, suppiler:supplier,item_name_for_proposal : itemNameForProposal.trim() ===""? name : itemNameForProposal  }}))
                            
                        }
                        else if (type === billOfQuantities.TYPES.ITEM && pricing.pricingListModalData.itemSpecification === billOfQuantities.TYPES.UNSPECIFIED  && isOneTimeUseChecked){
                            dispatch(createItem({item : { name : name, type : type , sectionId : pricing.pricingListModalData.sectionId, specification : pricing.pricingListModalData.itemSpecification, buy_unit_price : buyUnitPrice, sell_unit_price : sellUnitPrice, uom : uom.uom, margin : margin,item_type : itemType , cost_code : costCode, cost_type : costType, wastage_percentage : wastagePercentage, suppiler:supplier ,item_name_for_proposal : itemNameForProposal.trim() ===""? name : itemNameForProposal  }}))
                            
                        }
                        // console.log("submit", name , type)
                    }}
          >
            {pricingListRequestLoading ?
                        <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span>Saving...</span></>
                    :"Save"}
            
          </button>
          <button
            className="btn btn-danger"
            onClick={(e) => {
              e.preventDefault()
            dispatch(changeAddUpModalStatus())
            }}
            
        >
        Close
      </button>
      </div>
        </form>
      </div>
    </Modal.Body>
    {/* <Modal.Footer className="d-flex justify-content-center">
      <i>Haven't Sign up yet?</i>
      <i className="btn btn-warning">Sign up</i>
    </Modal.Footer> */}
  </Modal></div>
  )
}

export default PricingListPopupModel