import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ViewContacts from '../../components/viewContacts/ViewContacts'
import { changeCreateContactModalStatus, getContacts } from '../../features/ContactSlice'
import {ReactComponent as SearchIcon} from '../../assets/icons/icon-search.svg'
import './Contact.css'
import CreateContactPopupModal from '../../components/createContactPopupModal/createContactPopupModal'
const Contact = () => {
  const contactRequestData = useSelector((state)=>state.contact.contactRequestData)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getContacts())
  
   
  }, [])
  
  return (
    <div className='contact-container'>
        {/* <div className='contact-inner-top-container d-flex justify-content-center'>
                <h3>Contacts</h3>
        </div> */}
        <div className='contact-breadcrumb-container d-flex justify-content-between align-items-center'>
            <nav aria-label="breadcrumb" >
              <ol className="breadcrumb d-inline-flex">
                <li className="breadcrumb-item active"><a >Contacts</a></li>
                {/* <li className="breadcrumb-item active"><a >Project address</a></li>
                <li className="breadcrumb-item " aria-current="page">Estimate</li> */}
              </ol>
            </nav>
          <div className='d-inline-flex flex-row'>
            <div  className='d-inline-flex flex-row  h-60 '>
                {/* <img className="btn btn-ligh pricing-top-icon" src={redoIcon}/>
                <img className="btn btn-ligh pricing-top-icon" src={undoIcon}/>
                <img className="btn btn-ligh pricing-top-icon" src={printIcon}/>
                <img className="btn btn-ligh pricing-top-icon" src={exportIcon}/> */}
                 <div className='btn btn-outline-dark contact-top-icon '  onClick={()=>{dispatch(changeCreateContactModalStatus())}}>Add </div>
                <div  className='btn btn-outline-dark contact-top-icon'>Import  </div>
            </div>

            <div className='pricing-search-box'>
                <input className="pricing-search-box-input" type="search" placeholder="keyword Search" aria-label="Search"/>
                {/* <SearchIcon/> */}
                <button><SearchIcon/></button>
              {/* <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
              </div>
            </div>
          </div>
        {/* <div className='d-flex justify-content-around'>
            <button>Add </button>
            <button>Import  </button>
        </div> */}
        <ViewContacts/>
        <CreateContactPopupModal/>

    </div>
  )
}

export default Contact