export const packFilesToFormData = (files, projectId) => {
    const data = new FormData();

    [...files].forEach((file, i) => {
      data.append('files', file.file)
      // data.append('name', file.documentName)
      data.append('file_types', file.documentType)
      data.append('file_statuses', file.documentStatus)

      // data.append('files', {
      //   'file': file.file,
      //   'name': file.documentName,
      //   'type': file.documentType,
      //   'status': file.documentStatus,
      //   'project': formValues.PROJECT_NAME,
      // })

 
    })
    data.append('_id', projectId)


    // data.append('files', fileData)
    return data
  }